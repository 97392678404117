import { Component, OnInit, Input } from '@angular/core';
import { EquipmentService } from '../equipment.service';
import { Equipment } from '../equipment.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-equipment-details',
  templateUrl: './equipment-details.component.html',
  styleUrls: ['./equipment-details.component.scss']
})
export class EquipmentDetailsComponent implements OnInit {

  @Input() equipment: Equipment;
 
  constructor(
    private router: Router,
    private equipmentService: EquipmentService) { }
 
  ngOnInit() {
  }

  inactivate() {
    this.equipmentService
      .update(this.equipment.id, { active: false })
      .catch(err => console.log(err));
  }

  edit() {
    window.localStorage.removeItem("editEquipmentObj");
    localStorage.setItem('editEquipmentObj', JSON.stringify(this.equipment));
    this.router.navigate(['equipment-update']);
  }

}
