<h1 mat-dialog-title>Alterar nome</h1>
<div mat-dialog-content>
  <mat-form-field>
    <mat-label>Nome</mat-label>
    <input matInput [(ngModel)]="data.name">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancelar</button>
  <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Ok</button>
</div>