<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="box">

    <mat-card-header>
      <mat-card-title>Atualizar card</mat-card-title>
    </mat-card-header>

    <form [formGroup]="form" #myForm="ngForm" (ngSubmit)="onSubmit()" class="example-form">

      <mat-card-content>
        <mat-form-field class="example-full-width">
          <input matInput type="text" placeholder="Nome" [(ngModel)]="card.name" formControlName="name" required>
          <mat-error *ngIf="isFieldInvalid('name')">
            Informe o nome do card
          </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width" style="width: 80%; margin-right: 20px;">
          <input matInput type="text" placeholder="Cor" [(ngModel)]="card.color" formControlName="color" required>
          <mat-error *ngIf="isFieldInvalid('color')">
            Informe a cor
          </mat-error>
        </mat-form-field>
        <button class="color" mat-mini-fab [style.background-color]="'#' + card.color" type="button"
          (click)="changeState()">
          <mat-icon>colorize</mat-icon>
        </button>
        <div class="example-full-width" fxLayoutAlign="end">
          <color-github *ngIf="displayColor" [color]="'#' + card.color" (onChange)="changeComplete($event)">
          </color-github>
        </div>

        <mat-form-field class="example-full-width">
          <mat-label>Posição do Card</mat-label>
          <mat-select [(ngModel)]="card.number" [(value)]="card.number" formControlName="number">
            <mat-option *ngFor="let card of cards" [value]="card.value">
              {{card.viewValue}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="isFieldInvalid('number')">
            Informe a posição do card
          </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <mat-label>Classe de tempo</mat-label>
          <mat-select [(ngModel)]="card.subClass" (valueChange)="setClass($event)" [(value)]="card.subClass"
            formControlName="subClass">
            <mat-option [ngStyle]="{'backgroundColor': getColor(time.timeClass)}" *ngFor="let time of times"
              [value]="time.subClass">
              {{time.label}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="isFieldInvalid('subClass')">
            Informe a classe de tempo
          </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <mat-label>Frota</mat-label>
          <mat-select [(value)]="card.fleet" formControlName="fleet" [(ngModel)]="card.fleet" required>
            <mat-option *ngFor="let fleet of fleets" [value]="fleet.id">
              {{fleet.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="isFieldInvalid('fleet')">
            Informe a frota
          </mat-error>
        </mat-form-field>

      </mat-card-content>

      <button mat-raised-button color="primary" type="submit" class="btn-block">Alterar</button>
    </form>
  </mat-card>
</div>