<form [formGroup]="form" #myForm="ngForm" (ngSubmit)="onSubmit()" class="example-form">

    <table style="width:100%">
        <td>
            <div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
                <mat-card class="box">

                    <mat-card-header>
                        <mat-card-title>Configurações</mat-card-title>
                    </mat-card-header>

                    <mat-card-content>

                        <!-- FROTA -->
                        <mat-form-field class="example-full-width">
                            <mat-label>Frota</mat-label>
                            <mat-select [(value)]="chart.fleet_name" [(ngModel)]="chart.fleet_name"
                                formControlName="fleet" (selectionChange)="onFleetChange($event.value)">
                                <mat-option [value]="'all'">Todas</mat-option>
                                <mat-option *ngFor="let fleet of fleets" [value]="fleet.name">
                                    {{fleet.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <!-- EQUIPAMENTO -->
                        <mat-form-field class="example-full-width" *ngIf="isProd">
                            <mat-label>Equipamento</mat-label>
                            <mat-select [(value)]="chart.equipment_name" [(ngModel)]="chart.equipment_name"
                                formControlName="equipment" (selectionChange)="onEquipmentChange($event.value)">
                                <mat-option [value]="'all'">Todos</mat-option>
                                <mat-option *ngFor="let equipment of equipments" [value]="equipment.name">
                                    {{equipment.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <!-- MATERIAIS -->
                        <mat-form-field class="example-full-width" *ngIf="isProd">
                            <mat-label>Material</mat-label>
                            <mat-select [(value)]="chart.material_name" [(ngModel)]="chart.material_name"
                                formControlName="material" (selectionChange)="onMaterialChange($event.value)">
                                <mat-option [value]="'all'">Todos</mat-option>
                                <mat-option *ngFor="let material of materials" [value]="material.name">
                                    {{material.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <!-- UNIDADE -->
                        <mat-form-field class="example-full-width" *ngIf="isProd">
                            <mat-label>Unidade</mat-label>
                            <mat-select [(value)]="chart.unit" [(ngModel)]="chart.unit" formControlName="unit">
                                <mat-option *ngFor="let unit of units" [value]="unit.value">
                                    {{unit.label}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="isFieldInvalid('unit')">
                                Informe a unidade
                            </mat-error>
                        </mat-form-field>

                        <!-- AGREGADOR -->
                        <mat-slide-toggle formControlName="tripPeriod" [(ngModel)]="chart.isPeriodChecked">
                            
                        </mat-slide-toggle>
                        <div class="break">Agregar filtros pela
                            data de {{chart.isPeriodChecked ? "início" : "término"}}
                            da viagem</div>
                            
                        <!-- INTERVALO -->
                        <mat-form-field class="example-full-width">
                            <mat-label>Período</mat-label>
                            <mat-select [(value)]="chart.interval" [(ngModel)]="chart.interval"
                                formControlName="interval" (selectionChange)="onIntervalChange(chart.interval)"
                                required>
                                <mat-option *ngFor="let interval of intervals" [value]="interval.value">
                                    {{interval.label}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="isFieldInvalid('interval')">
                                Informe o período
                            </mat-error>
                        </mat-form-field>

                        <!-- MEDIA MOVEL -->
                        <mat-form-field *ngIf="isMovel" class="example-full-width">
                            <input matInput type="number" placeholder="Dias" [(ngModel)]="chart.movel"
                                formControlName="movel">
                        </mat-form-field>

                        <mat-form-field *ngIf="isMovel" class="example-full-width">
                            <input matInput type="number" placeholder="Intervalo (dias)" [(ngModel)]="chart.movel_days"
                                formControlName="movel_days">
                        </mat-form-field>

                        <mat-form-field *ngIf="isPersonalized" class="example-full-width">
                            <input matInput [max]="max" [owlDateTime]="dt1" [selectMode]="'range'"
                                [owlDateTimeTrigger]="dt1" placeholder="Início - Fim" [(ngModel)]="chart.interval_date"
                                formControlName="interval_date">
                            <owl-date-time #dt1></owl-date-time>
                        </mat-form-field>

                        <mat-card-header>
                            <mat-card-title>Meta</mat-card-title>
                        </mat-card-header>

                        <table *ngIf="isHourly">
                            <td>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="01:00" [(ngModel)]="chart.meta[0]"
                                        formControlName="h1" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="02:00" [(ngModel)]="chart.meta[1]"
                                        formControlName="h2" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="03:00" [(ngModel)]="chart.meta[2]"
                                        formControlName="h3" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="04:00" [(ngModel)]="chart.meta[3]"
                                        formControlName="h4" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="05:00" [(ngModel)]="chart.meta[4]"
                                        formControlName="h5" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="06:00" [(ngModel)]="chart.meta[5]"
                                        formControlName="h6" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="07:00" [(ngModel)]="chart.meta[6]"
                                        formControlName="h7" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="08:00" [(ngModel)]="chart.meta[7]"
                                        formControlName="h8" required>
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="09:00" [(ngModel)]="chart.meta[8]"
                                        formControlName="h9" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="10:00" [(ngModel)]="chart.meta[9]"
                                        formControlName="h10" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="11:00" [(ngModel)]="chart.meta[10]"
                                        formControlName="h11" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="12:00" [(ngModel)]="chart.meta[11]"
                                        formControlName="h12" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="13:00" [(ngModel)]="chart.meta[12]"
                                        formControlName="h13" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="14:00" [(ngModel)]="chart.meta[13]"
                                        formControlName="h14" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="15:00" [(ngModel)]="chart.meta[14]"
                                        formControlName="h15" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="16:00" [(ngModel)]="chart.meta[15]"
                                        formControlName="h16" required>
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="17:00" [(ngModel)]="chart.meta[16]"
                                        formControlName="h17" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="18:00" [(ngModel)]="chart.meta[17]"
                                        formControlName="h18" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="19:00" [(ngModel)]="chart.meta[18]"
                                        formControlName="h19" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="20:00" [(ngModel)]="chart.meta[19]"
                                        formControlName="h20" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="21:00" [(ngModel)]="chart.meta[20]"
                                        formControlName="h21" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="22:00" [(ngModel)]="chart.meta[21]"
                                        formControlName="h22" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="23:00" [(ngModel)]="chart.meta[22]"
                                        formControlName="h23" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="24:00" [(ngModel)]="chart.meta[23]"
                                        formControlName="h24" required>
                                </mat-form-field>
                            </td>
                        </table>

                        <table *ngIf="isMovel">
                            <td>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="1" [(ngModel)]="chart.meta[0]"
                                        formControlName="h1" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="2" [(ngModel)]="chart.meta[1]"
                                        formControlName="h2" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="3" [(ngModel)]="chart.meta[2]"
                                        formControlName="h3" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="4" [(ngModel)]="chart.meta[3]"
                                        formControlName="h4" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="5" [(ngModel)]="chart.meta[4]"
                                        formControlName="h5" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="6" [(ngModel)]="chart.meta[5]"
                                        formControlName="h6" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="7" [(ngModel)]="chart.meta[6]"
                                        formControlName="h7" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="8" [(ngModel)]="chart.meta[7]"
                                        formControlName="h8" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="9" [(ngModel)]="chart.meta[8]"
                                        formControlName="h9" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="10" [(ngModel)]="chart.meta[9]"
                                        formControlName="h10" required>
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="11" [(ngModel)]="chart.meta[10]"
                                        formControlName="h11" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="12" [(ngModel)]="chart.meta[11]"
                                        formControlName="h12" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="13" [(ngModel)]="chart.meta[12]"
                                        formControlName="h13" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="14" [(ngModel)]="chart.meta[13]"
                                        formControlName="h14" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="15" [(ngModel)]="chart.meta[14]"
                                        formControlName="h15" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="16" [(ngModel)]="chart.meta[15]"
                                        formControlName="h16" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="17" [(ngModel)]="chart.meta[16]"
                                        formControlName="h17" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="18" [(ngModel)]="chart.meta[17]"
                                        formControlName="h18" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="19" [(ngModel)]="chart.meta[18]"
                                        formControlName="h19" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="20" [(ngModel)]="chart.meta[19]"
                                        formControlName="h20" required>
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="21" [(ngModel)]="chart.meta[20]"
                                        formControlName="h21" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="22" [(ngModel)]="chart.meta[21]"
                                        formControlName="h22" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="23" [(ngModel)]="chart.meta[22]"
                                        formControlName="h23" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="24" [(ngModel)]="chart.meta[23]"
                                        formControlName="h24" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="25" [(ngModel)]="chart.meta[24]"
                                        formControlName="h25" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="26" [(ngModel)]="chart.meta[25]"
                                        formControlName="h26" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="27" [(ngModel)]="chart.meta[26]"
                                        formControlName="h27" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="28" [(ngModel)]="chart.meta[27]"
                                        formControlName="h28" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="29" [(ngModel)]="chart.meta[28]"
                                        formControlName="h29" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="30" [(ngModel)]="chart.meta[29]"
                                        formControlName="h30" required>
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="31" [(ngModel)]="chart.meta[30]"
                                        formControlName="h31" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="32" [(ngModel)]="chart.meta[31]"
                                        formControlName="h32" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="33" [(ngModel)]="chart.meta[32]"
                                        formControlName="h33" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="34" [(ngModel)]="chart.meta[33]"
                                        formControlName="h34" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="35" [(ngModel)]="chart.meta[34]"
                                        formControlName="h35" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="36" [(ngModel)]="chart.meta[35]"
                                        formControlName="h36" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="37" [(ngModel)]="chart.meta[36]"
                                        formControlName="h37" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="38" [(ngModel)]="chart.meta[37]"
                                        formControlName="h38" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="39" [(ngModel)]="chart.meta[38]"
                                        formControlName="h39" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="40" [(ngModel)]="chart.meta[39]"
                                        formControlName="h40" required>
                                </mat-form-field>
                            </td>
                        </table>

                        <table *ngIf="isDaily">
                            <td>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="1" [(ngModel)]="chart.meta[0]"
                                        formControlName="h1" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="2" [(ngModel)]="chart.meta[1]"
                                        formControlName="h2" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="3" [(ngModel)]="chart.meta[2]"
                                        formControlName="h3" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="4" [(ngModel)]="chart.meta[3]"
                                        formControlName="h4" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="5" [(ngModel)]="chart.meta[4]"
                                        formControlName="h5" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="6" [(ngModel)]="chart.meta[5]"
                                        formControlName="h6" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="7" [(ngModel)]="chart.meta[6]"
                                        formControlName="h7" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="8" [(ngModel)]="chart.meta[7]"
                                        formControlName="h8" required>
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="9" [(ngModel)]="chart.meta[8]"
                                        formControlName="h9" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="10" [(ngModel)]="chart.meta[9]"
                                        formControlName="h10" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="11" [(ngModel)]="chart.meta[10]"
                                        formControlName="h11" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="12" [(ngModel)]="chart.meta[11]"
                                        formControlName="h12" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="13" [(ngModel)]="chart.meta[12]"
                                        formControlName="h13" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="14" [(ngModel)]="chart.meta[13]"
                                        formControlName="h14" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="15" [(ngModel)]="chart.meta[14]"
                                        formControlName="h15" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="16" [(ngModel)]="chart.meta[15]"
                                        formControlName="h16" required>
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="17" [(ngModel)]="chart.meta[16]"
                                        formControlName="h17" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="18" [(ngModel)]="chart.meta[17]"
                                        formControlName="h18" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="19" [(ngModel)]="chart.meta[18]"
                                        formControlName="h19" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="20" [(ngModel)]="chart.meta[19]"
                                        formControlName="h20" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="21" [(ngModel)]="chart.meta[20]"
                                        formControlName="h21" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="22" [(ngModel)]="chart.meta[21]"
                                        formControlName="h22" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="23" [(ngModel)]="chart.meta[22]"
                                        formControlName="h23" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="24" [(ngModel)]="chart.meta[23]"
                                        formControlName="h24" required>
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="25" [(ngModel)]="chart.meta[24]"
                                        formControlName="h25" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="26" [(ngModel)]="chart.meta[25]"
                                        formControlName="h26" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="27" [(ngModel)]="chart.meta[26]"
                                        formControlName="h27" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="28" [(ngModel)]="chart.meta[27]"
                                        formControlName="h28" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="29" [(ngModel)]="chart.meta[28]"
                                        formControlName="h29" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="30" [(ngModel)]="chart.meta[29]"
                                        formControlName="h30" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="31" [(ngModel)]="chart.meta[30]"
                                        formControlName="h31" required>
                                </mat-form-field>
                            </td>
                        </table>

                        <table *ngIf="isWeekly">
                            <td>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="Domingo" [(ngModel)]="chart.meta[0]"
                                        formControlName="h1" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="Segunda" [(ngModel)]="chart.meta[1]"
                                        formControlName="h2" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="Terça" [(ngModel)]="chart.meta[2]"
                                        formControlName="h3" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="Quarta" [(ngModel)]="chart.meta[3]"
                                        formControlName="h4" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="Quinta" [(ngModel)]="chart.meta[4]"
                                        formControlName="h9" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="Sexta" [(ngModel)]="chart.meta[5]"
                                        formControlName="h10" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="Sábado" [(ngModel)]="chart.meta[6]"
                                        formControlName="h11" required>
                                </mat-form-field>
                            </td>
                        </table>

                        <table *ngIf="isMonthly">
                            <td>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="Janeiro" [(ngModel)]="chart.meta[0]"
                                        formControlName="h1" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="Fevereiro" [(ngModel)]="chart.meta[1]"
                                        formControlName="h2" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="Março" [(ngModel)]="chart.meta[2]"
                                        formControlName="h3" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="Abril" [(ngModel)]="chart.meta[3]"
                                        formControlName="h4" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="Maio" [(ngModel)]="chart.meta[4]"
                                        formControlName="h5" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="Junho" [(ngModel)]="chart.meta[5]"
                                        formControlName="h6" required>
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="Julho" [(ngModel)]="chart.meta[6]"
                                        formControlName="h9" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="Agosto" [(ngModel)]="chart.meta[7]"
                                        formControlName="h10" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="Setembro" [(ngModel)]="chart.meta[8]"
                                        formControlName="h11" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="Outubro" [(ngModel)]="chart.meta[9]"
                                        formControlName="h12" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="Novembro" [(ngModel)]="chart.meta[10]"
                                        formControlName="h13" required>
                                </mat-form-field>
                                <mat-form-field class="example-less-width">
                                    <input matInput type="number" placeholder="Dezembro" [(ngModel)]="chart.meta[11]"
                                        formControlName="h14" required>
                                </mat-form-field>
                            </td>
                        </table>
                    </mat-card-content>
                </mat-card>
            </div>
        </td>
        <td>
            <div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
                <mat-card class="box">

                    <mat-card-header>
                        <mat-card-title>Configurações</mat-card-title>
                    </mat-card-header>

                    <mat-card-content>

                        <!-- TÍTULO -->
                        <mat-checkbox [(ngModel)]="chart.use_title" formControlName="use_title">Título padrão
                        </mat-checkbox>

                        <mat-form-field class="example-full-width">
                            <input matInput [attr.disabled]="chart.use_title? true : null" type="text"
                                placeholder="Titulo" [(ngModel)]="chart.title" formControlName="title">
                        </mat-form-field>

                        <!-- COR DA META -->
                        <mat-form-field class="example-full-width" style="width: 70%; margin-right: 20px;">
                            <input matInput type="text" placeholder="Cor da meta" [(ngModel)]="chart.above_goal_color"
                                formControlName="meta_color" required>
                            <mat-error *ngIf="isFieldInvalid('meta_color')">
                                Informe a cor
                            </mat-error>
                        </mat-form-field>
                        <button class="color" mat-mini-fab [style.background-color]="'#' + chart.above_goal_color"
                            type="button" (click)="changeState(1)">
                            <mat-icon>colorize</mat-icon>
                        </button>
                        <div class="example-full-width" fxLayoutAlign="end">
                            <color-github *ngIf="displayMetaColor" [color]="'#' + chart.above_goal_color"
                                (onChange)="changeComplete($event, 1)">
                            </color-github>
                        </div>

                        <!-- COR PRINCIPAL -->
                        <mat-form-field class="example-full-width" style="width: 70%; margin-right: 20px;">
                            <input matInput type="text" placeholder="Cor principal"
                                [(ngModel)]="chart.within_goal_color" formControlName="main_color" required>
                            <mat-error *ngIf="isFieldInvalid('main_color')">
                                Informe a cor
                            </mat-error>
                        </mat-form-field>
                        <button class="color" mat-mini-fab [style.background-color]="'#' + chart.within_goal_color"
                            type="button" (click)="changeState(2)">
                            <mat-icon>colorize</mat-icon>
                        </button>
                        <div class="example-full-width" fxLayoutAlign="end">
                            <color-github *ngIf="displayMainColor" [color]="'#' + chart.within_goal_color"
                                (onChange)="changeComplete($event, 2)">
                            </color-github>
                        </div>

                        <button mat-raised-button color="yellow" type="button" (click)="setExample()"
                            class="btn-block">Visualizar</button>
                        <button mat-raised-button color="primary" type="submit" class="btn-block">Salvar</button>

                    </mat-card-content>
                </mat-card>
            </div>
        </td>
        <td>
            <mat-card class="example">
                <mat-card-content>
                    <div class="chart" [chart]="example"></div>
                </mat-card-content>
            </mat-card>
        </td>
    </table>
</form>