import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Equipment } from './equipment.model';
import { Location } from './location.model';

@Injectable({
  providedIn: 'root'
})
export class EquipmentService {
  private dbPath = '/equipments';
  private locationPath = '/locations';
  client: string;

  collection: AngularFirestoreCollection<Equipment> = null;

  constructor(private db: AngularFirestore) {
    this.client = JSON.parse(localStorage.getItem('documentData')).client_id;
    this.collection = db.collection('clients').doc(this.client).collection(this.dbPath, ref => ref.where('active', '==', true));
  }

  create(customer: Equipment): void {
    this.collection.add({ ...customer });
  }

  update(key: string, value: any): Promise<void> {
    return this.collection.doc(key).update(value);
  }

  delete(key: string): Promise<void> {
    return this.collection.doc(key).delete();
  }

  getList(): AngularFirestoreCollection<Equipment> {
    return this.collection;
  }

  getListBy(fleet: string): AngularFirestoreCollection<Equipment> {
    console.log(fleet);

    if (fleet == "all")
      return this.collection
    else
      return this.db.collection('clients').doc(this.client).collection(this.dbPath, ref => ref
        .where('fleet', '==', fleet))
  }

  getListByTime(start: Date, end: Date, uid: string): AngularFirestoreCollection<Location> {
    return this.db.collection('clients').doc(this.client).collection(this.dbPath)
      .doc(uid).collection(this.locationPath, ref => ref
        .where('date', '>=', start)
        .where('date', '<=', end)
        .orderBy('date', 'desc')
      );
  }

  getEquipment(key: string): AngularFirestoreDocument<Equipment> {
    return this.collection.doc(key);
  }

  deleteAll() {
    this.collection.get().subscribe(
      querySnapshot => {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
        });
      },
      error => {
        console.log('Error: ', error);
      });
  }
}
