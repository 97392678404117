import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../trips-list/trips-list.component';

@Component({
    selector: 'app-trip-dialog',
    templateUrl: './trip-dialog.component.html',
    styleUrls: ['./trip-dialog.component.scss']
})
export class TripDialogComponent {

    map: any;

    constructor(
        public dialogRef: MatDialogRef<TripDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {
        console.log(data.locations);

    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    mapReady(map) {
        this.map = map;
        this.centerMap();
    }

    private centerMap() {

        var bounds = new google.maps.LatLngBounds();

        this.data.locations.forEach(point =>
            bounds.extend(new google.maps.LatLng(point.position.latitude, point.position.longitude)))

        this.map.setCenter(bounds.getCenter());
        this.map.fitBounds(bounds);
    }
}