import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Profile } from 'src/app/profiles/profile.model';
import { User } from 'src/app/shared/models/user.interface';
import { UserService } from '../user.service';

@Component({
  selector: 'app-user-profile-item',
  templateUrl: './user-profile-item.component.html',
  styleUrls: ['./user-profile-item.component.scss']
})
export class UserProfileItemComponent implements OnInit {

  @Input() user: User;
  @Input() profiles: Profile[];

  constructor(
    private router: Router,
    private userService: UserService) { }

  ngOnInit() {
  }

  edit() {
    window.localStorage.removeItem("editUserObj");
    localStorage.setItem('editUserObj', JSON.stringify(this.user));
    this.router.navigate(['user-create']);
  }

  onChange(){
    this.userService.update(this.user.uid, this.user)
  }
}
