import { Injectable } from "@angular/core";
import { Chart } from "angular-highcharts";
import { map } from "rxjs/internal/operators/map";
import { MinutesService } from "src/services/minutes.service";
import { SubClass } from "../cards/sub-class.enum";
import { TimeClass } from "../cards/time-class.enum";
import { Board } from "../dashboard/board.model";
import { ChartDash } from "../dashboard/chart.model";
import { Unit } from "../dashboard/unit.enum";
import { RecordService } from "../records/record.service";
import { Shift } from "../shifts/shift.model";
import { ShiftService } from "../shifts/shift.service";
import { Dashboard } from "./dashboard.enum";
import { Format } from "./format.enum";
import { Interval } from "./interval.enum";

@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    dateBreak: number;
    today: Date;
    shifts: Shift[]
    oneDay: number = 24 * 60 * 60 * 1000;;
    oneHour: number = 60 * 60 * 1000;

    constructor(
        private minutesService: MinutesService,
        private shiftService: ShiftService,
        private recordService: RecordService
    ) {
        this.shiftService.getClient().valueChanges().subscribe(client => this.dateBreak = client.dateBreak * 60);
        this.today = new Date()
        this.getShifts()
    }

    monthDiff(dateFrom: Date, dateTo: Date) {
        return dateTo.getMonth() - dateFrom.getMonth() +
            (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
    }

    getShifts() {
        let shiftsCallback = this.shiftService.getList().snapshotChanges().pipe(
            map(changes =>
                changes.map(c =>
                    ({ id: c.payload.doc.id, ...c.payload.doc.data() })
                )
            )
        ).subscribe(shifts => {
            shiftsCallback.unsubscribe();
            this.shifts = shifts;
        })
    }

    getFleetChart(chart: ChartDash, data: [[string, number], [string, number]]): Chart {
        return new Chart({
            title: {
                text: this.getTitle(chart)
            },
            chart: {
                type: 'pie'
            },
            plotOptions: {
                series: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false,
                    }
                }
            },
            tooltip: {
                valueSuffix: '%'
            },
            series: [{
                showInLegend: false,
                name: this.getTitle(chart),
                type: undefined,
                cursor: 'pointer',
                colors: [this.getColor(chart, data[0][1]), '#a0a0a0'],
                data: data
            }]
        });
    }

    getBarChart(chart: ChartDash, data, intervalStart: number, meta): Chart {
        return new Chart({
            title: {
                text: this.getTitle(chart)
            },
            chart: {
                type: 'column'
            },
            xAxis: {
                categories: this.getCategories(chart, intervalStart)
            },
            plotOptions: {
                series: {
                    allowPointSelect: true
                }
            },
            series: [{
                showInLegend: false,
                color: "#" + chart.within_goal_color,
                name: "",
                type: undefined,
                data: data
            },
            {
                name: 'Meta',
                showInLegend: false,
                type: 'spline',
                color: "#" + chart.above_goal_color,
                data: meta,
                marker: {
                    enabled: false
                },
                dashStyle: "ShortDash",
                tooltip: {
                    valueSuffix: ''
                }
            }
            ]
        });
    }

    getCategories(chart: ChartDash, intervalStart: number) {
        var categories = new Array();
        let week = ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"]
        let month = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
            "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"]
        let movel: number[]
        let max: number;

        if (chart.interval == Interval.SEMANA_ANTERIOR || chart.interval == Interval.SEMANA_ATUAL)
            max = 7;
        else if (chart.interval == Interval.DIA_ATUAL || chart.interval == Interval.DIA_ANTERIOR ||
            chart.interval == Interval.TURNO_ATUAL || chart.interval == Interval.TURNO_ANTERIOR)
            max = 24;
        else if (chart.interval == Interval.MEDIA_MOVEL) {
            movel = new Array(chart.movel_days)
            let today = new Date()

            for (let d = chart.movel_days; d > 0; d--) {
                movel[d] = today.getDate()
                today.setDate(today.getDate() - 1)
            }

            intervalStart = 1
            max = chart.movel_days;
        }
        else max = 31

        for (let x = intervalStart, y = intervalStart, z = 0; x <= intervalStart + max; x++, y++, z++) {
            if (chart.interval == Interval.DIA_ATUAL || chart.interval == Interval.DIA_ANTERIOR ||
                chart.interval == Interval.TURNO_ATUAL || chart.interval == Interval.TURNO_ANTERIOR)
                if (y > 24) y = 0

            if (chart.interval == Interval.SEMANA_ATUAL || chart.interval == Interval.SEMANA_ANTERIOR)
                categories.push(week[z])
            else if (chart.interval == Interval.ANO_ATUAL || chart.interval == Interval.ANO_ANTERIOR)
                categories.push(month[z])
            else if (chart.interval == Interval.MEDIA_MOVEL)
                categories.push(movel[x])
            else
                categories.push(y)
        }

        return categories;
    }

    getMeta(chart: ChartDash, days: number, intervalStart: number) {
        let meta = chart.meta;

        var metas = new Array();

        if (chart.interval == Interval.SEMANA_ANTERIOR || chart.interval == Interval.SEMANA_ATUAL)
            intervalStart = 0

        else if (chart.interval == Interval.DIA_ATUAL || chart.interval == Interval.DIA_ANTERIOR ||
            chart.interval == Interval.TURNO_ATUAL || chart.interval == Interval.TURNO_ANTERIOR)
            intervalStart--

        for (let x = intervalStart, y = intervalStart; x < intervalStart + days; x++, y++) {

            if (chart.interval == Interval.DIA_ATUAL || chart.interval == Interval.DIA_ANTERIOR ||
                chart.interval == Interval.TURNO_ATUAL || chart.interval == Interval.TURNO_ANTERIOR) {

                if (y > 24) y = 0
                metas.push(meta[y])
            } else
                metas.push(meta[x])
        }

        return metas;
    }

    getTitle(chart: ChartDash): string {
        let title: string;
        let interval: string;
        let fleet: string;
        let unit: string = '';
        let material: string = '';

        switch (chart.interval) {
            case Interval.TURNO_ATUAL: interval = 'Turno atual'; break;
            case Interval.DIA_ATUAL: interval = 'Dia atual'; break;
            case Interval.SEMANA_ATUAL: interval = 'Semana atual'; break;
            case Interval.MES_ATUAL: interval = 'Mês atual'; break;
            case Interval.ANO_ATUAL: interval = 'Ano atual'; break;
            case Interval.MEDIA_MOVEL:
                interval = 'Media Movel - ' + chart.movel + ' dias';
                break;
            case Interval.TURNO_ANTERIOR: interval = 'Turno anterior'; break;
            case Interval.DIA_ANTERIOR: interval = 'Dia anterior'; break;
            case Interval.SEMANA_ANTERIOR: interval = 'Semana anterior'; break;
            case Interval.MES_ANTERIOR: interval = 'Mês anterior'; break;
            case Interval.ANO_ANTERIOR: interval = 'Ano anterior'; break;
            case Interval.PERSONALIZADO:
                interval = chart.start_time.toLocaleString() + ' - ' + chart.end_time.toLocaleString();
                break;
        }

        if (Dashboard[chart.type] === Dashboard.PROD) {
            unit = ' - '
            switch (chart.unit) {
                case Unit.VIAGENS: unit += 'Viagens'; break;
                case Unit.KT: unit += 'Kt (métricas)'; break;
                case Unit.T: unit += 't (métricas)'; break;
                case Unit.KTON: unit += 'kton (curtas)'; break;
                case Unit.TON: unit += 'ton (curtas)'; break;
                case Unit.KG: unit += 'kg'; break;
            }

            if (chart.material_name)
                material = ' - ' + chart.material_name
        }

        fleet = chart.fleet_name ? ' - Frota ' + chart.fleet_name : ''

        title = chart.use_title ? chart.default_title + fleet + ' - ' + interval + unit + material : chart.title;
        return title;
    }

    getColor(chart: ChartDash, number: number): string {
        let color: string;

        if (number < chart.goal - chart.negative_tolerance)
            color = chart.below_goal_color;
        else if (number > chart.goal + chart.positive_tolerance)
            color = chart.above_goal_color;
        else
            color = chart.within_goal_color;

        return '#' + color;
    }

    async getBoardData(chart: ChartDash, type: Dashboard) {

        const start: Date = this.getIntervalStart(chart);
        const end: Date = this.getIntervalEnd(chart);

        console.table({ start, end })

        let final: Board

        var percent = await this.getValue(start, end, chart, type);
        percent = Math.round(percent)

        const color: string = this.getColor(chart, percent)

        if (type === Dashboard.PROD) {

            let unit: string = ' '

            switch (chart.unit) {
                case Unit.VIAGENS: unit += 'Viagens'; break;
                case Unit.KT: unit += 'Kt (métricas)'; break;
                case Unit.T: unit += 't (métricas)'; break;
                case Unit.KTON: unit += 'kton (curtas)'; break;
                case Unit.TON: unit += 'ton (curtas)'; break;
                case Unit.KG: unit += 'kg'; break;
            }

            final = new Board(this.getTitle(chart), percent + unit, color)
        } else
            final = new Board(this.getTitle(chart), percent + '%', color)

        return final;
    }


    async getChartData(chart: ChartDash, type: Dashboard) {

        const start: Date = this.getIntervalStart(chart);
        const end: Date = this.getIntervalEnd(chart);

        console.table({ start, end })

        let final: Chart;

        switch (type) {
            case Dashboard.DF:
                final = await this.getDF(chart, start, end);
                break;
            case Dashboard.UG:
                final = await this.getUG(chart, start, end);
                break;
            case Dashboard.UE:
                final = await this.getUE(chart, start, end);
                break;
            case Dashboard.UGL:
                final = await this.getUGL(chart, start, end);
                break;
            case Dashboard.UEL:
                final = await this.getUEL(chart, start, end);
                break;
            case Dashboard.IOG:
                final = await this.getIOG(chart, start, end);
                break;
            case Dashboard.IOE:
                final = await this.getIOE(chart, start, end);
                break;
            case Dashboard.C:
                final = await this.getMC(chart, start, end);
                break;
            case Dashboard.P:
                final = await this.getMP(chart, start, end);
                break;
            case Dashboard.DM:
                final = await this.getDM(chart, start, end);
                break;
            case Dashboard.R7:
                final = await this.getR7(chart, start, end);
                break;
            case Dashboard.R30:
                final = await this.getR30(chart, start, end);
                break;
            case Dashboard.R365:
                final = await this.getR365(chart, start, end);
                break;
            case Dashboard.PROD:
                final = await this.getPROD(chart, start, end);
                break;
        }

        return final;
    }

    getIntervalStart(chart: ChartDash): Date {

        let interval = Interval[Object.values(Interval)[chart.interval]];
        let date: Date;

        switch (Interval[Interval[interval]]) {
            case Interval.TURNO_ATUAL:
                const shift = this.getCurrentShift()
                if (shift) {
                    date = this.getDate(shift.start);
                } else date = new Date()
                break;
            case Interval.TURNO_ANTERIOR:
                const previous = this.getPreviousShift()
                const day = this.minutesService.day(this.today)

                let previousDate = new Date()
                previousDate.setTime(previous.day < day ? this.today.getTime() - this.oneDay : this.today.getTime())

                if (previous) date = new Date(this.getDate(previous.start, previousDate).getTime())

                else date = new Date()
                break;
            case Interval.DIA_ATUAL:
                date = this.getDate(0);
                break;
            case Interval.DIA_ANTERIOR:
                date = new Date(this.getDate(0).getTime() - this.oneDay);
                break;
            case Interval.SEMANA_ATUAL:
                var x = this.getDate(0);
                // set to Monday of this week
                x.setDate(x.getDate() - (x.getDay() + 7) % 7);
                if (this.dateBreak >= 12 * 60) x.setTime(x.getTime() - this.oneDay)
                date = x;
                break;
            case Interval.SEMANA_ANTERIOR:
                var x = this.getDate(0);
                // set to Monday of this week
                x.setDate(x.getDate() - (x.getDay() + 7) % 7);
                x.setDate(x.getDate() - 7);
                if (this.dateBreak >= 12 * 60) x.setTime(x.getTime() - this.oneDay)
                date = x;
                break;
            case Interval.MES_ATUAL:
                var x = this.getDate(0);
                x.setDate(1);
                if (this.dateBreak >= 12 * 60) x.setTime(x.getTime() - this.oneDay)
                date = x;
                break;
            case Interval.MES_ANTERIOR:
                var x = this.getDate(0);
                x.setDate(0); // ultimo dia do mes anterior
                x.setDate(1);
                if (this.dateBreak >= 12 * 60) x.setTime(x.getTime() - this.oneDay)
                date = x;
                break;
            case Interval.ANO_ATUAL:
                var x = this.getDate(0);
                x.setMonth(0)
                x.setDate(1)
                if (this.dateBreak >= 12 * 60) x.setTime(x.getTime() - this.oneDay)
                date = x;
                break;
            case Interval.ANO_ANTERIOR:
                var x = this.getDate(0);
                x.setMonth(0)
                x.setDate(1)
                x.setFullYear(x.getFullYear() - 1)
                if (this.dateBreak >= 12 * 60) x.setTime(x.getTime() - this.oneDay)
                date = x;
                break;
            case Interval.MEDIA_MOVEL:
                date = new Date(this.getDate(0).getTime() - (this.oneDay * (chart.movel_days - 1)));
                break;
            case Interval.PERSONALIZADO:
                date = chart.interval_date[0].toDate()
                break;
        }

        return date;
    }

    getIntervalEnd(chart: ChartDash): Date {

        let interval = Interval[Object.values(Interval)[chart.interval]];
        let date: Date;

        switch (Interval[Interval[interval]]) {
            case Interval.TURNO_ATUAL:
            case Interval.DIA_ATUAL:
            case Interval.SEMANA_ATUAL:
            case Interval.MES_ATUAL:
            case Interval.ANO_ATUAL:
            case Interval.MEDIA_MOVEL:
                date = new Date();
                break;
            case Interval.TURNO_ANTERIOR:
                const previous = this.getPreviousShift()
                const day = this.minutesService.day(this.today)

                let previousDate = new Date()
                previousDate.setTime(previous.day < day ? this.today.getTime() - this.oneDay : this.today.getTime())

                if (previous)
                    date = new Date(this.getDate(previous.end, previousDate).getTime())

                else date = new Date()
                break;
            case Interval.DIA_ANTERIOR:
                date = new Date(this.getDate(1440).getTime() - this.oneDay);
                break;
            case Interval.SEMANA_ANTERIOR:
                var x = this.getDate(0);
                x.setDate(x.getDate() - (x.getDay() + 7) % 7);
                if (this.dateBreak >= 12 * 60) x.setTime(x.getTime() - this.oneDay)
                date = x;
                break;
            case Interval.MES_ANTERIOR:
                var x = this.getDate(1440)
                x.setDate(0);
                if (this.dateBreak >= 12 * 60) x.setTime(x.getTime() - this.oneDay)
                date = x;
                break;
            case Interval.ANO_ANTERIOR:
                var x = this.getDate(0);
                x.setMonth(0)
                x.setDate(1)
                if (this.dateBreak >= 12 * 60) x.setTime(x.getTime() - this.oneDay)
                date = x;
                break;
            case Interval.PERSONALIZADO:
                date = chart.interval_date[1].toDate()
                break;
        }

        return date;
    }

    getCurrentShift(): Shift {
        var now: number = this.minutesService.minute(this.today)
        var result: Shift = null;

        var day = this.minutesService.day(this.today)

        this.shifts.filter(x => x.day === day).forEach(shift => {

            var start = shift.start;
            var end = shift.end;

            console.table({ start, end, now })

            if (now >= start && now <= end) {
                result = shift;
            }
        })

        return result;
    }

    getPreviousShift(): Shift {

        const current = this.getCurrentShift();
        var result: Shift;
        var result: Shift = null;

        var day = this.minutesService.day(this.today)
        let index;

        const todays = this.shifts.filter(x => x.day === day);

        if (current)
            index = todays.findIndex(x => x.id === current.id);
        else {
            const todayShifts = todays.filter(x => x.end <= this.minutesService.minute(this.today));

            index = todayShifts.length;
        }

        if (index > 0)
            result = todays[index - 1]
        else {

            var yesterday = day - 1
            yesterday = yesterday < 1 ? 7 : yesterday

            const yesterdayShifts = this.shifts.filter(x => x.day === yesterday);
            result = yesterdayShifts[yesterdayShifts.length - 1]

            //TODO  colocar for

        }

        return result

    }

    getDate(time: number, date: Date = new Date): Date {

        var now: number = this.minutesService.minute(this.today)

        let tomorrow = false;
        let yesterday = false;
        let start = this.dateBreak + time;

        start = start >= 24 * 60 ? start - 1440 : start;

        if (time < (24 * 60) - this.dateBreak && now >= (24 * 60) - this.dateBreak) yesterday = true
        else if (time >= (24 * 60) - this.dateBreak && now < (24 * 60) - this.dateBreak) tomorrow = true

        const hours = Math.floor(start / 60);
        const minutes = start - (hours * 60);

        if (tomorrow) date.setTime(date.getTime() + this.oneDay)
        else if (yesterday) date.setTime(date.getTime() - this.oneDay)
        date.setHours(hours);
        date.setMinutes(minutes);
        date.setSeconds(0);

        return date;
    }

    getShiftEnd(time: number): Date {
        let shiftEndMinute = this.dateBreak + time;
        let addDay = false;

        if (shiftEndMinute > 1440) {
            addDay = true
            shiftEndMinute = shiftEndMinute - 1440;
        }

        const hours = Math.floor(shiftEndMinute / 60);
        const minutes = shiftEndMinute - (hours * 60);

        const shiftEnd: Date = new Date()

        if (addDay)
            shiftEnd.setDate(shiftEnd.getDate() + 1)
        shiftEnd.setHours(hours);
        shiftEnd.setMinutes(minutes);
        shiftEnd.setSeconds(0);

        return shiftEnd;
    }


    getMovel(data: number[], chart: ChartDash): number[] {
        const interval = chart.movel_days

        let movelData = new Array(interval).fill(0)

        for (let x = 0; x < interval; x++) {
            for (let y = x; y < x + chart.movel; y++) {
                movelData[x] += data[y]
            }

            movelData[x] /= chart.movel
        }

        console.table({ data, movelData });

        return movelData
    }

    private async getValue(start: Date, end: Date, chart: ChartDash, type: Dashboard) {

        let final: number;

        switch (type) {
            case Dashboard.DF: {

                const HH: number = this.minutesService.hh(start, end);
                const HM: number = await this.minutesService.getRecords(TimeClass.MANUTENCAO, null, start, end, chart.fleet_id);

                const HD = HH - HM;

                final = HH > 0 ? Math.round((HD / HH) * 100) : 0;
            }

                break;
            case Dashboard.UG: {

                const HH: number = this.minutesService.hh(start, end);
                const HM: number = await this.minutesService.getRecords(TimeClass.MANUTENCAO, null, start, end, chart.fleet_id);
                const HP: number = await this.minutesService.getRecords(TimeClass.PARADO, null, start, end, chart.fleet_id);

                const HD = HH - HM;
                const HT = HD - HP;

                final = HH > 0 ? Math.round((HT / HD) * 100) : 0
            }
                break;
            case Dashboard.UE: {

                const HH: number = this.minutesService.hh(start, end);
                const HM: number = await this.minutesService.getRecords(TimeClass.MANUTENCAO, null, start, end, chart.fleet_id);
                const HP: number = await this.minutesService.getRecords(TimeClass.PARADO, null, start, end, chart.fleet_id);
                const IE: number = await this.minutesService.getRecords(TimeClass.PARADO, SubClass.IMPEDIMENTO_EXTERNO, start, end, chart.fleet_id);

                const HD = HH - HM;
                const HT = HD - HP;

                final = HH > 0 ? Math.round((HT / (HD - IE)) * 100) : 0
            }
                break;
            case Dashboard.UGL: {

                const HH: number = this.minutesService.hh(start, end);
                const HM: number = await this.minutesService.getRecords(TimeClass.MANUTENCAO, null, start, end, chart.fleet_id);
                const OP: number = await this.minutesService.getRecords(TimeClass.TRABALHANDO, SubClass.OPERANDO, start, end, chart.fleet_id);

                const HD = HH - HM;

                final = HD > 0 ? Math.round((OP / HD) * 100) : 0
            }
                break;
            case Dashboard.UEL: {

                const HH: number = this.minutesService.hh(start, end);
                const HM: number = await this.minutesService.getRecords(TimeClass.MANUTENCAO, null, start, end, chart.fleet_id);
                const OP: number = await this.minutesService.getRecords(TimeClass.TRABALHANDO, SubClass.OPERANDO, start, end, chart.fleet_id);
                const IE: number = await this.minutesService.getRecords(TimeClass.PARADO, SubClass.IMPEDIMENTO_EXTERNO, start, end, chart.fleet_id);

                const HD = HH - HM;

                final = (HD - IE) > 0 ? Math.round((OP / (HD - IE)) * 100) : 0
            }
                break;
            case Dashboard.IOG: {
                const HH: number = this.minutesService.hh(start, end);
                const HM: number = await this.minutesService.getRecords(TimeClass.MANUTENCAO, null, start, end, chart.fleet_id);
                const HP: number = await this.minutesService.getRecords(TimeClass.PARADO, null, start, end, chart.fleet_id);

                const HD = HH - HM;
                const HT = HD - HP;

                final = HH > 0 ? Math.round((HT / HH) * 100) : 0
            }
                break;
            case Dashboard.IOE: {

                const HH: number = this.minutesService.hh(start, end);
                const HM: number = await this.minutesService.getRecords(TimeClass.MANUTENCAO, null, start, end, chart.fleet_id);
                const HP: number = await this.minutesService.getRecords(TimeClass.PARADO, null, start, end, chart.fleet_id);
                const IE: number = await this.minutesService.getRecords(TimeClass.PARADO, SubClass.IMPEDIMENTO_EXTERNO, start, end, chart.fleet_id);

                const HD = HH - HM;
                const HT = HD - HP;

                final = HH - IE > 0 ? Math.round((HT / (HH - IE)) * 100) : 0
            }
                break;
            case Dashboard.C: {

                const HM: number = await this.minutesService.getRecords(TimeClass.MANUTENCAO, null, start, end, chart.fleet_id);
                const MC: number = await this.minutesService.getRecords(TimeClass.MANUTENCAO, SubClass.CORRETIVA, start, end, chart.fleet_id);

                final = HM > 0 ? Math.round((MC / HM) * 100) : 0
            }
                break;
            case Dashboard.P: {

                const HM: number = await this.minutesService.getRecords(TimeClass.MANUTENCAO, null, start, end, chart.fleet_id);
                const MP: number = await this.minutesService.getRecords(TimeClass.MANUTENCAO, SubClass.PREVENTIVA, start, end, chart.fleet_id);

                final = HM > 0 ? Math.round((MP / HM) * 100) : 0
            }
                break;
            case Dashboard.DM: {

                var MTBF = await this.getMTBF(chart, start, end)
                var MTTR = await this.getMTTR(chart, start, end)

                final = MTTR + MTBF > 0 ? Math.round((MTBF / (MTTR + MTBF)) * 100) : 0
            }
                break;
            case Dashboard.R7: {

                var MTBF = await this.getMTBF(chart, start, end)
                final = Math.round(Math.pow(Math.exp(1), -(1 / MTBF) * 7) * 100)
            }
                break;
            case Dashboard.R30: {

                var MTBF = await this.getMTBF(chart, start, end)
                final = Math.round(Math.pow(Math.exp(1), -(1 / MTBF) * 30) * 100)
            }
                break;
            case Dashboard.R365: {

                var MTBF = await this.getMTBF(chart, start, end)
                final = Math.round(Math.pow(Math.exp(1), -(1 / MTBF) * 365) * 100)
            }
                break;
            case Dashboard.PROD: {

                var prod = await this.minutesService.getTrips(start, end, chart.equipment_id, chart.fleet_id, chart.material_name, chart.unit === Unit.VIAGENS)

                switch (chart.unit) {
                    case Unit.KT:
                        prod /= 1000000
                        break;
                    case Unit.T:
                        prod /= 1000
                        break;
                    case Unit.KTON:
                        prod /= 907184.74
                        break;
                    case Unit.TON:
                        prod /= 907.18474
                        break;
                }

                final = prod
            }
                break;
        }

        return final;
    }

    /**
     * Disponibilidade Física
     */
    async getDF(chart: ChartDash, start: Date, end: Date) {

        if (chart.format === Format.PIZZA) {

            var percent = await this.getValue(start, end, chart, Dashboard.DF);
            return this.getFleetChart(chart, [['Disponível', percent], ['Manutenção', 100 - percent]]);

        } else if (chart.format === Format.BAR) {

            let hours = Math.floor(Math.abs((+end - +start) / this.oneHour));
            let days = Math.round(Math.abs((+end - +start) / this.oneDay));
            let months = this.monthDiff(start, end)

            let HH: number[] = [];
            let HM: number[] = [];
            let interval: number = 0;
            let intervalStart: number = 0;

            if (chart.interval === Interval.DIA_ATUAL || chart.interval === Interval.DIA_ANTERIOR ||
                chart.interval === Interval.TURNO_ATUAL || chart.interval === Interval.TURNO_ANTERIOR) {

                HH = this.minutesService.hhToHours(start, end);
                HM = await this.minutesService.getRecordsToHours(TimeClass.MANUTENCAO, null, start, end, chart.fleet_id);

                interval = hours;
                intervalStart = start.getHours()

            } else if (chart.interval === Interval.SEMANA_ATUAL || chart.interval === Interval.SEMANA_ANTERIOR ||
                chart.interval === Interval.MES_ATUAL || chart.interval === Interval.MES_ANTERIOR) {

                HH = this.minutesService.hhToDays(start, end);
                HM = await this.minutesService.getRecordsToDays(TimeClass.MANUTENCAO, null, start, end, chart.fleet_id);

                interval = days;
                intervalStart = start.getDate()

            } else if (chart.interval === Interval.ANO_ATUAL || chart.interval === Interval.ANO_ANTERIOR) {

                HH = this.minutesService.hhToMonths(start, end);
                HM = await this.minutesService.getRecordsToMonths(TimeClass.MANUTENCAO, null, start, end, chart.fleet_id);

                interval = months;
                intervalStart = start.getMonth()

            } else if (chart.interval === Interval.MEDIA_MOVEL) {

                let movelStart = new Date(start)
                movelStart.setDate(movelStart.getDate() - (chart.movel - 1))

                HH = this.minutesService.hhToDays(movelStart, end);
                HM = await this.minutesService.getRecordsToDays(TimeClass.MANUTENCAO, null, movelStart, end, chart.fleet_id);

                days = Math.round(Math.abs((+end - +movelStart) / this.oneDay));

                interval = days;
            }

            console.table({ HH, HM })

            let data: number[] = new Array(interval)

            for (let index = 0; index < interval; index++) {

                const HD = HH[index] - (HM[index] ? HM[index] : 0);

                var percent = HH[index] > 0 ? Math.round((HD / HH[index]) * 100) : 0

                data[index] = percent
            }

            if (chart.interval === Interval.MEDIA_MOVEL) {
                interval = chart.movel_days
                data = this.getMovel(data, chart)
            }

            return this.getBarChart(chart, data, intervalStart, this.getMeta(chart, interval, intervalStart));
        }

    }

    /**
     * Utilização Global
     */
    async getUG(chart: ChartDash, start: Date, end: Date) {

        if (chart.format === Format.PIZZA) {
            var percent = await this.getValue(start, end, chart, Dashboard.UG);
            return this.getFleetChart(chart, [['Disponível', percent], ['Parado', 100 - percent]]);
        } else if (chart.format === Format.BAR) {

            let days = Math.round(Math.abs((+end - +start) / this.oneDay));
            let hours = Math.floor(Math.abs((+end - +start) / this.oneHour));
            let months = this.monthDiff(start, end)

            let HH: number[] = [];
            let HM: number[] = [];
            let HP: number[] = [];
            let interval: number = 0;
            let intervalStart: number = 0;

            if (chart.interval === Interval.DIA_ATUAL || chart.interval === Interval.DIA_ANTERIOR ||
                chart.interval === Interval.TURNO_ATUAL || chart.interval === Interval.TURNO_ANTERIOR) {

                HH = this.minutesService.hhToHours(start, end);
                HM = await this.minutesService.getRecordsToHours(TimeClass.MANUTENCAO, null, start, end, chart.fleet_id);
                HP = await this.minutesService.getRecordsToHours(TimeClass.PARADO, null, start, end, chart.fleet_id);

                interval = hours;
                intervalStart = start.getHours()

            } if (chart.interval === Interval.MES_ATUAL || chart.interval === Interval.MES_ANTERIOR) {

                HH = this.minutesService.hhToDays(start, end);
                HM = await this.minutesService.getRecordsToDays(TimeClass.MANUTENCAO, null, start, end, chart.fleet_id);
                HP = await this.minutesService.getRecordsToDays(TimeClass.PARADO, null, start, end, chart.fleet_id);

                interval = days;
                intervalStart = start.getDate()

            } else if (chart.interval === Interval.ANO_ATUAL || chart.interval === Interval.ANO_ANTERIOR) {

                HH = this.minutesService.hhToMonths(start, end);
                HM = await this.minutesService.getRecordsToMonths(TimeClass.MANUTENCAO, null, start, end, chart.fleet_id);
                HP = await this.minutesService.getRecordsToMonths(TimeClass.PARADO, null, start, end, chart.fleet_id);

                interval = months;
                intervalStart = start.getMonth()

            } else if (chart.interval === Interval.MEDIA_MOVEL) {

                let movelStart = new Date(start)
                movelStart.setDate(movelStart.getDate() - (chart.movel - 1))

                HH = this.minutesService.hhToDays(movelStart, end);
                HM = await this.minutesService.getRecordsToDays(TimeClass.MANUTENCAO, null, movelStart, end, chart.fleet_id);
                HP = await this.minutesService.getRecordsToDays(TimeClass.PARADO, null, movelStart, end, chart.fleet_id);

                days = Math.round(Math.abs((+end - +movelStart) / this.oneDay));

                interval = days;
            }

            console.log({ HH, HM, HP });

            let data: number[] = new Array(interval)

            for (let index = 0; index < interval; index++) {

                const HD = HH[index] - HM[index];
                const HT = HD - HP[index];

                let percent = HH[index] > 0 ? Math.round((HT / HD) * 100) : 0

                data[index] = percent
            }

            if (chart.interval === Interval.MEDIA_MOVEL) {
                interval = chart.movel_days
                data = this.getMovel(data, chart)
            }

            return this.getBarChart(chart, data, intervalStart, this.getMeta(chart, interval, intervalStart));
        }
    }

    /**
     * Utilização Efetiva
     */
    async getUE(chart: ChartDash, start: Date, end: Date) {

        if (chart.format === Format.PIZZA) {
            var percent = await this.getValue(start, end, chart, Dashboard.UE)
            return this.getFleetChart(chart, [['Disponível', percent], ['Manutenção', 100 - percent]]);

        } else if (chart.format === Format.BAR) {

            let days = Math.round(Math.abs((+end - +start) / this.oneDay));
            let hours = Math.floor(Math.abs((+end - +start) / this.oneHour));
            let months = this.monthDiff(start, end)

            let HH: number[] = [];
            let HM: number[] = [];
            let HP: number[] = [];
            let IE: number[] = [];
            let interval: number = 0;
            let intervalStart: number = 0;

            if (chart.interval === Interval.DIA_ATUAL || chart.interval === Interval.DIA_ANTERIOR ||
                chart.interval === Interval.TURNO_ATUAL || chart.interval === Interval.TURNO_ANTERIOR) {

                HH = this.minutesService.hhToHours(start, end);
                HM = await this.minutesService.getRecordsToHours(TimeClass.MANUTENCAO, null, start, end, chart.fleet_id);
                HP = await this.minutesService.getRecordsToHours(TimeClass.PARADO, null, start, end, chart.fleet_id);
                IE = await this.minutesService.getRecordsToHours(TimeClass.PARADO, SubClass.IMPEDIMENTO_EXTERNO, start, end, chart.fleet_id);

                interval = hours;
                intervalStart = start.getHours()

            } else if (chart.interval === Interval.SEMANA_ATUAL || chart.interval === Interval.SEMANA_ANTERIOR ||
                chart.interval === Interval.MES_ATUAL || chart.interval === Interval.MES_ANTERIOR) {

                HH = this.minutesService.hhToDays(start, end);
                HM = await this.minutesService.getRecordsToDays(TimeClass.MANUTENCAO, null, start, end, chart.fleet_id);
                HP = await this.minutesService.getRecordsToDays(TimeClass.PARADO, null, start, end, chart.fleet_id);
                IE = await this.minutesService.getRecordsToDays(TimeClass.PARADO, SubClass.IMPEDIMENTO_EXTERNO, start, end, chart.fleet_id);

                interval = days;
                intervalStart = start.getDate()

            } else if (chart.interval === Interval.ANO_ATUAL || chart.interval === Interval.ANO_ANTERIOR) {

                HH = this.minutesService.hhToMonths(start, end);
                HM = await this.minutesService.getRecordsToMonths(TimeClass.MANUTENCAO, null, start, end, chart.fleet_id);
                HP = await this.minutesService.getRecordsToMonths(TimeClass.PARADO, null, start, end, chart.fleet_id);
                IE = await this.minutesService.getRecordsToMonths(TimeClass.MANUTENCAO, SubClass.IMPEDIMENTO_EXTERNO, start, end, chart.fleet_id);

                interval = months;
                intervalStart = start.getMonth()

            } else if (chart.interval === Interval.MEDIA_MOVEL) {

                let movelStart = new Date(start)
                movelStart.setDate(movelStart.getDate() - (chart.movel - 1))

                HH = this.minutesService.hhToDays(movelStart, end);
                HM = await this.minutesService.getRecordsToDays(TimeClass.MANUTENCAO, null, movelStart, end, chart.fleet_id);
                HP = await this.minutesService.getRecordsToDays(TimeClass.PARADO, null, movelStart, end, chart.fleet_id);
                IE = await this.minutesService.getRecordsToDays(TimeClass.MANUTENCAO, SubClass.IMPEDIMENTO_EXTERNO, movelStart, end, chart.fleet_id);

                days = Math.round(Math.abs((+end - +movelStart) / this.oneDay));

                interval = days;
            }

            console.table({ HH, HM, HP, IE })

            let data: number[] = new Array(interval)

            for (let index = 0; index < interval; index++) {

                const HD = HH[index] - (HM[index] ? HM[index] : 0);
                const HT = HD - HP[index];

                var percent = HH[index] > 0 ? Math.round((HT / (HD - IE[index])) * 100) : 0

                data[index] = percent
            }

            if (chart.interval === Interval.MEDIA_MOVEL) {
                interval = chart.movel_days
                data = this.getMovel(data, chart)
            }

            return this.getBarChart(chart, data, intervalStart, this.getMeta(chart, interval, intervalStart));
        }

    }

    /**
     * Utilização Global Líquida
     */
    async getUGL(chart: ChartDash, start: Date, end: Date) {

        if (chart.format === Format.PIZZA) {
            var percent = await this.getValue(start, end, chart, Dashboard.UGL)
            return this.getFleetChart(chart, [['Utilização Global Líquida', percent], ['', 100 - percent]]);

        } else if (chart.format === Format.BAR) {

            let days = Math.round(Math.abs((+end - +start) / this.oneDay));
            let hours = Math.floor(Math.abs((+end - +start) / this.oneHour));
            let months = this.monthDiff(start, end)

            let HH: number[] = [];
            let HM: number[] = [];
            let OP: number[] = [];

            let interval: number = 0;
            let intervalStart: number = 0;

            if (chart.interval === Interval.DIA_ATUAL || chart.interval === Interval.DIA_ANTERIOR ||
                chart.interval === Interval.TURNO_ATUAL || chart.interval === Interval.TURNO_ANTERIOR) {

                HH = this.minutesService.hhToHours(start, end);
                HM = await this.minutesService.getRecordsToHours(TimeClass.MANUTENCAO, null, start, end, chart.fleet_id);
                OP = await this.minutesService.getRecordsToHours(TimeClass.TRABALHANDO, SubClass.OPERANDO, start, end, chart.fleet_id);

                interval = hours;
                intervalStart = start.getHours()

            } else if (chart.interval === Interval.SEMANA_ATUAL || chart.interval === Interval.SEMANA_ANTERIOR ||
                chart.interval === Interval.MES_ATUAL || chart.interval === Interval.MES_ANTERIOR) {

                HH = this.minutesService.hhToDays(start, end);
                HM = await this.minutesService.getRecordsToDays(TimeClass.MANUTENCAO, null, start, end, chart.fleet_id);
                OP = await this.minutesService.getRecordsToHours(TimeClass.TRABALHANDO, SubClass.OPERANDO, start, end, chart.fleet_id);

                interval = days;
                intervalStart = start.getDate()

            } else if (chart.interval === Interval.ANO_ATUAL || chart.interval === Interval.ANO_ANTERIOR) {

                HH = this.minutesService.hhToMonths(start, end);
                HM = await this.minutesService.getRecordsToMonths(TimeClass.MANUTENCAO, null, start, end, chart.fleet_id);
                OP = await this.minutesService.getRecordsToMonths(TimeClass.TRABALHANDO, SubClass.OPERANDO, start, end, chart.fleet_id);

                interval = months;
                intervalStart = start.getMonth()

            } else if (chart.interval === Interval.MEDIA_MOVEL) {

                let movelStart = new Date(start)
                movelStart.setDate(movelStart.getDate() - (chart.movel - 1))

                HH = this.minutesService.hhToDays(movelStart, end);
                HM = await this.minutesService.getRecordsToDays(TimeClass.MANUTENCAO, null, movelStart, end, chart.fleet_id);
                OP = await this.minutesService.getRecordsToDays(TimeClass.TRABALHANDO, SubClass.OPERANDO, movelStart, end, chart.fleet_id);

                days = Math.round(Math.abs((+end - +movelStart) / this.oneDay));

                interval = days;
            }

            console.log({ HH, HM, OP });

            let data: number[] = new Array(interval)

            for (let index = 0; index < interval; index++) {

                const HD = HH[index] - (HM[index] ? HM[index] : 0);

                var percent = HD > 0 ? Math.round((OP[index] / HD) * 100) : 0

                data[index] = percent
            }

            if (chart.interval === Interval.MEDIA_MOVEL) {
                interval = chart.movel_days
                data = this.getMovel(data, chart)
            }

            return this.getBarChart(chart, data, intervalStart, this.getMeta(chart, interval, intervalStart));
        }
    }

    /** 
     * Utilização Efetiva Líquida
     */
    async getUEL(chart: ChartDash, start: Date, end: Date) {

        if (chart.format === Format.PIZZA) {
            var percent = await this.getValue(start, end, chart, Dashboard.UEL)
            return this.getFleetChart(chart, [['Operando', percent], ['', 100 - percent]]);
        } else if (chart.format === Format.BAR) {

            let days = Math.round(Math.abs((+end - +start) / this.oneDay));
            let hours = Math.floor(Math.abs((+end - +start) / this.oneHour));
            let months = this.monthDiff(start, end)

            let HH: number[] = [];
            let HM: number[] = [];
            let OP: number[] = [];
            let IE: number[] = [];

            let interval: number = 0;
            let intervalStart: number = 0;

            if (chart.interval === Interval.DIA_ATUAL || chart.interval === Interval.DIA_ANTERIOR ||
                chart.interval === Interval.TURNO_ATUAL || chart.interval === Interval.TURNO_ANTERIOR) {

                HH = this.minutesService.hhToHours(start, end);
                HM = await this.minutesService.getRecordsToHours(TimeClass.MANUTENCAO, null, start, end, chart.fleet_id);
                OP = await this.minutesService.getRecordsToHours(TimeClass.TRABALHANDO, SubClass.OPERANDO, start, end, chart.fleet_id);
                IE = await this.minutesService.getRecordsToHours(TimeClass.PARADO, SubClass.IMPEDIMENTO_EXTERNO, start, end, chart.fleet_id);

                interval = hours;
                intervalStart = start.getHours()

            } else if (chart.interval === Interval.SEMANA_ATUAL || chart.interval === Interval.SEMANA_ANTERIOR ||
                chart.interval === Interval.MES_ATUAL || chart.interval === Interval.MES_ANTERIOR) {

                HH = this.minutesService.hhToDays(start, end);
                HM = await this.minutesService.getRecordsToDays(TimeClass.MANUTENCAO, null, start, end, chart.fleet_id);
                OP = await this.minutesService.getRecordsToDays(TimeClass.TRABALHANDO, SubClass.OPERANDO, start, end, chart.fleet_id);
                IE = await this.minutesService.getRecordsToDays(TimeClass.PARADO, SubClass.IMPEDIMENTO_EXTERNO, start, end, chart.fleet_id);

                interval = days;
                intervalStart = start.getDate()

            } else if (chart.interval === Interval.ANO_ATUAL || chart.interval === Interval.ANO_ANTERIOR) {

                HH = this.minutesService.hhToMonths(start, end);
                HM = await this.minutesService.getRecordsToMonths(TimeClass.MANUTENCAO, null, start, end, chart.fleet_id);
                OP = await this.minutesService.getRecordsToMonths(TimeClass.TRABALHANDO, SubClass.OPERANDO, start, end, chart.fleet_id);
                IE = await this.minutesService.getRecordsToMonths(TimeClass.PARADO, SubClass.IMPEDIMENTO_EXTERNO, start, end, chart.fleet_id);

                interval = months;
                intervalStart = start.getMonth()

            } else if (chart.interval === Interval.MEDIA_MOVEL) {

                let movelStart = new Date(start)
                movelStart.setDate(movelStart.getDate() - (chart.movel - 1))

                HH = this.minutesService.hhToDays(movelStart, end);
                HM = await this.minutesService.getRecordsToDays(TimeClass.MANUTENCAO, null, movelStart, end, chart.fleet_id);
                OP = await this.minutesService.getRecordsToDays(TimeClass.TRABALHANDO, SubClass.OPERANDO, movelStart, end, chart.fleet_id);
                IE = await this.minutesService.getRecordsToDays(TimeClass.PARADO, SubClass.IMPEDIMENTO_EXTERNO, movelStart, end, chart.fleet_id);

                days = Math.round(Math.abs((+end - +movelStart) / this.oneDay));

                interval = days;
            }

            console.log({ HH, HM, OP, IE });

            let data: number[] = new Array(interval)

            for (let index = 0; index < interval; index++) {

                const HD = HH[index] - (HM[index] ? HM[index] : 0);

                var percent = (HD - IE[index]) > 0 ? Math.round((OP[index] / (HD - IE[index])) * 100) : 0

                data[index] = percent
            }

            if (chart.interval === Interval.MEDIA_MOVEL) {
                interval = chart.movel_days
                data = this.getMovel(data, chart)
            }

            return this.getBarChart(chart, data, intervalStart, this.getMeta(chart, interval, intervalStart));
        }

    }

    /**
     * Índice Operacional Global
     */
    async getIOG(chart: ChartDash, start: Date, end: Date) {

        if (chart.format === Format.PIZZA) {
            var percent = await this.getValue(start, end, chart, Dashboard.IOG)
            return this.getFleetChart(chart, [['Horas trabalhadas', percent], ['', 100 - percent]]);
        } else if (chart.format === Format.BAR) {

            let days = Math.round(Math.abs((+end - +start) / this.oneDay));
            let hours = Math.floor(Math.abs((+end - +start) / this.oneHour));
            let months = this.monthDiff(start, end)

            let HH: number[] = [];
            let HM: number[] = [];
            let HP: number[] = [];

            let interval: number = 0;
            let intervalStart: number = 0;

            if (chart.interval === Interval.DIA_ATUAL || chart.interval === Interval.DIA_ANTERIOR ||
                chart.interval === Interval.TURNO_ATUAL || chart.interval === Interval.TURNO_ANTERIOR) {

                HH = this.minutesService.hhToHours(start, end);
                HM = await this.minutesService.getRecordsToHours(TimeClass.MANUTENCAO, null, start, end, chart.fleet_id);
                HP = await this.minutesService.getRecordsToHours(TimeClass.PARADO, null, start, end, chart.fleet_id);

                interval = hours;
                intervalStart = start.getHours()

            } else if (chart.interval === Interval.SEMANA_ATUAL || chart.interval === Interval.SEMANA_ANTERIOR ||
                chart.interval === Interval.MES_ATUAL || chart.interval === Interval.MES_ANTERIOR) {

                HH = this.minutesService.hhToDays(start, end);
                HM = await this.minutesService.getRecordsToDays(TimeClass.MANUTENCAO, null, start, end, chart.fleet_id);
                HP = await this.minutesService.getRecordsToDays(TimeClass.PARADO, null, start, end, chart.fleet_id);

                interval = days;
                intervalStart = start.getDate()

            } else if (chart.interval === Interval.ANO_ATUAL || chart.interval === Interval.ANO_ANTERIOR) {

                HH = this.minutesService.hhToMonths(start, end);
                HM = await this.minutesService.getRecordsToMonths(TimeClass.MANUTENCAO, null, start, end, chart.fleet_id);
                HP = await this.minutesService.getRecordsToMonths(TimeClass.PARADO, null, start, end, chart.fleet_id);

                interval = months;
                intervalStart = start.getMonth()

            } else if (chart.interval === Interval.MEDIA_MOVEL) {

                let movelStart = new Date(start)
                movelStart.setDate(movelStart.getDate() - (chart.movel - 1))

                HH = this.minutesService.hhToDays(movelStart, end);
                HM = await this.minutesService.getRecordsToDays(TimeClass.MANUTENCAO, null, movelStart, end, chart.fleet_id);
                HP = await this.minutesService.getRecordsToDays(TimeClass.PARADO, null, movelStart, end, chart.fleet_id);

                days = Math.round(Math.abs((+end - +movelStart) / this.oneDay));

                interval = days;
            }

            console.log({ HH, HM, HP });

            let data: number[] = new Array(interval)

            for (let index = 0; index < interval; index++) {

                const HD = HH[index] - (HM[index] ? HM[index] : 0);
                const HT = HD - HP[index];

                let percent = HH[index] > 0 ? Math.round((HT / HH[index]) * 100) : 0

                data[index] = percent
            }

            if (chart.interval === Interval.MEDIA_MOVEL) {
                interval = chart.movel_days
                data = this.getMovel(data, chart)
            }

            return this.getBarChart(chart, data, intervalStart, this.getMeta(chart, interval, intervalStart));
        }

    }

    /**
     * Índice Operacional Efetivo
     */
    async getIOE(chart: ChartDash, start: Date, end: Date) {

        if (chart.format === Format.PIZZA) {
            var percent = await this.getValue(start, end, chart, Dashboard.IOE)
            return this.getFleetChart(chart, [['Horas trabalhadas', percent], ['', 100 - percent]]);

        } else if (chart.format === Format.BAR) {

            let days = Math.round(Math.abs((+end - +start) / this.oneDay));
            let hours = Math.floor(Math.abs((+end - +start) / this.oneHour));
            let months = this.monthDiff(start, end)

            let HH: number[] = [];
            let HM: number[] = [];
            let IE: number[] = [];
            let HP: number[] = [];

            let interval: number = 0;
            let intervalStart: number = 0;

            if (chart.interval === Interval.DIA_ATUAL || chart.interval === Interval.DIA_ANTERIOR ||
                chart.interval === Interval.TURNO_ATUAL || chart.interval === Interval.TURNO_ANTERIOR) {

                HH = this.minutesService.hhToHours(start, end);
                HM = await this.minutesService.getRecordsToHours(TimeClass.MANUTENCAO, null, start, end, chart.fleet_id);
                HP = await this.minutesService.getRecordsToHours(TimeClass.PARADO, null, start, end, chart.fleet_id);
                IE = await this.minutesService.getRecordsToHours(TimeClass.PARADO, SubClass.IMPEDIMENTO_EXTERNO, start, end, chart.fleet_id);

                interval = hours;
                intervalStart = start.getHours()

            } else if (chart.interval === Interval.SEMANA_ATUAL || chart.interval === Interval.SEMANA_ANTERIOR ||
                chart.interval === Interval.MES_ATUAL || chart.interval === Interval.MES_ANTERIOR) {

                HH = this.minutesService.hhToDays(start, end);
                HM = await this.minutesService.getRecordsToDays(TimeClass.MANUTENCAO, null, start, end, chart.fleet_id);
                HP = await this.minutesService.getRecordsToDays(TimeClass.PARADO, null, start, end, chart.fleet_id);
                IE = await this.minutesService.getRecordsToDays(TimeClass.PARADO, SubClass.IMPEDIMENTO_EXTERNO, start, end, chart.fleet_id);

                interval = days;
                intervalStart = start.getDate()

            } else if (chart.interval === Interval.ANO_ATUAL || chart.interval === Interval.ANO_ANTERIOR) {

                HH = this.minutesService.hhToMonths(start, end);
                HM = await this.minutesService.getRecordsToMonths(TimeClass.MANUTENCAO, null, start, end, chart.fleet_id);
                HP = await this.minutesService.getRecordsToMonths(TimeClass.PARADO, null, start, end, chart.fleet_id);
                IE = await this.minutesService.getRecordsToMonths(TimeClass.PARADO, SubClass.IMPEDIMENTO_EXTERNO, start, end, chart.fleet_id);

                interval = months;
                intervalStart = start.getMonth()

            } else if (chart.interval === Interval.MEDIA_MOVEL) {

                let movelStart = new Date(start)
                movelStart.setDate(movelStart.getDate() - (chart.movel - 1))

                HH = this.minutesService.hhToDays(movelStart, end);
                HM = await this.minutesService.getRecordsToDays(TimeClass.MANUTENCAO, null, movelStart, end, chart.fleet_id);
                HP = await this.minutesService.getRecordsToDays(TimeClass.PARADO, null, movelStart, end, chart.fleet_id);
                IE = await this.minutesService.getRecordsToDays(TimeClass.PARADO, SubClass.IMPEDIMENTO_EXTERNO, movelStart, end, chart.fleet_id);

                days = Math.round(Math.abs((+end - +movelStart) / this.oneDay));

                interval = days;
            }

            console.log({ HH, HM, HP, IE });

            let data: number[] = new Array(interval)

            for (let index = 0; index < interval; index++) {

                const HD = HH[index] - (HM[index] ? HM[index] : 0);
                const HT = HD - HP[index];

                let percent = HH[index] - IE[index] > 0 ? Math.round((HT / (HH[index] - IE[index])) * 100) : 0

                data[index] = percent
            }

            if (chart.interval === Interval.MEDIA_MOVEL) {
                interval = chart.movel_days
                data = this.getMovel(data, chart)
            }

            return this.getBarChart(chart, data, intervalStart, this.getMeta(chart, interval, intervalStart));
        }
    }

    /**
     * Manutenção Corretiva
     */
    async getMC(chart: ChartDash, start: Date, end: Date) {

        if (chart.format === Format.PIZZA) {
            var percent = await this.getValue(start, end, chart, Dashboard.C)
            return this.getFleetChart(chart, [['Corretiva', percent], ['Manutenção', 100 - percent]]);

        } else if (chart.format === Format.BAR) {

            let days = Math.round(Math.abs((+end - +start) / this.oneDay));
            let hours = Math.floor(Math.abs((+end - +start) / this.oneHour));
            let months = this.monthDiff(start, end)

            let HM: number[] = [];
            let MC: number[] = [];

            let interval: number = 0;
            let intervalStart: number = 0;

            if (chart.interval === Interval.DIA_ATUAL || chart.interval === Interval.DIA_ANTERIOR ||
                chart.interval === Interval.TURNO_ATUAL || chart.interval === Interval.TURNO_ANTERIOR) {

                HM = await this.minutesService.getRecordsToHours(TimeClass.MANUTENCAO, null, start, end, chart.fleet_id);
                MC = await this.minutesService.getRecordsToHours(TimeClass.MANUTENCAO, SubClass.CORRETIVA, start, end, chart.fleet_id);

                interval = hours;
                intervalStart = start.getHours()

            } else if (chart.interval === Interval.SEMANA_ATUAL || chart.interval === Interval.SEMANA_ANTERIOR ||
                chart.interval === Interval.MES_ATUAL || chart.interval === Interval.MES_ANTERIOR) {

                HM = await this.minutesService.getRecordsToDays(TimeClass.MANUTENCAO, null, start, end, chart.fleet_id);
                MC = await this.minutesService.getRecordsToDays(TimeClass.MANUTENCAO, SubClass.CORRETIVA, start, end, chart.fleet_id);

                interval = days;
                intervalStart = start.getDate()

            } else if (chart.interval === Interval.ANO_ATUAL || chart.interval === Interval.ANO_ANTERIOR) {

                HM = await this.minutesService.getRecordsToMonths(TimeClass.MANUTENCAO, null, start, end, chart.fleet_id);
                MC = await this.minutesService.getRecordsToMonths(TimeClass.MANUTENCAO, SubClass.CORRETIVA, start, end, chart.fleet_id);

                interval = months;
                intervalStart = start.getMonth()

            } else if (chart.interval === Interval.MEDIA_MOVEL) {

                let movelStart = new Date(start)
                movelStart.setDate(movelStart.getDate() - (chart.movel - 1))

                HM = await this.minutesService.getRecordsToDays(TimeClass.MANUTENCAO, null, movelStart, end, chart.fleet_id);
                MC = await this.minutesService.getRecordsToDays(TimeClass.MANUTENCAO, SubClass.CORRETIVA, movelStart, end, chart.fleet_id);

                days = Math.round(Math.abs((+end - +movelStart) / this.oneDay));

                interval = days;
            }

            console.log({ HM, MC });

            let data: number[] = new Array(interval)

            for (let index = 0; index < interval; index++) {

                let percent = HM[index] > 0 ? Math.round((MC[index] / HM[index]) * 100) : 0

                data[index] = percent
            }

            if (chart.interval === Interval.MEDIA_MOVEL) {
                interval = chart.movel_days
                data = this.getMovel(data, chart)
            }

            return this.getBarChart(chart, data, intervalStart, this.getMeta(chart, interval, intervalStart));
        }

    }

    /**
     * Manutenção Preventiva
     */
    async getMP(chart: ChartDash, start: Date, end: Date) {

        if (chart.format === Format.PIZZA) {
            var percent = await this.getValue(start, end, chart, Dashboard.P)
            return this.getFleetChart(chart, [['Preventiva', percent], ['Manutenção', 100 - percent]]);

        } else if (chart.format === Format.BAR) {

            let days = Math.round(Math.abs((+end - +start) / this.oneDay));
            let hours = Math.floor(Math.abs((+end - +start) / this.oneHour));
            let months = this.monthDiff(start, end)

            let HM: number[] = [];
            let MP: number[] = [];

            let interval: number = 0;
            let intervalStart: number = 0;

            if (chart.interval === Interval.DIA_ATUAL || chart.interval === Interval.DIA_ANTERIOR ||
                chart.interval === Interval.TURNO_ATUAL || chart.interval === Interval.TURNO_ANTERIOR) {

                HM = await this.minutesService.getRecordsToHours(TimeClass.MANUTENCAO, null, start, end, chart.fleet_id);
                MP = await this.minutesService.getRecordsToHours(TimeClass.MANUTENCAO, SubClass.PREVENTIVA, start, end, chart.fleet_id);

                interval = hours;
                intervalStart = start.getHours()

            } else if (chart.interval === Interval.SEMANA_ATUAL || chart.interval === Interval.SEMANA_ANTERIOR ||
                chart.interval === Interval.MES_ATUAL || chart.interval === Interval.MES_ANTERIOR) {

                HM = await this.minutesService.getRecordsToDays(TimeClass.MANUTENCAO, null, start, end, chart.fleet_id);
                MP = await this.minutesService.getRecordsToDays(TimeClass.MANUTENCAO, SubClass.PREVENTIVA, start, end, chart.fleet_id);

                interval = days;
                intervalStart = start.getDate()

            } else if (chart.interval === Interval.ANO_ATUAL || chart.interval === Interval.ANO_ANTERIOR) {

                HM = await this.minutesService.getRecordsToMonths(TimeClass.MANUTENCAO, null, start, end, chart.fleet_id);
                MP = await this.minutesService.getRecordsToMonths(TimeClass.MANUTENCAO, SubClass.PREVENTIVA, start, end, chart.fleet_id);

                interval = months;
                intervalStart = start.getMonth()

            } else if (chart.interval === Interval.MEDIA_MOVEL) {

                let movelStart = new Date(start)
                movelStart.setDate(movelStart.getDate() - (chart.movel - 1))

                HM = await this.minutesService.getRecordsToDays(TimeClass.MANUTENCAO, null, movelStart, end, chart.fleet_id);
                MP = await this.minutesService.getRecordsToDays(TimeClass.MANUTENCAO, SubClass.PREVENTIVA, movelStart, end, chart.fleet_id);

                days = Math.round(Math.abs((+end - +movelStart) / this.oneDay));

                interval = days;
            }

            console.log({ HM, MP });

            let data: number[] = new Array(interval)

            for (let index = 0; index < interval; index++) {

                let percent = HM[index] > 0 ? Math.round((MP[index] / HM[index]) * 100) : 0

                data[index] = percent
            }

            if (chart.interval === Interval.MEDIA_MOVEL) {
                interval = chart.movel_days
                data = this.getMovel(data, chart)
            }

            return this.getBarChart(chart, data, intervalStart, this.getMeta(chart, interval, intervalStart));
        }
    }

    /**
     * Tempo Medio Para Reparo
     */
    async getMTTR(chart: ChartDash, start: Date, end: Date) {

        const MC: number = await this.minutesService.getRecords(TimeClass.MANUTENCAO, SubClass.CORRETIVA, start, end, chart.fleet_id);

        var MCSize: number = 0;

        let corretivaCallback = await this.recordService.getListByClass(TimeClass.MANUTENCAO, SubClass.CORRETIVA, chart.fleet_id, start, end).get().toPromise();
        MCSize = corretivaCallback.size

        console.table({ MC, MCSize });

        return MCSize > 0 ? Math.round(MC / MCSize) : 0
    }

    /**
     * Tempo Medio Entre Falhas
     */
    async getMTBF(chart: ChartDash, start: Date, end: Date) {

        const HH: number = this.minutesService.hh(start, end);
        const HM: number = await this.minutesService.getRecords(TimeClass.MANUTENCAO, null, start, end, chart.fleet_id);

        var MCSize: number = 0;

        let corretivaCallback = await this.recordService.getListByClass(3, 5, chart.fleet_id, start, end).get().toPromise();
        MCSize = corretivaCallback.size

        const HD = HH - HM
        console.table({ HH, HM, HD, MCSize });

        return MCSize > 0 ? Math.round(HD / MCSize) : 0
    }

    /**
     * Disponibilidade Mecânica
     */
    async getDM(chart: ChartDash, start: Date, end: Date) {
        var percent = await this.getValue(start, end, chart, Dashboard.DM)
        return this.getFleetChart(chart, [['Disponibilidade', percent], ['', 100 - percent]]);
    }

    async getR7(chart: ChartDash, start: Date, end: Date) {
        var percent = await this.getValue(start, end, chart, Dashboard.R7)
        return this.getFleetChart(chart, [['Disponibilidade', percent], ['', 100 - percent]]);
    }

    async getR30(chart: ChartDash, start: Date, end: Date) {
        var percent = await this.getValue(start, end, chart, Dashboard.R30)
        return this.getFleetChart(chart, [['Disponibilidade', percent], ['', 100 - percent]]);
    }

    async getR365(chart: ChartDash, start: Date, end: Date) {
        var percent = await this.getValue(start, end, chart, Dashboard.R365)
        return this.getFleetChart(chart, [['Disponibilidade', percent], ['', 100 - percent]]);
    }

    async getPROD(chart: ChartDash, start: Date, end: Date) {

        let days = Math.round(Math.abs((+end - +start) / this.oneDay));
        let hours = Math.floor(Math.abs((+end - +start) / this.oneHour));
        let months = this.monthDiff(start, end)

        let PROD: number[] = [];

        let interval: number = 0;
        let intervalStart: number = 0;

        if (chart.interval === Interval.DIA_ATUAL || chart.interval === Interval.DIA_ANTERIOR ||
            chart.interval === Interval.TURNO_ATUAL || chart.interval === Interval.TURNO_ANTERIOR) {

            PROD = await this.minutesService.getTripsToHours(start, end, chart.equipment_id, chart.fleet_id, chart.material_name, chart.unit === Unit.VIAGENS);

            interval = hours;

            intervalStart = start.getHours()

        } else if (chart.interval === Interval.ANO_ATUAL || chart.interval === Interval.ANO_ANTERIOR) {

            PROD = await this.minutesService.getTripsToMonths(start, end, chart.equipment_id, chart.fleet_id, chart.material_name, chart.unit === Unit.VIAGENS, chart);

            interval = months;
            intervalStart = start.getMonth()

        } else if (chart.interval === Interval.MEDIA_MOVEL) {

            let movelStart = new Date(start)
            movelStart.setDate(movelStart.getDate() - (chart.movel - 1))

            PROD = await this.minutesService.getTripsToDays(movelStart, end, chart.equipment_id, chart.fleet_id, chart.material_name, chart.unit === Unit.VIAGENS, chart);

            days = Math.round(Math.abs((+end - +movelStart) / this.oneDay));

            interval = days;

            console.log({ PROD });


        } else {

            PROD = await this.minutesService.getTripsToDays(start, end, chart.equipment_id, chart.fleet_id, chart.material_name, chart.unit === Unit.VIAGENS, chart);

            interval = days;
            intervalStart = start.getDate()
        }

        switch (chart.unit) {
            case Unit.KT:
                for (let x = 0; x < PROD.length; x++)
                    PROD[x] /= 1000000
                break;
            case Unit.T:
                for (let x = 0; x < PROD.length; x++)
                    PROD[x] /= 1000
                break;
            case Unit.KTON:
                for (let x = 0; x < PROD.length; x++)
                    PROD[x] /= 907184.74
                break;
            case Unit.TON:
                for (let x = 0; x < PROD.length; x++)
                    PROD[x] /= 907.18474
                break;
        }

        console.log({ PROD, intervalStart, interval });

        if (chart.interval === Interval.MEDIA_MOVEL) {
            interval = chart.movel_days
            PROD = this.getMovel(PROD, chart)
        }

        return this.getBarChart(chart, PROD, intervalStart, this.getMeta(chart, interval, intervalStart));
    }
}
