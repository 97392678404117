import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Profile } from './profile.model';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private dbPath = '/profiles';
  client: string;

  collection: AngularFirestoreCollection<Profile> = null;

  constructor(private db: AngularFirestore) {
  }

  create(customer: Profile) {
    this.client = JSON.parse(localStorage.getItem('documentData')).client_id;
    return this.db.collection('clients').doc(this.client).collection(this.dbPath).add({ ...customer });
  }

  update(key: string, value: any): Promise<void> {
    this.client = JSON.parse(localStorage.getItem('documentData')).client_id;
    return this.db.collection('clients').doc(this.client).collection(this.dbPath).doc(key).update(value);
  }

  delete(key: string): Promise<void> {
    this.client = JSON.parse(localStorage.getItem('documentData')).client_id;
    return this.db.collection('clients').doc(this.client).collection(this.dbPath).doc(key).delete();
  }

  getProfile(id: string): AngularFirestoreDocument<Profile> {
    if (!id) id = "admin"
    this.client = JSON.parse(localStorage.getItem('documentData')).client_id;

    let defaults = ["admin", "manager", "planning", "user"]
    
    if (defaults.includes(id)) return this.db.collection('profiles').doc(id);
    else
      return this.db.collection('clients').doc(this.client).collection(this.dbPath).doc(id);
  }

  getList(custom: boolean): AngularFirestoreCollection<Profile> {
    this.client = JSON.parse(localStorage.getItem('documentData')).client_id;
    return !custom ? this.db.collection('clients').doc(this.client).collection(this.dbPath) :
      this.db.collection('clients').doc(this.client).collection(this.dbPath, ref => ref.where('custom', '==', true));
  }
}
