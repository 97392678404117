import { Component, OnInit, ViewChild } from '@angular/core';
import { map } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { EquipmentService } from 'src/app/equipments/equipment.service';
import { firestore } from 'firebase';
import { FleetService } from 'src/app/fleets/fleet.service';
import { GeocercaService } from 'src/app/geocercas/geocerca.service';
import { Geocerca } from 'src/app/geocercas/geocerca.model';
import { Speeding } from '../speeding.model';
import { SpeedingService } from '../speeding.service';
import { MatDialog } from '@angular/material/dialog';
import { SpeedingDialogComponent } from '../speeding-dialog/speeding-dialog.component';

export interface DialogData {
  speeding: Speeding;
  geocercas: Geocerca[]
}
@Component({
  selector: 'app-speeding-list',
  templateUrl: './speeding-list.component.html',
  styleUrls: ['./speeding-list.component.scss']
})
export class SpeedingListComponent implements OnInit {

  speedings: Speeding[];
  equipments: any;
  geocercas: Geocerca[];
  equipment: string = "all";
  fleets: any;
  fleet: string = "all";
  dataSource: MatTableDataSource<Speeding>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    private geocercaService: GeocercaService,
    private fleetsService: FleetService,
    private equipmentService: EquipmentService,
    private speedingService: SpeedingService) { }

  classifyPoint = require("robust-point-in-polygon")

  displayedColumns: string[] = ['date', 'geocerca', 'speed', 'fleet', 'equipment', 'operator', 'map'];

  onDateChange(dateStart, dateEnd) {

    var start = new Date(dateStart);
    var end = new Date(dateEnd);

    if (!isNaN(+start) && !isNaN(+end))
      this.getListTime(start, end, this.equipment, this.fleet);
  }

  ngOnInit() {
    this.getEquipments();
    this.getFleets();
    this.getGeocercas();
    this.getList();

    var today = new Date();
    var yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    this.selectedMoments = [yesterday, today];

  }

  openDialog(speeding: Speeding): void {
    this.dialog.open(SpeedingDialogComponent, {
      width: '900px',
      height: '600px',
      data: {
        speeding: speeding,
        geocercas: this.geocercas
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public selectedMoments = [
    new Date(),
    new Date()
  ];

  getList() {
    this.speedingService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(speedings => {
      this.speedings = speedings;
      this.setGeocerca();
    });
  }

  setGeocerca() {

    this.speedings.forEach(speeding => {

      this.geocercas.forEach(geocerca => {

        var arr = Array();
        geocerca.points.forEach(point => {
          arr.push([point.lat, point.lng])
        })

        if (this.classifyPoint(arr, [speeding.location.latitude, speeding.location.longitude]) == -1)
          speeding.geocerca = geocerca.name;
      });

      if (!speeding.geocerca) speeding.geocerca = speeding.location.latitude + ', ' + speeding.location.longitude;

    });

    this.updateTable();
  }

  clear() {
    this.getList();
  }

  getEquipments() {
    this.equipmentService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(equipments => {
      this.equipments = equipments;
    });
  }

  getFleets() {
    this.fleetsService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(fleets => {
      this.fleets = fleets;
    });
  }

  getListTime(start: Date, end: Date, equipment: string, fleet: string) {
    this.speedingService.getListByTime(start, end, equipment, fleet).snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(records => {
      this.speedings = records;
      this.setGeocerca();
    });
  }

  private updateTable() {
    this.dataSource = new MatTableDataSource(this.speedings);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  delete() {
    this.speedingService.deleteAll();
  }

  getDateEnd(date: firestore.Timestamp): firestore.Timestamp {
    if (date == null) return firestore.Timestamp.now();
    else return date;
  }

  getDuration(dateStart: firestore.Timestamp, dateEnd: firestore.Timestamp) {
    if (dateEnd == null) dateEnd = firestore.Timestamp.now();

    return this.getDataDiff(dateStart.toDate(), dateEnd.toDate());
  }

  getDataDiff(startDate, endDate) {
    var milisecondsDiff = endDate - startDate;
    return Math.floor(milisecondsDiff / (1000 * 60 * 60)).toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ":" + (Math.floor(milisecondsDiff / (1000 * 60)) % 60).toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ":" + (Math.floor(milisecondsDiff / 1000) % 60).toLocaleString(undefined, { minimumIntegerDigits: 2 });
  }

  getGeocercas() {
    this.geocercaService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(geocercas => {
      this.geocercas = geocercas;
    });
  }
}
