import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GeocercaDialogData } from '../geocerca-list/geocerca-list.component';

@Component({
  selector: 'app-geocerca-dialog',
  templateUrl: './geocerca-dialog.component.html',
  styleUrls: ['./geocerca-dialog.component.scss']
})
export class GeocercaDialogComponent {

  map: any;

  constructor(
    public dialogRef: MatDialogRef<GeocercaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GeocercaDialogData) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  mapReady(map) {
    this.map = map;
    this.centerMap();
  }

  private centerMap() {

    var bounds = new google.maps.LatLngBounds();

    this.data.geocerca.points.forEach(point => bounds.extend(point))

    bounds.extend(bounds.getCenter())
    this.map.setCenter(bounds.getCenter());
    this.map.fitBounds(bounds);
  }
}
