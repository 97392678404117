export type Roles = 'ADMIN' | 'CLIENT' | 'OPERATOR';

export class User {
  uid: string;
  email: string;
  displayName?: string;
  emailVerified: boolean;
  password?: string;
  profile?: string;
  client_id?: string;
  client_name?: string;
  enabled?: boolean;

  constructor(email?: string, password?: string, displayName?: string) {
    this.email = email
    this.password = password
    this.displayName = displayName
  }
}