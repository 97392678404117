<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="box">

    <mat-card-header>
      <mat-card-title>Alterar frota</mat-card-title>
    </mat-card-header>

    <form [formGroup]="form" #myForm="ngForm" (ngSubmit)="onSubmit()" class="example-form">

      <mat-card-content>
        <mat-form-field class="example-full-width">
          <input matInput type="text" placeholder="Nome" [(ngModel)]="fleet.name" formControlName="name" required>
          <mat-error *ngIf="isFieldInvalid('name')">
            Informe o nome da frota
          </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <input matInput type="number" placeholder="Limite de velocidade" [(ngModel)]="fleet.speedLimit" formControlName="speedLimit" required>
          <mat-error *ngIf="isFieldInvalid('speedLimit')">
            Informe o limite de velocidade
          </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <mat-label>Classe</mat-label>
          <mat-select [(ngModel)]="fleet.fleetClass" [(value)]="fleet.fleetClass" formControlName="fleetClass"
          (selectionChange)="onFleetChange(fleet.fleetClass)">
            <mat-option *ngFor="let fleet of fleets" [value]="fleet.value">
              {{fleet.viewValue}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="isFieldInvalid('fleetClass')">
            Informe a classe da frota
          </mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="isTransport" class="example-full-width">
          <input matInput type="number" placeholder="Carga média (kg)" [(ngModel)]="fleet.load" formControlName="load">
          <mat-error *ngIf="isFieldInvalid('load')">
            Informe a carga média
          </mat-error>
        </mat-form-field>


      </mat-card-content>

      <button mat-raised-button color="primary" type="submit" class="btn-block">Alterar</button>
    </form>
  </mat-card>
</div>