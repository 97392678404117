<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="box">

    <mat-card-header>
      <mat-card-title>Adicionar frota</mat-card-title>
    </mat-card-header>

    <form [formGroup]="form" #myForm="ngForm" (ngSubmit)="onSubmit()" class="example-form">

      <mat-card-content>
        <mat-form-field class="example-full-width">
          <input matInput type="text" placeholder="Nome" [(ngModel)]="fleet.name" formControlName="name" required>
          <mat-error *ngIf="isFieldInvalid('name')">
            Informe o nome da frota
          </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <input matInput type="number" placeholder="Limite de velocidade" [(ngModel)]="fleet.speedLimit"
            formControlName="speedLimit" required>
          <mat-error *ngIf="isFieldInvalid('speedLimit')">
            Informe o limite de velocidade
          </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <mat-label>Classe</mat-label>
          <mat-select [(ngModel)]="fleet.fleetClass" [(value)]="fleet.fleetClass" formControlName="fleetClass"
            (selectionChange)="onFleetChange(fleet.fleetClass)">
            <mat-option *ngFor="let fleet of fleets" [value]="fleet.value">
              {{fleet.viewValue}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="isFieldInvalid('fleetClass')">
            Informe a classe da frota
          </mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="isTransport" class="example-full-width">
          <input matInput type="number" placeholder="Carga média (kg)" [(ngModel)]="fleet.load" formControlName="load">
        </mat-form-field>
      </mat-card-content>

      <button mat-raised-button type="button" (click)="openDialog()" class="btn-block" id="big-image">
          Ícones
      </button>
      <div #selectedIcon class="example-half-width"></div>
        <mat-list class="config-color">
          <h3 matSubheader>Configuração de cores</h3>
          <color-sketch class="color-picker" *ngIf="colorConfigIndex != null" [color]="fleet.configs[colorConfigIndex].color" [width]="'200px'" (onChange)="changeButtonColor($event, colorConfigIndex)"
      [presetColors]="['#FFFFFF','#D4E6C8','#B8D6A3','#9BC67E','#68A640','#B0FF8D','#7BFF41','#8DFF5A','#B0FF8D','#418320']"></color-sketch> 
          <mat-list-item *ngFor="let config of fleet.configs; index as i">
            {{ translations[config.name] || config.name }}: 
            <button mat-flat-button type="button" (click)="changeConfigState(i)" class="color-block" [ngStyle]="{'background-color': config.color}"></button>
            <!-- <svg class="example-small-width" width="40" height="40" xmlns="http://www.w3.org/2000/svg">
              <use attr.href="{{iconPath}}" transform="scale(0.13)"></use>
            </svg> -->
            <div #smallIcons class="example-small-width"></div>
          </mat-list-item>  
        </mat-list>
      <button mat-raised-button color="primary" type="submit" class="btn-block">Adicionar</button>
    </form>
  </mat-card>
</div>