import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { map } from 'rxjs/operators';
import { Card } from 'src/app/cards/card.model';
import { CardService } from 'src/app/cards/card.service';
import { FleetService } from 'src/app/fleets/fleet.service';
import { ContingencyItem } from '../contingency-item.model';
import { Contingency } from '../contingency.model';
import { ContingencyService } from '../contingency.service';

@Component({
  selector: 'app-contingency-filter-record',
  templateUrl: './contingency-filter-record.component.html',
  styleUrls: ['./contingency-filter-record.component.scss']
})
export class ContingencyFilterRecordComponent implements OnInit {

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  fleets: any;
  cards: Card[];
  contingency: Contingency
  default_contingency: Contingency

  constructor(
    private fleetsService: FleetService,
    private snackBar: MatSnackBar,
    private cardService: CardService,
    private contingencyService: ContingencyService
  ) { }

  ngOnInit(): void {
    this.getFleets()
    this.getCards()

    this.contingencyService.get('default_record').valueChanges()
      .subscribe((record) => {
        this.default_contingency = Object.assign(record)
        this.contingency = record
        this.getDefault();
      });
  }

  private getDefault() {
    var contingency = Object.assign(this.default_contingency) as Contingency

    this.cards.forEach(card => {

      var min: number = 10;
      var max: number = 10;

      if (card.timeClass === 1) {

        var a: ContingencyItem = Array.from(contingency.filters.values()).filter((item) => item.id === "1")[0]
        min = a.min
        max = a.max
      } else if (card.timeClass === 2) {
        var a: ContingencyItem = Array.from(contingency.filters.values()).filter((item) => item.id === "2")[0]
        min = a.min
        max = a.max
      } else if (card.timeClass === 3) {
        var a: ContingencyItem = Array.from(contingency.filters.values()).filter((item) => item.id === "3")[0]
        min = a.min
        max = a.max
      }

      contingency.filters.push(new ContingencyItem(card.id, card.name, min, max, true, true, true));
    });

    const contingencies = contingency.filters.map((obj) => { return Object.assign({}, obj); });
    contingency.filters = contingencies;

    this.contingency = contingency;
  }

  getFleets() {
    let sub = this.fleetsService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(fleets => {
      sub.unsubscribe();
      this.fleets = fleets;
    });
  }

  getCards() {
    this.cardService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(cards => {
      this.cards = cards;
    });
  }

  onFleetChange() {
    this.contingencyService.get(this.contingency.fleet).valueChanges()
      .subscribe((record) => {
        if (record)
          this.contingency = record
        else {
          const fleet = this.contingency.fleet
          this.contingency = this.default_contingency
          this.contingency.fleet = fleet
        }
      });
  }

  onSave() {
    this.openSnackBar("Filtros atualizados")
    this.contingencyService.createRecord(this.contingency)
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "Fechar", {
      duration: 2000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

}
