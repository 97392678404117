
export enum Dashboard {
    DF,
    UG,
    UE,
    UGL,
    UEL,
    IOG,
    IOE,
    C,
    P,
    MTBF,
    MTTR,
    DM,
    R7,
    R30,
    R365,
    PROD,
}