import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { Record } from './record.model';
import { firestore } from 'firebase';
import { Equipment } from '../equipments/equipment.model';

@Injectable({
  providedIn: 'root'
})
export class RecordService {

  private dbPath = '/records';
  client = "";

  collection: AngularFirestoreCollection<Record> = null;

  constructor(public db: AngularFirestore) {
    this.client = JSON.parse(localStorage.getItem('documentData')).client_id;
    this.collection = db.collection('clients').doc(this.client).collection(this.dbPath, ref => ref.orderBy("date_start", "desc"));
  }

  update(key: string, value: any): Promise<void> {
    return this.collection.doc(key).update(value);
  }

  delete(key: string): Promise<void> {
    return this.collection.doc(key).delete();
  }

  getList(): AngularFirestoreCollection<Record> {
    return this.collection;
  }

  getContingency(): AngularFirestoreCollection<Record> {
    return this.db.collection('clients').doc(this.client).collection(this.dbPath, ref => ref.where("contingency", "==", true).orderBy("date_start", "desc"));;
  }

  getListByTime(start: Date, end: Date, equipment: string, fleet: string, subClass: number): AngularFirestoreCollection<Record> {

    console.log(start);
    console.log(end);
    console.log(equipment);
    console.log(fleet);
    console.log(subClass);

    if (equipment == "all" && fleet == "all" && subClass == 0)
      return this.db.collection('clients').doc(this.client).collection(this.dbPath, ref => ref
        .where('date_start', '>=', start)
        .where('date_start', '<=', end)
        .orderBy("date_start", "desc")
      );

    else if (equipment == "all" && fleet == "all")
      return this.db.collection('clients').doc(this.client).collection(this.dbPath, ref => ref
        .orderBy("date_start", "desc")
        .where('date_start', '>=', start)
        .where('date_start', '<=', end)
        .where('sub_class', '==', subClass)
      );

    else if (equipment == "all")
      return this.db.collection('clients').doc(this.client).collection(this.dbPath, ref => ref
        .orderBy("date_start", "desc")
        .where('date_start', '>=', start)
        .where('date_start', '<=', end)
        .where('fleet_id', '==', fleet)
        .where('sub_class', '==', subClass)
      );

    else if (fleet == "all")
      return this.db.collection('clients').doc(this.client).collection(this.dbPath, ref => ref
        .orderBy("date_start", "desc")
        .where('date_start', '>=', start)
        .where('date_start', '<=', end)
        .where('equipment_id', '==', equipment)
        .where('sub_class', '==', subClass)
      );

    else if (subClass == 0)
      return this.db.collection('clients').doc(this.client).collection(this.dbPath, ref => ref
        .orderBy("date_start", "desc")
        .where('date_start', '>=', start)
        .where('date_start', '<=', end)
        .where('equipment_id', '==', equipment)
        .where('fleet_id', '==', fleet)
      );

    else
      return this.db.collection('clients').doc(this.client).collection(this.dbPath, ref => ref
        .orderBy("date_start", "desc")
        .where('date_start', '>=', start)
        .where('date_start', '<=', end)
        .where('equipment_id', '==', equipment)
        .where('fleet_id', '==', fleet)
        .where('sub_class', '==', subClass)
      );
  }

  getListByClass(timeClass: number, subClass: number, fleet: string, start: Date, end: Date): AngularFirestoreCollection<Record> {

    if (!subClass)
      return this.db.collection('clients').doc(this.client).collection(this.dbPath, ref => ref
        .where('date_start', '>=', start)
        .where('date_start', '<=', end)
        .where('time_class', '==', timeClass)
        .where('fleet_id', '==', fleet)
      );

    else
      return this.db.collection('clients').doc(this.client).collection(this.dbPath, ref => ref
        .where('date_start', '>=', start)
        .where('date_start', '<=', end)
        .where('time_class', '==', timeClass)
        .where('sub_class', '==', subClass)
        .where('fleet_id', '==', fleet)
      );
  }

  getListByStatus(start: Date, end: Date, equipment: string, status: string): AngularFirestoreCollection<Record> {
    console.log(equipment);
    console.log(status);

    return this.db.collection('clients').doc(this.client).collection(this.dbPath, ref => ref
      .orderBy("date_start", "desc")
      .where('date_start', '>=', start)
      .where('date_start', '<=', end)
      .where('equipment_id', '==', equipment)
      .where('status', '==', status)
    );
  }

  deleteAll() {
    this.collection.get().subscribe(
      querySnapshot => {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
        });
      },
      error => {
        console.log('Error: ', error);
      });
  }
}
