import { Component, OnInit } from '@angular/core';
import { FleetService } from '../fleet.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-fleets-list',
  templateUrl: './fleets-list.component.html',
  styleUrls: ['./fleets-list.component.scss']
})
export class FleetsListComponent implements OnInit {

  fleets: any;
 
  constructor(private fleetsService: FleetService) { }
 
  ngOnInit() {
    this.getList();
  }
 
  getList() {
    this.fleetsService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(fleets => {
      this.fleets = fleets;
    });
  }
 
  delete() {
    this.fleetsService.deleteAll();
  }

}
