import { Component, OnInit } from '@angular/core';
import { CardService } from '../card.service';
import { CardsIcon } from '../card.model';
import { MatDialog } from '@angular/material/dialog';
import { CardDialogComponent } from '../card-dialog/card-dialog.component';
import { MatIconRegistry } from '@angular/material/icon';
import { ColorEvent } from 'ngx-color';
import { CardNameDialogComponent } from '../card-name-dialog/card-name-dialog.component';
import { FleetService } from 'src/app/fleets/fleet.service';
import { map } from 'rxjs/operators';
import { Fleet } from 'src/app/fleets/fleet.model';

export interface CardDialogData {
  card: number;
  icon: number;
  name: string;
}

@Component({
  selector: 'app-card-icon',
  templateUrl: './card-icon.component.html',
  styleUrls: ['./card-icon.component.scss']
})
export class CardIconComponent implements OnInit {

  breakpoint: number;
  cards: CardsIcon[];
  cardsFromFleet: CardsIcon;
  state: any;
  fleets: Fleet[]
  fleet: string

  display1: boolean = false;
  display2: boolean = false;
  display3: boolean = false;
  display4: boolean = false;
  display5: boolean = false;
  display6: boolean = false;

  constructor(
    public matIconRegistry: MatIconRegistry,
    public dialog: MatDialog,
    private cardService: CardService,
    private fleetService: FleetService
  ) { }

  openDialog(card: number): void {
    const dialogRef = this.dialog.open(CardDialogComponent, {
      width: '900px',
      height: '600px',
      data: { card: card }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        switch (result.card) {
          case 1:
            this.cardsFromFleet.card1.icon = result.icon;
            break;
          case 2:
            this.cardsFromFleet.card2.icon = result.icon;
            break;
          case 3:
            this.cardsFromFleet.card3.icon = result.icon;
            break;
          case 4:
            this.cardsFromFleet.card4.icon = result.icon;
            break;
        }

        this.updateCard();
      }
    });
  }

  openDialogName(card: number): void {
    const dialogRef = this.dialog.open(CardNameDialogComponent, {
      width: '250px',
      data: { card: card }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);

      if (result) {
        switch (result.card) {
          case 1:
            this.cardsFromFleet.card1.name = result.name;
            break;
          case 2:
            this.cardsFromFleet.card2.name = result.name;
            break;
          case 3:
            this.cardsFromFleet.card3.name = result.name;
            break;
          case 4:
            this.cardsFromFleet.card4.name = result.name;
            break;
        }

        this.updateCard();
      }
    });
  }

  changeComplete($event: ColorEvent, card: number) {
    this.display1 = false;
    this.display2 = false;
    this.display3 = false;
    this.display4 = false;
    this.display5 = false;
    this.display6 = false;

    console.log($event.color.hex.replace(/#/i, ''));

    var color = $event.color.hex.replace(/#/i, '');

    switch (card) {
      case 1:
        this.cardsFromFleet.card1.color = color;
        break;
      case 2:
        this.cardsFromFleet.card2.color = color;
        break;
      case 3:
        this.cardsFromFleet.card3.color = color;
        break;
      case 4:
        this.cardsFromFleet.card4.color = color;
        break;
      case 5:
        this.cardsFromFleet.card5.color = color;
        break;
      case 6:
        this.cardsFromFleet.card6.color = color;
        break;
    }

    this.updateCard();
  }

  changeState(card: number) {
    switch (card) {
      case 1:
        this.display1 = !this.display1;
        break;
      case 2:
        this.display2 = !this.display2;
        break;
      case 3:
        this.display3 = !this.display3;
        break;
      case 4:
        this.display4 = !this.display4;
        break;
      case 5:
        this.display5 = !this.display5;
        break;
      case 6:
        this.display6 = !this.display6;
        break;
    }
  }

  ngOnInit() {
    this.getCardsIcon();
    this.getFleets();

    if (window.innerWidth <= 1600) this.breakpoint = 2;
    if (window.innerWidth <= 400) this.breakpoint = 1;

  }

  onResize(event) {
    if (event.target.innerWidth <= 1600) this.breakpoint = 2;
    if (event.target.innerWidth <= 400) this.breakpoint = 1;
  }

  updateCard() {
    this.cardService.updateCard(this.cardsFromFleet);
  }

  getCardsIcon() {
    this.cardService.getCardsIcon().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(cards => {
      this.cards = cards;
    });
  }

  getFleets() {
    this.fleetService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(fleets => {
      this.fleets = fleets;
      this.fleet = fleets[0].id
      this.onUpdateFleet()
    });
  }

  onUpdateFleet() {
    this.cardsFromFleet = this.cards.find(card => card.fleet == this.fleet)

    if (!this.cardsFromFleet) {
      this.cardsFromFleet = new CardsIcon(this.fleet);
      console.log(this.cardsFromFleet);
      this.cardService.createCardsIcon(this.cardsFromFleet)
    }
  }
}
