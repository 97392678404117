<div *ngIf="card" class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="box" [ngStyle]="{'backgroundColor': '#' + card.color}">

    <mat-card-header>
      <mat-card-title>{{card.name}}</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <div class="example-full-width">
        <mat-label>Cor: </mat-label> {{card.color}}
      </div>
      <div class="example-full-width">
        <mat-label>Número: </mat-label> {{card.number}}
      </div>
    </mat-card-content>

    <button mat-raised-button color="primary" (click)='edit()' class="btn-block">Editar</button>
    <button mat-raised-button color="warn" (click)='inactivate()' class="btn-block">Apagar</button>
  </mat-card>
</div>