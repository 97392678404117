import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Shift } from '../shift.model';
import { ShiftService } from '../shift.service';

@Component({
  selector: 'app-shift-create',
  templateUrl: './shift-create.component.html',
  styleUrls: ['./shift-create.component.scss']
})
export class ShiftCreateComponent implements OnInit {

  shifts: Shift[];
  days = [
    { day: 1, label: "Domingo" },
    { day: 2, label: "Segunda-feira" },
    { day: 3, label: "Terça-feira" },
    { day: 4, label: "Quarta-feira" },
    { day: 5, label: "Quinta-feira" },
    { day: 6, label: "Sexta-feira" },
    { day: 7, label: "Sábado" }
  ]

  dateBreak: number = 0;

  hours = [
    { value: 0, label: '00:00' },
    { value: 1, label: '01:00' },
    { value: 2, label: '02:00' },
    { value: 3, label: '03:00' },
    { value: 4, label: '04:00' },
    { value: 5, label: '05:00' },
    { value: 6, label: '06:00' },
    { value: 7, label: '07:00' },
    { value: 8, label: '08:00' },
    { value: 9, label: '09:00' },
    { value: 10, label: '10:00' },
    { value: 11, label: '11:00' },
    { value: 12, label: '12:00' },
    { value: 13, label: '13:00' },
    { value: 14, label: '14:00' },
    { value: 15, label: '15:00' },
    { value: 16, label: '16:00' },
    { value: 17, label: '17:00' },
    { value: 18, label: '18:00' },
    { value: 19, label: '19:00' },
    { value: 20, label: '20:00' },
    { value: 21, label: '21:00' },
    { value: 22, label: '22:00' },
    { value: 23, label: '23:00' },
  ];

  constructor(
    private router: Router,
    private shiftService: ShiftService
  ) { }

  ngOnInit(): void {
    this.getDateBreak();
    this.getList();
  }

  getDateBreak() {
    this.shiftService.getClient().valueChanges().subscribe(client => {
      this.dateBreak = client.dateBreak;
    });
  }

  getList() {
    this.shiftService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(shifts => {
      this.shifts = shifts;
    });
  }

  getShiftsFrom(day: number): Shift[] {
    return this.shifts ? this.shifts.filter(shift => shift.day === day) : null;
  }

  onHourChange(hour: number) {
    this.shiftService.setDateBreak(hour);
  }

  edit(day: number) {
    window.localStorage.removeItem("editDayObj");
    localStorage.setItem('editDayObj', JSON.stringify(this.days[day - 1]));
    this.router.navigate(['shift-update']);
  }

}
