<div *ngIf="material" class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="box" [ngStyle]="{'backgroundColor': '#' + material.color}">

    <mat-card-header>
      <mat-card-title>{{material.name}}</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <div class="example-full-width">
        <mat-label>Cor: </mat-label> {{material.color}}
      </div>
    </mat-card-content>

    <button mat-raised-button color="primary" (click)='edit()' class="btn-block">Editar</button>
    <button mat-raised-button color="warn" (click)='inactivate()' class="btn-block">Apagar</button>
  </mat-card>
</div>