import { Component, OnInit, ViewChild } from '@angular/core';
import { GeocercaService } from '../geocerca.service';
import { Geocerca } from '../geocerca.model';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBar } from '@angular/material/snack-bar';
import { NgForm, FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { AgmMap } from '@agm/core';
import { Router } from '@angular/router';
import { GeocercaDialogComponent } from '../geocerca-dialog/geocerca-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ColorEvent } from 'ngx-color';

declare const google: any;

@Component({
  selector: 'app-geocerca-add-manual',
  templateUrl: './geocerca-add-manual.component.html',
  styleUrls: ['./geocerca-add-manual.component.scss']
})
export class GeocercaAddManualComponent implements OnInit {

  pointList: { lat: number, lng: number }[] = [];

  drawingManager: any;
  selectedArea = 0;

  @ViewChild(AgmMap) map: any;
  polygon: any;

  geocerca: Geocerca = new Geocerca();
  uid: string;

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  @ViewChild('myFormPoint') private myFormPoint: NgForm;
  @ViewChild('myForm') private myForm: NgForm;

  private formSubmitAttempt: boolean;

  formPoint = new FormGroup({
    latitude: new FormControl(''),
    longitude: new FormControl('')
  });

  form = new FormGroup({
    name: new FormControl(''),
    color: new FormControl(''),
    load: new FormControl(''),
    unload: new FormControl('')
  });

  constructor(
    public dialog: MatDialog,
    private geocercaService: GeocercaService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,) { }

  openDialog(): void {
    this.dialog.open(GeocercaDialogComponent, {
      width: '900px',
      height: '600px',
      data: { geocerca: this.geocerca }
    });
  }

  ngOnInit() {

    this.form = this.fb.group({
      name: ['', Validators.required],
      color: ['', Validators.required],
      load: [''],
      unload: ['']
    });

    this.formPoint = this.fb.group({
      latitude: ['', Validators.required],
      longitude: ['', Validators.required]
    });

    const geocercaObj = JSON.parse(window.localStorage.getItem("editGeocercaObj"));
    if (geocercaObj) {
      this.uid = geocercaObj;
      this.geocerca = this.geocerca;
      this.pointList = this.geocerca.points;
    }
  }

  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitAttempt)
    );
  }

  isFieldInvalidPoint(field: string) {
    return (
      (!this.formPoint.get(field).valid && this.formPoint.get(field).touched) ||
      (this.formPoint.get(field).untouched && this.formSubmitAttempt)
    );
  }

  deleteSelectedShape() {
    this.pointList = [];
    this.geocerca.points = [];
    this.myFormPoint.resetForm();
  }

  addPoint(lat: any, lng: any) {
    if (this.formPoint.valid) {
      this.pointList.push({ lat: parseFloat(lat), lng: parseFloat(lng) });
      this.geocerca.points = this.pointList;
      this.myFormPoint.resetForm();
    } else this.openSnackBar("Preencha todos os campos");
  }

  onSubmit() {
    if (this.form.valid) {

      if (this.geocerca.points.length < 3) {
        this.openSnackBar("Adicione todos os pontos");
        return;
      }

      if (this.uid != null) {
        this.inactivate();
      }

      this.openSnackBar("Geocerca inserida");
      this.save();
    } else this.openSnackBar("Preencha todos os campos");
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "Fechar", {
      duration: 2000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  save() {
    delete this.geocerca.id;
    this.geocercaService.create(this.geocerca);
    this.geocerca = new Geocerca();
    this.myForm.resetForm();
    this.myFormPoint.resetForm();
    this.deleteSelectedShape()
  }

  inactivate() {
    this.geocercaService.update(this.geocerca.id, { active: false })
      .catch(err => console.log(err));
  }

  ngOnDestroy() {
    window.localStorage.removeItem("editGeocercaObj");
  }

  displayColor: boolean = false;

  changeState(){
    this.displayColor = !this.displayColor;
  }

  changeComplete($event: ColorEvent) {
    this.displayColor = false;

    var color = $event.color.hex.replace(/#/i, '');

    this.geocerca.color = color;
  }
}
