import { Component, OnInit, Input } from '@angular/core';
import { Material } from '../material.model';
import { MaterialService } from '../material.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-material-details',
  templateUrl: './material-detail.component.html',
  styleUrls: ['./material-detail.component.scss']
})
export class MaterialDetailComponent implements OnInit {

  @Input() material: Material;

  constructor(
    private router: Router,
    private materialService: MaterialService) { }

  ngOnInit() {
  }

  inactivate() {
    this.materialService
      .update(this.material.id, { active: false })
      .catch(err => console.log(err));
  }

  edit() {
    window.localStorage.removeItem("editMaterialObj");
    localStorage.setItem('editMaterialObj', JSON.stringify(this.material));
    this.router.navigate(['material-update']);
  }
}
