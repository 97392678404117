<div *ngIf="checklist" class="wrapper">

  <button [disableRipple]="true" class="bt" mat-mini-fab color="warn" type="button" (click)="inactivate()">
    <mat-icon>clear</mat-icon>
  </button>

  <div class="name">
    {{checklist.name}}
  </div>

  <mat-checkbox disabled="true" [(ngModel)]="checklist.critical">
  </mat-checkbox>

</div>