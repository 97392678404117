import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Client } from 'src/app/auth/client.model';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss']
})
export class GeneralComponent implements OnInit {

  isChecked = true;
  askPassword: Boolean = true
  formGroup: FormGroup;

  client: Client

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  constructor(
    formBuilder: FormBuilder,
    private authService: AuthService,
    private snackBar: MatSnackBar) {
    this.formGroup = formBuilder.group({
      enableMaintenance: [''],
      maintenanceManager: [''],
      maintenanceOperator: [''],
    });
  }

  ngOnInit(): void {
    this.authService.getClient().valueChanges().subscribe(client => {
      this.client = client
    });
  }

  onFormSubmit() {
    this.client.maintenanceManager = this.client.enableMaintenance
    if (!this.client.enableMaintenance) this.client.maintenanceOperator = false
    
    this.authService.setClient(this.client)
    this.openSnackBar('Configurações atualizadas')
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "Fechar", {
      duration: 2000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }
}
