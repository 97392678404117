<h1 mat-dialog-title>Modelo do gráfico</h1>

<table>
  <td>
    <h1 mat-dialog-title>Operação</h1>
    <mat-action-list role="list">
      <button mat-list-item (click)="onClick(graph)" *ngFor="let graph of operacao"> {{ graph.default_title}}
      </button>
    </mat-action-list>
  </td>
  <td>
    <h1 mat-dialog-title>Manutenção</h1>
    <mat-action-list role="list">
      <button mat-list-item (click)="onClick(graph)" *ngFor="let graph of manutencao"> {{ graph.default_title}}
      </button>
    </mat-action-list>
  </td>
</table>