import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/shared/models/user.interface';
import { UserService } from '../user.service';

@Component({
  selector: 'app-user-item',
  templateUrl: './user-item.component.html',
  styleUrls: ['./user-item.component.scss']
})
export class UserItemComponent implements OnInit {

  @Input() user: User;

  constructor(
    private router: Router,
    private userService: UserService) { }

  ngOnInit() {
  }

  enable(enabled: boolean) {
    this.userService
      .update(this.user.uid, { enabled: enabled })
      .catch(err => console.log(err));
  }

  edit() {
    window.localStorage.removeItem("editUserObj");
    localStorage.setItem('editUserObj', JSON.stringify(this.user));
    this.router.navigate(['user-create']);
  }
}
