<body>

  <div class="login-wrapper" fxLayout="row wrap" fxLayout.lt-sm="column wrap" fxLayoutAlign="center center">
    <mat-form-field class="example-full-width mat-elevation-z7">
      <mat-label class="example-header">Equipamento</mat-label>
      <mat-select [(value)]="equipment" (selectionChange)="onDateChange(dateStart.value, dateEnd.value)">
        <mat-option [value]="'all'">Todos</mat-option>
        <mat-option *ngFor="let equipment of equipments" [value]="equipment.id">
          {{equipment.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="example-full-width mat-elevation-z7">
      <mat-label class="example-header">Classe de tempo</mat-label>
      <mat-select [(value)]="subClass" (selectionChange)="onDateChange(dateStart.value, dateEnd.value)">
        <mat-option [value]="0">Todas</mat-option>
        <mat-option *ngFor="let time of times" [ngStyle]="{'backgroundColor': getColor(time.timeClass)}"
          [value]="time.subClass">
          {{time.label}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="example-full-width mat-elevation-z7">
      <mat-label class="example-header">Frota</mat-label>
      <mat-select [(value)]="fleet" (selectionChange)="onDateChange(dateStart.value, dateEnd.value)">
        <mat-option [value]="'all'">Todas</mat-option>
        <mat-option *ngFor="let fleet of fleets" [value]="fleet.id">
          {{fleet.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="example-full-width mat-elevation-z7">
      <mat-label class="example-header">Início</mat-label>
      <input matInput #dateStart [owlDateTimeTrigger]="dt12" [owlDateTime]="dt12" [selectMode]="'rangeFrom'"
        [(ngModel)]="selectedMoments" (dateTimeInput)="onDateChange(dateStart.value, dateEnd.value)">
      <owl-date-time #dt12></owl-date-time>
    </mat-form-field>

    <mat-form-field class="example-full-width mat-elevation-z7">
      <mat-label class="example-header">Fim</mat-label>
      <input matInput #dateEnd [owlDateTimeTrigger]="dt13" [owlDateTime]="dt13" [selectMode]="'rangeTo'"
        [(ngModel)]="selectedMoments" (dateTimeInput)="onDateChange(dateStart.value, dateEnd.value)">
      <owl-date-time #dt13></owl-date-time>
    </mat-form-field>

    <button mat-raised-button color="basic" type="submit" (click)="clear()" class="btn-block">Limpar</button>
  </div>

  <mat-form-field class="example-fill-width mat-elevation-z3">
    <mat-label>Filtro</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="" #input>
  </mat-form-field>

  <div class="container mat-elevation-z8">
    <mat-table matTableExporter [dataSource]="dataSource" #exporter="matTableExporter" [hiddenColumns]="[8]" matSort>

      <ng-container matColumnDef="date_start">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Início </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.date_start.toDate() | date:'dd/MM/yyyy - HH:mm:ss'}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="date_end">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Fim </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{getDateEnd(row.date_end).toDate() | date:'dd/MM/yyyy - HH:mm:ss'}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="duration">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Duração </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{getDuration(row.date_start, row.date_end)}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="geocerca">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Geocerca </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.geocerca}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="fleet">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Frota </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.fleet}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="equipment">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Equipamento </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.equipment}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="operator">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Operador </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.operator}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.status}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="map">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Ações </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button mat-icon-button (click)="openDialog(row)">
            <mat-icon>map</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="8">No data matching the filter "{{input.value}}"</td>
      </tr>
    </mat-table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100, 1000, 10000]" showFirstLastButtons></mat-paginator>
  </div>
  <button class="commands" mat-raised-button
    (click)="exporter.exportTable('xlsx', {fileName:'Tempos', Props: {Author: 'MinePro'}})">Excel</button>
  <button class="commands" mat-raised-button
    (click)="exporter.exportTable('csv', {fileName:'Tempos', Props: {Author: 'MinePro'}})">Csv</button>
</body>