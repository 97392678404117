<div class="select" fxLayout="row" fxLayoutAlign="center center">
  <mat-form-field class="example-full-width">
    <mat-label>Frota</mat-label>
    <mat-select [(value)]="fleet" (selectionChange)="onUpdateFleet()" [(ngModel)]="fleet">
      <mat-option *ngFor="let fleet of fleets" [value]="fleet.id">
        {{fleet.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<mat-grid-list [cols]="breakpoint" rowHeight="2:1.5" (window:resize)="onResize($event)">
  <div *ngFor="let card of cardsFromFleet">
    <mat-grid-tile>
      <app-card-details [card]='card'></app-card-details>
    </mat-grid-tile>
  </div>
</mat-grid-list>