import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Chart } from 'angular-highcharts';
import { ColorEvent } from 'ngx-color';
import { map } from 'rxjs/operators';
import { Equipment } from 'src/app/equipments/equipment.model';
import { EquipmentService } from 'src/app/equipments/equipment.service';
import { Fleet } from 'src/app/fleets/fleet.model';
import { FleetService } from 'src/app/fleets/fleet.service';
import { Interval } from 'src/app/home/interval.enum';
import { Material } from 'src/app/materials/material.model';
import { MaterialService } from 'src/app/materials/material.service';
import { ChartDash } from '../chart.model';
import { ChartService } from '../chart.service';
import { Unit } from '../unit.enum';

interface ListModel {
  value: number;
  label: string;
}

@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.scss']
})
export class BoardComponent implements OnInit {

  chart: ChartDash = new ChartDash();
  example: Chart;
  fleets: Fleet[];
  equipments: Equipment[];
  materials: Material[];
  fleet: Fleet
  equipment: Equipment
  material: Material

  isPersonalized: boolean = false;
  isHourly: boolean = false;
  isDaily: boolean = false;
  isWeekly: boolean = false;
  isMonthly: boolean = false;
  isProd: boolean = false;

  public max = new Date();

  intervals: ListModel[] = [
    { value: Interval.TURNO_ATUAL, label: 'Turno atual' },
    { value: Interval.TURNO_ANTERIOR, label: 'Turno anterior' },
    { value: Interval.DIA_ATUAL, label: 'Dia atual' },
    { value: Interval.DIA_ANTERIOR, label: 'Dia anterior' },
    { value: Interval.SEMANA_ATUAL, label: 'Semana atual' },
    { value: Interval.SEMANA_ANTERIOR, label: 'Semana anterior' },
    { value: Interval.MES_ATUAL, label: 'Mês atual' },
    { value: Interval.MES_ANTERIOR, label: 'Mês anterior' },
    { value: Interval.ANO_ATUAL, label: 'Ano atual' },
    { value: Interval.ANO_ANTERIOR, label: 'Ano anterior' },
    { value: Interval.MEDIA_MOVEL, label: 'Média móvel' },
    { value: Interval.PERSONALIZADO, label: 'Personalizado' },
  ];

  units: ListModel[] = [
    { value: Unit.VIAGENS, label: 'Viagens' },
    { value: Unit.KT, label: 'Kt (métricas)' },
    { value: Unit.T, label: 'T (métricas)' },
    { value: Unit.KTON, label: 'Kton (curtas)' },
    { value: Unit.TON, label: 'Ton (curtas)' },
    { value: Unit.KG, label: 'Kg' }
  ]

  @ViewChild('myForm') private myForm: NgForm;

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  private formSubmitAttempt: boolean;
  form = new FormGroup({
    title: new FormControl(''),
    use_title: new FormControl(''),

    fleet: new FormControl(''),
    equipment: new FormControl(''),
    material: new FormControl(''),
    interval: new FormControl(''),
    unit: new FormControl(''),
    interval_date: new FormControl(''),
    within_goal_color: new FormControl(''),
    below_goal_color: new FormControl(''),
    above_goal_color: new FormControl(''),
    goal: new FormControl(''),
    negative_tolerance: new FormControl(''),
    positive_tolerance: new FormControl(''),
  });

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private snackBar: MatSnackBar,
    private fleetsService: FleetService,
    private materialService: MaterialService,
    private equipmentService: EquipmentService,
    private chartService: ChartService) { }

  ngOnInit(): void {
    this.getFleets()
    this.getMaterials()

    this.form = this.fb.group({
      title: [],
      use_title: ['', Validators.required],
      fleet: [],
      unit: [],
      equipment: [],
      material: [],
      interval: ['', Validators.required],
      interval_date: [],
      within_goal_color: ['', Validators.required],
      below_goal_color: ['', Validators.required],
      above_goal_color: ['', Validators.required],
      goal: ['', Validators.required],
      negative_tolerance: ['', Validators.required],
      positive_tolerance: ['', Validators.required],
    });

    this.chart = JSON.parse(window.localStorage.getItem("editChartObj"));

    if (!this.chart) {
      this.openSnackBar('Ação inválida.')
      this.router.navigate(['charts']);
      return;
    }

    console.log(this.chart);

    if (this.chart.interval_date) this.isPersonalized = true
    if (this.chart.type === 'PROD') this.isProd = true

  }

  public selectedMoments = [
    new Date(),
    new Date()
  ];

  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitAttempt)
    );
  }

  save() {
    console.log(this.chart);

    if (!this.equipment) {
      delete this.chart.equipment_id
      delete this.chart.equipment_name
    }

    if (!this.material) {
      delete this.chart.material_id
      delete this.chart.material_name
    }

    if (!this.fleet) {
      delete this.chart.fleet_id
      delete this.chart.fleet_name
    }

    if (this.chart.model) {
      delete this.chart.id
      delete this.chart.model
      this.chartService.create(this.chart);
    }
    else
      this.chartService.set(this.chart);
    this.chart = new ChartDash();
  }

  onSubmit() {
    if (this.form.valid) {
      if (!this.chart.use_title && !this.form.get('title').value) {
        this.openSnackBar("Preencha todos os campos");
        return;
      } else if (this.chart.use_title) delete this.chart.title

      if (this.isPersonalized) {
        if (!this.form.get('interval_date').value) {
          this.openSnackBar("Informe as datas do intervalo");
          return;
        }
      } else
        delete this.chart.interval_date

      if (this.chart.goal + this.chart.positive_tolerance > 100) {
        this.openSnackBar("Tolerância positiva maior que 100%")
        return
      } else if (this.chart.goal - this.chart.negative_tolerance < 0) {
        this.openSnackBar("Tolerância negativa menor que 0%")
        return
      }

      this.openSnackBar("Gráfico atualizado");
      this.save();
      this.router.navigate(['charts']);
    } else this.openSnackBar("Preencha todos os campos");
  }

  ngOnDestroy() {
    window.localStorage.removeItem("editChartObj");
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "Fechar", {
      duration: 2000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  getFleets() {
    this.fleetsService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(fleets => {
      this.fleets = fleets;
    });
  }

  getEquipments() {
    let sub = this.equipmentService.getListBy(this.fleet.id).snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(equipments => {
      sub.unsubscribe();
      this.equipments = equipments;
    });
  }

  getMaterials() {
    this.materialService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(materials => {
      this.materials = materials;
    });
  }

  onFleetChange(name: string) {
    if(name === "all") {
      this.fleet = null
      return
    }
    this.fleet = this.getFleetBy(name);
    this.chart.fleet_id = this.fleet.id;
    this.getEquipments()
  }

  onEquipmentChange(name: string) {
    if(name === "all") {
      this.equipment = null
      return
    }
    this.equipment = this.getEquipmentBy(name);
    this.chart.equipment_id = this.equipment.id;
  }

  onMaterialChange(name: string) {
    if(name === "all") {
      this.material = null
      return
    }
    this.material = this.getMaterialBy(name);
    this.chart.material_id = this.material.id;
  }

  onIntervalChange(interval: number) {
    this.isPersonalized = interval == Interval.PERSONALIZADO
    this.isHourly = interval == Interval.TURNO_ATUAL || interval == Interval.TURNO_ANTERIOR || interval == Interval.DIA_ATUAL || interval == Interval.DIA_ANTERIOR
    this.isWeekly = interval == Interval.SEMANA_ATUAL || interval == Interval.SEMANA_ANTERIOR
    this.isDaily = interval == Interval.MES_ATUAL || interval == Interval.MES_ANTERIOR
    this.isMonthly = interval == Interval.ANO_ATUAL || interval == Interval.ANO_ANTERIOR

    this.chart.interval = interval
  }

  onDateChange(dateStart, dateEnd) {

    var start = new Date(dateStart);
    var end = new Date(dateEnd);

    if (!isNaN(+start) && !isNaN(+end))
      console.log({ start, end });
  }

  getFleetBy(name): Fleet {
    return this.fleets.find((item) => item.name === name);
  }

  getEquipmentBy(name): Equipment {
    return this.equipments.find((item) => item.name === name);
  }

  getMaterialBy(name): Material {
    return this.materials.find((item) => item.name === name);
  }

  displayBelowColor: boolean = false;
  displayWithinColor: boolean = false;
  displayAboveColor: boolean = false;

  changeState(type) {
    if (type == 1)
      this.displayBelowColor = !this.displayBelowColor;
    else if (type == 2)
      this.displayWithinColor = !this.displayWithinColor;
    else
      this.displayAboveColor = !this.displayAboveColor;
  }

  changeComplete($event: ColorEvent, type) {
    this.displayBelowColor = false;
    this.displayWithinColor = false;
    this.displayAboveColor = false;

    var color = $event.color.hex.replace(/#/i, '');

    if (type == 1)
      this.chart.below_goal_color = color;

    else if (type == 2)
      this.chart.within_goal_color = color;

    else
      this.chart.above_goal_color = color;

  }
}
