<form class="example-form" [formGroup]="formGroup" (ngSubmit)="onFormSubmit()" ngNativeValidate>

    <!-- <mat-slide-toggle formControlName="tripPeriod" [(ngModel)]="isChecked">Agregar filtros pela data de {{isChecked ? "início" : "término"}} da
        viagem</mat-slide-toggle> -->

    <mat-slide-toggle formControlName="enableMaintenance" [(ngModel)]="client.enableMaintenance">Exigir senha para liberar da manutenção</mat-slide-toggle>
    <mat-slide-toggle *ngIf="client.enableMaintenance" formControlName="maintenanceManager" disabled="true" [(ngModel)]="client.enableMaintenance">Aceitar senha do gestor autorizado</mat-slide-toggle>
    <mat-slide-toggle *ngIf="client.enableMaintenance" formControlName="maintenanceOperator" [(ngModel)]="client.maintenanceOperator">Aceitar senha de qualquer operador</mat-slide-toggle>

    <button mat-raised-button type="submit">Salvar</button>

</form>