import { Component, OnInit, ViewChild } from '@angular/core';
import { Card } from '../card.model';
import { CardService } from '../card.service';
import { Router } from '@angular/router';
import { MatSnackBar, MatSnackBarVerticalPosition, MatSnackBarHorizontalPosition } from '@angular/material/snack-bar';
import { FormGroup, FormControl, Validators, FormBuilder, NgForm } from '@angular/forms';
import { ColorEvent } from 'ngx-color';
import { FleetService } from 'src/app/fleets/fleet.service';
import { map } from 'rxjs/operators';
import { Fleet } from 'src/app/fleets/fleet.model';

interface CardsNumber {
  value: number;
  viewValue: string;
}

interface TimeClass {
  subClass: number;
  timeClass: number;
  label: string;
}

@Component({
  selector: 'app-card-update',
  templateUrl: './card-update.component.html',
  styleUrls: ['./card-update.component.scss']
})
export class CardUpdateComponent implements OnInit {

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  fleets: Fleet[]
  card: Card = new Card();

  @ViewChild('myForm') private myForm: NgForm;

  private formSubmitAttempt: boolean;
  form = new FormGroup({
    name: new FormControl(''),
    color: new FormControl(''),
    number: new FormControl(''),
    subClass: new FormControl(''),
    fleet: new FormControl('')
  });

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private fleetService: FleetService,
    private snackBar: MatSnackBar,
    private cardService: CardService) { }

  ngOnInit() {
    this.getFleets()
    this.form = this.fb.group({
      name: ['', Validators.required],
      color: ['', Validators.required],
      number: ['', Validators.required],
      subClass: ['', Validators.required],
      fleet: ['', Validators.required]
    });

    this.card = JSON.parse(window.localStorage.getItem("editCardObj"));
    if (!this.card) {
      this.openSnackBar('Ação inválida.')
      this.router.navigate(['cards']);
      return;
    }
  }

  cards: CardsNumber[] = [
    { value: 2, viewValue: '2º card' },
    { value: 3, viewValue: '3º card' },
    { value: 4, viewValue: '4º card' },
    { value: 5, viewValue: '5º card' }
  ];

  times: TimeClass[] = [
    { subClass: 1, timeClass: 1, label: 'Operando' },
    { subClass: 2, timeClass: 1, label: 'Infraestrutura' },
    { subClass: 3, timeClass: 2, label: 'Atraso interno' },
    { subClass: 4, timeClass: 2, label: 'Impedimento externo' },
    { subClass: 5, timeClass: 3, label: 'Corretiva' },
    { subClass: 6, timeClass: 3, label: 'Preventiva' },
  ];

  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitAttempt)
    );
  }

  save() {
    const fleet = this.getFleetBy(this.card.fleet);
    this.card.fleetName = fleet.name;
    delete this.card.id;
    this.cardService.create(this.card);
    this.card = new Card();
  }

  getFleetBy(id: string) : Fleet{
    return this.fleets.find((item) => item.id === id);
  }

  getColor(n: number) {
    let color: string;
    switch (n) {
      case 1: color = '#99ff99'
        break;
      case 2: color = '#ff9999'
        break;
      case 3: color = '#ffff99'
        break;
    }
    return color;
  }

  inactivate() {
    this.cardService.update(this.card.id, { active: false })
      .catch(err => console.log(err));
  }

  onSubmit() {
    if (this.form.valid) {
      this.openSnackBar("Card atualizado");
      this.inactivate();
      this.save();
      this.router.navigate(['cards']);
    } else this.openSnackBar("Preencha todos os campos");
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "Fechar", {
      duration: 2000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  ngOnDestroy() {
    window.localStorage.removeItem("editCardObj");
  }

  displayColor: boolean = false;

  changeState() {
    this.displayColor = !this.displayColor;
  }

  changeComplete($event: ColorEvent) {
    this.displayColor = false;

    var color = $event.color.hex.replace(/#/i, '');
    this.card.color = color;
  }
  
  setClass(subClass) {
    const time = this.times.filter(time => time.subClass === subClass)[0];
    this.card.timeClass = time.timeClass;
  }

  getFleets() {
    this.fleetService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(fleets => {
      this.fleets = fleets;
    });
  }
}
