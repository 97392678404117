import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormControl, Validators, FormBuilder, NgForm } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { ProfileService } from '../profile.service';
import { Profile } from '../profile.model';
import { ProfileConfig } from '../profile-config.model';

@Component({
  selector: 'app-profile-create',
  templateUrl: './profile-create.component.html',
  styleUrls: ['./profile-create.component.scss']
})
export class ProfileCreateComponent implements OnInit {

  profile: Profile;
  toUpdate: boolean = false
  title: string = "Adicionar perfil"
  button: string = "Adicionar"

  @ViewChildren("checkboxes") checkboxes: QueryList<MatCheckbox>;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  @ViewChild('myForm') private myForm: NgForm;

  private formSubmitAttempt: boolean;

  form = new FormGroup({
    name: new FormControl(''),
    identifier: new FormControl('')
  });

  private readonly PROFILE_OBJ = "editProfileObj";

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private profileService: ProfileService) { }

  ngOnInit() {
    this.form = this.fb.group({
      name: ['', Validators.required]
    });

    this.profile = JSON.parse(window.localStorage.getItem(this.PROFILE_OBJ));

    if (!this.profile) {
      this.profile = new Profile()
    } else {
      this.toUpdate = true
      this.title = "Alterar perfil"
      this.button = "Alterar"
    }
  }

  ngOnDestroy() {
    window.localStorage.removeItem(this.PROFILE_OBJ);
  }

  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitAttempt)
    );
  }

  save() {
    if (!this.toUpdate)
      this.profileService.create(this.profile);
    else
      this.profileService.update(this.profile.id, this.profile);

    this.myForm.resetForm();
    this.uncheckAll()
    this.profile = new Profile();
  }

  onSubmit() {
    if (this.form.valid) {
      this.openSnackBar(this.toUpdate ? "Perfil atualizado" : "Perfil inserido");
      this.save();
    } else this.openSnackBar("Preencha todos os campos");
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "Fechar", {
      duration: 2000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  onConfigsChange(config: ProfileConfig, checked: boolean) {
    this.profile.configs.find(it => it.key === config.key).value = checked
  }

  onReportsChange(config: ProfileConfig, checked: boolean) {
    this.profile.reports.find(it => it.key === config.key).value = checked
  }

  onReportsEditChange(config: ProfileConfig, checked: boolean) {
    this.profile.reportsEdit.find(it => it.key === config.key).value = checked
  }

  uncheckAll() {
    this.checkboxes.forEach((element) => {
      element.checked = false;
    });
  }
}
