<div class="list">
    <div class="wrapper">
        <div class="name">
            Nome
        </div>
    </div>

    <div *ngFor="let user of users">
        <app-user-profile-item [user]='user' [profiles]='profiles'></app-user-profile-item>
    </div>

    <button mat-mini-fab color="primary" type="button" [routerLink]="['/user-create']">
        <mat-icon>add</mat-icon>
    </button>
</div>