export const environment = {
  production: true,
  appVersion: require('../../package.json').version,
  firebaseConfig: {
    apiKey: "AIzaSyAq3YENIEkAg2HIYZ0Ct2_tosG_c1mNNm4",
    authDomain: "exact-270817.firebaseapp.com",
    databaseURL: "https://exact-270817.firebaseio.com",
    projectId: "exact-270817",
    storageBucket: "exact-270817.appspot.com",
    messagingSenderId: "802174540359",
    appId: "1:802174540359:web:1dc924c1f5de8ccd2a6f62",
    measurementId: "G-J1VD4BLD6C"
  },
  googleMapsKey: "AIzaSyDd4JpFSlDuVDubvW6KnOkcVaLSRXurVEw"
};
