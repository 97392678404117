import { ProfileConfig } from "./profile-config.model"

export class Profile {
    id: string
    name: string
    custom: boolean
    configs: ProfileConfig[] = []
    reports: ProfileConfig[] = []
    reportsEdit: ProfileConfig[] = []

    constructor(id: string = "", name: string = "") {
        const configs: ProfileConfig[] = [
            { value: false, key: "profiles", name: "Perfis" },
            { value: false, key: "dashboards", name: "Dashboards" },
            { value: false, key: "shifts", name: "Turnos" },
            { value: false, key: "cards", name: "Cards" },
            { value: false, key: "material", name: "Materiais" },
            { value: false, key: "equipment", name: "Equipamentos" },
            { value: false, key: "checklist", name: "Checklists" },
            { value: false, key: "drivers", name: "Operador" },
            { value: false, key: "fleet", name: "Frotas" },
            { value: false, key: "geocerca", name: "Geocerca" },
            { value: false, key: "contingencies", name: "Contingências" },
            { value: false, key: "maintenance", name: "Liberar manutenção" },
        ];

        const reports: ProfileConfig[] = [
            { value: false, key: "trips", name: "Viagens" },
            { value: false, key: "records", name: "Tempos" },
            { value: false, key: "speed", name: "Velocidade" },
            { value: false, key: "fuel", name: "Abastecimento" },
            { value: false, key: "checklist", name: "Checklists" },
            { value: false, key: "realtime", name: "Mapa Realtime" },
            { value: false, key: "timeline", name: "Mapa Timeline" },
            { value: false, key: "contingencies", name: "Contingências" },
            { value: false, key: "dispatch", name: "Despacho" },
            { value: false, key: "geofence", name: "Geocercas" },
            { value: false, key: "maintenance", name: "Manutenção" },
        ];

        const reportsEdit: ProfileConfig[] = [
            { value: false, key: "trips", name: "Edição de Viagens" },
            { value: false, key: "records", name: "Edição de Tempos" },
        ];

        if (name) {
            this.id = id
            this.name = name
        }
        
        this.configs = configs
        this.reports = reports
        this.reportsEdit = reportsEdit
        this.custom = true
    }
}
