import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Geocerca } from '../geocerca.model';
import { GeocercaService } from '../geocerca.service';
import { MatDialog } from '@angular/material/dialog';
import { GeocercaDialogComponent } from '../geocerca-dialog/geocerca-dialog.component';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

export interface GeocercaDialogData {
  geocerca: Geocerca;
}

declare const google: any;

@Component({
  selector: 'app-geocerca-list',
  templateUrl: './geocerca-list.component.html',
  styleUrls: ['./geocerca-list.component.scss']
})
export class GeocercaListComponent implements OnInit {

  dataSource: MatTableDataSource<Geocerca>;
  geocercas: any;
  geocerca: Geocerca;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  
  displayedColumns: string[] = ['name', 'color', 'load', 'unload', 'map'];
  
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private geocercaService: GeocercaService) { }

  openDialog(): void {
    const dialogRef = this.dialog.open(GeocercaDialogComponent, {
      width: '900px',
      height: '600px',
      data: { geocerca: this.geocerca }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openMap(geocerca: Geocerca) {
    this.geocerca = geocerca;
    this.openDialog();
    // this.getTripList(trip.date_start.toDate(), trip.date_end.toDate(), trip.equipment_id);
  }

  delete(geocerca: Geocerca) {
    this.geocercaService.update(geocerca.id, {active: false});
  }
  
  edit(geocerca: Geocerca) {
    window.localStorage.removeItem("editGeocercaObj");
    localStorage.setItem('editGeocercaObj', JSON.stringify(geocerca));
    this.router.navigate(['geocerca-add']);
  }

  ngOnInit(): void {
    this.getList();
  }

  getList() {
    this.geocercaService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(geocercas => {
      this.geocercas = geocercas;
      this.dataSource = new MatTableDataSource(geocercas);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
}
