import { Component, OnInit, Input } from '@angular/core';
import { Card } from '../card.model';
import { CardService } from '../card.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-card-details',
  templateUrl: './card-details.component.html',
  styleUrls: ['./card-details.component.scss']
})
export class CardDetailsComponent implements OnInit {

  @Input() card: Card;
 
  constructor(
    private router: Router,
    private cardService: CardService) { }
 
  ngOnInit() {
  }

  inactivate() {
    this.cardService
      .update(this.card.id, { active: false })
      .catch(err => console.log(err));
  }

  edit() {
    window.localStorage.removeItem("editCardObj");
    localStorage.setItem('editCardObj', JSON.stringify(this.card));
    this.router.navigate(['card-update']);
  }
}
