<h1 mat-dialog-title>{{data.geocerca.name}}
</h1>
<div mat-dialog-content>
  <agm-map (mapReady)="mapReady($event)" [latitude]="data.geocerca.points[0].lat" [longitude]="data.geocerca.points[0].lng" [zoom]="15"
    [fullscreenControl]='true' [mapTypeControl]='true' [mapTypeId]="'satellite'">

    <agm-polygon [paths]="data.geocerca.points" [fillColor]="'#' + data.geocerca.color"
      [strokeColor]="'#' + data.geocerca.color">
    </agm-polygon>
  </agm-map>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Fechar</button>
</div>