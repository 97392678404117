<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">

  <mat-card class="box">

    <mat-card-header>
      <img mat-card-image class="logo-image" src="../../../assets/images/logo.png" alt="Logo MinePro">
    </mat-card-header>

    <form [formGroup]="loginForm" (ngSubmit)="onLogin()" class="example-form">
      <mat-card-content>
        <mat-form-field class="example-full-width">
          <input matInput type="email" placeholder="Email" formControlName="email" required>
          <mat-error *ngIf="isFieldInvalid('email')">
            Por favor informe o email
          </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <input matInput type="password" placeholder="Senha" formControlName="password" required>
          <mat-error *ngIf="isFieldInvalid('password')">
            Por favor informe a senha
          </mat-error>
        </mat-form-field>
      </mat-card-content>

      <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
      <button mat-raised-button color="primary" type="submit" class="btn-block">Login</button>
    </form>

    <!-- <mat-card-bottom>
      <div>
        <p class="bottom">
          Não tem uma conta? <a [routerLink]="['/register']"> Registrar </a>
        </p>
      </div>
    </mat-card-bottom> -->
  </mat-card>
</div>