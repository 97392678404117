import { Component, OnInit, ViewChild } from '@angular/core';
import { Driver } from '../driver.model';
import { DriverService } from '../driver.service';
import { FleetService } from 'src/app/fleets/fleet.service';
import { map } from 'rxjs/operators';
import { Route } from '@angular/compiler/src/core';
import { Router } from '@angular/router';
import { Fleet } from 'src/app/fleets/fleet.model';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition
} from '@angular/material/snack-bar';
import { FormControl, NgForm, FormGroup, Validators, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-driver-update',
  templateUrl: './driver-update.component.html',
  styleUrls: ['./driver-update.component.scss']
})
export class DriverUpdateComponent implements OnInit {

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  fleets: any;
  driver: Driver = new Driver();

  @ViewChild('myForm') private myForm: NgForm;

  private formSubmitAttempt: boolean;

  form = new FormGroup({
    name: new FormControl(''),
    identifier: new FormControl('')
  });

  private readonly DRIVER_OBJ = "editDriverObj";

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private driverService: DriverService,
    private fleetsService: FleetService,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      identifier: ['', Validators.required]
    });

    this.driver = JSON.parse(window.localStorage.getItem(this.DRIVER_OBJ));
    if (!this.driver) {
      this.openSnackBar('Ação inválida.')
      this.router.navigate(['drivers']);
      return;
    }
    this.getFleets();
  }

  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitAttempt)
    );
  }

  new(): void {
    this.driver = new Driver();
  }

  save() {
    delete this.driver.id;
    this.driverService.create(this.driver);
    this.myForm.resetForm();
    this.driver = new Driver();
  }

  inactivate() {
    this.driverService.update(this.driver.id, { active: false })
      .catch(err => console.log(err));
  }

  onSubmit() {
    if (this.form.valid) {
      this.inactivate();
      this.save();
      this.openSnackBar('Operador atualizado')
      this.router.navigate(['drivers']);
    } else this.openSnackBar("Preencha todos os campos");
  }

  getFleets() {
    this.fleetsService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(fleets => {
      this.fleets = fleets;
    });
  }

  onChangeFleets(id, name, event) {
    if (event.checked) {
      this.driver.fleets.push({ id: id, name: name });
    } else {
      this.driver.fleets = this.driver.fleets.filter((item) => item.id !== id);
    }
  }

  isSelected(fleet: Fleet) {
    return (this.driver.fleets.some(e => e.id === fleet.id));
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "Fechar", {
      duration: 2000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  ngOnDestroy() {
    window.localStorage.removeItem(this.DRIVER_OBJ);
  }

}
