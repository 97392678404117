import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { Map } from './map.model';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  private dbPath = '/users';

  positions: AngularFireList<Map>;
  
  constructor(db: AngularFireDatabase) {
    var client = JSON.parse(localStorage.getItem('documentData')).client_id;

    var today = new Date();
    var yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    this.positions = db.list(this.dbPath + "/" + client, ref =>
    ref.orderByChild('date').startAt(yesterday.getTime()));
  }

  getList(): AngularFireList<Map> {
    return this.positions;
  }

}
