<div *ngIf="user" class="wrapper">

    <div class="email">
        {{user.email}}
    </div>

    <mat-form-field class="example-full-width">
        <mat-select [(value)]="user.profile" (selectionChange)="onChange()">
            <mat-option *ngFor="let role of profiles"
                [value]="role.id">
                {{role.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>

</div>