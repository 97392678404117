import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { Checklist } from './checklist.model';

@Injectable({
  providedIn: 'root'
})
export class ChecklistService {
  private dbPath = '/checklist';
  client: string;

  collection: AngularFirestoreCollection<Checklist> = null;

  constructor(db: AngularFirestore) {
    this.client = JSON.parse(localStorage.getItem('documentData')).client_id;
    this.collection = db.collection('clients').doc(this.client).collection(this.dbPath, ref => ref.where('active', '==', true));
  }

  create(customer: Checklist): void {
    this.collection.add({ ...customer });
  }

  update(key: string, value: any): Promise<void> {
    return this.collection.doc(key).update(value);
  }

  delete(key: string): Promise<void> {
    return this.collection.doc(key).delete();
  }

  getList(): AngularFirestoreCollection<Checklist> {
    return this.collection;
  }
}
