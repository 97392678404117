import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-fleet-dialog',
  templateUrl: './fleet-dialog.component.html',
  styleUrls: ['./fleet-dialog.component.scss']
})
export class FleetDialogComponent {

  images: string[];
  selectedImage: string | null = null;

  constructor(
    public dialogRef: MatDialogRef<FleetDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
      this.images = data.images;
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  setImage(image: string){
    this.selectedImage = image;
    this.dialogRef.close(this.selectedImage);
  }
}
