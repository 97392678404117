import { Component, OnInit, Input } from '@angular/core';
import { Driver } from '../driver.model';
import { DriverService } from '../driver.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-driver-details',
  templateUrl: './driver-details.component.html',
  styleUrls: ['./driver-details.component.scss']
})
export class DriverDetailsComponent implements OnInit {

  @Input() driver: Driver;

  constructor(private router: Router,
    private driverService: DriverService) { }

  ngOnInit() {
  }

  inactivate() {
    this.driverService
      .update(this.driver.id, { active: false })
      .catch(err => console.log(err));
  }

  edit() {
    window.localStorage.removeItem("editDriverObj");
    localStorage.setItem('editDriverObj', JSON.stringify(this.driver));
    this.router.navigate(['driver-update']);
  }
}
