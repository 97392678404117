import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { Fleet } from './fleet.model';

@Injectable({
  providedIn: 'root'
})
export class FleetService {
  private dbPath = '/fleets';

  collection: AngularFirestoreCollection<Fleet> = null;

  constructor(private db: AngularFirestore) {
    var client = JSON.parse(localStorage.getItem('documentData')).client_id;
    this.collection = db.collection('clients').doc(client).collection(this.dbPath, ref => ref.where('active', '==', true));
  }

  create(customer: Fleet): void {
    this.collection.add({ ...customer });
  }

  update(key: string, value: any): Promise<void> {
    return this.collection.doc(key).update(value);
  }

  delete(key: string): Promise<void> {
    return this.collection.doc(key).delete();
  }

  getList(): AngularFirestoreCollection<Fleet> {
    return this.collection;
  }

  deleteAll() {
    this.collection.get().subscribe(
      querySnapshot => {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
        });
      },
      error => {
        console.log('Error: ', error);
      });
  }
}
