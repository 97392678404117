import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { User } from '../shared/models/user.interface';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  client: string;

  collection: AngularFirestoreCollection<User> = null;

  constructor(private db: AngularFirestore) {
    const document = JSON.parse(localStorage.getItem('documentData'));
    if (document) {
      this.client = document.client_id;
      this.collection = db.collection('users', ref => ref.where('client_id', '==', this.client).where('enabled', "==", true));
    }
  }

  create(customer: User): void {
    this.collection.add({ ...customer });
  }

  update(key: string, value: any): Promise<void> {
    return this.collection.doc(key).set(value, { merge: true });
  }

  delete(key: string): Promise<void> {
    return this.collection.doc(key).delete();
  }

  getList(): AngularFirestoreCollection<User> {
    return this.collection;
  }

  getAll(): AngularFirestoreCollection<User> {
    return this.db.collection('users', ref => ref.where('client_id', '==', this.client));
  }
}
