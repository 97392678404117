import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBar } from '@angular/material/snack-bar';
import { User } from 'src/app/shared/models/user.interface';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})

export class RegisterComponent {

  private formSubmitAttempt: boolean;
  private horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  private verticalPosition: MatSnackBarVerticalPosition = 'top';
  isLoading = false;

  registerForm = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
    client: new FormControl(''),
    name: new FormControl(''),
  });

  async onRegister() {
    const { email, password, client, name } = this.registerForm.value;
    try {
      let user: User = new User(email, password, '')
      user.client_name = client
      user.displayName = name
      await this.authSvc.register(user, true)
        .then(_ => {
          this.checkUserIsVerified();
        }).catch(error => {
          let message: string = "";
          if (error.code === 'auth/email-already-exists' || error.code === "auth/email-already-in-use") message = "Email já utilizado"
          else if (error.code === "auth/weak-password") message = "Senha menor que 6 caracteres"
          else if (error === 200) message = "Cliente já existe"
          else message = "Falha ao inserir"

          this.openSnackBar(message);

        })
      this.isLoading = false;

    } catch (error) {

      if (error.code === 'auth/email-already-exists' ||
        error.code === 'auth/email-already-in-use') {
        this.openSnackBar('Já existe uma conta com esse email')
      } else if (error.code === 'auth/invalid-email') {
        this.openSnackBar('Email inválido')
      }

      this.isLoading = false;
      console.log(error);
    }
  }

  constructor(
    private fb: FormBuilder,
    private authSvc: AuthService,
    private router: Router,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.registerForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      client: ['', Validators.required],
      name: ['', Validators.required]
    });
  }

  isFieldInvalid(field: string) {
    return (
      (!this.registerForm.get(field).valid && this.registerForm.get(field).touched) ||
      (this.registerForm.get(field).untouched && this.formSubmitAttempt)
    );
  }

  @Input() error: string | null;

  private checkUserIsVerified() {
    if (this.authSvc.isEmailVerified) {
      this.router.navigate(['/home']);
    } else if (this.authSvc.isAuthenticated) {
      this.router.navigate(['/verification']);
    } else {
      this.router.navigate(['/register']);
    }
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "Fechar", {
      duration: 2000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }
}