import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBarVerticalPosition, MatSnackBarHorizontalPosition, MatSnackBar } from '@angular/material/snack-bar';
import { NgForm, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { User } from 'src/app/shared/models/user.interface';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  user: User = new User()

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  @ViewChild('myForm') private myForm: NgForm;

  private formSubmitAttempt: boolean;

  form = new FormGroup({
    name: new FormControl(''),
    email: new FormControl({ disabled: true })
  });

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private authService: AuthService) { }

  ngOnInit() {

    this.user = this.authService.getUser
    console.log(this.user);

    this.form = this.fb.group({
      name: ['', Validators.required],
      email: []
    });
  }

  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitAttempt)
    );
  }

  onSubmit() {
    if (this.form.valid) {
      this.openSnackBar("Usuário atualizado");
      this.save();
    } else this.openSnackBar("Preencha todos os campos");
  }

  save() {
    this.authService.updateUser(this.user, true)
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "Fechar", {
      duration: 2000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  resetPassword() {
    this.authService.resetPassword(this.user.email)
    this.openSnackBar("Email enviado!");
  }

}
