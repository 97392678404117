<mat-grid-list [cols]="4" rowHeight="2:1.5">
    <mat-grid-tile>
        <mat-card class="box_break">
            <mat-card-header>
                <mat-card-title>Quebra de data</mat-card-title>
                <mat-card-content>
                    <mat-form-field class="example-full-width">
                        <mat-select [(value)]="dateBreak" (selectionChange)="onHourChange(dateBreak)">
                          <mat-option *ngFor="let hour of hours" [value]="hour.value">
                            {{hour.label}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                </mat-card-content>
            </mat-card-header>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile>
        <mat-card class="box" (click)="edit(1)">
            <mat-card-header>
                <mat-card-title>Domingo</mat-card-title>
                <mat-card-content>
                    <mat-action-list role="list">
                        <mat-list-item role="listitem" *ngFor="let shift of getShiftsFrom(1)">{{shift.label}}
                        </mat-list-item>
                    </mat-action-list>
                </mat-card-content>
            </mat-card-header>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile>
        <mat-card class="box" (click)="edit(2)">
            <mat-card-header>
                <mat-card-title>Segunda-feira</mat-card-title>
                <mat-card-content>
                    <mat-action-list role="list">
                        <mat-list-item role="listitem" *ngFor="let shift of getShiftsFrom(2)">{{shift.label}}
                        </mat-list-item>
                    </mat-action-list>
                </mat-card-content>
            </mat-card-header>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile>
        <mat-card class="box" (click)="edit(3)">
            <mat-card-header>
                <mat-card-title>Terça-feira</mat-card-title>
                <mat-card-content>
                    <mat-action-list role="list">
                        <mat-list-item role="listitem" *ngFor="let shift of getShiftsFrom(3)">{{shift.label}}
                        </mat-list-item>
                    </mat-action-list>
                </mat-card-content>
            </mat-card-header>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile>
        <mat-card class="box" (click)="edit(4)">
            <mat-card-header>
                <mat-card-title>Quarta-feira</mat-card-title>
                <mat-card-content>
                    <mat-action-list role="list">
                        <mat-list-item role="listitem" *ngFor="let shift of getShiftsFrom(4)">{{shift.label}}
                        </mat-list-item>
                    </mat-action-list>
                </mat-card-content>
            </mat-card-header>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile>
        <mat-card class="box" (click)="edit(5)">
            <mat-card-header>
                <mat-card-title>Quinta-feira</mat-card-title>
                <mat-card-content>
                    <mat-action-list role="list">
                        <mat-list-item role="listitem" *ngFor="let shift of getShiftsFrom(5)">{{shift.label}}
                        </mat-list-item>
                    </mat-action-list>
                </mat-card-content>
            </mat-card-header>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile>
        <mat-card class="box" (click)="edit(6)">
            <mat-card-header>
                <mat-card-title>Sexta-feira</mat-card-title>
                <mat-card-content>
                    <mat-action-list role="list">
                        <mat-list-item role="listitem" *ngFor="let shift of getShiftsFrom(6)">{{shift.label}}
                        </mat-list-item>
                    </mat-action-list>
                </mat-card-content>
            </mat-card-header>
        </mat-card>
    </mat-grid-tile>
    <mat-grid-tile>
        <mat-card class="box" (click)="edit(7)">
            <mat-card-header>
                <mat-card-title>Sábado</mat-card-title>
                <mat-card-content>
                    <mat-action-list role="list">
                        <mat-list-item role="listitem" *ngFor="let shift of getShiftsFrom(7)">{{shift.label}}
                        </mat-list-item>
                    </mat-action-list>
                </mat-card-content>
            </mat-card-header>
        </mat-card>
    </mat-grid-tile>
</mat-grid-list>