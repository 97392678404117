import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Contingency } from '../contingency.model';
import { ContingencyService } from '../contingency.service';

@Component({
  selector: 'app-contingency-filter-trip',
  templateUrl: './contingency-filter-trip.component.html',
  styleUrls: ['./contingency-filter-trip.component.scss']
})
export class ContingencyFilterTripComponent implements OnInit {

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  
  contingency: Contingency
  default_contingency: Contingency

  constructor(
    private contingencyService: ContingencyService,
    private snackBar: MatSnackBar
    ) { }

  ngOnInit(): void {

    this.contingencyService.get('default_trip').valueChanges()
      .subscribe((trip) => {
        this.default_contingency = trip
        this.contingencyService.get('trip').valueChanges()
          .subscribe((trip) => {
            if (trip)
              this.contingency = trip
            else {
              this.contingency = this.default_contingency
            }
          });
      });
  }

  onSave() {
    this.openSnackBar("Filtros atualizados")
    this.contingencyService.create('trip', this.contingency)
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "Fechar", {
      duration: 2000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }
}
