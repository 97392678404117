import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Chart } from 'angular-highcharts';
import firebase, { firestore } from 'firebase';
import { ColorEvent } from 'ngx-color';
import { map } from 'rxjs/operators';
import { Fleet } from 'src/app/fleets/fleet.model';
import { FleetService } from 'src/app/fleets/fleet.service';
import { Dashboard } from 'src/app/home/dashboard.enum';
import { Interval } from 'src/app/home/interval.enum';
import { ChartDash } from '../chart.model';
import { ChartService } from '../chart.service';
import { Unit } from '../unit.enum';

interface IntervalList {
  value: number;
  label: string;
}

@Component({
  selector: 'app-chart-pizza',
  templateUrl: './chart-pizza.component.html',
  styleUrls: ['./chart-pizza.component.scss']
})
export class ChartPizzaComponent implements OnInit {

  chart: ChartDash = new ChartDash();
  example: Chart;
  fleets: Fleet[];
  isPersonalized: boolean = false;
  isMovel: boolean = false;
  public max = new Date();

  intervals: IntervalList[] = [
    { value: Interval.TURNO_ATUAL, label: 'Turno atual' },
    { value: Interval.TURNO_ANTERIOR, label: 'Turno anterior' },
    { value: Interval.DIA_ATUAL, label: 'Dia atual' },
    { value: Interval.DIA_ANTERIOR, label: 'Dia anterior' },
    { value: Interval.SEMANA_ATUAL, label: 'Semana atual' },
    { value: Interval.SEMANA_ANTERIOR, label: 'Semana anterior' },
    { value: Interval.MES_ATUAL, label: 'Mês atual' },
    { value: Interval.MES_ANTERIOR, label: 'Mês anterior' },
    { value: Interval.ANO_ATUAL, label: 'Ano atual' },
    { value: Interval.ANO_ANTERIOR, label: 'Ano anterior' },
    { value: Interval.MEDIA_MOVEL, label: 'Média móvel' },
    { value: Interval.PERSONALIZADO, label: 'Personalizado' },
  ];

  @ViewChild('myForm') private myForm: NgForm;

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  private formSubmitAttempt: boolean;
  form = new FormGroup({
    title: new FormControl(''),
    use_title: new FormControl(''),
    within_goal_color: new FormControl(''),
    below_goal_color: new FormControl(''),
    above_goal_color: new FormControl(''),
    fleet: new FormControl(''),
    interval: new FormControl(''),
    goal: new FormControl(''),
    negative_tolerance: new FormControl(''),
    positive_tolerance: new FormControl(''),
    movel: new FormControl(''),
    interval_date: new FormControl(''),
  });

  constructor(
    private fleetsService: FleetService,
    private fb: FormBuilder,
    private router: Router,
    private snackBar: MatSnackBar,
    private chartService: ChartService) { }

  ngOnInit(): void {
    this.getFleets();


    var today = new Date();
    var yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    this.selectedMoments = [yesterday, today];

    this.form = this.fb.group({
      title: [],
      use_title: ['', Validators.required],
      within_goal_color: ['', Validators.required],
      below_goal_color: ['', Validators.required],
      above_goal_color: ['', Validators.required],
      fleet: ['', Validators.required],
      interval: ['', Validators.required],
      goal: ['', Validators.required],
      negative_tolerance: ['', Validators.required],
      positive_tolerance: ['', Validators.required],
      movel: [],
      interval_date: [],
    });

    this.chart = JSON.parse(window.localStorage.getItem("editChartObj"));
    if (!this.chart) {
      this.openSnackBar('Ação inválida.')
      this.router.navigate(['charts']);
      return;
    }

    if (this.chart.movel) this.isMovel = true
    if (this.chart.interval_date) this.isPersonalized = true

    this.setExample();
  }

  public selectedMoments = [
    new Date(),
    new Date()
  ];

  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitAttempt)
    );
  }

  save() {

    if (this.isMovel) this.chart.movel_days = 1

    if (this.chart.model) {
      delete this.chart.id
      delete this.chart.model
      this.chartService.create(this.chart);
    }
    else
      this.chartService.set(this.chart);
    this.chart = new ChartDash();

  }

  onSubmit() {
    if (this.form.valid) {
      if (!this.chart.use_title && !this.form.get('title').value) {
        this.openSnackBar("Preencha todos os campos");
        return;
      } else if (this.chart.use_title) delete this.chart.title

      if (this.isMovel) {
        if (!this.form.get('movel').value) {
          this.openSnackBar("Informe os dias");
          return;
        }
      } else delete this.chart.movel

      if (this.isPersonalized) {
        if (!this.form.get('interval_date').value) {
          this.openSnackBar("Informe as datas do intervalo");
          return;
        }
      } else
        delete this.chart.interval_date

      if (this.chart.goal + this.chart.positive_tolerance > 100) {
        this.openSnackBar("Tolerância positiva maior que 100%")
        return
      } else if (this.chart.goal - this.chart.negative_tolerance < 0) {
        this.openSnackBar("Tolerância negativa menor que 0%")
        return
      }

      this.openSnackBar("Gráfico atualizado");
      this.save();
      this.router.navigate(['charts']);
    } else this.openSnackBar("Preencha todos os campos");
  }

  ngOnDestroy() {
    window.localStorage.removeItem("editChartObj");
  }

  displayBelowColor: boolean = false;
  displayWithinColor: boolean = false;
  displayAboveColor: boolean = false;

  changeState(type) {
    if (type == 1)
      this.displayBelowColor = !this.displayBelowColor;
    else if (type == 2)
      this.displayWithinColor = !this.displayWithinColor;
    else
      this.displayAboveColor = !this.displayAboveColor;
  }

  changeComplete($event: ColorEvent, type) {
    this.displayBelowColor = false;
    this.displayWithinColor = false;
    this.displayAboveColor = false;

    var color = $event.color.hex.replace(/#/i, '');

    if (type == 1)
      this.chart.below_goal_color = color;

    else if (type == 2)
      this.chart.within_goal_color = color;

    else
      this.chart.above_goal_color = color;

  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "Fechar", {
      duration: 2000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  setExample() {
    this.example = new Chart({
      title: {
        text: this.getTitle(this.chart)
      },
      chart: {
        type: 'pie'
      },
      plotOptions: {
        series: {
          allowPointSelect: true
        }
      },
      tooltip: {
        valueSuffix: '%'
      },
      series: [{
        showInLegend: false,
        name: this.getTitle(this.chart),
        type: undefined,
        cursor: 'pointer',
        colors: [this.getColor(this.chart, 65.5), '#a0a0a0'],
        data: [['65.5', 65.5], ['Parado', 34.5]]
      }]
    });
  }

  getTitle(chart: ChartDash): string {
    let title: string;
    let interval: string;
    let fleet: string;
    let unit: string = '';

    switch (chart.interval) {
      case Interval.TURNO_ATUAL: interval = 'Turno atual'; break;
      case Interval.DIA_ATUAL: interval = 'Dia atual'; break;
      case Interval.SEMANA_ATUAL: interval = 'Semana atual'; break;
      case Interval.MES_ATUAL: interval = 'Mês atual'; break;
      case Interval.ANO_ATUAL: interval = 'Ano atual'; break;
      case Interval.MEDIA_MOVEL:
        interval = 'Media Movel - ' + chart.movel + ' dias';
        break;
      case Interval.TURNO_ANTERIOR: interval = 'Turno anterior'; break;
      case Interval.DIA_ANTERIOR: interval = 'Dia anterior'; break;
      case Interval.SEMANA_ANTERIOR: interval = 'Semana anterior'; break;
      case Interval.MES_ANTERIOR: interval = 'Mês anterior'; break;
      case Interval.ANO_ANTERIOR: interval = 'Ano anterior'; break;
      case Interval.PERSONALIZADO:
        interval = chart.start_time.toLocaleString() + ' - ' + chart.end_time.toLocaleString();
        break;
    }

    if (Dashboard[chart.type] === Dashboard.PROD) {
      unit = ' - '
      switch (chart.unit) {
        case Unit.VIAGENS: unit += 'Viagens'; break;
        case Unit.KT: unit += 'Kt (métricas)'; break;
        case Unit.T: unit += 't (métricas)'; break;
        case Unit.KTON: unit += 'kton (curtas)'; break;
        case Unit.TON: unit += 'ton (curtas)'; break;
        case Unit.KG: unit += 'kg'; break;
      }
    }

    fleet = chart.fleet_name ? ' - Frota ' + chart.fleet_name : ''

    title = chart.use_title ? chart.default_title + fleet + ' - ' + interval + unit : chart.title;
    return title;
  }

  getColor(chart: ChartDash, number: number): string {
    let color: string;

    if (number < chart.goal - chart.negative_tolerance)
      color = chart.below_goal_color;
    else if (number > chart.goal + chart.positive_tolerance)
      color = chart.above_goal_color;
    else
      color = chart.within_goal_color;

    return '#' + color;
  }

  getFleets() {
    this.fleetsService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(fleets => {
      this.fleets = fleets;
    });
  }

  onFleetChange(name: string) {
    this.chart.fleet_id = this.getFleetBy(name).id;
  }

  onIntervalChange(interval: number) {
    this.isPersonalized = interval == Interval.PERSONALIZADO
    this.isMovel = interval == Interval.MEDIA_MOVEL

    console.table(this.isPersonalized + " - " + this.isMovel);

  }
  onDateChange(dateStart, dateEnd) {

    var start = new Date(dateStart);
    var end = new Date(dateEnd);

    if (!isNaN(+start) && !isNaN(+end))
      console.log({ start, end });

  }

  getFleetBy(name): Fleet {
    return this.fleets.find((item) => item.name === name);
  }
}
