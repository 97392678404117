<mat-grid-list [cols]="6" rowHeight="2:1.5">
    <div *ngFor="let image of images">
        <mat-grid-tile (click)='setImage(image)'>
            <div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
                <mat-card class="box">
                    <mat-card-content>
                        <img src='./assets/images/cards-icon/{{image}}.svg' alt="" width="60" height="60">
                    </mat-card-content>
                </mat-card>
            </div>

        </mat-grid-tile>
    </div>
</mat-grid-list>