import { Component, OnInit } from '@angular/core';
import { CardService } from '../card.service';
import { map } from 'rxjs/operators';
import { Card } from '../card.model';
import { Fleet } from 'src/app/fleets/fleet.model';
import { FleetService } from 'src/app/fleets/fleet.service';

@Component({
  selector: 'app-card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['./card-list.component.scss']
})
export class CardListComponent implements OnInit {

  cards: Card[]
  cardsFromFleet: Card[]
  fleet: string
  fleets: Fleet[]
  breakpoint: number

  constructor(
    private cardService: CardService,
    private fleetService: FleetService
  ) { }

  ngOnInit() {
    this.getCards();
    this.getFleets();

    this.breakpoint = (window.innerWidth <= 400) ? 2 : 4;
  }

  onResize(event) {
    this.breakpoint = (event.target.innerWidth <= 400) ? 2 : 4;
  }

  getCards() {
    this.cardService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(cards => {
      this.cards = cards;
    });
  }

  getFleets() {
    this.fleetService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(fleets => {
      this.fleets = fleets;
      this.fleet = fleets[0].id
      this.onUpdateFleet()
    });
  }

  onUpdateFleet() {
    this.cardsFromFleet = this.cards.filter(card => card.fleet == this.fleet)
  }

  delete() {
    this.cardService.deleteAll();
  }
}
