import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Dashboard } from 'src/app/home/dashboard.enum';
import { DialogChartModelData } from '../chart-list/chart-list.component';
import { ChartDash } from '../chart.model';

@Component({
  selector: 'app-chart-model-dialog',
  templateUrl: './chart-model-dialog.component.html',
  styleUrls: ['./chart-model-dialog.component.scss']
})
export class ChartModelDialogComonent {

  operacao: ChartDash[]
  manutencao: ChartDash[]

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<ChartModelDialogComonent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogChartModelData) {

    if (this.data.format === 1)
      data.models = data.models.filter(function (el) { return el.type != 'PROD' });

    this.operacao = data.models.filter(chart => chart.category === 1);
    this.manutencao = data.models.filter(chart => chart.category === 2);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onClick(graph: ChartDash) {
    this.dialogRef.close();
    graph.format = this.data.format
    window.localStorage.removeItem("editChartObj");
    localStorage.setItem('editChartObj', JSON.stringify(graph));

    if (graph.format === 1)
      this.router.navigate(['chart-pizza']);
    else if (graph.format === 2)
      this.router.navigate(['chart-bar']);
    else if (graph.format === 3)
      this.router.navigate(['board']);

  }

}
