<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
    <mat-card class="box">

        <mat-card-header>
            <mat-card-title>{{day.label}}</mat-card-title>
        </mat-card-header>

        <mat-card-content>
            <div fxLayout="row" *ngFor="let shift of shifts">
                <div class="label">
                    {{shift.label}} - {{timeConvert(shift.start)}}/{{timeConvert(shift.end)}}
                </div>

                <div class="buttons">
                    <button mat-mini-fab type="button" (click)="edit(shift)">
                        <mat-icon>edit</mat-icon>
                    </button>

                    <button mat-mini-fab type="button" color="warn" (click)="inactivate(shift)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </div>
        </mat-card-content>

        <button mat-raised-button color="primary" type="button" class="btn-block" (click)="create()">Adicionar</button>
    </mat-card>
</div>