import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { SubClass } from "src/app/cards/sub-class.enum";
import { TimeClass } from "src/app/cards/time-class.enum";
import { ChartDash } from "src/app/dashboard/chart.model";
import { Record } from "src/app/records/record.model";
import { RecordService } from "src/app/records/record.service";
import { Shift } from "src/app/shifts/shift.model";
import { ShiftService } from "src/app/shifts/shift.service";
import { Trip } from "src/app/trips/trip.model";
import { TripService } from "src/app/trips/trip.service";

@Injectable({
    providedIn: 'root'
})
export class MinutesService {
    dateBreak: number;
    oneDay: number = 24 * 60 * 60 * 1000;
    oneHour: number = 60 * 60 * 1000;
    shifts: Shift[]

    constructor(
        private recordService: RecordService,
        private shiftService: ShiftService,
        private tripsService: TripService
    ) {
        this.shiftService.getClient().valueChanges().subscribe(client => {
            this.dateBreak = client.dateBreak * 60
        });
        this.getShifts()
    }

    getShifts() {
        let shiftsCallback = this.shiftService.getList().snapshotChanges().pipe(
            map(changes =>
                changes.map(c =>
                    ({ id: c.payload.doc.id, ...c.payload.doc.data() })
                )
            )
        ).subscribe(shifts => {
            shiftsCallback.unsubscribe();
            this.shifts = shifts;
        })
    }

    /**
     * Busca apontamentos de acordo com tempo, frota e classes
     * 
     * @param timeClass classe de tempo
     * @param subClas subclasse
     * @param start início do periodo
     * @param end fim do periodo
     * @param fleet id da frota
     */
    getRecords = async (
        timeClass: TimeClass,
        subClas: SubClass,
        start: Date,
        end: Date,
        fleet: string
    ) => {

        var minutes: number = 0;

        let recordsCallback = await this.recordService.getListByClass(timeClass, subClas, fleet, start, end).get().toPromise();

        recordsCallback.docs.forEach(data => {

            const record = data.data() as Record;

            const startDate: Date = record.date_start.toDate();
            const endDate = !record.date_end ? end : record.date_end.toDate().getTime() > end.getTime() ? end : record.date_end.toDate();

            const dayStart = this.day(startDate);
            const dayEnd = this.day(endDate);

            const recordStart = this.minute(startDate)
            const recordEnd = this.minute(endDate)

            var min: number = 0;

            let days = Math.round(Math.abs((+endDate - +startDate) / this.oneDay));

            for (var x = 0, day = dayStart; x <= days; x++, day++) {
                day = day > 7 ? 1 : day;

                this.shifts.filter(x => x.day === day).forEach(shift => {

                    if (shift.day == dayStart && x == 0 && shift.end < recordStart)
                        return;
                    if (shift.day == dayEnd && x == days && shift.start > recordEnd)
                        return;

                    var shiftStart: number = shift.start;
                    var shiftEnd: number = shift.end;

                    if (shift.day == dayStart && x == 0 && shiftStart < recordStart)
                        shiftStart = recordStart;

                    if (shift.day == dayEnd && x == days && shiftEnd > recordEnd)
                        shiftEnd = recordEnd;

                    // aqui só pega o tempo disponível do turno

                    if (recordStart > shift.end || recordEnd < shift.start) return

                    // se o apontamento começa no turno
                    if (recordStart >= shiftStart && recordStart < shiftEnd)
                        if (recordEnd >= shiftEnd)
                            min += shiftEnd - recordStart;
                        else min += recordEnd - recordStart;

                    // se o apontamento começa antes do inicio e termina depois do fim
                    if (recordStart < shiftStart && recordEnd > shiftEnd)
                        min += shiftEnd - shiftStart;

                    // se o apontamento começa antes do inicio e termina antes do fim
                    if (recordStart < shiftStart && recordEnd <= shiftEnd && recordEnd > shiftStart)
                        min += recordEnd - shiftStart;

                });
            }

            minutes += min;
        })

        return minutes;
    }

    /**
     * Busca apontamentos de acordo com tempo, frota e classes
     * 
     * @param timeClass classe de tempo
     * @param subClas subclasse
     * @param start início do periodo
     * @param end fim do periodo
     * @param fleet id da frota
     */
    getRecordsToMonths = async (
        timeClass: TimeClass,
        subClas: SubClass,
        start: Date,
        end: Date,
        fleet: string
    ) => {

        let recordsCallback = await this.recordService.getListByClass(timeClass, subClas, fleet, start, end).get().toPromise();

        let months = this.monthDiff(start, end)
        let result: number[] = new Array(months + 1).fill(0)

        recordsCallback.docs.forEach(data => {

            const record = data.data() as Record;

            const startDate: Date = record.date_start.toDate();
            const endDate = !record.date_end ? end : record.date_end.toDate().getTime() > end.getTime() ? end : record.date_end.toDate();

            const dayStart = this.day(startDate);
            const dayEnd = this.day(endDate);

            const recordStart = this.minute(startDate)
            const recordEnd = this.minute(endDate)

            let currentDate: Date = new Date(startDate)

            var min: number = 0;

            let days = Math.round(Math.abs((+endDate - +startDate) / this.oneDay));

            for (var x = 0, day = dayStart; x <= days; x++, day++) {
                day = day > 7 ? 1 : day;

                this.shifts.filter(x => x.day === day).forEach(shift => {

                    if (shift.day == dayStart && x == 0 && shift.end < recordStart)
                        return;
                    if (shift.day == dayEnd && x == days && shift.start > recordEnd)
                        return;

                    var shiftStart: number = shift.start;
                    var shiftEnd: number = shift.end;

                    if (shift.day == dayStart && x == 0 && shiftStart < recordStart)
                        shiftStart = recordStart;

                    if (shift.day == dayEnd && x == days && shiftEnd > recordEnd)
                        shiftEnd = recordEnd;

                    // aqui só pega o tempo disponível do turno

                    if (recordStart > shift.end || recordEnd < shift.start) return

                    // se o apontamento começa no turno
                    if (recordStart > shiftStart && recordStart < shiftEnd)
                        if (shift.day < dayEnd || recordEnd >= shiftEnd)
                            min += shiftEnd - recordStart;
                        else min += recordEnd - recordStart;

                    // se o apontamento começa antes do inicio e termina depois do fim
                    if (recordStart < shiftStart && recordEnd > shiftEnd)
                        min += shiftEnd - shiftStart;

                    // se o apontamento começa antes do inicio e termina antes do fim
                    if (recordStart < shiftStart && recordEnd <= shiftEnd && recordEnd > shiftStart)
                        min += recordEnd - shiftStart;

                    let month = currentDate.getMonth()

                    result[month] += min

                    currentDate.setDate(currentDate.getDate() + 1)

                });
            }
        })

        return result;
    }

    /**
     * Busca apontamentos de acordo com tempo, frota e classes
     * 
     * @param timeClass classe de tempo
     * @param subClas subclasse
     * @param start início do periodo
     * @param end fim do periodo
     * @param fleet id da frota
     */
    getRecordsToDays = async (
        timeClass: TimeClass,
        subClas: SubClass,
        start: Date,
        end: Date,
        fleet: string
    ) => {

        let recordsCallback = await this.recordService.getListByClass(timeClass, subClas, fleet, start, end).get().toPromise();

        let daysFinal = Math.round(Math.abs((+end - +start) / this.oneDay));

        let result: number[] = new Array(daysFinal).fill(0)

        recordsCallback.docs.forEach(data => {

            const record = data.data() as Record;

            const startDate: Date = record.date_start.toDate();
            const endDate = !record.date_end ? end : record.date_end.toDate().getTime() > end.getTime() ? end : record.date_end.toDate();

            const dayStart = this.day(startDate);
            const dayEnd = this.day(endDate);

            const recordStart = this.minute(startDate)
            const recordEnd = this.minute(endDate)

            var min: number = 0;

            let days = Math.round(Math.abs((+endDate - +startDate) / this.oneDay));

            for (var x = 0, day = dayStart; x <= days; x++, day++) {
                day = day > 7 ? 1 : day;

                this.shifts.filter(x => x.day === day).forEach(shift => {

                    if (shift.day == dayStart && x == 0 && shift.end < recordStart)
                        return;
                    if (shift.day == dayEnd && x == days && shift.start > recordEnd)
                        return;

                    var shiftStart: number = shift.start;
                    var shiftEnd: number = shift.end;

                    if (shift.day == dayStart && x == 0 && shiftStart < recordStart)
                        shiftStart = recordStart;

                    if (shift.day == dayEnd && x == days && shiftEnd > recordEnd)
                        shiftEnd = recordEnd;

                    // aqui só pega o tempo disponível do turno

                    if (recordStart > shift.end || recordEnd < shift.start) return

                    // se o apontamento começa no turno
                    if (recordStart > shiftStart && recordStart < shiftEnd)
                        if (shift.day < dayEnd || recordEnd >= shiftEnd)
                            min += shiftEnd - recordStart;
                        else min += recordEnd - recordStart;

                    // se o apontamento começa antes do inicio e termina depois do fim
                    if (recordStart < shiftStart && recordEnd > shiftEnd)
                        min += shiftEnd - shiftStart;

                    // se o apontamento começa antes do inicio e termina antes do fim
                    if (recordStart < shiftStart && recordEnd <= shiftEnd && recordEnd > shiftStart)
                        min += recordEnd - shiftStart;

                    let diff = this.dayMonth(startDate) + x

                    result[diff] += min

                });
            }
        })

        return result;
    }

    /**
     * Busca apontamentos de acordo com tempo, frota e classes
     * 
     * @param timeClass classe de tempo
     * @param subClas subclasse
     * @param start início do periodo
     * @param end fim do periodo
     * @param fleet id da frota
     */
    getRecordsToHours = async (
        timeClass: TimeClass,
        subClas: SubClass,
        start: Date,
        end: Date,
        fleet: string
    ) => {

        let recordsCallback = await this.recordService.getListByClass(timeClass, subClas, fleet, start, end).get().toPromise();

        let hours = Math.floor(Math.abs((+end - +start) / this.oneHour));

        let array: number[] = new Array(hours).fill(0)

        recordsCallback.docs.forEach(data => {

            const record = data.data() as Record;

            const startDate: Date = record.date_start.toDate();
            const endDate = !record.date_end ? end : record.date_end.toDate().getTime() > end.getTime() ? end : record.date_end.toDate();

            const dayStart = this.day(startDate);
            const dayEnd = this.day(endDate);

            const recordStart = this.minute(startDate)
            const recordEnd = this.minute(endDate)

            let days = Math.round(Math.abs((+endDate - +startDate) / this.oneDay));

            for (var x = 0, day = dayStart; x <= days; x++, day++) {
                day = day > 7 ? 1 : day;

                this.shifts.filter(x => x.day === day).forEach(shift => {

                    if (shift.day == dayStart && x == 0 && shift.end < recordStart)
                        return;
                    if (shift.day == dayEnd && x == days && shift.start > recordEnd)
                        return;

                    var shiftStart: number = shift.start;
                    var shiftEnd: number = shift.end;

                    if (shift.day == dayStart && x == 0 && shiftStart < recordStart)
                        shiftStart = recordStart;

                    if (shift.day == dayEnd && x == days && shiftEnd > recordEnd)
                        shiftEnd = recordEnd;

                    // aqui só pega o tempo disponível do turno
                    if (recordStart > shift.end || recordEnd < shift.start) return

                    let index = Math.floor(shiftStart / 60)
                    let shiftHours = Math.floor((shiftEnd - shiftStart) / 60)

                    for (let x = 0; x <= shiftHours; x++) {

                        let hour = 0
                        const hourStart = (index + x) * 60;
                        const hourEnd = (index + x + 1) * 60;

                        // se o apontamento começa na hora
                        if (shiftStart > hourStart && shiftStart < hourEnd)
                            if (shiftEnd >= hourEnd)
                                hour += hourEnd - shiftStart;
                            else hour += shiftEnd - shiftStart;

                        // se o apontamento começa antes do inicio e termina depois do fim
                        if (shiftStart < hourStart && shiftEnd > hourEnd)
                            hour += hourEnd - hourStart;

                        // se o apontamento começa antes do inicio e termina antes do fim
                        if (shiftStart < hourStart && shiftEnd <= hourEnd && shiftEnd > hourStart)
                            hour += shiftEnd - hourStart;

                        array[index + x] = hour
                    }

                });
            }
        })

        return array;
    }

    /**
     * Busca apontamentos de acordo com tempo, frota e classes
     * 
     * @param timeClass classe de tempo
     * @param subClas subclasse
     * @param start início do periodo
     * @param end fim do periodo
     * @param fleet id da frota
     */
    getTrips = async (
        start: Date,
        end: Date,
        equipment: string,
        fleet: string,
        material: string,
        count: boolean
    ) => {

        let tripsCallback = await this.tripsService.getWithFilter(start, end, equipment, fleet, material).get().toPromise();

        let load: number = 0

        tripsCallback.docs.forEach(data => {

            const trip = data.data() as Trip;

            const startDate: Date = trip.date_start.toDate();
            const endDate = !trip.date_end ? end : trip.date_end.toDate().getTime() > end.getTime() ? end : trip.date_end.toDate();

            const dayStart = this.day(startDate);

            const tripStart = this.minute(startDate)
            const tripEnd = this.minute(endDate)

            this.shifts.filter(x => x.day === dayStart).forEach(shift => {

                if (shift.end < tripStart)
                    return;
                if (shift.start > tripEnd)
                    return;

                var shiftStart: number = shift.start;
                var shiftEnd: number = shift.end;

                if (shiftStart < tripStart)
                    shiftStart = tripStart;

                if (shiftEnd > tripEnd)
                    shiftEnd = tripEnd;

                load += count ? 1 : trip.load

            });
        })

        return load;
    }

    /**
     * Busca apontamentos de acordo com tempo, frota e classes
     * 
     * @param timeClass classe de tempo
     * @param subClas subclasse
     * @param start início do periodo
     * @param end fim do periodo
     * @param fleet id da frota
     */
    getTripsToHours = async (
        start: Date,
        end: Date,
        equipment: string,
        fleet: string,
        material: string,
        count: boolean
    ) => {

        let tripsCallback = await this.tripsService.getWithFilter(start, end, equipment, fleet, material).get().toPromise();

        let hours = Math.floor(Math.abs((+end - +start) / this.oneHour));

        let array: number[] = new Array(hours + 1).fill(0)

        tripsCallback.docs.forEach(data => {

            const trip = data.data() as Trip;

            const startDate: Date = trip.date_start.toDate();
            const endDate = !trip.date_end ? end : trip.date_end.toDate().getTime() > end.getTime() ? end : trip.date_end.toDate();

            const dayStart = this.day(startDate);
            const dayEnd = this.day(endDate);

            const tripStart = this.minute(startDate)
            const tripEnd = this.minute(endDate)

            this.shifts.filter(x => x.day === dayStart).forEach(shift => {

                if (shift.end < tripStart)
                    return;
                if (shift.start > tripEnd)
                    return;

                var shiftStart: number = shift.start;
                var shiftEnd: number = shift.end;

                if (shiftStart < tripStart)
                    shiftStart = tripStart;

                if (shiftEnd > tripEnd)
                    shiftEnd = tripEnd;

                let index = Math.floor(tripStart / 60)

                array[index] += count ? 1 : trip.load

            });
        })

        return array;
    }

    /**
     * Busca apontamentos de acordo com tempo, frota e classes
     * 
     * @param timeClass classe de tempo
     * @param subClas subclasse
     * @param start início do periodo
     * @param end fim do periodo
     * @param fleet id da frota
     */
    getTripsToDays = async (
        start: Date,
        end: Date,
        equipment: string,
        fleet: string,
        material: string,
        count: boolean,
        chart: ChartDash
    ) => {

        let tripsCallback = await this.tripsService.getWithFilter(start, end, equipment, fleet, material).get().toPromise();

        const dayMonthStart = this.dayMonth(start)
        const dateIntervalStart = new Date(start.getFullYear(), start.getMonth(), dayMonthStart, (this.dateBreak / 60))
        const dateIntervalEnd = new Date(end.getFullYear(), end.getMonth(), this.dayMonth(end), (this.dateBreak / 60))

        let daysFinal = Math.floor(Math.abs((+dateIntervalEnd - +dateIntervalStart) / this.oneDay));

        let array: number[] = new Array(daysFinal).fill(0)

        tripsCallback.docs.forEach(data => {

            const trip = data.data() as Trip;

            const startDate: Date = trip.date_start.toDate();
            const endDate: Date = !trip.date_end ? end : trip.date_end.toDate().getTime() > end.getTime() ? end : trip.date_end.toDate();

            const dayStart = this.day(startDate);
            const dayEnd = this.day(endDate);

            const tripStart = this.minute(startDate)
            const tripEnd = this.minute(endDate)

            // this.shifts.filter(x => x.day === dayStart).forEach(shift => {

            //     if (shift.end < tripStart)
            //         return;
            //     if (shift.start > tripEnd)
            //         return;

            //     var shiftStart: number = shift.start;
            //     var shiftEnd: number = shift.end;

            //     if (shiftStart < tripStart)
            //         shiftStart = tripStart;

            //     if (shiftEnd > tripEnd)
            //         shiftEnd = tripEnd;



            // });

            // let period: Date = chart.isPeriodChecked ? startDate : endDate

            let diff = Math.floor(Math.abs((+startDate - +start) / this.oneDay));

            array[diff] += count ? 1 : trip.load
        })

        return array;
    }

    /**
     * Busca apontamentos de acordo com tempo, frota e classes
     * 
     * @param timeClass classe de tempo
     * @param subClas subclasse
     * @param start início do periodo
     * @param end fim do periodo
     * @param fleet id da frota
     */
    getTripsToMonths = async (
        start: Date,
        end: Date,
        equipment: string,
        fleet: string,
        material: string,
        count: boolean,
        chart: ChartDash 
    ) => {

        let tripsCallback = await this.tripsService.getWithFilter(start, end, equipment, fleet, material).get().toPromise();

        let daysFinal = Math.round(Math.abs((+end - +start) / this.oneDay));

        let months = this.monthDiff(start, end)
        let array: number[] = new Array(months + 1).fill(0)

        tripsCallback.docs.forEach(data => {

            const trip = data.data() as Trip;

            const startDate: Date = trip.date_start.toDate();
            const endDate = !trip.date_end ? end : trip.date_end.toDate().getTime() > end.getTime() ? end : trip.date_end.toDate();

            const dayStart = this.day(startDate);
            const dayEnd = this.day(endDate);

            const tripStart = this.minute(startDate)
            const tripEnd = this.minute(endDate)

            let period: Date = chart.isPeriodChecked ? startDate : endDate

            let month = period.getMonth()

            array[month] += count ? 1 : trip.load

            // this.shifts.filter(x => x.day === dayStart).forEach(shift => {

            //     if (shift.end < tripStart)
            //         return;
            //     if (shift.start > tripEnd)
            //         return;

            //     var shiftStart: number = shift.start;
            //     var shiftEnd: number = shift.end;

            //     if (shiftStart < tripStart)
            //         shiftStart = tripStart;

            //     if (shiftEnd > tripEnd)
            //         shiftEnd = tripEnd;


            //     currentDate.setDate(currentDate.getDate() + 1)

            // });
            
        })

        return array;
    }

    /**
     * horas horizonte
     */
    hh = (start: Date, end: Date) => {

        const dayStart = this.day(start);
        const dayEnd = this.day(end);

        const minuteStart = this.minute(start)
        const minuteEnd = this.minute(end)

        var HH: number = 0;

        let days = Math.round(Math.abs((+end - +start) / this.oneDay));

        for (var x = 0, day = dayStart; x <= days; x++, day++) {
            day = day > 7 ? 1 : day;

            this.shifts.filter(x => x.day === day).forEach(shift => {

                if (shift.day == dayStart && x == 0 && shift.end < minuteStart)
                    return;
                if (shift.day == dayEnd && x == days && shift.start > minuteEnd)
                    return;

                var start: number = shift.start;
                var end: number = shift.end;

                if (shift.day == dayStart && x == 0 && start < minuteStart)
                    start = minuteStart;

                if (shift.day == dayEnd && x == days && end > minuteEnd)
                    end = minuteEnd;

                HH += end - start;
            });
        }

        return HH;
    }

    /**
     * horas horizonte
     */
    hhToDays = (start: Date, end: Date) => {

        const dayStart = this.day(start);
        const dayEnd = this.day(end);

        const minuteStart = this.minute(start)
        const minuteEnd = this.minute(end)

        let days = Math.round(Math.abs((+end - +start) / this.oneDay));
        let array: number[] = new Array(days)

        for (var x = 0, day = dayStart; x <= days - 1; x++, day++) {
            day = day > 7 ? 1 : day;

            let daySum = 0;

            this.shifts.filter(y => y.day === day).forEach(shift => {

                if (shift.day == dayStart && x == 0 && shift.end < minuteStart)
                    return;
                if (shift.day == dayEnd && x == days && shift.start > minuteEnd)
                    return;

                var start: number = shift.start;
                var end: number = shift.end;

                if (shift.day == dayStart && x == 0 && start < minuteStart)
                    start = minuteStart;

                if (shift.day == dayEnd && x == days && end > minuteEnd)
                    end = minuteEnd;

                daySum += end - start;
            });

            array[x] = daySum
        }

        return array;
    }

    hhToMonths = (start: Date, end: Date) => {

        const dayStart = this.day(start);
        const dayEnd = this.day(end);

        const minuteStart = this.minute(start)
        const minuteEnd = this.minute(end)

        let days = Math.round(Math.abs((+end - +start) / this.oneDay));
        let months = this.monthDiff(start, end)
        let array: number[] = new Array(months + 1).fill(0)

        let currentDate: Date = new Date(start)

        for (var x = 0, day = dayStart; x <= days - 1; x++, day++) {
            day = day > 7 ? 1 : day;

            let daySum = 0;

            this.shifts.filter(y => y.day === day).forEach(shift => {

                if (shift.day == dayStart && x == 0 && shift.end < minuteStart)
                    return;
                if (shift.day == dayEnd && x == days && shift.start > minuteEnd)
                    return;

                var start: number = shift.start;
                var end: number = shift.end;

                if (shift.day == dayStart && x == 0 && start < minuteStart)
                    start = minuteStart;

                if (shift.day == dayEnd && x == days && end > minuteEnd)
                    end = minuteEnd;

                daySum += end - start;
            });

            array[currentDate.getMonth()] += daySum

            currentDate.setDate(currentDate.getDate() + 1)
        }

        return array;
    }

    /**
     * horas horizonte
     */
    hhToHours = (start: Date, end: Date) => {

        const dayStart = this.day(start);
        const dayEnd = this.day(end);

        const minuteStart = this.minute(start)
        const minuteEnd = this.minute(end)

        let hours = Math.floor(Math.abs((+end - +start) / this.oneHour));
        let days = Math.floor(Math.abs((+end - +start) / this.oneDay));
        let array: number[] = new Array(hours).fill(0)

        for (var x = 0, day = dayStart; x <= days; x++, day++) {
            day = day > 7 ? 1 : day;

            this.shifts.filter(x => x.day === day).forEach(shift => {

                if (shift.day == dayStart && x == 0 && shift.end < minuteStart)
                    return;
                if (shift.day == dayEnd && x == days && shift.start > minuteEnd)
                    return;

                var shiftStart: number = shift.start;
                var shiftEnd: number = shift.end;

                if (shift.day == dayStart && x == 0 && shiftStart < minuteStart)
                    shiftStart = minuteStart;

                if (shift.day == dayEnd && x == days && shiftEnd > minuteEnd)
                    shiftEnd = minuteEnd;

                let index = Math.floor((shiftStart - minuteStart) / 60)
                let shiftHours = Math.floor((shiftEnd - shiftStart) / 60)

                for (let x = 0; x <= shiftHours; x++) {

                    let hour = 0
                    if (shiftEnd >= (index + x + 1) * 60) hour = 60
                    else hour = shiftEnd - (index + x) * 60

                    array[index + x] = hour
                }
            });

        }

        return array;
    }

    /**
     * Verifica uma data e retorna o dia da semana referente 
     * 
     * @param date hora a ser verificada
     * @returns dia da semana com base na quebra de data
     */
    day(date: Date): number {
        var minutes: number = (date.getHours() * 60) + date.getMinutes();

        var day = date.getDay() + 1

        if (this.dateBreak < 12 * 60) {
            minutes -= this.dateBreak
            if (minutes <= this.dateBreak) minutes += 24 * 60
        } else minutes += (24 * 60) - this.dateBreak

        minutes = minutes >= 24 * 60 ? minutes - 24 * 60 : minutes

        if (this.dateBreak < 12 * 60 && minutes >= (24 * 60) - this.dateBreak)
            day--
        else if (this.dateBreak >= 12 * 60 && minutes < (24 * 60) - this.dateBreak)
            day++

        day = day < 1 ? 7 : day > 7 ? 1 : day

        return day;
    }

    /**
     * Verifica uma data e retorna o dia do mes referente 
     * 
     * @param date hora a ser verificada
     * @returns dia do mes com base na quebra de data
     */
    dayMonth(date: Date): number {
        var minutes: number = (date.getHours() * 60) + date.getMinutes();

        var day = date.getDate()

        if (this.dateBreak < 12 * 60) {
            minutes -= this.dateBreak
            if (minutes <= this.dateBreak) minutes += 24 * 60
        } else minutes += (24 * 60) - this.dateBreak

        minutes = minutes >= 24 * 60 ? minutes - 24 * 60 : minutes

        if (this.dateBreak < 12 * 60 && minutes >= (24 * 60) - this.dateBreak)
            date.setDate(day - 1)
        else if (this.dateBreak >= 12 * 60 && minutes < (24 * 60) - this.dateBreak)
            date.setDate(day + 1)

        return date.getDate();
    }

    /**
     * @returns minuto do dia de acordo com a quebra de data 
     */
    minute = (date: Date): number => {
        var minute: number = (date.getHours() * 60) + date.getMinutes();

        if (this.dateBreak < 12 * 60) {
            minute -= this.dateBreak;
            if (minute <= this.dateBreak)
                minute += 24 * 60;
        } else
            minute += (24 * 60) - this.dateBreak;

        minute = minute >= 24 * 60 ? minute - 24 * 60 : minute;
        return minute;
    }

    monthDiff(dateFrom: Date, dateTo: Date) {
        return dateTo.getMonth() - dateFrom.getMonth() +
            (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
    }
}