
export enum Interval {
    TURNO_ATUAL,
    TURNO_ANTERIOR,
    DIA_ATUAL,
    DIA_ANTERIOR,
    SEMANA_ATUAL,
    SEMANA_ANTERIOR,
    MES_ATUAL,
    MES_ANTERIOR,
    ANO_ATUAL,
    ANO_ANTERIOR,
    MEDIA_MOVEL,
    PERSONALIZADO
}