import { Component, OnInit, ViewChild } from '@angular/core';
import { map } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { EquipmentService } from 'src/app/equipments/equipment.service';
import { firestore } from 'firebase';
import { FleetService } from 'src/app/fleets/fleet.service';
import { GeocercaService } from 'src/app/geocercas/geocerca.service';
import { Geocerca } from 'src/app/geocercas/geocerca.model';
import { MatDialog } from '@angular/material/dialog';
import { GeofenceHistory } from '../geofence-history';
import { GeofenceHistoryService } from '../geofence-history.service';
import { Equipment } from 'src/app/equipments/equipment.model';
import { log } from 'console';

@Component({
  selector: 'app-geofence-history',
  templateUrl: './geofence-history.component.html',
  styleUrls: ['./geofence-history.component.scss']
})
export class GeofenceHistoryComponent implements OnInit {

  histories: GeofenceHistory[];
  equipments: Equipment[];
  geocercas: Geocerca[];
  equipment: string = "all";
  fleets: any;
  fleet: string = "all";
  dataSource: MatTableDataSource<GeofenceHistory>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    private geocercaService: GeocercaService,
    private fleetsService: FleetService,
    private equipmentService: EquipmentService,
    private geofenceHistoryService: GeofenceHistoryService) { }

  classifyPoint = require("robust-point-in-polygon")

  displayedColumns: string[] = [];

  onDateChange(dateStart, dateEnd) {

    var start = new Date(dateStart);
    var end = new Date(dateEnd);

    if (!isNaN(+start) && !isNaN(+end))
      this.getListTime(start, end, this.equipment, this.fleet);
  }

  ngOnInit() {
    this.getEquipments();
    this.getFleets();
    this.getGeocercas();
    this.getList();

    var today = new Date();
    var yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    this.selectedMoments = [yesterday, today];

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public selectedMoments = [
    new Date(),
    new Date()
  ];

  getList() {
    this.geofenceHistoryService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(histories => {
      this.histories = histories;
      this.getTable()
    });
  }

  data = []
  getTable() {
    var obj = {}
    this.data = []

    this.equipments.forEach(equipment => {
      const histories = this.histories.filter((geofence: GeofenceHistory) => geofence.equipment_id === equipment.id);

      obj["Equipamento"] = equipment.name

      var sum = 0
      histories.forEach(geofence => {

        sum += (parseInt(obj[geofence.geofence]) || 0) + geofence.duration
        obj[geofence.geofence] = sum
        sum = 0
      });

      // converter
      this.geocercas.forEach(geocerca => {
        obj[geocerca.name] = this.toTime((obj[geocerca.name] || 0))
      })

      this.data.push(obj)
      obj = {}
    });

    this.updateTable()
  }

  toTime(seconds: number) {
    var date = new Date(null);
    date.setSeconds(seconds);

    var days = this.toDays(seconds)
    var result = ""

    if (days > 0) {
      result = result.concat(days + "D ")
    }
    console.log(days);
    

    result = result.concat(date.toISOString().substr(11, 8))

    return result;
  }

  toDays(seconds: number) {
    const _S_PER_DAY = 60 * 60 * 24;
    return Math.floor(seconds / _S_PER_DAY);
  }

  clear() {
    this.getList();
  }

  getEquipments() {
    this.equipmentService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(equipments => {
      this.equipments = equipments;
    });
  }

  getFleets() {
    this.fleetsService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(fleets => {
      this.fleets = fleets;
    });
  }

  getListTime(start: Date, end: Date, equipment: string, fleet: string) {
    this.geofenceHistoryService.getListByTime(start, end, equipment, fleet).snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(histories => {
      this.histories = histories;
      this.getTable()
    });
  }

  private updateTable() {
    this.dataSource = new MatTableDataSource(this.data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getGeocercas() {
    this.geocercaService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(geocercas => {
      this.geocercas = geocercas;
      this.displayedColumns.push("Equipamento")

      this.geocercas.forEach(geofence => {
        this.displayedColumns.push(geofence.name)
      });

      console.log(this.geocercas)
      console.log(this.displayedColumns)
    });
  }
}
