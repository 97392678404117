import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Card, CardIcon, CardsIcon } from './card.model';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CardService {
  private dbPath = '/cards';
  private dbPathIcon = '/cards-icon';
  private storePath = 'card-icons/';

  collection: AngularFirestoreCollection<Card> = null;
  iconCollection: AngularFirestoreCollection<CardsIcon> = null;
  clientDoc: AngularFirestoreDocument<CardsIcon>;

  constructor(db: AngularFirestore,
    private storage: AngularFireStorage) {
    var client = JSON.parse(localStorage.getItem('documentData')).client_id;
    this.clientDoc = db.collection('clients').doc(client);
    this.collection = this.clientDoc.collection(this.dbPath, ref => ref.where('active', '==', true));
    this.iconCollection = this.clientDoc.collection(this.dbPathIcon);
  }

  getCardsIcon(): AngularFirestoreCollection<CardsIcon> {
    return this.iconCollection;
  }

  getImage(image: string): Observable<any> {
    return this.storage.ref(this.storePath + image).getDownloadURL();
  }

  create(card: Card): void {
    this.collection.add({ ...card });
  }

  createCardsIcon(card: CardsIcon): void {
    this.iconCollection.add({ ...card });
  }

  update(key: string, value: any): Promise<void> {
    return this.collection.doc(key).update(value);
  }

  updateCard(card: CardsIcon): Promise<void> {
    return this.iconCollection.doc(card.id).update(card);
  }

  delete(key: string): Promise<void> {
    return this.collection.doc(key).delete();
  }

  getList(): AngularFirestoreCollection<Card> {
    return this.collection;
  }

  deleteAll() {
    this.collection.get().subscribe(
      querySnapshot => {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
        });
      },
      error => {
        console.log('Error: ', error);
      });
  }
}
