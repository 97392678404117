<div class="map-layout">
  <div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
    <mat-form-field class="example-full-width">
      <mat-label>Equipamento</mat-label>
      <mat-select [(value)]="equipment" (selectionChange)="onDateChange(dateStart.value, dateEnd.value)">
        <mat-option *ngFor="let equipment of equipments" [value]="equipment.id">
          {{equipment.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <mat-label>Início</mat-label>
      <input matInput #dateStart [owlDateTimeTrigger]="dt12" [owlDateTime]="dt12" [selectMode]="'rangeFrom'"
        [(ngModel)]="selectedMoments" (dateTimeInput)="onDateChange(dateStart.value, dateEnd.value)">
      <owl-date-time #dt12></owl-date-time>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <mat-label>Fim</mat-label>
      <input matInput #dateEnd [owlDateTimeTrigger]="dt13" [owlDateTime]="dt13" [selectMode]="'rangeTo'"
        [(ngModel)]="selectedMoments" (dateTimeInput)="onDateChange(dateStart.value, dateEnd.value)">
      <owl-date-time #dt13></owl-date-time>
    </mat-form-field>

    <button mat-raised-button color="basic" type="submit" (click)="toggleGeocercas()"
      class="btn-block">{{showGeocercasText}}</button>
  </div>

  <agm-map (mapReady)="mapReady($event)" [latitude]="lat" [longitude]="lng" [zoom]="16" [fullscreenControl]='true'
    [mapTypeControl]='true' [mapTypeId]="'satellite'">

    <agm-polyline [strokeColor]="'#fff'">
      <agm-polyline-point *ngFor="let location of locations" [latitude]="location.position.latitude"
        [longitude]="location.position.longitude">
      </agm-polyline-point>
    </agm-polyline>

    <ng-container *ngIf="showGeocercas">
      <agm-polygon [paths]="geocerca.points" *ngFor="let geocerca of geocercas" [fillColor]="'#' + geocerca.color"
        [strokeColor]="'#' + geocerca.color">
      </agm-polygon>
    </ng-container>
    
    <agm-marker *ngFor="let point of points" [latitude]="point.lat" [longitude]="point.lng" [iconUrl]=" {
      url: './assets/images/truck_01a.svg',
      scaledSize: {
          width: 60,
          height: 40
      }
    }">
      <agm-info-window>
        <div>
          <h2>{{point.equipment_name}}</h2>
          <p>Operador: {{point.driver}}</p>
          <p>Status: {{point.status}}</p>
          <p>Frota: {{point.fleet_name}}</p>
        </div>
      </agm-info-window>
    </agm-marker>
  </agm-map>
</div>