<h2 mat-dialog-title>Escolha um ícone</h2>
<mat-grid-list [cols]="4" rowHeight="2:1.5">
    <div *ngFor="let image of images">
        <mat-grid-tile (click)='setImage(image)'>
            <div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
                <mat-card class="box">
                    <mat-card-content>
                        <img [src]="'../../../assets/images/fleets-icon/' + image" alt="" width="140px">
                    </mat-card-content>
                </mat-card>
            </div>
        </mat-grid-tile>
    </div>
</mat-grid-list>