import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { map } from 'rxjs/operators';
import { Driver } from 'src/app/drivers/driver.model';
import { DriverService } from 'src/app/drivers/driver.service';
import { Equipment } from 'src/app/equipments/equipment.model';
import { EquipmentService } from 'src/app/equipments/equipment.service';
import { Geocerca } from 'src/app/geocercas/geocerca.model';
import { GeocercaService } from 'src/app/geocercas/geocerca.service';
import { Trip } from 'src/app/trips/trip.model';
import { TripService } from 'src/app/trips/trip.service';
import { DispatchDialogComponent } from '../dispatch-dialog/dispatch-dialog.component';
import { Message } from '../message.model';

class DispatchItem {
  load: Equipment[] = []
  loadQueue: Equipment[] = []
  empty: Equipment[] = []
  full: Equipment[] = []
  unloadQueue: Equipment[] = []
  unload: Equipment[] = []
}

export class DispatchDialogData {
  equipment: Equipment
  equipments: Equipment[]
  geocercas: Geocerca[]
  driver: Driver
  trip: Trip
}

@Component({
  selector: 'app-dispatch',
  templateUrl: './dispatch.component.html',
  styleUrls: ['./dispatch.component.scss']
})
export class DispatchComponent implements OnInit {

  equipments: Equipment[]
  loads: Equipment[]
  maintence: Equipment[]
  stopped: Equipment[]
  withoutDriver: Equipment[]

  geocercas: Geocerca[]

  items: DispatchItem[] = []
  displayedColumns: string[] = ['load', 'loadQueue', 'empty', 'full', 'unloadQueue', 'unload'];
  dataSource: MatTableDataSource<DispatchItem>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('TABLE') table: ElementRef;

  constructor(
    public dialog: MatDialog,
    public driverService: DriverService,
    private fns: AngularFireFunctions,
    public tripService: TripService,
    public geocercaService: GeocercaService,
    private equipmentService: EquipmentService
  ) { }

  ngOnInit(): void {
    this.getEquipments()
    this.getGeocercas()
  }

  openDialog(data: DispatchDialogData): void {
    const dialogRef = this.dialog.open(DispatchDialogComponent, {
      width: '300px',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.sendMessage(result)
      }
    });
  }

  async onEquipmentClick(equipment: Equipment) {
    const data = new DispatchDialogData()
    data.equipment = equipment
    data.equipments = this.loads
    data.geocercas = this.geocercas

    console.log(equipment);
    var trip = null

    if (equipment.driverId)
      await this.driverService.getDriver(equipment.driverId).ref.get()
        .then(async doc => {
          if (doc.exists) {
            const driver = doc.data() as Driver;
            data.driver = driver
          }
        })


    if (equipment.tripId)
      await this.tripService.getTrip(equipment.tripId).ref.get()
        .then(async doc => {
          trip = doc.data() as Trip;
          data.trip = trip
        })

    this.openDialog(data)

  }

  getGeocercas() {
    let sub = this.geocercaService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(geocercas => {
      sub.unsubscribe();
      this.geocercas = geocercas;
    });
  }

  sendMessage(message: Message) {
    let addMessage = this.fns.httpsCallable('sendMessage');

    const senderId = localStorage.getItem('ntk')

    addMessage(JSON.stringify({
      token: message.token,
      message: message.message,
      loadUID: message.loadUID,
      senderId: senderId,
      hasActions: message.hasActions ? "1" : "0"
    })).toPromise()
      .then((result) => {
        console.log(result);
      }).catch(e => {
        console.log(e);
      })
  }

  getEquipments() {
    this.equipmentService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(equipments => {
      this.equipments = equipments;
      this.filterState()
      this.getMaintence()
      this.getStopped()
      this.getWithoutDriver()
    });
  }

  getMaintence() {
    this.maintence = this.equipments.filter(equ => equ.subClass === 5 || equ.subClass === 6);
  }

  getStopped() {
    this.stopped = this.equipments.filter(equ => (equ.subClass === 3 || equ.subClass === 4) && equ.driverId !== null && equ.fleetClass !== 2);
  }

  getWithoutDriver() {
    this.withoutDriver = this.equipments.filter(equ => equ.subClass !== 5 && equ.subClass !== 6 && equ.driverId === null && equ.fleetClass !== 2);
  }

  filterState() {
    this.loads = this.equipments.filter(equ => equ.subClass != 5 && equ.subClass != 6 && equ.fleetClass === 2);

    this.items = []

    let notMaintenceEquipments = this.equipments.filter(equ => equ.subClass != 3 && equ.subClass != 4 && equ.subClass != 5 && equ.subClass != 6 && equ.driverId !== null)

    this.loads.forEach(load => {
      const loading: Equipment[] = notMaintenceEquipments.filter(equ => equ.loadId === load.id && equ.step === 2);
      const loadQueue: Equipment[] = notMaintenceEquipments.filter(equ => equ.loadId === load.id && equ.step === 5);
      const empty: Equipment[] = notMaintenceEquipments.filter(equ => equ.loadId === load.id && equ.step === 1);
      const full: Equipment[] = notMaintenceEquipments.filter(equ => equ.loadId === load.id && equ.step === 3);
      const unloadQueue: Equipment[] = notMaintenceEquipments.filter(equ => equ.loadId === load.id && equ.step === 6);
      const unload: Equipment[] = notMaintenceEquipments.filter(equ => equ.loadId === load.id && equ.step === 4);

      loading.unshift(load)
      const dispatch = new DispatchItem();
      dispatch.load = loading
      dispatch.loadQueue = loadQueue
      dispatch.empty = empty
      dispatch.full = full
      dispatch.unloadQueue = unloadQueue
      dispatch.unload = unload

      this.items.push(dispatch)
    })

    this.updateTable()
  }

  public getIcon(equipment: Equipment): string {
    let icon: string;

    if (equipment.fleetClass == 1)
      switch (equipment.step) {
        case 1:
        case 5: icon = "a1.svg";
          break;
        case 2: icon = "loading.svg";
          break;
        case 3: icon = "a3.svg";
          break;
        case 4: icon = "a4.svg";
          break;
        case 6: icon = "23.svg";
          break;
      }
    else if (equipment.fleetClass == 2)
      switch (equipment.step) {
        case 1: icon = "b1.svg";
          break;
        case 2: icon = "b2.svg";
          break;
      }

    if (equipment.fleetClass == 1) {
      if (equipment.subClass === 3 || equipment.subClass === 4)
        icon = "a1.svg"

      if (equipment.driverId === null)
        icon = "operator.svg"
    }


    if (equipment.subClass === 5 || equipment.subClass === 6)
      switch (equipment.fleetClass) {
        case 1: icon = "maintence.svg";
          break;
        case 2: icon = "maintence2.svg";
          break;
      }

    return './assets/images/map/' + icon;
  }

  private updateTable() {
    this.dataSource = new MatTableDataSource(this.items);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
}