<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="box">

    <mat-card-header>
      <mat-card-title>Alterar material</mat-card-title>
    </mat-card-header>

    <form [formGroup]="form" #myForm="ngForm" (ngSubmit)="onSubmit()" class="example-form">

      <mat-card-content>
        <mat-form-field class="example-full-width">
          <input matInput type="text" placeholder="Nome" [(ngModel)]="material.name" formControlName="name" required>
          <mat-error *ngIf="isFieldInvalid('name')">
            Informe o nome do material
          </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width" style="width: 80%; margin-right: 20px;">
          <input matInput type="text" placeholder="Cor" [(ngModel)]="material.color" formControlName="color" required>
          <mat-error *ngIf="isFieldInvalid('color')">
            Informe a cor
          </mat-error>
        </mat-form-field>
        <button class="color" mat-mini-fab [style.background-color]="'#' + material.color" type="button"
          (click)="changeState()">
          <mat-icon>colorize</mat-icon>
        </button>
        <div class="example-full-width" fxLayoutAlign="end">
          <color-github *ngIf="displayColor" [color]="'#' + material.color" (onChange)="changeComplete($event)">
          </color-github>
        </div>
      </mat-card-content>

      <button mat-raised-button color="primary" type="submit" class="btn-block">Alterar</button>
    </form>
  </mat-card>
</div>