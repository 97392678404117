import { Component, OnInit, ViewChild } from '@angular/core';
import { map } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { EquipmentService } from 'src/app/equipments/equipment.service';
import { FleetService } from 'src/app/fleets/fleet.service';
import { GeocercaService } from 'src/app/geocercas/geocerca.service';
import { MatDialog } from '@angular/material/dialog';
import { Maintenance } from '../maintenance.model';
import { MaintenanceService } from '../maintenance.service';

@Component({
  selector: 'app-maintenance-list',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {

  maintenance: Maintenance[];
  equipments: any;
  equipment: string = "all";
  fleets: any;
  fleet: string = "all";
  dataSource: MatTableDataSource<Maintenance>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    public dialog: MatDialog,
    private geocercaService: GeocercaService,
    private fleetsService: FleetService,
    private equipmentService: EquipmentService,
    private maintenanceService: MaintenanceService) { }

  classifyPoint = require("robust-point-in-polygon")

  displayedColumns: string[] = ['date_start', 'date_start', 'name', 'responsible_start', 'responsible_end', 'fleet', 'equipment'];

  onDateChange(dateStart, dateEnd) {

    var start = new Date(dateStart);
    var end = new Date(dateEnd);

    if (!isNaN(+start) && !isNaN(+end))
      this.getListTime(start, end, this.equipment, this.fleet);
  }

  ngOnInit() {
    this.getEquipments();
    this.getFleets();
    this.getList();

    var today = new Date();
    var yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    this.selectedMoments = [yesterday, today];

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public selectedMoments = [
    new Date(),
    new Date()
  ];

  getList() {
    this.maintenanceService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(maintenance => {
      this.maintenance = maintenance;
      this.updateTable();
    });
  }

  clear() {
    this.getList();
  }

  getEquipments() {
    this.equipmentService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(equipments => {
      this.equipments = equipments;
    });
  }

  getFleets() {
    this.fleetsService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(fleets => {
      this.fleets = fleets;
    });
  }

  getListTime(start: Date, end: Date, equipment: string, fleet: string) {
    this.maintenanceService.getListByTime(start, end, equipment, fleet).snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(maintenance => {
      this.maintenance = maintenance;
      this.updateTable();
    });
  }

  private updateTable() {
    this.dataSource = new MatTableDataSource(this.maintenance);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
}
