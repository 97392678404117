<body>
  <div class="login-wrapper" fxLayout="row wrap" fxLayout.lt-sm="column wrap" fxLayoutAlign="center center">
    <mat-form-field class="example-full-width mat-elevation-z7">
      <mat-label class="example-header">Equipamento</mat-label>
      <mat-select [(value)]="equipment" (selectionChange)="getEquipment()">
        <mat-option [value]="'all'">Todos</mat-option>
        <mat-option *ngFor="let equipment of equipmentsDrop" [value]="equipment.id">
          {{equipment.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="example-full-width mat-elevation-z7">
      <mat-label class="example-header">Frota</mat-label>
      <mat-select [(value)]="fleet" (selectionChange)="onDateChange(dateStart.value, dateEnd.value)">
        <mat-option [value]="'all'">Todas</mat-option>
        <mat-option *ngFor="let fleet of fleets" [value]="fleet.id">
          {{fleet.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="example-full-width mat-elevation-z7">
      <mat-label class="example-header">Início</mat-label>
      <input matInput #dateStart [owlDateTimeTrigger]="dt12" [owlDateTime]="dt12" [selectMode]="'rangeFrom'"
        [(ngModel)]="selectedMoments" (dateTimeInput)="onDateChange(dateStart.value, dateEnd.value)">
      <owl-date-time #dt12></owl-date-time>
    </mat-form-field>

    <mat-form-field class="example-full-width mat-elevation-z7">
      <mat-label class="example-header">Fim</mat-label>
      <input matInput #dateEnd [owlDateTimeTrigger]="dt13" [owlDateTime]="dt13" [selectMode]="'rangeTo'"
        [(ngModel)]="selectedMoments" (dateTimeInput)="onDateChange(dateStart.value, dateEnd.value)">
      <owl-date-time #dt13></owl-date-time>
    </mat-form-field>

    <button mat-raised-button color="basic" type="submit" (click)="clear()" class="btn-block">Limpar</button>
  </div>

  <mat-form-field class="example-fill-width mat-elevation-z3">
    <mat-label>Filtro</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="" #input>
  </mat-form-field>

  <div class="mat-elevation-z8" style="margin-bottom: 50px;" #TABLE>
    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Nome </th>
        <td mat-cell *matCellDef="let row"> {{row.name}} </td>
      </ng-container>

      <ng-container matColumnDef="speed">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Velocidade Media (Km/h) </th>
        <td mat-cell *matCellDef="let row"> {{row.speed}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="8">No data matching the filter "{{input.value}}"</td>
      </tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100, 1000, 10000]" showFirstLastButtons></mat-paginator>
  </div>
</body>