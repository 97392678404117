<h1 mat-dialog-title>Cadastro de turno</h1>

<form [formGroup]="form" #myForm="ngForm" (ngSubmit)="onSubmit()" class="example-form">

    <mat-form-field class="example-full-width">
        <input matInput type="text" placeholder="Legenda" [(ngModel)]="data.shift.label" formControlName="label"
            required>
        <mat-error *ngIf="isFieldInvalid('label')">
            Informe a legenda
        </mat-error>
    </mat-form-field>

    <ngx-material-timepicker #start></ngx-material-timepicker>
    <ngx-material-timepicker #end></ngx-material-timepicker>

    <mat-form-field class="example-full-width">
        <input matInput type="text" [ngxTimepicker]="start" [format]="24" placeholder="Início" [(ngModel)]="startInput"
            formControlName="start" required>
        <mat-error *ngIf="isFieldInvalid('start')">
            Informe o início do turno
        </mat-error>
    </mat-form-field>

    <mat-form-field class="example-full-width">
        <input matInput type="number" placeholder="Tolerância (min)" [(ngModel)]="data.shift.toleranceStart"
            formControlName="toleranceStart" required>
        <mat-error *ngIf="isFieldInvalid('toleranceStart')">
            Informe a tolerância
        </mat-error>
    </mat-form-field>

    <mat-form-field class="example-full-width">
        <input matInput type="text" [ngxTimepicker]="end" [format]="24" placeholder="Fim" [(ngModel)]="endInput"
            formControlName="end" required>
        <mat-error *ngIf="isFieldInvalid('end')">
            Informe o fim do turno
        </mat-error>
    </mat-form-field>

    <mat-form-field class="example-full-width">
        <input matInput type="number" placeholder="Tolerância (min)" [(ngModel)]="data.shift.toleranceEnd"
            formControlName="toleranceEnd" required>
        <mat-error *ngIf="isFieldInvalid('toleranceEnd')">
            Informe a tolerância
        </mat-error>
    </mat-form-field>


    <button mat-button type="button" (click)="onNoClick()">Cancelar</button>
    <button mat-raised-button color="primary" type="submit" class="btn-block">Adicionar</button>
</form>