import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth/angular-fire-auth';
import { AppComponent } from '../app.component';
import { map } from 'rxjs/operators';
import { Trip } from '../trips/trip.model';
import { ChartDash } from '../dashboard/chart.model';
import { ChartService } from '../dashboard/chart.service';
import { DashboardService } from './dashboard.service';
import { Dashboard } from './dashboard.enum';
import { Chart } from 'angular-highcharts';
import { Board } from '../dashboard/board.model';
import { AngularFireFunctions } from '@angular/fire/functions';

@Component({
   selector: 'app-home',
   templateUrl: './home.component.html',
   styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

   breakpoint: number;
   dateBreak: number;
   version = this.myapp.version;
   dailyTrips: Trip[];
   monthlyTrips: Trip[];
   fleetData: {} = [];

   graphs: Chart[];
   boards: Board[] = new Array()


   constructor(
      private dashboardService: DashboardService,
      private chartService: ChartService,
      public myapp: AppComponent,
      public afAuth: AngularFireAuth) { }

   ngOnInit(): void {
      this.getCharts();
      this.breakpoint = (window.innerWidth <= 400) ? 1 : 2;
   }

   onResize(event) {
      this.breakpoint = (event.target.innerWidth <= 400) ? 1 : 2;
   }

   getCharts() {
      this.chartService.getList().snapshotChanges().pipe(
         map(changes =>
            changes.map(c =>
               ({ id: c.payload.doc.id, ...c.payload.doc.data() })
            )
         )
      ).subscribe(charts => {
         this.setGraphs(charts);
      });
   }

   setGraphs(charts: ChartDash[]) {
      this.graphs = [];

      charts.forEach(async chart => {
         if (chart.format === 3) {
            console.log(chart);

            this.boards.push(await this.dashboardService.getBoardData(chart, Dashboard[chart.type]))
         }
         else
            this.graphs.push(await this.dashboardService.getChartData(chart, Dashboard[chart.type]));
      })

      console.log(this.boards);

   }

}
