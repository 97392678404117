import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AuthService } from '../../auth/services/auth.service';
import { Router } from '@angular/router';
import { User } from '../../shared/models/user.interface';
import { ProfileService } from 'src/app/profiles/profile.service';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent implements OnInit {
  isExpanded = false;
  opened = false;
  user: User
  profile: string

  public configs$: Observable<{}>
  public reports$: Observable<{}>
  public user$: Observable<User>
  myBool: boolean

  constructor(
    public authSvc: AuthService,
    public profileService: ProfileService,
    public afAuth: AngularFireAuth,
    private router: Router) { }

  ngOnInit(): void {

    this.user = this.authSvc.getUser
    this.user$ = of(this.user)

    this.authSvc.myBool$.subscribe((newBool) => {
      this.myBool = newBool
      this.user = this.authSvc.getUser

      if (newBool) {
        this.user$ = of(this.user)
        this.getProfile()
      } else {
        this.user$ = null
      }
    });

    if (this.authSvc.getProfile)
      this.getProfile()
  }

  getProfile() {

    let profile = this.authSvc.getProfile

    let configs = {}
    let reports = {}

    profile.configs.forEach((config) => configs[config.key] = config.value)
    this.configs$ = of(configs)
    this.configs$.subscribe((_) => {
      this.profile = profile.name
    });

    profile.reports.forEach((report) => reports[report.key] = report.value)
    this.reports$ = of(reports)
    this.reports$.subscribe((_) => {
    });
  }

  async onLogout() {
    try {
      this.user$ = null
      await this.authSvc.logout();
      indexedDB.deleteDatabase('firebaseLocalStorageDb');
      this.router.navigate(['/login']);
    } catch (error) {
      console.log(error);
    }
  }

}
