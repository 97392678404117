import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../records-list/records-list.component';

@Component({
    selector: 'app-records-dialog',
    templateUrl: './records-dialog.component.html',
    styleUrls: ['./records-dialog.component.scss']
})
export class RecordsDialogComponent {

    map: any;

    constructor(
        public dialogRef: MatDialogRef<RecordsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    mapReady(map) {
        this.map = map;
        this.centerMap();
    }

    private centerMap() {

        var bounds = new google.maps.LatLngBounds();

        bounds.extend(new google.maps.LatLng(this.data.record.location.latitude, this.data.record.location.longitude))

        this.map.setCenter(bounds.getCenter());
        this.map.fitBounds(bounds);
    }
}