<div class="select" fxLayoutAlign="center center">
    <mat-form-field class="example-full-width">
        <mat-label>Frota</mat-label>
        <mat-select [(value)]="fleet" (selectionChange)="onUpdateFleet()" [(ngModel)]="fleet">
            <mat-option *ngFor="let fleet of fleets" [value]="fleet.id">
                {{fleet.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>

<div *ngIf="cardsFromFleet">
    <div fxLayoutAlign="center center">
        <div class="left">
            <div class="slider rel">
                <mat-card (click)='openDialog(1)' class="box" [ngStyle]="{'backgroundColor': '#424242'}">

                    <mat-card-content>
                        <svg-icon fxLayoutAlign="center center"
                            src="./assets/images/cards-icon/{{cardsFromFleet.card1.icon}}.svg" [stretch]="false"
                            [svgStyle]="{'fill': '#' + cardsFromFleet.card1.color}"></svg-icon>
                    </mat-card-content>
                    <mat-card-header fxLayoutAlign="center center">
                        <mat-card-title>{{cardsFromFleet.card1.name}}</mat-card-title>
                    </mat-card-header>

                </mat-card>
            </div>
            <div class="content rel">
                <button class="color" mat-mini-fab [style.background-color]="'#' + cardsFromFleet.card1.color"
                    type="button" (click)="changeState(1)"><mat-icon>colorize</mat-icon></button>
                <button class="name" mat-raised-button type="button" (click)="openDialogName(1)">Alterar
                    nome</button>
            </div>
            <color-github *ngIf="display1" [color]="'#' + cardsFromFleet.card1.color"
                (onChange)="changeComplete($event, 1)">
            </color-github>
        </div>

        <div class="rigth">
            <div class="slider rel">
                <mat-card (click)='openDialog(2)' class="box" [ngStyle]="{'backgroundColor': '#424242'}">

                    <mat-card-content>
                        <svg-icon fxLayoutAlign="center center"
                            src="./assets/images/cards-icon/{{cardsFromFleet.card2.icon}}.svg" [stretch]="false"
                            [svgStyle]="{'fill': '#' + cardsFromFleet.card2.color}"></svg-icon>
                    </mat-card-content>
                    <mat-card-header fxLayoutAlign="center center">
                        <mat-card-title>{{cardsFromFleet.card2.name}}</mat-card-title>
                    </mat-card-header>

                </mat-card>
            </div>
            <div class="content rel">
                <button class="color" mat-mini-fab [style.background-color]="'#' + cardsFromFleet.card2.color"
                    type="button" (click)="changeState(2)"><mat-icon>colorize</mat-icon></button>
                <button class="name" mat-raised-button type="button" (click)="openDialogName(2)">Alterar
                    nome</button>
            </div>
            <color-github *ngIf="display2" [color]="'#' + cardsFromFleet.card2.color"
                (onChange)="changeComplete($event, 2)">
            </color-github>
        </div>
    </div>
    <div fxLayoutAlign="center center">
        <div class="left">
            <div class="slider rel">
                <mat-card (click)='openDialog(3)' class="box" [ngStyle]="{'backgroundColor': '#424242'}">

                    <mat-card-content>
                        <svg-icon fxLayoutAlign="center center"
                            src="./assets/images/cards-icon/{{cardsFromFleet.card3.icon}}.svg" [stretch]="false"
                            [svgStyle]="{'fill': '#' + cardsFromFleet.card3.color}"></svg-icon>
                    </mat-card-content>
                    <mat-card-header fxLayoutAlign="center center">
                        <mat-card-title>{{cardsFromFleet.card3.name}}</mat-card-title>
                    </mat-card-header>

                </mat-card>
            </div>
            <div class="content rel">
                <button class="color" mat-mini-fab [style.background-color]="'#' + cardsFromFleet.card3.color"
                    type="button" (click)="changeState(3)"><mat-icon>colorize</mat-icon></button>
                <button class="name" mat-raised-button type="button" (click)="openDialogName(3)">Alterar
                    nome</button>
            </div>
            <color-github *ngIf="display3" [color]="'#' + cardsFromFleet.card3.color"
                (onChange)="changeComplete($event, 3)">
            </color-github>
        </div>

        <div class="rigth">
            <div class="slider rel">
                <mat-card (click)='openDialog(4)' class="box" [ngStyle]="{'backgroundColor': '#424242'}">

                    <mat-card-content>
                        <svg-icon fxLayoutAlign="center center"
                            src="./assets/images/cards-icon/{{cardsFromFleet.card4.icon}}.svg" [stretch]="false"
                            [svgStyle]="{'fill': '#' + cardsFromFleet.card4.color}"></svg-icon>
                    </mat-card-content>

                    <mat-card-header fxLayoutAlign="center center">
                        <mat-card-title>{{cardsFromFleet.card4.name}}</mat-card-title>
                    </mat-card-header>
                </mat-card>
            </div>
            <div class="content rel">
                <button class="color" mat-mini-fab [style.background-color]="'#' + cardsFromFleet.card4.color"
                    type="button" (click)="changeState(4)"><mat-icon>colorize</mat-icon></button>
                <button class="name" mat-raised-button type="button" (click)="openDialogName(4)">Alterar
                    nome</button>
            </div>
            <color-github *ngIf="display4" [color]="'#' + cardsFromFleet.card4.color"
                (onChange)="changeComplete($event, 4)">
            </color-github>
        </div>
    </div>
    <div fxLayoutAlign="center center" style="margin-bottom: 150px;">
        <div class="left">
            <div class="slider rel">
                <mat-card class="box" [ngStyle]="{'backgroundColor': '#424242'}">

                    <mat-card-content>
                        <svg-icon fxLayoutAlign="center center" src="./assets/images/cards-icon/13.svg"
                            [stretch]="false" [svgStyle]="{'fill': '#' + cardsFromFleet.card5.color}"></svg-icon>
                    </mat-card-content>
                    <mat-card-header fxLayoutAlign="center center">
                        <mat-card-title>{{cardsFromFleet.card5.name}}</mat-card-title>
                    </mat-card-header>

                </mat-card>
            </div>
            <div class="content rel">
                <button class="color" mat-mini-fab [style.background-color]="'#' + cardsFromFleet.card5.color"
                    type="button" (click)="changeState(5)"><mat-icon>colorize</mat-icon></button>
            </div>
            <color-github *ngIf="display5" [color]="'#' + cardsFromFleet.card5.color"
                (onChange)="changeComplete($event, 5)">
            </color-github>
        </div>

        <div class="rigth">
            <div class="slider rel">
                <mat-card class="box" [ngStyle]="{'backgroundColor': '#424242'}">

                    <mat-card-content>
                        <svg-icon fxLayoutAlign="center center" src="./assets/images/cards-icon/12.svg"
                            [stretch]="false" [svgStyle]="{'fill': '#' + cardsFromFleet.card6.color}"></svg-icon>
                    </mat-card-content>

                    <mat-card-header fxLayoutAlign="center center">
                        <mat-card-title>{{cardsFromFleet.card6.name}}</mat-card-title>
                    </mat-card-header>
                </mat-card>
            </div>
            <div class="content rel">
                <button class="color" mat-mini-fab [style.background-color]="'#' + cardsFromFleet.card6.color"
                    type="button" (click)="changeState(6)"><mat-icon>colorize</mat-icon></button>
            </div>
            <color-github *ngIf="display6" [color]="'#' + cardsFromFleet.card6.color"
                (onChange)="changeComplete($event, 6)">
            </color-github>
        </div>
    </div>
</div>