import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Trip } from './trip.model';

@Injectable({
  providedIn: 'root'
})
export class TripService {

  private dbPath = '/trips';
  client = "";

  collection: AngularFirestoreCollection<Trip> = null;

  constructor(private db: AngularFirestore) {
    this.client = JSON.parse(localStorage.getItem('documentData')).client_id;
    this.collection = db.collection('clients').doc(this.client).collection(this.dbPath, ref => ref.orderBy("date_start", "desc"));
  }

  update(key: string, value: any): Promise<void> {
    return this.collection.doc(key).update(value);
  }

  delete(key: string): Promise<void> {
    return this.collection.doc(key).delete();
  }

  getList(): AngularFirestoreCollection<Trip> {
    return this.collection;
  }

  getTrip(key: string): AngularFirestoreDocument<Trip> {
    return this.collection.doc(key);
  }

  getContingency(): AngularFirestoreCollection<Trip> {
    return this.db.collection('clients').doc(this.client).collection(this.dbPath, ref => ref.where("contingency", "==",true).orderBy("date_start", "desc"));
  }

  getListByTime(start: Date, end: Date, equipment: string, fleet: string): AngularFirestoreCollection<Trip> {

    if (equipment == "all" && fleet == "all")
      return this.db.collection('clients').doc(this.client).collection(this.dbPath, ref => ref
        .where('date_start', '>=', start)
        .where('date_start', '<=', end)
        .orderBy("date_start", "desc")
      );

    else if (equipment == "all")
      return this.db.collection('clients').doc(this.client).collection(this.dbPath, ref => ref
        .orderBy("date_start", "desc")
        .where('date_start', '>=', start)
        .where('date_start', '<=', end)
        .where('fleet_id', '==', fleet)
      );

    else if (fleet == "all")
      return this.db.collection('clients').doc(this.client).collection(this.dbPath, ref => ref
        .orderBy("date_start", "desc")
        .where('date_start', '>=', start)
        .where('date_start', '<=', end)
        .where('equipment_id', '==', equipment)
      );

    else
      return this.db.collection('clients').doc(this.client).collection(this.dbPath, ref => ref
        .orderBy("date_start", "desc")
        .where('date_start', '>=', start)
        .where('date_start', '<=', end)
        .where('equipment_id', '==', equipment)
        .where('fleet_id', '==', fleet)
      );
  }

  getWithFilter(start: Date, end: Date, equipment: string, fleet: string, material: string):
    AngularFirestoreCollection<Trip> {

      console.log({start});
      console.log({end});
      console.log({equipment});
      console.log({fleet});
      console.log({material});
      

    if (!equipment && !fleet && !material)
      return this.db.collection('clients').doc(this.client).collection(this.dbPath, ref => ref
        .where('date_start', '>=', start)
        .where('date_start', '<=', end)
        .orderBy("date_start", "desc")
      );

    else if (!equipment && !material)
      return this.db.collection('clients').doc(this.client).collection(this.dbPath, ref => ref
        .orderBy("date_start", "desc")
        .where('date_start', '>=', start)
        .where('date_start', '<=', end)
        .where('fleet_id', '==', fleet)
      );

    else if (!fleet && !material)
      return this.db.collection('clients').doc(this.client).collection(this.dbPath, ref => ref
        .orderBy("date_start", "desc")
        .where('date_start', '>=', start)
        .where('date_start', '<=', end)
        .where('equipment_id', '==', equipment)
      );

    else if (!equipment && !fleet)
      return this.db.collection('clients').doc(this.client).collection(this.dbPath, ref => ref
        .orderBy("date_start", "desc")
        .where('date_start', '>=', start)
        .where('date_start', '<=', end)
        .where('material', '==', material)
      );

    else if (!equipment)
      return this.db.collection('clients').doc(this.client).collection(this.dbPath, ref => ref
        .orderBy("date_start", "desc")
        .where('date_start', '>=', start)
        .where('date_start', '<=', end)
        .where('fleet_id', '==', fleet)
        .where('material', '==', material)
      );

    else if (!fleet)
      return this.db.collection('clients').doc(this.client).collection(this.dbPath, ref => ref
        .orderBy("date_start", "desc")
        .where('date_start', '>=', start)
        .where('date_start', '<=', end)
        .where('equipment_id', '==', equipment)
        .where('material', '==', material)
      );

    else if (!material)
      return this.db.collection('clients').doc(this.client).collection(this.dbPath, ref => ref
        .orderBy("date_start", "desc")
        .where('date_start', '>=', start)
        .where('date_start', '<=', end)
        .where('equipment_id', '==', equipment)
        .where('fleet_id', '==', fleet)
      );

    else
      return this.db.collection('clients').doc(this.client).collection(this.dbPath, ref => ref
        .orderBy("date_start", "desc")
        .where('date_start', '>=', start)
        .where('date_start', '<=', end)
        .where('equipment_id', '==', equipment)
        .where('fleet_id', '==', fleet)
        .where('material', '==', material)
      );
  }

  deleteAll() {
    this.collection.get().subscribe(
      querySnapshot => {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
        });
      },
      error => {
        console.log('Error: ', error);
      });
  }
}
