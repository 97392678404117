import { Component, OnInit, ViewChild } from '@angular/core';
import { Material } from '../material.model';
import { MaterialService } from '../material.service';
import { MatSnackBarVerticalPosition, MatSnackBarHorizontalPosition, MatSnackBar } from '@angular/material/snack-bar';
import { NgForm, FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ColorEvent } from 'ngx-color';

@Component({
  selector: 'app-material-create',
  templateUrl: './material-create.component.html',
  styleUrls: ['./material-create.component.scss']
})
export class MaterialCreateComponent implements OnInit {

  material: Material = new Material();

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  @ViewChild('myForm') private myForm: NgForm;

  private formSubmitAttempt: boolean;

  form = new FormGroup({
    name: new FormControl(''),
    color: new FormControl('')
  });

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private materialService: MaterialService) { }
 
  ngOnInit() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      color: ['', Validators.required]
    });
  }

  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitAttempt)
    );
  }

  save() {
    this.materialService.create(this.material);
    this.myForm.resetForm();
    this.material = new Material();
  }
 
  onSubmit() {
    if (this.form.valid) {
      this.openSnackBar("Material inserido");
      this.save();
    } else this.openSnackBar("Preencha todos os campos");
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "Fechar", {
      duration: 2000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }
  
  displayColor: boolean = false;

  changeState(){
    this.displayColor = !this.displayColor;
  }

  changeComplete($event: ColorEvent) {
    this.displayColor = false;

    var color = $event.color.hex.replace(/#/i, '');

    this.material.color = color;
  }
}
