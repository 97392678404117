<div fxLayout="column" fxLayoutAlign="center center">
    <mat-card-header>
        <mat-card-title>Configurações dos filtros de viagens</mat-card-title>
    </mat-card-header>

    <div class="list">
        <div *ngFor="let filter of contingency.filters">
            <app-filter-trip-item [contingency]='filter'></app-filter-trip-item>
        </div>

        <div class="wrapper" fxLayout="row" fxLayoutAlign="center center">

            <div class="name">
                Geocerca
            </div>

            <mat-checkbox class="checkbox-full-width" type="checkbox" [(ngModel)]="contingency.geocerca.carga"
                [disabled]='contingency.geocerca.all || contingency.geocerca.automatic'>
                Carga
            </mat-checkbox>

            <mat-checkbox class="checkbox-full-width" type="checkbox" [(ngModel)]="contingency.geocerca.descarga"
                [disabled]='contingency.geocerca.all || contingency.geocerca.automatic'>
                Descarga
            </mat-checkbox>

            <mat-checkbox class="checkbox-full-width" type="checkbox" [(ngModel)]="contingency.geocerca.all"
                [disabled]='contingency.geocerca.automatic'>
                Todas
            </mat-checkbox>

            <mat-checkbox class="checkbox-full-width" type="checkbox" [(ngModel)]="contingency.geocerca.automatic">
                Automático
            </mat-checkbox>
        </div>
    </div>

    <button mat-raised-button color="primary" type="button" class="btn-block" (click)="onSave()">Salvar</button>
</div>