export class Fleet {
    id: string;
    name: string;
    fleetClass: number;
    fleetIcon: string;
    speedLimit: number;
    active: boolean = true;
    load: number;
    configs: {name: string, color: string}[] = [
        {"name": "Operating", "color": "#009900"},
        {"name": "Operational stops", "color": "#FFC000"},
        {"name": "Offline for more than 1 hour", "color": "#C00000"},
        {"name": "Offline for more than 12 hours", "color": "#7F7F7F"},
        {"name": "Out of fleet", "color": "#7F7F7F"},
        {"name": "Maintenance up to 12 hours", "color": "#FFC000"},
        {"name": "Maintenance for more than 12 hours", "color": "#7F7F7F"},
    ];
}
