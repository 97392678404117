import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Chart } from 'angular-highcharts';
import { ColorEvent } from 'ngx-color';
import { map } from 'rxjs/operators';
import { Equipment } from 'src/app/equipments/equipment.model';
import { EquipmentService } from 'src/app/equipments/equipment.service';
import { Fleet } from 'src/app/fleets/fleet.model';
import { FleetService } from 'src/app/fleets/fleet.service';
import { Dashboard } from 'src/app/home/dashboard.enum';
import { Interval } from 'src/app/home/interval.enum';
import { Material } from 'src/app/materials/material.model';
import { MaterialService } from 'src/app/materials/material.service';
import { ChartDash } from '../chart.model';
import { ChartService } from '../chart.service';
import { Unit } from '../unit.enum';

interface Hour {
  value: number;
  viewValue: string;
}

interface ListModel {
  value: number;
  label: string;
}

@Component({
  selector: 'app-chart-bar',
  templateUrl: './chart-bar.component.html',
  styleUrls: ['./chart-bar.component.scss']
})
export class ChartBarComponent implements OnInit {

  chart: ChartDash = new ChartDash();
  example: Chart;
  fleets: Fleet[];
  equipments: Equipment[];
  materials: Material[];
  fleet: Fleet
  equipment: Equipment
  material: Material

  isPersonalized: boolean = false;
  isMovel: boolean = false;
  isHourly: boolean = false;
  isDaily: boolean = false;
  isWeekly: boolean = false;
  isMonthly: boolean = false;
  isProd: boolean = false;

  public max = new Date();

  intervals: ListModel[] = [
    { value: Interval.TURNO_ATUAL, label: 'Turno atual' },
    { value: Interval.TURNO_ANTERIOR, label: 'Turno anterior' },
    { value: Interval.DIA_ATUAL, label: 'Dia atual' },
    { value: Interval.DIA_ANTERIOR, label: 'Dia anterior' },
    { value: Interval.SEMANA_ATUAL, label: 'Semana atual' },
    { value: Interval.SEMANA_ANTERIOR, label: 'Semana anterior' },
    { value: Interval.MES_ATUAL, label: 'Mês atual' },
    { value: Interval.MES_ANTERIOR, label: 'Mês anterior' },
    { value: Interval.ANO_ATUAL, label: 'Ano atual' },
    { value: Interval.ANO_ANTERIOR, label: 'Ano anterior' },
    { value: Interval.MEDIA_MOVEL, label: 'Média móvel' },
    { value: Interval.PERSONALIZADO, label: 'Personalizado' },
  ];

  units: ListModel[] = [
    { value: Unit.VIAGENS, label: 'Viagens' },
    { value: Unit.KT, label: 'Kt (métricas)' },
    { value: Unit.T, label: 'T (métricas)' },
    { value: Unit.KTON, label: 'Kton (curtas)' },
    { value: Unit.TON, label: 'Ton (curtas)' },
    { value: Unit.KG, label: 'Kg' }
  ]

  @ViewChild('myForm') private myForm: NgForm;

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  private formSubmitAttempt: boolean;
  form = new FormGroup({
    title: new FormControl(''),
    meta_color: new FormControl(''),
    main_color: new FormControl(''),
    use_title: new FormControl(''),

    fleet: new FormControl(''),
    equipment: new FormControl(''),
    material: new FormControl(''),
    interval: new FormControl(''),
    unit: new FormControl(''),
    movel: new FormControl(''),
    movel_days: new FormControl(''),
    interval_date: new FormControl(''),
    tripPeriod: new FormControl(''),

    h1: new FormControl(''),
    h2: new FormControl(''),
    h3: new FormControl(''),
    h4: new FormControl(''),
    h5: new FormControl(''),
    h6: new FormControl(''),
    h7: new FormControl(''),
    h8: new FormControl(''),
    h9: new FormControl(''),
    h10: new FormControl(''),
    h11: new FormControl(''),
    h12: new FormControl(''),
    h13: new FormControl(''),
    h14: new FormControl(''),
    h15: new FormControl(''),
    h16: new FormControl(''),
    h17: new FormControl(''),
    h18: new FormControl(''),
    h19: new FormControl(''),
    h20: new FormControl(''),
    h21: new FormControl(''),
    h22: new FormControl(''),
    h23: new FormControl(''),
    h24: new FormControl(''),
    h25: new FormControl(''),
    h26: new FormControl(''),
    h27: new FormControl(''),
    h28: new FormControl(''),
    h29: new FormControl(''),
    h30: new FormControl(''),
    h31: new FormControl(''),
    h32: new FormControl(''),
    h33: new FormControl(''),
    h34: new FormControl(''),
    h35: new FormControl(''),
    h36: new FormControl(''),
    h37: new FormControl(''),
    h38: new FormControl(''),
    h39: new FormControl(''),
    h40: new FormControl(''),
  });

  hours: Hour[] = [
    { value: 1, viewValue: '01:00' },
    { value: 2, viewValue: '02:00' },
    { value: 3, viewValue: '03:00' },
    { value: 4, viewValue: '04:00' },
    { value: 5, viewValue: '05:00' },
    { value: 6, viewValue: '06:00' },
    { value: 7, viewValue: '07:00' },
    { value: 8, viewValue: '08:00' },
    { value: 9, viewValue: '09:00' },
    { value: 10, viewValue: '10:00' },
    { value: 11, viewValue: '11:00' },
    { value: 12, viewValue: '12:00' },
    { value: 13, viewValue: '13:00' },
    { value: 14, viewValue: '14:00' },
    { value: 15, viewValue: '15:00' },
    { value: 16, viewValue: '16:00' },
    { value: 17, viewValue: '17:00' },
    { value: 18, viewValue: '18:00' },
    { value: 19, viewValue: '19:00' },
    { value: 20, viewValue: '20:00' },
    { value: 21, viewValue: '21:00' },
    { value: 22, viewValue: '22:00' },
    { value: 23, viewValue: '23:00' },
    { value: 24, viewValue: '24:00' }
  ];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private snackBar: MatSnackBar,
    private fleetsService: FleetService,
    private materialService: MaterialService,
    private equipmentService: EquipmentService,
    private chartService: ChartService) { }

  ngOnInit(): void {
    this.getFleets()
    this.getMaterials()

    this.form = this.fb.group({
      title: [],
      meta_color: ['', Validators.required],
      main_color: ['', Validators.required],
      use_title: ['', Validators.required],
      fleet: [],
      unit: [],
      equipment: [],
      material: [],
      interval: ['', Validators.required],
      movel: [],
      movel_days: [],
      interval_date: [],
      tripPeriod: [''],

      h1: [],
      h2: [],
      h3: [],
      h4: [],
      h5: [],
      h6: [],
      h7: [],
      h8: [],
      h9: [],
      h10: [],
      h11: [],
      h12: [],
      h13: [],
      h14: [],
      h15: [],
      h16: [],
      h17: [],
      h18: [],
      h19: [],
      h20: [],
      h21: [],
      h22: [],
      h23: [],
      h24: [],
      h25: [],
      h26: [],
      h27: [],
      h28: [],
      h29: [],
      h30: [],
      h31: [],
      h32: [],
      h33: [],
      h34: [],
      h35: [],
      h36: [],
      h37: [],
      h38: [],
      h39: [],
      h40: [],
    });

    this.chart = JSON.parse(window.localStorage.getItem("editChartObj"));

    if (!this.chart.meta) {
      if (this.chart.format === 2)
        this.chart.meta = new Array(40).fill(0)
    }

    if (!this.chart) {
      this.openSnackBar('Ação inválida.')
      this.router.navigate(['charts']);
      return;
    }

    if(!this.chart.isPeriodChecked) this.chart.isPeriodChecked = true

    console.log(this.chart);

    if (this.chart.movel) this.isMovel = true
    if (this.chart.interval_date) this.isPersonalized = true
    if (this.chart.type === 'PROD') this.isProd = true

    this.onIntervalChange(this.chart.interval)
    this.setExample();
  }

  public selectedMoments = [
    new Date(),
    new Date()
  ];

  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitAttempt)
    );
  }

  save() {
    console.log(this.chart);
    
    if (!this.equipment) {
      delete this.chart.equipment_id
      delete this.chart.equipment_name
    }

    if (!this.material) {
      delete this.chart.material_id
      delete this.chart.material_name
    }

    if (!this.fleet) {
      delete this.chart.fleet_id
      delete this.chart.fleet_name
    }

    if (this.chart.model) {
      delete this.chart.id
      delete this.chart.model
      this.chartService.create(this.chart);
    }
    else
      this.chartService.set(this.chart);
    this.chart = new ChartDash();
  }

  onSubmit() {
    if (this.form.valid) {
      if (!this.chart.use_title && !this.form.get('title').value) {
        this.openSnackBar("Preencha todos os campos");
        return;
      } else if (this.chart.use_title) delete this.chart.title

      if (this.isMovel) {
        if (!this.form.get('movel').value) {
          this.openSnackBar("Informe os dias");
          return;
        }
      } else delete this.chart.movel

      if (this.isPersonalized) {
        if (!this.form.get('interval_date').value) {
          this.openSnackBar("Informe as datas do intervalo");
          return;
        }
      } else
        delete this.chart.interval_date

      this.openSnackBar("Gráfico atualizado");
      this.save();
      this.router.navigate(['charts']);
    } else this.openSnackBar("Preencha todos os campos");
  }

  ngOnDestroy() {
    window.localStorage.removeItem("editChartObj");
  }

  displayMetaColor: boolean = false;
  displayMainColor: boolean = false;

  changeState(type) {
    if (type == 1)
      this.displayMetaColor = !this.displayMetaColor;
    else
      this.displayMainColor = !this.displayMainColor;
  }

  changeComplete($event: ColorEvent, type) {
    this.displayMetaColor = false;
    this.displayMainColor = false;

    var color = $event.color.hex.replace(/#/i, '');

    if (type == 1)
      this.chart.above_goal_color = color;
    else
      this.chart.within_goal_color = color;
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "Fechar", {
      duration: 2000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  setExample() {
    this.example = new Chart({
      title: {
        text: this.getTitle(this.chart)
      },
      chart: {
        type: 'column'
      },
      xAxis: {
        categories: this.getCategories()
      },
      yAxis: {
        title: {
          text: 'Toneladas'
        }
      },
      plotOptions: {
        series: {
          allowPointSelect: true
        }
      },
      series: [{
        showInLegend: false,
        color: "#" + this.chart.within_goal_color,
        name: "Toneladas",
        type: undefined,
        data: this.getData()
      },
      {
        name: 'Meta',
        showInLegend: false,
        type: 'spline',
        color: "#" + this.chart.above_goal_color,
        data: this.getMeta(this.chart),
        marker: {
          enabled: false
        },
        dashStyle: "ShortDash",
        tooltip: {
          valueSuffix: 't'
        }
      }
      ]
    });
  }

  getCategories() {
    var categories = new Array();

    let max: number;
    if (this.chart.interval == Interval.SEMANA_ANTERIOR || this.chart.interval == Interval.SEMANA_ATUAL)
      max = 7;
    else if (this.chart.interval == Interval.DIA_ATUAL || this.chart.interval == Interval.DIA_ANTERIOR ||
      this.chart.interval == Interval.TURNO_ATUAL || this.chart.interval == Interval.TURNO_ANTERIOR)
      max = 24;
    else if (this.chart.interval == Interval.MEDIA_MOVEL)
      max = this.chart.movel;
    else max = 31

    for (let x = 1; x <= max; x++) {
      categories.push(x)
    }

    return categories;
  }

  getData() {
    var dailyTonnes: number[] = [200, 300, 400, 300, 500, 300, 500, 400, 500, 500, 200, 200, 500, 520, 500, 200, 110, 500, 400, 300, 500, 20, 500, 50, 500, 500, 400, 400, 500, 400, 300];

    let data = new Array()

    let max: number;
    if (this.chart.interval == Interval.SEMANA_ANTERIOR || this.chart.interval == Interval.SEMANA_ATUAL)
      max = 7;
    else if (this.chart.interval == Interval.DIA_ATUAL || this.chart.interval == Interval.DIA_ANTERIOR ||
      this.chart.interval == Interval.TURNO_ATUAL || this.chart.interval == Interval.TURNO_ANTERIOR)
      max = 24;
    else if (this.chart.interval == Interval.MEDIA_MOVEL)
      max = this.chart.movel;
    else max = 31


    for (let x = 1; x <= max; x++) {
      data.push(dailyTonnes[x - 1])
    }

    return data;
  }


  getMeta(chart: ChartDash) {
    let meta = chart.meta;

    var metas = new Array();

    let max: number;
    if (this.chart.interval == Interval.SEMANA_ANTERIOR || this.chart.interval == Interval.SEMANA_ATUAL)
      max = 7;
    else if (this.chart.interval == Interval.DIA_ATUAL || this.chart.interval == Interval.DIA_ANTERIOR ||
      this.chart.interval == Interval.TURNO_ATUAL || this.chart.interval == Interval.TURNO_ANTERIOR)
      max = 24;
    else if (this.chart.interval == Interval.MEDIA_MOVEL)
      max = this.chart.movel;
    else max = 31

    for (let x = 1; x <= max; x++) {
      metas.push(meta[x - 1])
    }

    return metas;
  }

  getTitle(chart: ChartDash): string {
    let title: string;
    let interval: string;
    let fleet: string;
    let unit: string = '';

    switch (chart.interval) {
      case Interval.TURNO_ATUAL: interval = 'Turno atual'; break;
      case Interval.DIA_ATUAL: interval = 'Dia atual'; break;
      case Interval.SEMANA_ATUAL: interval = 'Semana atual'; break;
      case Interval.MES_ATUAL: interval = 'Mês atual'; break;
      case Interval.ANO_ATUAL: interval = 'Ano atual'; break;
      case Interval.MEDIA_MOVEL:
        interval = 'Media Movel - ' + chart.movel + ' dias';
        break;
      case Interval.TURNO_ANTERIOR: interval = 'Turno anterior'; break;
      case Interval.DIA_ANTERIOR: interval = 'Dia anterior'; break;
      case Interval.SEMANA_ANTERIOR: interval = 'Semana anterior'; break;
      case Interval.MES_ANTERIOR: interval = 'Mês anterior'; break;
      case Interval.ANO_ANTERIOR: interval = 'Ano anterior'; break;
      case Interval.PERSONALIZADO:
        interval = chart.start_time.toLocaleString() + ' - ' + chart.end_time.toLocaleString();
        break;
    }

    if (Dashboard[chart.type] === Dashboard.PROD) {
      unit = ' - '
      switch (chart.unit) {
        case Unit.VIAGENS: unit += 'Viagens'; break;
        case Unit.KT: unit += 'Kt (métricas)'; break;
        case Unit.T: unit += 't (métricas)'; break;
        case Unit.KTON: unit += 'kton (curtas)'; break;
        case Unit.TON: unit += 'ton (curtas)'; break;
        case Unit.KG: unit += 'kg'; break;
      }
    }

    fleet = chart.fleet_name ? ' - Frota ' + chart.fleet_name : ''

    title = chart.use_title ? chart.default_title + fleet + ' - ' + interval + unit : chart.title;
    return title;
  }

  getFleets() {
    this.fleetsService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(fleets => {
      this.fleets = fleets;
    });
  }

  getEquipments() {
    let sub = this.equipmentService.getListBy(this.fleet.id).snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(equipments => {
      sub.unsubscribe();
      this.equipments = equipments;
    });
  }

  getMaterials() {
    this.materialService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(materials => {
      this.materials = materials;
    });
  }

  onFleetChange(name: string) {
    if(name === "all") {
      this.fleet = null
      return
    }
    this.fleet = this.getFleetBy(name);
    this.chart.fleet_id = this.fleet.id;
    this.getEquipments()
  }

  onEquipmentChange(name: string) {
    if(name === "all") {
      this.equipment = null
      return
    }
    this.equipment = this.getEquipmentBy(name);
    this.chart.equipment_id = this.equipment.id;
  }

  onMaterialChange(name: string) {
    if(name === "all") {
      this.material = null
      return
    }
    this.material = this.getMaterialBy(name);
    this.chart.material_id = this.material.id;
  }

  onIntervalChange(interval: number) {
    this.isPersonalized = interval == Interval.PERSONALIZADO
    this.isMovel = interval == Interval.MEDIA_MOVEL
    this.isHourly = interval == Interval.TURNO_ATUAL || interval == Interval.TURNO_ANTERIOR || interval == Interval.DIA_ATUAL || interval == Interval.DIA_ANTERIOR
    this.isWeekly = interval == Interval.SEMANA_ATUAL || interval == Interval.SEMANA_ANTERIOR
    this.isDaily = interval == Interval.MES_ATUAL || interval == Interval.MES_ANTERIOR
    this.isMonthly = interval == Interval.ANO_ATUAL || interval == Interval.ANO_ANTERIOR

    this.chart.interval = interval
  }

  onDateChange(dateStart, dateEnd) {

    var start = new Date(dateStart);
    var end = new Date(dateEnd);

    if (!isNaN(+start) && !isNaN(+end))
      console.log({ start, end });

  }

  getFleetBy(name): Fleet {
    return this.fleets.find((item) => item.name === name);
  }

  getEquipmentBy(name): Equipment {
    return this.equipments.find((item) => item.name === name);
  }

  getMaterialBy(name): Material {
    return this.materials.find((item) => item.name === name);
  }
}
