<h1 mat-dialog-title>{{data.record.status}}</h1>
<h1 mat-dialog-title>Início {{data.record.date_start.toDate() | date:'dd/MM/yyyy - HH:mm:ss'}},
  Fim {{data.record.date_end.toDate() | date:'dd/MM/yyyy - HH:mm:ss'}}
</h1>
<div mat-dialog-content>
  <agm-map (mapReady)="mapReady($event)" [latitude]="data.record.location.latitude"
    [longitude]="data.record.location.longitude" [zoom]="16" [fullscreenControl]='true' [mapTypeControl]='true'
    [mapTypeId]="'satellite'">

    <agm-marker [latitude]="data.record.location.latitude" [longitude]="data.record.location.longitude">
    </agm-marker>

    <agm-polygon [paths]="geocerca.points" *ngFor="let geocerca of data.geocercas" [fillColor]="'#' + geocerca.color"
      [strokeColor]="'#' + geocerca.color">
    </agm-polygon>

  </agm-map>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Fechar</button>
</div>