import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';

/* Routing */
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

/* Timepicker */
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';

/* Forms */
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ChartModule } from 'angular-highcharts';

import { AgmCoreModule } from '@agm/core';

import { ColorSketchModule } from 'ngx-color/sketch';
import { ColorGithubModule } from 'ngx-color/github'; // <color-github></color-github>


import { HttpClientModule } from '@angular/common/http';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { InlineSVGModule } from 'ng-inline-svg';

/* Date time picker */
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

/* Angular Material */
import { MatIconRegistry } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
// import { MatChipsModule } from '@angular/material/chips';
// import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
// import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { HomeComponent } from './home/home.component';
// import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatRippleModule, MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
// import { MatSliderModule } from '@angular/material/slider';
// import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';

// import { MatTooltipModule } from '@angular/material/tooltip';
// import { MatTreeModule } from '@angular/material/tree';

/* Components */
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';

/* Firebase */
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';

import { AuthService } from './auth/services/auth.service';
import { environment } from 'src/environments/environment';

import { EmailVerificationComponent } from './auth/send-email/send-email.component';
import { MainNavComponent } from './components/main-nav/main-nav.component';
import { EquipmentDetailsComponent } from './equipments/equipment-details/equipment-details.component';
import { EquipmentsListComponent } from './equipments/equipments-list/equipments-list.component';
import { CreateEquipmentComponent } from './equipments/create-equipment/create-equipment.component';
import { EquipmentService } from './equipments/equipment.service';
import { MaterialCreateComponent } from './materials/material-create/material-create.component';
import { CardCreateComponent } from './cards/card-create/card-create.component';
import { DriverCreateComponent } from './drivers/driver-create/driver-create.component';
import { DriverDetailsComponent } from './drivers/driver-details/driver-details.component';
import { DriversListComponent } from './drivers/drivers-list/drivers-list.component';
import { MaterialsListComponent } from './materials/materials-list/materials-list.component';
import { MaterialDetailComponent } from './materials/material-details/material-detail.component';
import { CardListComponent } from './cards/card-list/card-list.component';
import { CardDetailsComponent } from './cards/card-details/card-details.component';
import { FleetCreateComponent } from './fleets/fleet-create/fleet-create.component';
import { FleetDetailsComponent } from './fleets/fleet-details/fleet-details.component';
import { FleetsListComponent } from './fleets/fleets-list/fleets-list.component';
import { DriverUpdateComponent } from './drivers/driver-update/driver-update.component';
import { EquipmentUpdateComponent } from './equipments/equipment-update/equipment-update.component';
import { MaterialUpdateComponent } from './materials/material-update/material-update.component';
import { CardUpdateComponent } from './cards/card-update/card-update.component';
import { FleetUpdateComponent } from './fleets/fleet-update/fleet-update.component';
import { RecordsListComponent } from './records/records-list/records-list.component';
import { RecordsDialogComponent } from './records/records-dialog/records-dialog.component';
import { TripsListComponent} from './trips/trips-list/trips-list.component';
import { GeocercaCreateComponent } from './geocercas/geocerca-create/geocerca-create.component';
import { GeocercaListComponent } from './geocercas/geocerca-list/geocerca-list.component';
import { GeocercaDialogComponent } from './geocercas/geocerca-dialog/geocerca-dialog.component';
import { GeocercaAddManualComponent } from './geocercas/geocerca-add-manual/geocerca-add-manual.component';
import { CardIconComponent } from './cards/card-icon/card-icon.component';
import { CardDialogComponent } from './cards/card-dialog/card-dialog.component';
import { CardNameDialogComponent } from './cards/card-name-dialog/card-name-dialog.component';
import { FuelComponent } from './records/fuel/fuel.component';
import { ChecklistCreateComponent } from './checklist/checklist-create/checklist-create.component';
import { ChecklistItemComponent } from './checklist/checklist-item/checklist-item.component';
import { ChecklistUpdateComponent } from './checklist/checklist-update/checklist-update.component';
import { ChecklistListComponent } from './checklist/checklist-list/checklist-list.component';
import { ChecklistDialogComponent } from './checklist/checklist-dialog/checklist-dialog.component';
import { SpeedListComponent } from './speed/speed-list/speed-list.component';
import { SpeedingListComponent } from './speeding/speeding-list/speeding-list.component';
import { SpeedingDialogComponent } from './speeding/speeding-dialog/speeding-dialog.component';
import { TimelineComponent } from './map/timeline/timeline.component';
import { MapComponent } from './map/realtime/map.component';
import { TripDialogComponent } from './trips/trip-dialog/trip-dialog.component';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import { ChartListComponent } from './dashboard/chart-list/chart-list.component';
import { ChartDetailsComponent } from './dashboard/chart-details/chart-details.component';
import { ChartModelDialogComonent } from './dashboard/chart-model-dialog/chart-model-dialog.component';
import { ChartPizzaComponent } from './dashboard/chart-pizza/chart-pizza.component';
import { ShiftCreateComponent } from './shifts/shift-create/shift-create.component';
import { ShiftEditComponent } from './shifts/shift-edit/shift-edit.component';
import { ShiftDialogComponent } from './shifts/shift-dialog/shift-dialog.component';
import { ChartBarComponent } from './dashboard/chart-bar/chart-bar.component';
import { BoardComponent } from './dashboard/board/board.component';
import { GeneralComponent } from './settings/general/general.component';
import { ProfileComponent } from './auth/profile/profile.component';
import { ProfilesListComponent } from './profiles/profiles-list/profiles-list.component';
import { ProfileCreateComponent } from './profiles/profile-create/profile-create.component';
import { ProfileItemComponent } from './profiles/profile-item/profile-item.component';
import { UserListComponent } from './users/user-list/user-list.component';
import { UserCreateComponent } from './users/user-create/user-create.component';
import { UserItemComponent } from './users/user-item/user-item.component';
import { UserProfileComponent } from './users/user-profile/user-profile.component';
import { UserProfileItemComponent } from './users/user-profile-item/user-profile-item.component';
import { ContingencyFilterTripComponent } from './contingency/contingency-filter-trip/contingency-filter-trip.component';
import { ContingencyFilterRecordComponent } from './contingency/contingency-filter-record/contingency-filter-record.component';
import { FilterRecordItemComponent } from './contingency/filter-record-item/filter-record-item.component';
import { FilterTripItemComponent } from './contingency/filter-trip-item/filter-trip-item.component';
import { ContingencyRecordsComponent } from './contingency/contingency-records/contingency-records.component';
import { ContingencyTripsComponent } from './contingency/contingency-trips/contingency-trips.component';
import { ContingencyReasonsDialogComponent } from './contingency/contingency-reasons-dialog/contingency-reasons-dialog.component';
import { DispatchComponent } from './dispatch/dispatch/dispatch.component';
import { DispatchDialogComponent } from './dispatch/dispatch-dialog/dispatch-dialog.component';
import { MatTableExporterModule } from 'mat-table-exporter';
import { GeofenceHistoryComponent } from './geofence/geofence-history/geofence-history.component';
import { SettingsComponent } from './settings/settings/settings.component';
import { MaintenanceComponent } from './maintenance/maintenance/maintenance.component';
import { FleetDialogComponent } from './fleets/fleet-dialog/fleet-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    EmailVerificationComponent,
    MainNavComponent,
    EquipmentDetailsComponent,
    EquipmentsListComponent,
    CreateEquipmentComponent,
    MaterialCreateComponent,
    CardCreateComponent,
    DriverCreateComponent,
    DriverDetailsComponent,
    DriversListComponent,
    MaterialsListComponent,
    MaterialDetailComponent,
    CardListComponent,
    CardDetailsComponent,
    FleetCreateComponent,
    FleetDetailsComponent,
    FleetsListComponent,
    DriverUpdateComponent,
    EquipmentUpdateComponent,
    MaterialUpdateComponent,
    CardUpdateComponent,
    FleetUpdateComponent,
    RecordsListComponent,
    RecordsDialogComponent,
    ContingencyReasonsDialogComponent,
    TripsListComponent,
    TripDialogComponent,
    MapComponent,
    GeocercaCreateComponent,
    GeocercaListComponent,
    GeocercaDialogComponent,
    GeocercaAddManualComponent,
    CardIconComponent,
    CardDialogComponent,
    CardNameDialogComponent,
    FuelComponent,
    ChecklistCreateComponent,
    ChecklistItemComponent,
    ChecklistUpdateComponent,
    ChecklistListComponent,
    ChecklistDialogComponent,
    ChartModelDialogComonent,
    ShiftDialogComponent,
    SpeedListComponent,
    SpeedingListComponent,
    SpeedingDialogComponent,
    TimelineComponent,
    ChartListComponent,
    ChartDetailsComponent,
    ChartPizzaComponent,
    ShiftCreateComponent,
    ShiftEditComponent,
    ShiftDialogComponent,
    ChartBarComponent,
    BoardComponent,
    GeneralComponent,
    ProfileComponent,
    ProfilesListComponent,
    ProfileCreateComponent,
    ProfileItemComponent,
    UserListComponent,
    UserCreateComponent,
    UserItemComponent,
    UserProfileComponent,
    UserProfileItemComponent,
    ContingencyFilterTripComponent,
    ContingencyFilterRecordComponent,
    FilterRecordItemComponent,
    FilterTripItemComponent,
    ContingencyRecordsComponent,
    ContingencyTripsComponent,
    DispatchComponent,
    DispatchDialogComponent,
    GeofenceHistoryComponent,
    SettingsComponent,
    MaintenanceComponent,
    FleetDialogComponent
  ],
  imports: [
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsKey,
      libraries: ['places', 'drawing', 'geometry']
    }),
    FlexLayoutModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatGridListModule,
    MatInputModule,
    AppRoutingModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatCardModule,
    LayoutModule,
    MatSidenavModule,
    MatListModule,
    MatSnackBarModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatCheckboxModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatDatepickerModule,
    MatNativeDateModule,
    OwlDateTimeModule,
    MatTableModule,
    MatTabsModule,
    MatPaginatorModule,
    MatSortModule,
    MatDialogModule,
    OwlNativeDateTimeModule,
    HttpClientModule, AngularSvgIconModule.forRoot(), InlineSVGModule.forRoot(),
    ColorSketchModule,
    ColorGithubModule,
    AgmSnazzyInfoWindowModule,
    ChartModule,
    NgxMaterialTimepickerModule,
    MatTableExporterModule
  ],
  entryComponents: [TripDialogComponent, GeocercaDialogComponent, 
    CardDialogComponent, CardNameDialogComponent, ChecklistUpdateComponent, ChecklistDialogComponent,
    ChartModelDialogComonent, ShiftDialogComponent, SpeedingDialogComponent, RecordsDialogComponent, 
    ContingencyReasonsDialogComponent, DispatchDialogComponent, FleetDialogComponent],
  providers: [MatDatepickerModule, AuthService, EquipmentService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
