import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, Subject } from 'rxjs';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, DocumentReference, } from '@angular/fire/firestore';
import { User } from 'src/app/shared/models/user.interface';
import { CardsIcon } from 'src/app/cards/card.model';
import { ProfileService } from 'src/app/profiles/profile.service';
import { Profile } from 'src/app/profiles/profile.model';
import { Router } from '@angular/router';
import { Client } from '../client.model';
import { UserService } from 'src/app/users/user.service';
import { ContingencyItem } from 'src/app/contingency/contingency-item.model';
import { Contingency } from 'src/app/contingency/contingency.model';
import { ContingencyGeocercaItem } from 'src/app/contingency/contingency-geocerca-item.model';

@Injectable({ providedIn: 'root' })
export class AuthService {
  public user$: Observable<User>;

  myBool$: Observable<boolean>;
  private boolSubject: Subject<boolean>;

  user: User = null;
  client: string = "";

  constructor(
    public profileService: ProfileService,
    public userService: UserService,
    public afAuth: AngularFireAuth,
    private db: AngularFirestore,
    private afs: AngularFirestore) {

    this.boolSubject = new Subject<boolean>();
    this.myBool$ = this.boolSubject.asObservable();

    this.afAuth.onAuthStateChanged(user => {
      this.user = user;

      if (user) {
        console.log("AUTH CHANGE ================");
        console.log(user);
      }
    });
  }

  getClient(): AngularFirestoreDocument<Client> {
    this.client = JSON.parse(localStorage.getItem('documentData')).client_id;
    return this.db.collection('clients').doc(this.client);
  }

  setClient(client: Client) {
    this.db.collection('clients').doc(this.client).set(client);
  }

  myBool(newValue) {
    this.myBool$ = newValue;
    this.boolSubject.next(newValue);
  }

  async resetPassword(email: string): Promise<void> {
    try {
      return this.afAuth.sendPasswordResetEmail(email);
    } catch (error) {
      console.log(error);
    }
  }

  async sendVerificationEmail(): Promise<void> {
    return (await this.afAuth.currentUser).sendEmailVerification();
  }

  async login(email: string, password: string): Promise<boolean> {
    let response = false
    await this.afAuth.signInWithEmailAndPassword(
      email,
      password
    ).then(async res => {
      this.setLocalUser(res.user)
      await this.get().then((result) => {
        response = result
      })
    })

    return response
  }

  setLocalUser(user: User) {
    localStorage.setItem('user', JSON.stringify(user))
  }

  async updateUser(user: User, admin: boolean = false): Promise<void> {
    if (admin)
      this.setLocalUser(user)

    this.userService
      .update(user.uid, { displayName: user.displayName })

    return await this.afAuth.currentUser.then((result) => {
      result.updateProfile({
        displayName: user.displayName
      })
    });
  }

  async register(user: User, admin: boolean) {

    let name = user.displayName

    if (!admin) {
      user.client_id = JSON.parse(localStorage.getItem('documentData')).client_id
      user.profile = "user"
    } else {
      user.profile = "admin"
    }

    await this.afAuth
      .createUserWithEmailAndPassword(user.email, user.password)
      .then(async userCredential => {

        await userCredential.user.updateProfile({
          displayName: user.displayName
        }).then(async _ => {

          user.uid = userCredential.user.uid
          user.displayName = name
          user.email = userCredential.user.email
          user.enabled = true

          delete user.password
          delete user.emailVerified

          if (admin) {
            await this.setNewClient(user.client_name)
              .then(async doc => {
                user.client_id = doc.id
                this.afs.collection('users').doc(userCredential.user.uid).set(JSON.parse(JSON.stringify(user)), { merge: true })
                this.afs.collection('clients').doc('Exact Tecnologia').collection('contingency').doc('default_trip').set({ ...this.getContingencyTrip() })
                this.afs.collection('clients').doc('Exact Tecnologia').collection('contingency').doc('default_record').set({ ...this.getContingencyRecord() })
                this.setLocalUser(user)
              })
              .catch(error => {
                console.log(error);
                return error
              });
          } else {
            this.afs.collection('users').doc(userCredential.user.uid).set(JSON.parse(JSON.stringify(user)), { merge: true })
          }

        })
        return user;
      }).catch(error => {

        return error
      });

    if (admin)
      await this.get()

    await this.sendVerificationEmail();
  }

  async logout(): Promise<void> {
    try {
      this.myBool(false)
      localStorage.removeItem('user')
      localStorage.removeItem('profile')
      await this.afAuth.signOut();
    } catch (error) {
      console.log(error);
    }
  }

  get getUser(): User {
    return JSON.parse(localStorage.getItem('user')) as User
  }

  get getProfile(): Profile {
    return JSON.parse(localStorage.getItem('profile')) as Profile
  }

  get isAuthenticated(): boolean {
    return this.afAuth.user != null
  }

  get isEmailVerified(): boolean {
    return this.isAuthenticated ? this.user.emailVerified : false;
  }

  get currentUserId(): string {
    return this.isAuthenticated ? this.user.uid : null;
  }

  get userData(): any {
    if (!this.isAuthenticated) {
      return [];
    }

    return [
      {
        id: this.user.uid,
        displayName: this.user.displayName,
        email: this.user.email
      }
    ];
  }

  public setNewClient(name: string): Promise<DocumentReference> {
    const userRef: AngularFirestoreCollection<Client> = this.afs.collection(`clients`);

    const data: Client = {
      name: name,
      timeZone: 'America/Sao_Paulo',
      enableMaintenance: true,
      maintenanceManager: true,
      maintenanceOperator: false,
      dateBreak: 0,
      card1: { color: "ffffff", icon: 27, name: "Card A" },
      card2: { color: "ffffff", icon: 27, name: "Card B" },
      card3: { color: "ffffff", icon: 27, name: "Card C" },
      card4: { color: "ffffff", icon: 27, name: "Card D" },
      card5: { color: "ffffff", icon: 13, name: "Abastecer" },
      card6: { color: "ffffff", icon: 12, name: "Trocar turno" }
    };

    return userRef.add(data)
  }

  async get(): Promise<boolean> {

    if (!this.isAuthenticated) return null;

    return new Promise((resolve, reject) => {

      this.afs.collection("users").doc(this.currentUserId)
        .valueChanges()
        .subscribe(async (doc) => {

          const user = doc as User;

          if (user.enabled === false) {

            await this.afAuth.signOut();
            localStorage.removeItem('user');
            localStorage.removeItem('profile');
            indexedDB.deleteDatabase('firebaseLocalStorageDb');

            resolve(false)

          } else {
            var obj: {} = JSON.parse(localStorage.getItem("user"));
            obj['profile'] = user.profile;
            localStorage.setItem('user', JSON.stringify(obj));
            localStorage.setItem('documentData', JSON.stringify(user));

            this.profileService.getProfile(user.profile).valueChanges()
              .subscribe((profile) => {
                console.log(profile);
                localStorage.setItem('profile', JSON.stringify(profile));

                resolve(true)
                this.myBool(true)
              });
          }
        })
    });
  }

  public subscribe() {
    this.user$.subscribe((user) => {
    });
  }

  private getContingencyRecord(): Contingency {
    var contingency = new Contingency();
    contingency.filters = [];

    contingency.filters.push(new ContingencyItem('1', "Hora trabalhada", 20, 43200, true, true, true));
    contingency.filters.push(new ContingencyItem('2', "Hora parada", 5, 43200, true, true, true));
    contingency.filters.push(new ContingencyItem('3', "Manutenção", 120, 7776000, true, true, true));
    contingency.filters.push(new ContingencyItem('11', "Movimentando vazio", 10, 43200, true, true, true));
    contingency.filters.push(new ContingencyItem('12', "Carregando", 10, 43200, true, true, true));
    contingency.filters.push(new ContingencyItem('13', "Movimentando cheio", 60, 600, true, true, true));
    contingency.filters.push(new ContingencyItem('14', "Basculando", 20, 300, true, true, true));
    contingency.filters.push(new ContingencyItem('21', "Carregando caminhão", 60, 43200, true, true, true));
    contingency.filters.push(new ContingencyItem('22', "Aguardando caminhão", 5, 43200, true, true, true));
    contingency.filters.push(new ContingencyItem('checklist', "Checklist", 20, 900, true, true, true));
    contingency.filters.push(new ContingencyItem('fuel', "Abastecimento", 120, 1800, true, true, true));

    const contingencies = contingency.filters.map((obj) => { return Object.assign({}, obj); });
    contingency.filters = contingencies;

    return contingency;
  }

  private getContingencyTrip(): Contingency {
    var contingency = new Contingency()
    contingency.filters = [
      new ContingencyItem('duration', 'Duração (s)', 45, 2400, true, false),
      new ContingencyItem('dmt_full', 'DMT Full (m)', 200, 20000, true, true),
      new ContingencyItem('dmt_empty', 'DMT Vazio (m)', 200, 20000, true, false),
      new ContingencyItem('vmc', 'VMC (km/h)', 1, 60, true, false),
      new ContingencyItem('vmv', 'VMV (km/h)', 1, 60, true, false),
      new ContingencyItem('peak', 'Peak (km/h)', 1, 60, true, true),
    ]

    const contingencies = contingency.filters.map((obj) => { return Object.assign({}, obj) });
    contingency.filters = contingencies
    contingency.geocerca = Object.assign({}, new ContingencyGeocercaItem(true, true, true, false))
    return contingency
  }
}