<div class="list">
    <div class="wrapper">
        <div class="name">
            Usuários
        </div>
    </div>

    <div *ngFor="let user of users">
        <app-user-item [user]='user'></app-user-item>
    </div>

    <button mat-mini-fab color="primary" type="button" [routerLink]="['/user-create']">
        <mat-icon>add</mat-icon>
    </button>
</div>

<div *ngIf="disabled.length > 0" class="list">
    <div class="wrapper">
        <div class="name">
            Desativados
        </div>
    </div>

    <div *ngFor="let user of disabled">
        <app-user-item [user]='user'></app-user-item>
    </div>
</div>