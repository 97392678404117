import { Component, OnInit, ViewChild } from '@angular/core';
import { GeocercaService } from '../geocerca.service';
import { Geocerca } from '../geocerca.model';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBar } from '@angular/material/snack-bar';
import { NgForm, FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { AgmMap } from '@agm/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { ColorEvent } from 'ngx-color';

declare const google: any;

@Component({
  selector: 'app-geocerca-create',
  templateUrl: './geocerca-create.component.html',
  styleUrls: ['./geocerca-create.component.scss']
})
export class GeocercaCreateComponent implements OnInit {

  lat = 20.5937;
  lng = 78.9629;

  geocercas: Geocerca[];

  public show: boolean = false;
  public buttonName: any = 'Exibir pontos';

  pointList: { lat: number, lng: number }[] = [];
  polygonPts: {}[] = [];

  drawingManager: any;
  selectedShape: any;
  selectedArea = 0;

  @ViewChild(AgmMap) map: any;
  polygon: any;

  geocerca: Geocerca = new Geocerca();
  uid: string;

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  @ViewChild('myForm') private myForm: NgForm;

  private formSubmitAttempt: boolean;

  form = new FormGroup({
    name: new FormControl(''),
    color: new FormControl(''),
    load: new FormControl(''),
    unload: new FormControl('')
  });

  toggle() {
    this.show = !this.show;

    if (this.show)
      this.buttonName = "Ocultar pontos";
    else
      this.buttonName = "Exibir pontos";
  }

  constructor(
    private router: Router,
    private geocercaService: GeocercaService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,) {

    var classifyPoint = require("robust-point-in-polygon")
    var polygon = [
      [-19.87814633830776, -44.08016156574759],
      [-20.016272986033748, -43.98952435871634],
      [-19.89493452535461, -43.82060956379446],
      [-19.774795311829706, -43.94420575520071]]

    console.log(
      classifyPoint(polygon, [-19.9191667, -43.938655]),
      classifyPoint(polygon, [-19.9191667, -43.938655]),
      classifyPoint(polygon, [-19.9191667, -43.938655]),
      classifyPoint(polygon, [1, 2]),
      classifyPoint(polygon, [100000, 10000]))
  }

  ngOnInit() {
    this.setCurrentPosition();
    this.getGeocercas();

    this.form = this.fb.group({
      name: ['', Validators.required],
      color: ['', Validators.required],
      load: [''],
      unload: ['']
    });

    this.geocerca = JSON.parse(window.localStorage.getItem("editGeocercaObj"));
    if (this.geocerca) {
      this.uid = this.geocerca.id;
    } else {
      this.geocerca = new Geocerca();
    }

  }

  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitAttempt)
    );
  }

  onMapReady(map) {
    this.map = map;
    this.initDrawingManager(map);

  }

  initDrawingManager = (map: any) => {
    const self = this;

    var myCounty = new google.maps.Polygon({
      paths: this.geocerca.points,
      strokeColor: '#' + this.geocerca.color,
      fillColor: '#' + this.geocerca.color,
      map: map
    });
    myCounty.setMap(map);

    const options = {
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: ['polygon'],
      },
      polygonOptions: {
        draggable: true,
        editable: true,
      },
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
    };

    this.drawingManager = new google.maps.drawing.DrawingManager(options);
    this.drawingManager.setMap(map);

    google.maps.event.addListener(
      this.drawingManager,
      'overlaycomplete',
      (event) => {
        if (event.type === google.maps.drawing.OverlayType.POLYGON) {
          const paths = event.overlay.getPaths();
          console.log(paths.getArray());


          for (let p = 0; p < paths.getLength(); p++) {
            google.maps.event.addListener(
              paths.getAt(p),
              'set_at',
              () => {
                if (!event.overlay.drag) {
                  self.updatePointList(event.overlay.getPath());
                }
              }
            );

            google.maps.event.addListener(
              paths.getAt(p),
              'insert_at',
              () => {
                self.updatePointList(event.overlay.getPath());
              }
            );

            google.maps.event.addListener(
              paths.getAt(p),
              'remove_at',
              () => {
                self.updatePointList(event.overlay.getPath());
              }
            );
          }
          self.updatePointList(event.overlay.getPath());
        }

        if (event.type !== google.maps.drawing.OverlayType.MARKER) {
          // Switch back to non-drawing mode after drawing a shape.
          self.drawingManager.setDrawingMode(null);
          // To hide:
          self.drawingManager.setOptions({
            drawingControl: false,
          });

          // set selected shape object
          const newShape = event.overlay;
          newShape.type = event.type;
          this.setSelection(newShape);
        }
      }
    );
  }

  private setCurrentPosition() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
      });
    }
  }

  clearSelection() {
    if (this.selectedShape) {
      this.selectedShape.setEditable(false);
      this.selectedShape = null;
      this.pointList = [];
    }
  }

  setSelection(shape) {
    this.clearSelection();
    this.selectedShape = shape;
    shape.setEditable(true);
  }

  deleteSelectedShape() {
    if (this.selectedShape) {
      this.selectedShape.setMap(null);
      this.selectedArea = 0;
      this.pointList = [];

      this.drawingManager.setOptions({
        drawingControl: true,
      });
    }
  }

  updatePointList(path) {
    this.pointList = [];
    const len = path.getLength();
    for (let i = 0; i < len; i++) {
      this.pointList.push(
        path.getAt(i).toJSON()
      );
    }

    this.geocerca.points = this.pointList;

    this.selectedArea = google.maps.geometry.spherical.computeArea(
      path
    );
  }

  addPoint(lat: number, lng: number) {

    var coordinate = new google.maps.LatLng(lat, lng);

    this.pointList.push({ lat: coordinate.lat(), lng: coordinate.lng() });

    // if (this.polygon)
    //   // this.polygon.setMap(null);

    console.log(this.pointList);
    this.polygon = new google.maps.Polygon({
      paths: this.pointList,
      editable: true,
      draggable: true,
    });


    // Set polygon to map
    // this.polygon.setMap(this.map);

    // this.geocerca.points = this.polygonPts;

  }

  onSubmit() {
    if (this.form.valid) {

      if (this.uid != null) {
        this.inactivate();
      }

      this.openSnackBar("Geocerca inserida");
      this.save();
    } else this.openSnackBar("Preencha todos os campos");
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "Fechar", {
      duration: 2000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  save() {
    delete this.geocerca.id;
    this.geocercaService.create(this.geocerca);
    this.geocerca = new Geocerca();
    this.myForm.resetForm();
    this.deleteSelectedShape()
  }

  inactivate() {
    this.geocercaService.update(this.geocerca.id, { active: false })
      .catch(err => console.log(err));
  }


  ngOnDestroy() {
    window.localStorage.removeItem("editGeocercaObj");
  }

  private centerMap() {

    var boundsAll = new google.maps.LatLngBounds();

    this.geocercas.forEach(geocerca => {
      var bounds = new google.maps.LatLngBounds();

      geocerca.points.forEach(point => bounds.extend(point))

      boundsAll.extend(bounds.getCenter())
    })

    this.lat = boundsAll.getCenter().lat();
    this.lng = boundsAll.getCenter().lng();

    this.map.setCenter(boundsAll.getCenter());
    this.map.fitBounds(boundsAll);
  }

  getGeocercas() {
    this.geocercaService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(geocercas => {
      this.geocercas = geocercas;
      this.centerMap();
    });
  }

  displayColor: boolean = false;

  changeState() {
    this.displayColor = !this.displayColor;
  }

  changeComplete($event: ColorEvent) {
    this.displayColor = false;

    var color = $event.color.hex.replace(/#/i, '');

    this.geocerca.color = color;
  }

}
