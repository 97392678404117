<ng-template #loading>
  Loading....
</ng-template>


<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center" *ngIf="user$ | async as user; else loading">

  <mat-card class="box">

    <mat-card-header>
      <mat-card-title>Cadastro realizado</mat-card-title>
    </mat-card-header>

    <mat-card-content>
      <p class="text-center">
        Nós enviamos um email de confirmação para <strong>{{ user?.email }}.</strong>
      </p>
      <p class="text-center">
        Por favor verifique seu email e clique no link pra confirmar seu endereço de email.
      </p>
    </mat-card-content>

    <form (ngSubmit)="onSendEmail()" class="example-form">

      <button mat-raised-button color="primary" type="submit" class="btn-block">Reenviar email</button>
    </form>
  </mat-card>
</div>