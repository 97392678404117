<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="box">

    <mat-card-header>
      <mat-card-title>Adicionar equipamento</mat-card-title>
    </mat-card-header>

    <form [formGroup]="form" #myForm="ngForm" (ngSubmit)="onSubmit()" class="example-form">

      <mat-card-content>
        <mat-form-field class="example-full-width">
          <input matInput type="text" placeholder="TAG" [(ngModel)]="equipment.name" formControlName="name" required>
          <mat-error *ngIf="isFieldInvalid('name')">
            Informe a TAG do equipamento
          </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <mat-label>Frota</mat-label>
          <mat-select [(value)]="equipment.fleet" formControlName="fleet" [(ngModel)]="equipment.fleet" (selectionChange)="onFleetChange(equipment.fleet)" required>
            <mat-option *ngFor="let fleet of fleets" [value]="fleet.id">
              {{fleet.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="isFieldInvalid('fleet')">
            Informe a frota do equipamento
          </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <input matInput type="number" placeholder="Horímetro" [(ngModel)]="equipment.hourmeter" formControlName="hourmeter" required>
          <mat-error *ngIf="isFieldInvalid('hourmeter')">
            Horímetro
          </mat-error>
        </mat-form-field>

        <mat-form-field *ngIf="isTransport" class="example-full-width">
          <input matInput type="number" placeholder="Quilômetros" [(ngModel)]="equipment.kilometers" formControlName="kilometers">
          <mat-error *ngIf="isFieldInvalid('kilometers')">
            Quilometragem
          </mat-error>
        </mat-form-field>

      </mat-card-content>

      <button mat-raised-button color="primary" type="submit" class="btn-block">Adicionar</button>
    </form>
  </mat-card>
</div>