import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { mergeMapTo } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {

  title = 'Login';
  isAuthenticated: boolean;
  public version: string = environment.appVersion;

  constructor(private afMessaging: AngularFireMessaging) {

    navigator.serviceWorker.addEventListener('message', this.onReceiveMesssage.bind(this))

    this.requestPermission()
    this.requestToken()
  }

  onReceiveMesssage(event) {
    if (event.data != null) {
      var payload = event.data.notification
      window.alert(payload.title + " - " + payload.body)
    }
  }

  requestToken() {
    this.afMessaging.requestToken
      .subscribe(
        (token) => { localStorage.setItem('ntk', token); },
        (error) => { console.error(error); },
      );
  }

  requestPermission() {
    this.afMessaging.requestPermission
      .pipe(mergeMapTo(this.afMessaging.tokenChanges))
      .subscribe(
        (token) => { localStorage.setItem('ntk', token); },
        (error) => { console.error(error); },
      );
  }
}
