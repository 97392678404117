<mat-form-field class="example-fill-width">
  <mat-label>Filtro</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="" #input>
</mat-form-field>

<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Nome </th>
      <td mat-cell *matCellDef="let row"> {{row.name}} </td>
    </ng-container>

    <ng-container matColumnDef="color">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Cor </th>
      <td mat-cell *matCellDef="let row"> #{{row.color}} </td>
    </ng-container>

    <ng-container matColumnDef="load">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Hab. Carga </th>
      <td mat-cell *matCellDef="let row"> {{row.load ? 'Sim' : 'Não'}} </td>
    </ng-container>

    <ng-container matColumnDef="unload">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Hab. Descarga </th>
      <td mat-cell *matCellDef="let row"> {{row.unload ? 'Sim' : 'Não'}} </td>
    </ng-container>

    <ng-container matColumnDef="map">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Ações </th>
      <td mat-cell *matCellDef="let row">
        <button mat-icon-button (click)="edit(row)">
          <mat-icon>edit</mat-icon>
        </button>
        <button mat-icon-button (click)="delete(row)">
          <mat-icon>delete</mat-icon>
        </button>
        <button mat-icon-button (click)="openMap(row)">
          <mat-icon>map</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="8">No data matching the filter "{{input.value}}"</td>
    </tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>