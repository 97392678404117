<h1 mat-dialog-title>Data {{data.speeding.date.toDate() | date:'dd/MM/yyyy - HH:mm:ss'}}</h1>
<h1 mat-dialog-title>Velocidade: {{data.speeding.speed}} km/h</h1>
<div mat-dialog-content>
  <agm-map (mapReady)="mapReady($event)" [latitude]="data.speeding.location.latitude"
    [longitude]="data.speeding.location.longitude" [zoom]="16" [fullscreenControl]='true' [mapTypeControl]='true'
    [mapTypeId]="'satellite'">

    <agm-marker [latitude]="data.speeding.location.latitude" [longitude]="data.speeding.location.longitude">
    </agm-marker>

    <agm-polygon [paths]="geocerca.points" *ngFor="let geocerca of data.geocercas" [fillColor]="'#' + geocerca.color"
      [strokeColor]="'#' + geocerca.color">
    </agm-polygon>

  </agm-map>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Fechar</button>
</div>