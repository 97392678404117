import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Contingency } from './contingency.model';

@Injectable({
  providedIn: 'root'
})
export class ContingencyService {

  private dbPath = '/contingency';
 
  collection: AngularFirestoreCollection<Contingency> = null;
 
  constructor(private db: AngularFirestore) {
      var client = JSON.parse(localStorage.getItem('documentData')).client_id;
    this.collection = db.collection('clients').doc(client).collection(this.dbPath, ref => ref.where('active', '==', true));
  }
 
  create(type: string, contingency: Contingency): void {
    console.log(contingency);
    
    this.collection.doc(type).set({...contingency});
  }
 
  createRecord(contingency: Contingency): void {
    
    this.collection.doc(contingency.fleet).set({...contingency});
  }
 
  update(key: string, value: any): Promise<void> {
    return this.collection.doc(key).update(value);
  }
 
  delete(key: string): Promise<void> {
    return this.collection.doc(key).delete();
  }
 
  get(type: string): AngularFirestoreDocument<Contingency> {
    return this.collection.doc(type);
  }
 
  deleteAll() {
    this.collection.get().subscribe(
      querySnapshot => {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
        });
      },
      error => {
        console.log('Error: ', error);
      });
  }
}
