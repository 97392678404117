import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { Geocerca } from 'src/app/geocercas/geocerca.model';
import { GeocercaService } from 'src/app/geocercas/geocerca.service';
import { Map } from '../map.model';
import { MapService } from '../map.service';

export interface InfoWindowData {
  name: string;
  lat: number;
  lng: number;
  geocerca: Geocerca;
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

  map: any;
  lat: number;
  lng: number;
  equipment: string;

  infoWindowData: InfoWindowData = {
    name: undefined,
    lat: undefined,
    lng: undefined,
    geocerca: undefined
  }

  points: Map[];
  geocercas: Geocerca[];

  trackByUid = (index: number, obj: Map): string => {
    return obj.uid;
  };

  constructor(
    private geocercaService: GeocercaService,
    private mapService: MapService) { }

  mapReady(map) {
    this.map = map;
  }

  ngOnInit(): void {
    this.getList();
    this.getGeocercas();

    var today = new Date();
    var yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    this.selectedMoments = [yesterday, today];
  }

  public selectedMoments = [
    new Date(),
    new Date()
  ];

  private getList() {
    this.mapService.getList().valueChanges().subscribe(points => {
      this.points = points;
      console.log(points);
    })
  }

  public getIcon(fleetClass: number, status: number): string {
    var icon: string;
    if (fleetClass == 1)
      switch (status) {
        case 1: icon = "a1.svg";
          break;
        case 2: icon = "a2.svg";
          break;
        case 3: icon = "a3.svg";
          break;
        case 4: icon = "a4.svg";
          break;
      }
    else if (fleetClass == 2)
      switch (status) {
        case 1: icon = "b1.svg";
          break;
        case 2: icon = "b2.svg";
          break;
      }

    return './assets/images/map/' + icon;
  }

  public polyClick(geocerca: Geocerca, infoWindow) {
    this.lat = geocerca.points[0].lat
    this.lng = geocerca.points[0].lng

    this.infoWindowData.name = geocerca.name
    this.infoWindowData.lat = geocerca.points[0].lat
    this.infoWindowData.lng = geocerca.points[0].lng
    this.infoWindowData.geocerca = geocerca
    infoWindow.open();

    this.centerMapBy(geocerca)
  }

  private centerMap() {

    var boundsAll = new google.maps.LatLngBounds();

    this.geocercas.forEach(geocerca => {
      var bounds = new google.maps.LatLngBounds();

      geocerca.points.forEach(point => bounds.extend(point))

      boundsAll.extend(bounds.getCenter())
    })

    this.lat = boundsAll.getCenter().lat();
    this.lng = boundsAll.getCenter().lng();

    this.map.setCenter(boundsAll.getCenter());
    this.map.fitBounds(boundsAll);
  }


  private centerMapBy(geocerca: Geocerca) {

    var boundsAll = new google.maps.LatLngBounds();

    geocerca.points.forEach(point => boundsAll.extend(point))

    this.lat = boundsAll.getCenter().lat();
    this.lng = boundsAll.getCenter().lng();

    this.map.setCenter(boundsAll.getCenter());
    this.map.fitBounds(boundsAll);
  }


  getGeocercas() {
    this.geocercaService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(geocercas => {
      this.geocercas = geocercas;
      console.log(geocercas);

      this.centerMap();
    });
  }
}
