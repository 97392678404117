import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { GeofenceHistory } from './geofence-history';

@Injectable({
  providedIn: 'root'
})
export class GeofenceHistoryService {

  private dbPath = '/geofence-history';
  client = "";

  collection: AngularFirestoreCollection<GeofenceHistory> = null;

  constructor(public db: AngularFirestore) {
    this.client = JSON.parse(localStorage.getItem('documentData')).client_id;
    this.collection = db.collection('clients').doc(this.client).collection(this.dbPath, ref => ref.orderBy("date_start", "desc"));
  }

  getList(): AngularFirestoreCollection<GeofenceHistory> {
    return this.collection;
  }

  getListByTime(start: Date, end: Date, equipment: string, fleet: string): AngularFirestoreCollection<GeofenceHistory> {

    if (equipment == "all" && fleet == "all")
      return this.db.collection('clients').doc(this.client).collection(this.dbPath, ref => ref
        .where('date_start', '>=', start)
        .where('date_start', '<=', end)
        .orderBy("date_start", "desc")
      );

    else if (equipment == "all")
      return this.db.collection('clients').doc(this.client).collection(this.dbPath, ref => ref
        .orderBy("date_start", "desc")
        .where('date_start', '>=', start)
        .where('date_start', '<=', end)
        .where('fleet_id', '==', fleet)
      );

    else if (fleet == "all")
      return this.db.collection('clients').doc(this.client).collection(this.dbPath, ref => ref
        .orderBy("date_start", "desc")
        .where('date_start', '>=', start)
        .where('date_start', '<=', end)
        .where('equipment_id', '==', equipment)
      );

    else
      return this.db.collection('clients').doc(this.client).collection(this.dbPath, ref => ref
        .orderBy("date_start", "desc")
        .where('date_start', '>=', start)
        .where('date_start', '<=', end)
        .where('equipment_id', '==', equipment)
        .where('fleet_id', '==', fleet)
      );
  }
}
