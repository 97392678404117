import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { EquipmentService } from 'src/app/equipments/equipment.service';
import { map } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { Equipment } from 'src/app/equipments/equipment.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FleetService } from 'src/app/fleets/fleet.service';
import { Fleet } from 'src/app/fleets/fleet.model';
import { firestore } from 'firebase';

@Component({
  selector: 'app-speed-list',
  templateUrl: './speed-list.component.html',
  styleUrls: ['./speed-list.component.scss']
})
export class SpeedListComponent implements OnInit {

  equipment: string = "all";
  fleet: string = "all";

  equipments: Equipment[];
  equipmentsDrop: Equipment[];
  fleets: Fleet[];

  dataSource: MatTableDataSource<Equipment>;
  yesterday: Date;
  today: Date;

  public selectedMoments = [
    new Date(),
    new Date()
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('TABLE') table: ElementRef;

  displayedColumns: string[] = ['name', 'speed'];
  constructor(
    private equipmentService: EquipmentService,
    private fleetsService: FleetService
  ) { }

  ngOnInit(): void {
    this.getEquipments();
    this.getEquipmentsDrop();
    this.getFleets();

    this.setDate();
  }

  private setDate() {
    this.today = new Date();
    this.yesterday = new Date(this.today);
    this.yesterday.setDate(this.today.getDate() - 1);

    this.selectedMoments = [this.yesterday, this.today];
  }

  getEquipments() {
    let sub = this.equipmentService.getListBy(this.fleet).snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(equipments => {
      sub.unsubscribe();
      this.equipments = equipments;
      this.equipments.forEach(async equipment => {
        await this.getListTime(this.yesterday, this.today, equipment.id).subscribe(locations => {
          console.log(locations);
          var lastPoint: firestore.GeoPoint;
          var hours = 0;
          var distance = 0;
          var media = 0;

          if (locations.length > 0) {

            locations.forEach((location, index) => {
              if (index == 0) {
                lastPoint = location.position;
                // start = location.date.toDate();
              } else
                distance += this.calculateDistance(lastPoint, location.position);

              // if (index == locations.length - 1) {
              //   end = location.date.toDate();
              // }

              lastPoint = location.position
            })

            const milliseconds = Math.abs(this.today.getTime() - this.yesterday.getTime());
            hours += milliseconds / 36e5;

            if (hours > 0) media = distance / hours;
            media = parseFloat(media.toFixed(2));
          }
          equipment.speed = media;
        });
      })

      this.updateTable();
    });
  }

  calculateDistance(a: firestore.GeoPoint, b: firestore.GeoPoint) {
    var R = 6371; // Radius of the earth in kilometers
    var dLat = this.deg2rad(b.latitude - a.latitude); // deg2rad below
    var dLon = this.deg2rad(b.longitude - a.longitude);
    var aa =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(a.latitude)) * Math.cos(this.deg2rad(b.latitude)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(aa), Math.sqrt(1 - aa));
    var d = R * c; // Distance in KM
    return d;
  }

  deg2rad(deg) {
    return deg * (Math.PI / 180)
  }

  getEquipmentsDrop() {
    let sub = this.equipmentService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(equipments => {
      sub.unsubscribe();
      this.equipmentsDrop = equipments;
    });
  }

  getEquipment() {

    this.fleet = "all";

    if (this.equipment == 'all') {
      this.getEquipments();
      return;
    }

    this.equipmentService.getEquipment(this.equipment).ref.get()
      .then(async doc => {
        if (doc.exists) {
          const equipment = doc.data() as Equipment;
          console.log(equipment);

          await this.getListTime(this.yesterday, this.today, this.equipment).subscribe(locations => {
            console.log(locations);
            
            var lastPoint: firestore.GeoPoint;
            var hours = 0;
            var distance = 0;
            var media = 0;

            if (locations.length > 0) {

              locations.forEach((location, index) => {
                if (index == 0) {
                  lastPoint = location.position;
                  // start = location.date.toDate();
                } else
                  distance += this.calculateDistance(lastPoint, location.position);

                // if (index == locations.length - 1) {
                //   end = location.date.toDate();
                // }

                lastPoint = location.position
              })

              const milliseconds = Math.abs(this.today.getTime() - this.yesterday.getTime());
              hours += milliseconds / 36e5;

              if (hours > 0) media = distance / hours;
              media = parseFloat(media.toFixed(2));

            }

            equipment.speed = media;
          });

          this.equipments = [];
          this.equipments.push(equipment);
          this.updateTable();
        }
      })
  }

  onDateChange(dateStart, dateEnd) {

    this.yesterday = new Date(dateStart);
    this.today = new Date(dateEnd);

    if (!isNaN(+this.yesterday) && !isNaN(+this.today))
      this.getEquipments();
  }

  getListTime(start: Date, end: Date, uid: string) {
    return this.equipmentService.getListByTime(start, end, uid).snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    );
  }

  private updateTable() {
    this.dataSource = new MatTableDataSource(this.equipments);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getFleets() {
    let sub = this.fleetsService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(fleets => {
      sub.unsubscribe();
      this.fleets = fleets;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  clear() {
    this.equipment = "all";
    this.fleet = "all";
    this.setDate();
    this.getEquipments();
  }
}
