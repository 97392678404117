import { Component, OnInit, ViewChild } from '@angular/core';
import { Equipment } from '../equipment.model';
import { EquipmentService } from '../equipment.service';
import { FleetService } from 'src/app/fleets/fleet.service';
import { map } from 'rxjs/operators';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { FormGroup, FormControl, NgForm, Validators, FormBuilder } from '@angular/forms';
import { Fleet } from 'src/app/fleets/fleet.model';

@Component({
  selector: 'app-equipment-update',
  templateUrl: './equipment-update.component.html',
  styleUrls: ['./equipment-update.component.scss']
})
export class EquipmentUpdateComponent implements OnInit {

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  isTransport: boolean = false;
  fleet: Fleet

  fleets: Fleet[];
  equipment: Equipment = new Equipment();

  @ViewChild('myForm') private myForm: NgForm;

  private formSubmitAttempt: boolean;

  form = new FormGroup({
    name: new FormControl(''),
    fleet: new FormControl(''),
    hourmeter: new FormControl(''),
    kilometers: new FormControl('')
  });

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private snackBar: MatSnackBar,
    private equipmentService: EquipmentService,
    private fleetsService: FleetService) { }


  ngOnInit() {
    this.getFleets();

    this.form = this.fb.group({
      name: ['', Validators.required],
      fleet: ['', Validators.required],
      hourmeter: ['', Validators.required],
      kilometers: []
    });

    this.equipment = JSON.parse(window.localStorage.getItem("editEquipmentObj"));
    if (!this.equipment) {
      this.openSnackBar('Ação inválida.')
      this.router.navigate(['equipments']);
      return;
    }
  }

  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitAttempt)
    );
  }

  new(): void {
    this.equipment = new Equipment();
  }

  save() {
    delete this.equipment.id;
    this.equipment.fleetClass = this.fleet.fleetClass;
    this.equipment.fleetName = this.fleet.name;
    this.equipmentService.create(this.equipment);
    this.myForm.resetForm();
    this.equipment = new Equipment();
  }

  getFleetBy(id): Fleet {
    return this.fleets.find((item) => item.id === id);
  }

  inactivate() {
    this.equipmentService.update(this.equipment.id, { active: false })
      .catch(err => console.log(err));
  }

  onSubmit() {
    if (this.form.valid) {
      this.inactivate();
      this.save();
      this.openSnackBar('Equipamento atualizado')
      this.router.navigate(['equipments']);
    } else this.openSnackBar("Preencha todos os campos");
  }

  getFleets() {
    this.fleetsService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(fleets => {
      this.fleets = fleets;
      this.onFleetChange(this.equipment.fleet)
    });
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "Fechar", {
      duration: 2000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  ngOnDestroy() {
    window.localStorage.removeItem("editEquipmentObj");
  }

  onFleetChange(fleetId: string) {
    this.fleet = this.getFleetBy(fleetId)
    this.isTransport = this.fleet.fleetClass == 1;
  }

}
