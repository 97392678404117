import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Trip } from 'src/app/trips/trip.model';
import { DispatchDialogData } from '../dispatch/dispatch.component';
import { Message } from '../message.model';

@Component({
    selector: 'app-dispatch-dialog',
    templateUrl: './dispatch-dialog.component.html',
    styleUrls: ['./dispatch-dialog.component.scss']
})
export class DispatchDialogComponent {

    message: Message = new Message()

    constructor(
        public dialogRef: MatDialogRef<DispatchDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DispatchDialogData) {
        if (this.data.trip)
            this.setGeocerca()
        else data.trip = new Trip()

        console.log({data});
        

        this.data.equipments = this.data.equipments.filter(item => item.id !== data.equipment.loadId);

        this.message.token = data.equipment.token
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    classifyPoint = require("robust-point-in-polygon")

    setGeocerca() {
        console.log(this.data.geocercas);

        this.data.geocercas.forEach(geocerca => {

            const trip = this.data.trip

            var arr = Array();
            geocerca.points.forEach(point => {
                arr.push([point.lat, point.lng])
            })

            if (this.classifyPoint(arr, [trip.location_start.latitude, trip.location_start.longitude]) == -1)
                this.data.trip.geocerca_start = geocerca.name;


            if (trip.location_end)
                if (this.classifyPoint(arr, [trip.location_end.latitude, trip.location_end.longitude]) == -1)
                    this.data.trip.geocerca_end = geocerca.name;
        });
    }

    closeDialog() {
        console.log(this.message);
        
        if (this.message.hasActions && this.message.message === "") {
            window.alert("Informe a mensagem")
        } else {
            this.dialogRef.close(this.message)
        }
    }
}