<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
    <mat-card class="box">
  
      <mat-card-header>
        <mat-card-title>Editar perfil</mat-card-title>
      </mat-card-header>
  
      <form [formGroup]="form" #myForm="ngForm" (ngSubmit)="onSubmit()" class="example-form">
  
        <mat-card-content>
          <mat-form-field class="example-full-width">
            <input matInput type="text" placeholder="Nome" [(ngModel)]="user.displayName" formControlName="name" required>
            <mat-error *ngIf="isFieldInvalid('name')">
              Informe o nome
            </mat-error>
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <input readonly matInput type="text" placeholder="Email" [ngModel]="user.email" formControlName="email">
          </mat-form-field>
  
        </mat-card-content>
  
        <button mat-raised-button type="button" class="btn-block" (click)="resetPassword()">Resetar senha</button>
        <button mat-raised-button color="primary" type="submit" class="btn-block">Atualizar</button>
      </form>
    </mat-card>
  </div>