<div class="fleet-details" *ngIf="fleet">
  <div class="fleet-info">
    <label>TAG: </label> {{fleet.name}}
  </div>
  <div class="fleet-info">
    <label>Limite de velocidade: </label> {{fleet.speedLimit}}
  </div>
  <!-- <div>
      <label>Age: </label> {{customer.id}}
    </div> -->
  <div class="fleet-info-class">
    <label>Classe: </label> {{fleet.fleetClass}}
  </div>
  <div class="button-container">
    <button mat-raised-button color="primary" (click)='edit()'>Editar</button>
    <button mat-raised-button color="warn" (click)='inactivate()'>Apagar</button>
  </div>
  <hr />
</div>