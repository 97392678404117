import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Component, Input } from '@angular/core';

import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition
} from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})

export class LoginComponent {

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  isLoading = false;

  private formSubmitAttempt: boolean;
  loginForm = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
  });

  constructor(
    private fb: FormBuilder,
    private authSvc: AuthService,
    private router: Router,
    private snackBar: MatSnackBar) { }

  ngOnInit() {

    if(this.authSvc.getUser) this.router.navigate(['/home']);

    this.loginForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  isFieldInvalid(field: string) {
    return (
      (!this.loginForm.get(field).valid && this.loginForm.get(field).touched) ||
      (this.loginForm.get(field).untouched && this.formSubmitAttempt)
    );
  }

  @Input() error: string | null;

  async onLogin() {
    this.isLoading = true;
    const { email, password } = this.loginForm.value;
    try {
      await this.authSvc.login(email, password).then((result) => {
        this.isLoading = false;

        if (result) {
          this.checkUserIsVerified();
        } else {
          this.openSnackBar('Acesso não autorizado')
          this.authSvc.logout();
        }
      })
    } catch (error) {

      if (error.code) {
        this.openSnackBar('Usuario ou senha incorretos')
      } else {
        this.openSnackBar('Usuario ou69888888888 incorretos')
      }
      this.isLoading = false;
      console.log(error);
    }
  }

  private checkUserIsVerified() {
    if (this.authSvc.isEmailVerified) {
      this.router.navigate(['/home']);
    } else if (this.authSvc.isAuthenticated) {
      this.router.navigate(['/verification']);
    } else {
      this.router.navigate(['/register']);
    }
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "Fechar", {
      duration: 2000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }
}