<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="box">

    <mat-card-header>
      <mat-card-title>{{title}}</mat-card-title>
    </mat-card-header>

    <form [formGroup]="form" #myForm="ngForm" (ngSubmit)="onSubmit()" class="example-form">

      <mat-card-content>
        <mat-form-field class="example-full-width">
          <input matInput type="text" placeholder="Nome" [(ngModel)]="profile.name" formControlName="name" required>
          <mat-error *ngIf="isFieldInvalid('name')">
            Informe o nome do perfil
          </mat-error>
        </mat-form-field>

        <div class="row">
          <div class="column">
            <mat-label class="mat-card-content">Configurações</mat-label>
            <span class="example-list-section">
              <li *ngFor="let config of profile.configs">
                <mat-checkbox type="checkbox" [checked]="config.value"
                  (change)="onConfigsChange(config, $event.checked)">
                  {{config.name}}
                </mat-checkbox>
              </li>
            </span>
          </div>

          <div class="column">
            <mat-label class="mat-card-content">Relatórios</mat-label>
            <span class="example-list-section">
              <li *ngFor="let report of profile.reports">
                <mat-checkbox type="checkbox" [checked]="report.value"
                  (change)="onReportsChange(report, $event.checked)">
                  {{report.name}}
                </mat-checkbox>
              </li>
            </span>
          </div>

          <div class="column">
            <mat-label class="mat-card-content">Edição de Relatórios</mat-label>
            <span class="example-list-section">
              <li *ngFor="let reportEdit of profile.reportsEdit">
                <mat-checkbox type="checkbox" [checked]="reportEdit.value"
                  (change)="onReportsEditChange(reportEdit, $event.checked)">
                  {{reportEdit.name}}
                </mat-checkbox>
              </li>
            </span>
          </div>
        </div>

      </mat-card-content>

      <button mat-raised-button color="primary" type="submit" class="btn-block">{{button}}</button>
    </form>
  </mat-card>
</div>