<div *ngIf="chart" class="login-wrapper" fxLayout="row">
  <mat-card class="box" *ngIf="!isAdd">
    <mat-card-header class="center">
      <mat-card-title>{{getTitle()}}</mat-card-title>
    </mat-card-header>

    <button mat-mini-fab type="button" (click)="edit()">
      <mat-icon>edit</mat-icon>
    </button>

    <button mat-mini-fab type="button" color="warn" (click)="inactivate()">
      <mat-icon>delete</mat-icon>
    </button>

  </mat-card>

  <mat-card class="box-click" [matMenuTriggerFor]="menu" #menuTrigger *ngIf="isAdd">
    <mat-card-header>
      <mat-menu class="mat-menu" #menu="matMenu">
        <button mat-menu-item (click)="openDialog(1)">Pizza</button>
        <button mat-menu-item (click)="openDialog(2)">Barras</button>
        <button mat-menu-item (click)="openDialog(3)">Quadro</button>
      </mat-menu>
      <mat-card-title class="center">{{getTitle()}} </mat-card-title>
      <mat-card-title class="plus">+ </mat-card-title>
    </mat-card-header>
  </mat-card>
</div>