import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChecklistDialogData } from '../checklist-create/checklist-create.component';
import { Checklist } from '../checklist.model';
import { ChecklistService } from '../checklist.service';

@Component({
  selector: 'app-checklist-update',
  templateUrl: './checklist-update.component.html',
  styleUrls: ['./checklist-update.component.scss']
})
export class ChecklistUpdateComponent  {

  checklist: Checklist = new Checklist();

  constructor(
    private chechlistService: ChecklistService,
    public dialogRef: MatDialogRef<ChecklistUpdateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ChecklistDialogData) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  save() {    
    this.chechlistService.create(this.checklist);
    this.checklist = new Checklist();
    this.dialogRef.close();
  }

}
