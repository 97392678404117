import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { Fuel } from './fuel.model';

@Injectable({
  providedIn: 'root'
})
export class FuelService {

  private dbPath = '/fuel';
  client = "";

  collection: AngularFirestoreCollection<Fuel> = null;

  constructor(public db: AngularFirestore) {
    this.client = JSON.parse(localStorage.getItem('documentData')).client_id;
    this.collection = db.collection('clients').doc(this.client).collection(this.dbPath, ref => ref.orderBy("date_start", "desc"));
  }

  update(key: string, value: any): Promise<void> {
    return this.collection.doc(key).update(value);
  }

  delete(key: string): Promise<void> {
    return this.collection.doc(key).delete();
  }

  getList(): AngularFirestoreCollection<Fuel> {
    return this.collection;
  }

  getListByTime(start: Date, end: Date, equipment: string, fleet: string): AngularFirestoreCollection<Fuel> {
    console.log(equipment);
    console.log(fleet);


    if (equipment == "all" && fleet == "all")
      return this.db.collection('clients').doc(this.client).collection(this.dbPath, ref => ref
        .where('date_start', '>=', start)
        .where('date_start', '<=', end)
        .orderBy("date_start", "desc")
      );

    else if (equipment == "all")
      return this.db.collection('clients').doc(this.client).collection(this.dbPath, ref => ref
        .orderBy("date_start", "desc")
        .where('date_start', '>=', start)
        .where('date_start', '<=', end)
        .where('fleet_id', '==', fleet)
      );

    else if (fleet == "all")
      return this.db.collection('clients').doc(this.client).collection(this.dbPath, ref => ref
        .orderBy("date_start", "desc")
        .where('date_start', '>=', start)
        .where('date_start', '<=', end)
        .where('equipment_id', '==', equipment)
      );

    else
      return this.db.collection('clients').doc(this.client).collection(this.dbPath, ref => ref
        .orderBy("date_start", "desc")
        .where('date_start', '>=', start)
        .where('date_start', '<=', end)
        .where('equipment_id', '==', equipment)
        .where('fleet_id', '==', fleet)
      );
  }

  deleteAll() {
    this.collection.get().subscribe(
      querySnapshot => {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
        });
      },
      error => {
        console.log('Error: ', error);
      });
  }
}
