import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { Fleet } from '../fleet.model';
import { FleetService } from '../fleet.service';
import { MatSnackBarVerticalPosition, MatSnackBarHorizontalPosition, MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormControl, FormBuilder, Validators, NgForm } from '@angular/forms';
import { FleetDialogComponent } from '../fleet-dialog/fleet-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ColorEvent } from 'ngx-color';
import { HttpClient } from '@angular/common/http';
import { Data } from '@angular/router';
import { SVG, Svg } from '@svgdotjs/svg.js';


interface FleetClass {
  value: number;
  viewValue: string;
}

@Component({
  selector: 'app-fleet-create',
  templateUrl: './fleet-create.component.html',
  styleUrls: ['./fleet-create.component.scss']
})

export class FleetCreateComponent implements OnInit, AfterViewInit {

  fleet: Fleet = new Fleet();
  submitted = false;
  isTransport: boolean = false;
  isInvalidLoad: boolean = false;
  
  iconPath: string = "assets/images/fleets-icon/truck_01a.svg#groupImage";

  @ViewChild('myForm') private myForm: NgForm;
  @ViewChild('selectedIcon') selectedIcon!: ElementRef; 
  @ViewChildren('smallIcons') smallIcons!: QueryList<ElementRef>;

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  private formSubmitAttempt: boolean;
  form = new FormGroup({
    name: new FormControl(''),
    speedLimit: new FormControl(''),
    fleetClass: new FormControl(''),
    load: new FormControl(''),
  });

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private fleetService: FleetService,
    private dialog: MatDialog,
    private http: HttpClient) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: ['', Validators.required],
      speedLimit: ['', Validators.required],
      fleetClass: ['', Validators.required],
      load: [],
    });
  }

  ngAfterViewInit(): void {
    this.loadConfigSvgs();
    this.loadSvg(this.iconPath, this.selectedIcon.nativeElement);   
  }

  
  //abrir dialog de acordo com a classe da frota
  openDialog(): void {
    const fleetClassValue = this.form.get('fleetClass').value;
    let images = [];

    switch(fleetClassValue) {
      case 1:
        images = [
          'truck_01a.svg', 
          'truck_01b.svg', 
          'truck_02a.svg', 
          'truck_02b.svg'
        ];
        break;
      case 2:
        images = [
          'loader_01a.svg', 
          'loader_01b.svg', 
          'excavator_01a.svg', 
          'excavator_01b.svg'
        ];
        break;
      case 3:
        images = [
          'crane_01a.svg', 
          'dozer_01a.svg', 
          'dozer_01b.svg', 
          'drill_01a.svg',
          'drill_01b.svg', 
          'grader_01a.svg', 
          'grader_01b.svg', 
          'watertruck_01a.svg',
          'watertruck_01b.svg'
        ];
        break;
      default:
        images = [];
    }
    if (images.length > 0) {
      let dialogRef = this.dialog.open(FleetDialogComponent, {
        disableClose: true,
        width: '900px',
        height: '600px',
        data: { images }
      });
  
      dialogRef.afterClosed().subscribe(result => {
        this.fleet.fleetIcon = result;
        this.iconPath = `./assets/images/fleets-icon/${result}#groupImage`;
        this.loadSvg(this.iconPath, this.selectedIcon.nativeElement);
        this.loadConfigSvgs();
        this.openSnackBar("Ícone da frota inserido");
      });
    }
  }

  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitAttempt)
    );
  }

  fleets: FleetClass[] = [
    { value: 1, viewValue: 'Transporte' },
    { value: 2, viewValue: 'Carregamento' },
    { value: 3, viewValue: 'Outros' }
  ];

  new(): void {
    this.submitted = false;
    this.fleet = new Fleet();
  }

  save() {
    this.fleetService.create(this.fleet);
    this.myForm.resetForm();
    this.isTransport = false;
    this.fleet = new Fleet();
  }

  onSubmit() {
    if (this.form.valid) {
      if (this.isTransport) {
        
        if (!this.form.get('load').value){
          this.isInvalidLoad = true;
          this.openSnackBar("Preencha todos os campos");
          return;
        }

      } else delete this.fleet.load
      this.openSnackBar("Frota inserida");
      this.save();
    } else this.openSnackBar("Preencha todos os campos");
  }

  onFleetChange(fleetClass: number) {
    this.isTransport = fleetClass == 1;
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "Fechar", {
      duration: 2000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  displayColor: boolean = false;
  colorConfigIndex: number | null = null;

  translations: { [key: string]: string } = {
    'Operating': 'Operando',
    'Operational stops': 'Paradas operacionais',
    'Offline for more than 1 hour': 'Offline por mais de 1 hora',
    'Offline for more than 12 hours': 'Offline por mais de 12 horas',
    'Out of fleet': 'Fora de frota',
    'Maintenance up to 12 hours': 'Manutenção até 12 horas',
    'Maintenance for more than 12 hours': 'Manutenção por mais de 12 horas'
  }

  changeState() {
    this.displayColor = !this.displayColor;
  }

  changeConfigState(index: number) {
    if (this.colorConfigIndex === index) {
      this.colorConfigIndex = null;
    } else {
      this.colorConfigIndex = index;
    }
    console.log(index);
  }

  changeButtonColor($event: ColorEvent, index: number) {
    var color = $event.color.hex;
    this.fleet.configs[index].color = color;
    const colorElements = this.smallIcons.toArray()[index].nativeElement.querySelectorAll('.changeableColor');
    

    colorElements.forEach((element: SVGPathElement) => {
      element.setAttribute('fill', color);
    });
  }

  loadSvg(svgPath: string, container: HTMLElement) {
    fetch(svgPath).then((response) => response.text())
    .then((svgContent) => {
      const parser = new DOMParser();
      const doc = parser.parseFromString(svgContent, 'image/svg+xml');
      const innerSvg = doc.querySelector('svg');

      if (innerSvg) {
        const groupElement = innerSvg.querySelector('#groupImage');
    
        if (groupElement) {
          groupElement.removeAttribute('clip-path'); // remove o clip path para renderizar o svg corretamente
        }

        innerSvg.style.width = '80%';
        innerSvg.style.height = '80%';
    
        container.innerHTML = '';
        container.appendChild(innerSvg);

        innerSvg.setAttribute('preserveAspectRatio', 'xMidYMid meet');
      }
    })
  }

  loadConfigSvgs() {
    this.smallIcons.forEach((container, index) => {
      this.loadSvg(this.iconPath, container.nativeElement);
    })
  }
}
