<body>
    <div class="mat-elevation-z8" style="margin-bottom: 30px;" #TABLE>
        <table mat-table [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="load">
                <th mat-header-cell *matHeaderCellDef> Carga </th>
                <td mat-cell *matCellDef="let row">
                    <div class="td-center">
                        <div class="equipment" *ngFor="let equipment of row.load">
                            <div (click)="onEquipmentClick(equipment)" style="cursor: pointer;">
                                <svg-icon fxLayoutAlign="center center" src="{{getIcon(equipment)}}" [stretch]="false">
                                </svg-icon>
                                <div class="fill-width">{{equipment.name}}</div>
                            </div>
                        </div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="loadQueue">
                <th mat-header-cell *matHeaderCellDef> Fila Carga </th>
                <td mat-cell *matCellDef="let row">
                    <div class="td-center">
                        <div class="equipment" *ngFor="let equipment of row.loadQueue">
                            <div (click)="onEquipmentClick(equipment)" style="cursor: pointer;">
                                <svg-icon fxLayoutAlign="center center" src="{{getIcon(equipment)}}" [stretch]="false">
                                </svg-icon>
                                <div class="fill-width">{{equipment.name}}</div>
                            </div>
                        </div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="empty">
                <th mat-header-cell *matHeaderCellDef> Vazio </th>
                <td mat-cell *matCellDef="let row">
                    <div class="td-center">
                        <div class="equipment" *ngFor="let equipment of row.empty">
                            <div (click)="onEquipmentClick(equipment)" style="cursor: pointer;">
                                <svg-icon fxLayoutAlign="center center" src="{{getIcon(equipment)}}" [stretch]="false">
                                </svg-icon>
                                <div class="fill-width">{{equipment.name}}</div>
                            </div>
                        </div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="full">
                <th mat-header-cell *matHeaderCellDef> Carregado </th>
                <td mat-cell *matCellDef="let row">
                    <div class="td-center">
                        <div class="equipment" *ngFor="let equipment of row.full">
                            <div (click)="onEquipmentClick(equipment)" style="cursor: pointer;">
                                <svg-icon fxLayoutAlign="center center" src="{{getIcon(equipment)}}" [stretch]="false">
                                </svg-icon>
                                <div class="fill-width">{{equipment.name}}</div>
                            </div>
                        </div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="unloadQueue">
                <th mat-header-cell *matHeaderCellDef> Fila Descarga </th>
                <td mat-cell *matCellDef="let row">
                    <div class="td-center">
                        <div class="equipment" *ngFor="let equipment of row.unloadQueue">
                            <div (click)="onEquipmentClick(equipment)" style="cursor: pointer;">
                                <svg-icon fxLayoutAlign="center center" src="{{getIcon(equipment)}}" [stretch]="false">
                                </svg-icon>
                                <div class="fill-width">{{equipment.name}}</div>
                            </div>
                        </div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="unload">
                <th mat-header-cell *matHeaderCellDef> Descarga </th>
                <td mat-cell *matCellDef="let row">
                    <div class="td-center">
                        <div class="equipment" *ngFor="let equipment of row.unload">
                            <div (click)="onEquipmentClick(equipment)" style="cursor: pointer;">
                                <svg-icon fxLayoutAlign="center center" src="{{getIcon(equipment)}}" [stretch]="false">
                                </svg-icon>
                                <div class="fill-width">{{equipment.name}}</div>
                            </div>
                        </div>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>

    <table>
        <th class='col-6'>
            <div class="mat-elevation-z8" style="margin-bottom: 30px; height: 105px;">
                <div mat-header-cell> Manutenção </div>
                <div class="child" *ngFor="let equipment of maintence">
                    <div (click)="onEquipmentClick(equipment)" style="cursor: pointer;">
                        <svg-icon fxLayoutAlign="center center" src="{{getIcon(equipment)}}" [stretch]="false">
                        </svg-icon>
                        <div class="fill-width">{{equipment.name}}</div>
                    </div>
                </div>
            </div>
        </th>

        <th class='col-6'>
            <div class="mat-elevation-z8" style="margin-bottom: 30px; height: 105px; margin-left: 20px;">
                <div mat-header-cell> Atraso / Impedimento </div>
                <div class="child" *ngFor="let equipment of stopped">
                    <div (click)="onEquipmentClick(equipment)" style="cursor: pointer;">
                        <svg-icon fxLayoutAlign="center center" src="{{getIcon(equipment)}}" [stretch]="false">
                        </svg-icon>
                        <div class="fill-width">{{equipment.name}} ({{equipment.loadName}})</div>
                    </div>
                </div>
            </div>
        </th>
    </table>

    <table>
        <th class='col-12'>
            <div class="mat-elevation-z8" style="margin-bottom: 50px;">
                <div mat-header-cell> Sem operador </div>
                <div class="row">
                    <div class="child" *ngFor="let equipment of withoutDriver">
                        <div (click)="onEquipmentClick(equipment)" style="cursor: pointer;">
                            <svg-icon fxLayoutAlign="center center" src="{{getIcon(equipment)}}" [stretch]="false">
                            </svg-icon>
                            <div class="fill-width">{{equipment.name}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </th>
    </table>
</body>