import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { EmailVerificationComponent } from './auth/send-email/send-email.component';
import { ProfileComponent } from './auth/profile/profile.component';
import { EquipmentsListComponent } from './equipments/equipments-list/equipments-list.component';
import { CreateEquipmentComponent } from './equipments/create-equipment/create-equipment.component';
import { MaterialCreateComponent } from './materials/material-create/material-create.component';
import { CardCreateComponent } from './cards/card-create/card-create.component';
import { DriverCreateComponent } from './drivers/driver-create/driver-create.component';
import { DriversListComponent } from './drivers/drivers-list/drivers-list.component';
import { MaterialsListComponent } from './materials/materials-list/materials-list.component';
import { CardListComponent } from './cards/card-list/card-list.component';
import { FleetsListComponent } from './fleets/fleets-list/fleets-list.component';
import { FleetCreateComponent } from './fleets/fleet-create/fleet-create.component';
import { DriverUpdateComponent } from './drivers/driver-update/driver-update.component';
import { EquipmentUpdateComponent } from './equipments/equipment-update/equipment-update.component';
import { MaterialUpdateComponent } from './materials/material-update/material-update.component';
import { CardUpdateComponent } from './cards/card-update/card-update.component';
import { FleetUpdateComponent } from './fleets/fleet-update/fleet-update.component';
import { RecordsListComponent } from './records/records-list/records-list.component';
import { TripsListComponent } from './trips/trips-list/trips-list.component';
import { GeocercaCreateComponent } from './geocercas/geocerca-create/geocerca-create.component';
import { GeocercaListComponent } from './geocercas/geocerca-list/geocerca-list.component';
import { GeocercaAddManualComponent } from './geocercas/geocerca-add-manual/geocerca-add-manual.component';
import { CardIconComponent } from './cards/card-icon/card-icon.component';
import { FuelComponent } from './records/fuel/fuel.component';
import { ChecklistCreateComponent } from './checklist/checklist-create/checklist-create.component';
import { ChecklistListComponent } from './checklist/checklist-list/checklist-list.component';
import { SpeedListComponent } from './speed/speed-list/speed-list.component';
import { SpeedingListComponent } from './speeding/speeding-list/speeding-list.component';
import { TimelineComponent } from './map/timeline/timeline.component';
import { MapComponent } from './map/realtime/map.component';
import { ChartListComponent } from './dashboard/chart-list/chart-list.component';
import { ChartPizzaComponent } from './dashboard/chart-pizza/chart-pizza.component';
import { ShiftCreateComponent } from './shifts/shift-create/shift-create.component';
import { ShiftEditComponent } from './shifts/shift-edit/shift-edit.component';
import { ChartBarComponent } from './dashboard/chart-bar/chart-bar.component';
import { BoardComponent } from './dashboard/board/board.component';
import { GeneralComponent } from './settings/general/general.component';
import { AuthGuard } from './auth/guards/auth.guard';
import { ProfilesListComponent } from './profiles/profiles-list/profiles-list.component';
import { ProfileCreateComponent } from './profiles/profile-create/profile-create.component';
import { UserCreateComponent } from './users/user-create/user-create.component';
import { UserListComponent } from './users/user-list/user-list.component';
import { UserProfileComponent } from './users/user-profile/user-profile.component';
import { RoleGuard } from './auth/guards/role.guard';
import { ContingencyFilterTripComponent } from './contingency/contingency-filter-trip/contingency-filter-trip.component';
import { ContingencyFilterRecordComponent } from './contingency/contingency-filter-record/contingency-filter-record.component';
import { ContingencyRecordsComponent } from './contingency/contingency-records/contingency-records.component';
import { ContingencyTripsComponent } from './contingency/contingency-trips/contingency-trips.component';
import { DispatchComponent } from './dispatch/dispatch/dispatch.component';
import { GeofenceHistoryComponent } from './geofence/geofence-history/geofence-history.component';
import { MaintenanceComponent } from './maintenance/maintenance/maintenance.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'verification', component: EmailVerificationComponent },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
  { path: 'equipments', component: EquipmentsListComponent, canActivate: [AuthGuard, RoleGuard], data: { role: ['equipment'], type: ['configs'] } },
  { path: 'equipment-add', component: CreateEquipmentComponent, canActivate: [AuthGuard, RoleGuard], data: { role: ['equipment'], type: ['configs'] } },
  { path: 'equipment-update', component: EquipmentUpdateComponent, canActivate: [AuthGuard, RoleGuard], data: { role: ['equipment'], type: ['configs'] } },
  { path: 'checklist', component: ChecklistCreateComponent, canActivate: [AuthGuard, RoleGuard], data: { role: ['checklist'], type: ['configs'] } },
  { path: 'checklists', component: ChecklistListComponent, canActivate: [AuthGuard, RoleGuard], data: { role: ['checklist'], type: ['reports'] } },
  { path: 'materials', component: MaterialsListComponent, canActivate: [AuthGuard, RoleGuard], data: { role: ['material'], type: ['configs'] } },
  { path: 'material-add', component: MaterialCreateComponent, canActivate: [AuthGuard, RoleGuard], data: { role: ['material'], type: ['configs'] } },
  { path: 'material-update', component: MaterialUpdateComponent, canActivate: [AuthGuard, RoleGuard], data: { role: ['material'], type: ['configs'] } },
  { path: 'cards', component: CardListComponent, canActivate: [AuthGuard, RoleGuard], data: { role: ['cards'], type: ['configs'] } },
  { path: 'card-icons', component: CardIconComponent, canActivate: [AuthGuard, RoleGuard], data: { role: ['cards'], type: ['configs'] } },
  { path: 'card-add', component: CardCreateComponent, canActivate: [AuthGuard, RoleGuard], data: { role: ['cards'], type: ['configs'] } },
  { path: 'card-update', component: CardUpdateComponent, canActivate: [AuthGuard, RoleGuard], data: { role: ['cards'], type: ['configs'] } },
  { path: 'drivers', component: DriversListComponent, canActivate: [AuthGuard, RoleGuard], data: { role: ['drivers'], type: ['configs'] } },
  { path: 'driver-add', component: DriverCreateComponent, canActivate: [AuthGuard, RoleGuard], data: { role: ['drivers'], type: ['configs'] } },
  { path: 'driver-update', component: DriverUpdateComponent, canActivate: [AuthGuard, RoleGuard], data: { role: ['drivers'], type: ['configs'] } },
  { path: 'fleets', component: FleetsListComponent, canActivate: [AuthGuard, RoleGuard], data: { role: ['fleet'], type: ['configs'] } },
  { path: 'fleet-add', component: FleetCreateComponent, canActivate: [AuthGuard, RoleGuard], data: { role: ['fleet'], type: ['configs'] } },
  { path: 'fleet-update', component: FleetUpdateComponent, canActivate: [AuthGuard, RoleGuard], data: { role: ['fleet'], type: ['configs'] } },
  { path: 'records', component: RecordsListComponent, canActivate: [AuthGuard, RoleGuard], data: { role: ['records'], type: ['reports'] } },
  { path: 'fuel', component: FuelComponent, canActivate: [AuthGuard, RoleGuard], data: { role: ['fuel'], type: ['reports'] } },
  { path: 'trips', component: TripsListComponent, canActivate: [AuthGuard, RoleGuard], data: { role: ['trips'], type: ['reports'] } },
  { path: 'map', component: MapComponent, canActivate: [AuthGuard, RoleGuard], data: { role: ['realtime'], type: ['reports'] } },
  { path: 'timeline-map', component: TimelineComponent, canActivate: [AuthGuard, RoleGuard], data: { role: ['timeline'], type: ['reports'] } },
  { path: 'geocerca-add', component: GeocercaCreateComponent, canActivate: [AuthGuard, RoleGuard], data: { role: ['geocerca'], type: ['configs'] } },
  { path: 'geocerca-manual', component: GeocercaAddManualComponent, canActivate: [AuthGuard, RoleGuard], data: { role: ['geocerca'], type: ['configs'] } },
  { path: 'geocercas', component: GeocercaListComponent, canActivate: [AuthGuard, RoleGuard], data: { role: ['geocerca'], type: ['configs'] } },
  { path: 'geofence-history', component: GeofenceHistoryComponent, canActivate: [AuthGuard, RoleGuard], data: { role: ['geocerca'], type: ['configs'] } },
  { path: 'speed', component: SpeedListComponent, canActivate: [AuthGuard, RoleGuard], data: { role: ['speed'], type: ['reports'] } },
  { path: 'speeding', component: SpeedingListComponent, canActivate: [AuthGuard, RoleGuard], data: { role: ['speed'], type: ['reports'] } },
  { path: 'charts', component: ChartListComponent, canActivate: [AuthGuard, RoleGuard], data: { role: ['dashboards'], type: ['configs'] } },
  { path: 'chart-pizza', component: ChartPizzaComponent, canActivate: [AuthGuard, RoleGuard], data: { role: ['dashboards'], type: ['configs'] } },
  { path: 'chart-bar', component: ChartBarComponent, canActivate: [AuthGuard, RoleGuard], data: { role: ['dashboards'], type: ['configs'] } },
  { path: 'shifts', component: ShiftCreateComponent, canActivate: [AuthGuard, RoleGuard], data: { role: ['shifts'], type: ['configs'] } },
  { path: 'shift-update', component: ShiftEditComponent, canActivate: [AuthGuard, RoleGuard], data: { role: ['shifts'], type: ['configs'] } },
  { path: 'board', component: BoardComponent, canActivate: [AuthGuard, RoleGuard], data: { role: ['dashboards'], type: ['configs'] } },
  { path: 'settings', component: GeneralComponent, canActivate: [AuthGuard], data: { role: ['equipment'], type: ['configs'] } },
  { path: 'profile-list', component: ProfilesListComponent, canActivate: [AuthGuard, RoleGuard], data: { role: ['profiles'], type: ['configs'] } },
  { path: 'profile-create', component: ProfileCreateComponent, canActivate: [AuthGuard, RoleGuard], data: { role: ['profiles'], type: ['configs'] } },
  { path: 'user-list', component: UserListComponent, canActivate: [AuthGuard, RoleGuard], data: { role: ['profiles'], type: ['configs'] } },
  { path: 'user-create', component: UserCreateComponent, canActivate: [AuthGuard, RoleGuard], data: { role: ['profiles'], type: ['configs'] } },
  { path: 'user-profile', component: UserProfileComponent, canActivate: [AuthGuard, RoleGuard], data: { role: ['profiles'], type: ['configs'] } },
  { path: 'contingency-filter-trip', component: ContingencyFilterTripComponent, canActivate: [AuthGuard, RoleGuard], data: { role: ['profiles'], type: ['configs'] } },
  { path: 'contingency-filter-record', component: ContingencyFilterRecordComponent, canActivate: [AuthGuard, RoleGuard], data: { role: ['profiles'], type: ['configs'] } },
  { path: 'contingency-records', component: ContingencyRecordsComponent, canActivate: [AuthGuard, RoleGuard], data: { role: ['profiles'], type: ['configs'] } },
  { path: 'contingency-trips', component: ContingencyTripsComponent, canActivate: [AuthGuard, RoleGuard], data: { role: ['profiles'], type: ['configs'] } },
  { path: 'dispatch', component: DispatchComponent, canActivate: [AuthGuard, RoleGuard], data: { role: ['profiles'], type: ['configs'] } },
  { path: 'maintenance', component: MaintenanceComponent, canActivate: [AuthGuard, RoleGuard], data: { role: ['maintenance'], type: ['reports'] } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }