import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Dashboard } from 'src/app/home/dashboard.enum';
import { Interval } from 'src/app/home/interval.enum';
import { ChartModelDialogComonent } from '../chart-model-dialog/chart-model-dialog.component';
import { ChartDash } from '../chart.model';
import { ChartService } from '../chart.service';
import { Unit } from '../unit.enum';

@Component({
  selector: 'app-chart-details',
  templateUrl: './chart-details.component.html',
  styleUrls: ['./chart-details.component.scss']
})
export class ChartDetailsComponent implements OnInit {

  @Input() chart: ChartDash;
  isAdd: boolean = false;
  models: ChartDash[];

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private chartService: ChartService
  ) { }

  ngOnInit(): void {
    if (this.chart.id == "add") {
      this.isAdd = true;
      this.getModels()
    }
  }

  inactivate() {
    this.chartService
      .delete(this.chart.id)
      .catch(err => console.log(err));
  }

  edit() {
    window.localStorage.removeItem("editChartObj");
    localStorage.setItem('editChartObj', JSON.stringify(this.chart));

    if (this.chart.format === 1)
      this.router.navigate(['chart-pizza']);
    if (this.chart.format === 2)
      this.router.navigate(['chart-bar']);
    else if (this.chart.format === 3)
      this.router.navigate(['board']);

  }

  getTitle(): string {
    let title: string;
    let interval: string;
    let fleet: string;
    let unit: string = '';

    switch (this.chart.interval) {
      case Interval.TURNO_ATUAL: interval = 'Turno atual'; break;
      case Interval.DIA_ATUAL: interval = 'Dia atual'; break;
      case Interval.SEMANA_ATUAL: interval = 'Semana atual'; break;
      case Interval.MES_ATUAL: interval = 'Mês atual'; break;
      case Interval.ANO_ATUAL: interval = 'Ano atual'; break;
      case Interval.MEDIA_MOVEL:
        interval = 'Media Movel - ' + this.chart.movel + ' dias';
        break;
      case Interval.TURNO_ANTERIOR: interval = 'Turno anterior'; break;
      case Interval.DIA_ANTERIOR: interval = 'Dia anterior'; break;
      case Interval.SEMANA_ANTERIOR: interval = 'Semana anterior'; break;
      case Interval.MES_ANTERIOR: interval = 'Mês anterior'; break;
      case Interval.ANO_ANTERIOR: interval = 'Ano anterior'; break;
      case Interval.PERSONALIZADO:
        interval = this.chart.start_time.toLocaleString() + ' - ' + this.chart.end_time.toLocaleString();
        break;
    }

    if (Dashboard[this.chart.type] === Dashboard.PROD) {
      unit = ' - '
      switch (this.chart.unit) {
        case Unit.VIAGENS: unit += 'Viagens'; break;
        case Unit.KT: unit += 'Kt (métricas)'; break;
        case Unit.T: unit += 't (métricas)'; break;
        case Unit.KTON: unit += 'kton (curtas)'; break;
        case Unit.TON: unit += 'ton (curtas)'; break;
        case Unit.KG: unit += 'kg'; break;
      }
    }

    fleet = this.chart.fleet_name ? ' - Frota ' + this.chart.fleet_name : ''

    title = this.chart.use_title ? this.chart.default_title + fleet + ' - ' + interval + unit : this.chart.title;
    return title;
  }

  getModels() {
    this.chartService.getModels().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(models => {
      this.models = models;
    });
  }

  openDialog(format: number): void {
    const dialogRef = this.dialog.open(ChartModelDialogComonent, {
      width: '700px',
      data: { models: this.models, format: format }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    });
  }
}
