import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CardDialogData } from '../card-icon/card-icon.component';

@Component({
  selector: 'app-card-name-dialog',
  templateUrl: './card-name-dialog.component.html',
  styleUrls: ['./card-name-dialog.component.scss']
})
export class CardNameDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<CardNameDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CardDialogData) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  setImage(card: number){
    this.data.icon = card;
    this.dialogRef.close(this.data);
  }

}
