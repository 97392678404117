<div *ngIf="user" class="wrapper">

    <button *ngIf="!user.enabled" [disableRipple]="true" class="bt" mat-mini-fab type="button" (click)="enable(true)">
      <mat-icon>check</mat-icon>
    </button>

    <button *ngIf="user.enabled" [disableRipple]="true" class="bt" mat-mini-fab color="warn" type="button" (click)="enable(false)">
      <mat-icon>clear</mat-icon>
    </button>

    <button *ngIf="user.enabled" [disableRipple]="true" class="bt" mat-mini-fab type="button" (click)="edit()">
      <mat-icon>edit</mat-icon>
    </button>
  
    <div class="email">
      {{user.displayName}}
      {{user.email}}
    </div>
  
  </div>