<div class="wrapper" fxLayout="row" fxLayoutAlign="center center">

    <div class="name">
        {{contingency.name}}
    </div>

    <mat-form-field class="example-full-width">
        <input matInput type="number" placeholder="Mínimo" [(ngModel)]="contingency.min" 
            [disabled]='contingency.automatic'>
    </mat-form-field>

    <mat-form-field class="example-full-width">
        <input matInput type="number" placeholder="Máximo" [(ngModel)]="contingency.max"
            [disabled]='contingency.automatic'>
    </mat-form-field>

    <mat-checkbox  class="checkbox-full-width" type="checkbox" [(ngModel)]="contingency.active" [disabled]='contingency.automatic'>
        Ativo
    </mat-checkbox>

    <mat-checkbox  class="checkbox-full-width" type="checkbox" [(ngModel)]="contingency.automatic">
        Automático
    </mat-checkbox>
</div>