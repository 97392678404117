import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Day } from '../day.model';
import { ShiftDialogComponent } from '../shift-dialog/shift-dialog.component';
import { Shift } from '../shift.model';
import { ShiftService } from '../shift.service';

export interface DialogShiftModelData {
  shift: Shift;
  dateBreak: number;
}

@Component({
  selector: 'app-shift-edit',
  templateUrl: './shift-edit.component.html',
  styleUrls: ['./shift-edit.component.scss']
})
export class ShiftEditComponent implements OnInit {

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  day: Day;
  dateBreak: number;
  shifts: Shift[];

  constructor(
    public dialog: MatDialog,
    private shiftService: ShiftService,
    private router: Router,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {

    this.day = JSON.parse(window.localStorage.getItem("editDayObj"));
    if (!this.day) {
      this.openSnackBar('Ação inválida.')
      this.router.navigate(['shifts']);
      return;
    }

    this.getDateBreak();
  }

  getList() {
    this.shiftService.getListBy(this.day.day).snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(shifts => {
      this.shifts = shifts;
    });
  }

  edit(shift: Shift) {
    this.openDialog(shift);
  }

  create() {
    let shift = new Shift();
    shift.day = this.day.day;
    this.openDialog(shift);
  }

  openDialog(shift: Shift): void {
    const dialogRef = this.dialog.open(ShiftDialogComponent, {
      width: '250px',
      data: { shift: shift, dateBreak: this.dateBreak }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.id)
          this.inactivate(result);
        this.save(result);
      }
    });
  }

  timeConvert(time: number) {

    let dateBreak = this.dateBreak * 60;

    time = dateBreak + time;
    time = time > 1440 ? time - 1440 : time;

    var hours =  Math.floor(time / 60);
    var minutes = time - (hours * 60);

    return (hours + '').padStart(2, '0') + ":" + (minutes + '').padStart(2, '0');
  }

  getDateBreak() {
    this.shiftService.getClient().valueChanges().subscribe(client => {
      this.dateBreak = client.dateBreak;
      console.log("DATEBREAK " + client.dateBreak);

      this.getList();
    });
  }

  save(shift: Shift) {
    if (shift.id)
      delete shift.id;
    this.shiftService.create(shift);
  }

  inactivate(shift: Shift) {
    this.shiftService.update(shift.id, { active: false })
      .catch(err => console.log(err));
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "Fechar", {
      duration: 2000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

}
