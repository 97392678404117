import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { EquipmentService } from 'src/app/equipments/equipment.service';
import { firestore } from 'firebase';
import { FleetService } from 'src/app/fleets/fleet.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GeocercaService } from 'src/app/geocercas/geocerca.service';
import { Geocerca } from 'src/app/geocercas/geocerca.model';
import { RecordService } from 'src/app/records/record.service';
import { Record } from 'src/app/records/record.model';
import { RecordsDialogComponent } from 'src/app/records/records-dialog/records-dialog.component';
import { ContingencyReasonsDialogComponent } from '../contingency-reasons-dialog/contingency-reasons-dialog.component';

interface TimeClass {
  subClass: number;
  timeClass: number;
  label: string;
}
export interface DialogData {
  record: Record;
  geocercas: Geocerca[];
}
@Component({
  selector: 'app-contingency-records',
  templateUrl: './contingency-records.component.html',
  styleUrls: ['./contingency-records.component.scss']
})
export class ContingencyRecordsComponent implements OnInit {
  records: Record[];
  equipments: any;
  geocercas: Geocerca[];
  equipment: string = "all";
  fleets: any;
  fleet: string = "all";
  subClass: number = 0;
  dataSource: MatTableDataSource<Record>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('TABLE') table: ElementRef;

  times: TimeClass[] = [
    { subClass: 1, timeClass: 1, label: 'Operando' },
    { subClass: 2, timeClass: 1, label: 'Infraestrutura' },
    { subClass: 3, timeClass: 2, label: 'Atraso interno' },
    { subClass: 4, timeClass: 2, label: 'Impedimento externo' },
    { subClass: 5, timeClass: 3, label: 'Corretiva' },
    { subClass: 6, timeClass: 3, label: 'Preventiva' },
  ];

  constructor(
    public dialog: MatDialog,
    private geocercaService: GeocercaService,
    private fleetsService: FleetService,
    private equipmentService: EquipmentService,
    private recordService: RecordService) { }

  classifyPoint = require("robust-point-in-polygon")

  displayedColumns: string[] = ['date_start', 'date_end', 'duration', 'geocerca', 'fleet', 'equipment', 'operator', 'status', 'map', 'filter', 'edit', 'select'];

  onDateChange(dateStart, dateEnd) {

    var start = new Date(dateStart);
    var end = new Date(dateEnd);

    if (!isNaN(+start) && !isNaN(+end))
      this.getListTime(start, end, this.equipment, this.fleet, this.subClass);
  }

  ngOnInit() {
    this.getEquipments();
    this.getFleets();
    this.getGeocercas();
    this.getList();

    var today = new Date();
    var yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    this.selectedMoments = [yesterday, today];

  }

  openDialogReasons(record: Record): void {
    this.dialog.open(ContingencyReasonsDialogComponent, {
      width: '350px',
      data: record.contingency_reasons 
    });
  }

  openDialog(record: Record): void {
    this.dialog.open(RecordsDialogComponent, {
      width: '900px',
      height: '600px',
      data: {
        record: record,
        geocercas: this.geocercas,
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public selectedMoments = [
    new Date(),
    new Date()
  ];

  getList() {
    this.recordService.getContingency().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(records => {
      this.records = records;
      this.setGeocerca();
    });
  }

  setGeocerca() {

    this.records.forEach(record => {

      this.geocercas.forEach(geocerca => {

        var arr = Array();
        geocerca.points.forEach(point => {
          arr.push([point.lat, point.lng])
        })

        if (record.location && this.classifyPoint(arr, [record.location.latitude, record.location.longitude]) == -1)
          record.geocerca = geocerca.name;
      });

      if (!record.geocerca && record.location) record.geocerca = record.location.latitude + ', ' + record.location.longitude;
    });

    this.updateTable();
  }

  clear() {
    this.getList();
  }

  getEquipments() {
    this.equipmentService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(equipments => {
      this.equipments = equipments;
    });
  }

  getFleets() {
    this.fleetsService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(fleets => {
      this.fleets = fleets;
    });
  }

  getListTime(start: Date, end: Date, equipment: string, fleet: string, subClass: number) {
    this.recordService.getListByTime(start, end, equipment, fleet, subClass).snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(records => {
      this.records = records;
      this.setGeocerca();
    });
  }

  private updateTable() {
    this.dataSource = new MatTableDataSource(this.records);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  delete() {
    this.recordService.deleteAll();
  }

  getDateEnd(date: firestore.Timestamp): firestore.Timestamp {
    if (date == null) return firestore.Timestamp.now();
    else return date;
  }

  getDuration(dateStart: firestore.Timestamp, dateEnd: firestore.Timestamp) {
    if (dateEnd == null) dateEnd = firestore.Timestamp.now();

    return this.getDataDiff(dateStart.toDate(), dateEnd.toDate());
  }

  getDataDiff(startDate, endDate) {
    var milisecondsDiff = endDate - startDate;
    return Math.floor(milisecondsDiff / (1000 * 60 * 60)).toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ":" + (Math.floor(milisecondsDiff / (1000 * 60)) % 60).toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ":" + (Math.floor(milisecondsDiff / 1000) % 60).toLocaleString(undefined, { minimumIntegerDigits: 2 });
  }

  getGeocercas() {
    this.geocercaService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(geocercas => {
      this.geocercas = geocercas;
    });
  }

  getColor(n: number) {
    let color: string;
    switch (n) {
      case 1: color = '#99ff99'
        break;
      case 2: color = '#ff9999'
        break;
      case 3: color = '#ffff99'
        break;
    }
    return color;
  }

  selection = new Map()

  onChangeSelect(row: Record, event) {
    const position = this.records.findIndex(record => record.id == row.id)

    this.selection.set(row.id, event.checked)
  }
}
