import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { map } from 'rxjs/operators';
import { DialogShiftModelData } from '../shift-edit/shift-edit.component';
import { Shift } from '../shift.model';
import { ShiftService } from '../shift.service';

@Component({
  selector: 'app-shift-dialog',
  templateUrl: './shift-dialog.component.html',
  styleUrls: ['./shift-dialog.component.scss']
})
export class ShiftDialogComponent {

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  startInput: string;
  endInput: string;

  shifts: Shift[];
  private formSubmitAttempt: boolean;

  form = new FormGroup({
    label: new FormControl(''),
    start: new FormControl(''),
    end: new FormControl(''),
    toleranceStart: new FormControl(''),
    toleranceEnd: new FormControl(''),
  });

  constructor(
    private fb: FormBuilder,
    private shiftService: ShiftService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ShiftDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogShiftModelData) {

    this.form = this.fb.group({
      label: ['', Validators.required],
      start: ['', Validators.required],
      end: ['', Validators.required],
      toleranceStart: ['', Validators.required],
      toleranceEnd: ['', Validators.required],
    });

    if (!data.shift.toleranceStart)
      data.shift.toleranceStart = 0;

    if (!data.shift.toleranceEnd)
      data.shift.toleranceEnd = 0;
      
    this.getList();
  }

  getList() {
    this.shiftService.getListBy(this.data.shift.day).snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(shifts => {
      this.shifts = shifts;
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.form.valid) {
      switch (this.checkInterval()) {
        case 0:
          this.openSnackBar("Turno cadastrado");
          this.dialogRef.close(this.data.shift);
          break;
        case 1:
          this.openSnackBar("O turno não pode ultrapassar a quebra de data");
          break;
        case 2:
          this.openSnackBar("O horário de término deve ser maior que o horário de início");
          break;
        case 3:
          this.openSnackBar("Já existe um turno cadastrado nesse intervalo");
          break;
      }
    }
  }

  checkInterval(): number {

    /**
     * 0 - SUCCESS
     * 1 - DATEBREAK
     * 2 - END < START
     * 3 - INVALID INTERVAL
     */

    let success = 0;

    let splitStart = this.startInput.split(":");
    let splitEnd = this.endInput.split(":");

    let start = (Number(splitStart[0]) * 60) + Number(splitStart[1]);
    let end = (Number(splitEnd[0]) * 60) + Number(splitEnd[1]);
    let dateBreak = this.data.dateBreak * 60;

    let diffStart = start - dateBreak;
    diffStart = diffStart < 0 ? diffStart + 1440 : diffStart;

    let diffEnd = end - dateBreak;

    diffEnd = diffEnd < 0 ? diffEnd + 1440 : diffEnd;
    diffEnd = end === dateBreak ? 1440 : diffEnd;

    if (diffEnd <= diffStart) return 2;

    this.data.shift.start = diffStart;
    this.data.shift.end = diffEnd;

    let data = this.data.shift;

    if (this.shifts)
      this.shifts.forEach(shift => {
        if (diffStart > shift.start && diffStart < shift.end || diffEnd < shift.end && diffEnd > shift.start) {
          if (data.id && data.id != shift.id || !data.id)
            success = 3;
        }
      })

    return success;
  }

  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitAttempt)
    );
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "Fechar", {
      duration: 2000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

}
