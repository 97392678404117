import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogChecklistData } from '../checklist-list/checklist-list.component';

@Component({
  selector: 'app-checklist-dialog',
  templateUrl: './checklist-dialog.component.html',
  styleUrls: ['./checklist-dialog.component.scss']
})
export class ChecklistDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ChecklistDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogChecklistData) {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getStatus(status: number): string {
    var text: string;
    if (status == 1) text = "Conforme"
    if (status == 2) text = "Não conforme"
    if (status == 3) text = "N/A"
    return text;
  }

  getObs(obs: string): string {
    var text: string = "";
    if (obs) text = " - " + obs;

    return text;
  }
}
