export class Equipment {
    id: string;
    name: string;
    fleet: string;
    fleetClass: number;
    subClass: number;
    fleetName: string;
    active: boolean = true;
    speed: number;
    loadId: string;
    loadName: string;
    driverId: string;
    tripId: string;
    status: string;
    step: number;
    token: string;
    hourmeter: number;
    kilometers: number;
}
