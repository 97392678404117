import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Driver } from '../driver.model';
import { DriverService } from '../driver.service';
import { FleetService } from 'src/app/fleets/fleet.service';
import { map } from 'rxjs/operators';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormControl, Validators, FormBuilder, NgForm } from '@angular/forms';
import { Fleet } from 'src/app/fleets/fleet.model';
import { MatCheckbox } from '@angular/material/checkbox';

@Component({
  selector: 'app-driver-create',
  templateUrl: './driver-create.component.html',
  styleUrls: ['./driver-create.component.scss']
})
export class DriverCreateComponent implements OnInit {

  fleets: Fleet[];
  driver: Driver = new Driver();

  @ViewChildren("checkboxes") checkboxes: QueryList<MatCheckbox>;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  @ViewChild('myForm') private myForm: NgForm;

  private formSubmitAttempt: boolean;

  form = new FormGroup({
    name: new FormControl(''),
    identifier: new FormControl('')
  });

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private driverService: DriverService,
    private fleetsService: FleetService) { }

  ngOnInit() {
    this.getFleets();

    this.form = this.fb.group({
      name: ['', Validators.required],
      identifier: ['', Validators.required]
    });
  }

  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitAttempt)
    );
  }

  save() {
    this.driverService.create(this.driver);
    this.myForm.resetForm();
    this.uncheckAll()
    this.driver = new Driver();
  }

  onSubmit() {
    if (this.form.valid) {
      this.openSnackBar("Operador inserido");
      this.save();
    } else this.openSnackBar("Preencha todos os campos");
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "Fechar", {
      duration: 2000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  getFleets() {
    this.fleetsService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(fleets => {
      this.fleets = fleets;
    });
  }

  onChangeFleets(id, name, event) {
    if (event.checked) {
      this.driver.fleets.push({ id: id, name: name });
    } else {
      this.driver.fleets = this.driver.fleets.filter((item) => item.id !== id);
    }
  }

  uncheckAll() {
    this.checkboxes.forEach((element) => {
      element.checked = false;
    });
  }
}
