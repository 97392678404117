import { Component, OnInit } from '@angular/core';
import { ChecklistService } from '../checklist.service';
import { map } from 'rxjs/operators';
import { Checklist } from '../checklist.model';
import { MatDialog } from '@angular/material/dialog';
import { ChecklistUpdateComponent } from '../checklist-update/checklist-update.component';
import { Fleet } from 'src/app/fleets/fleet.model';
import { FleetService } from 'src/app/fleets/fleet.service';

export interface ChecklistDialogData {
  fleets: Fleet[];
}
@Component({
  selector: 'app-checklist-create',
  templateUrl: './checklist-create.component.html',
  styleUrls: ['./checklist-create.component.scss']
})
export class ChecklistCreateComponent implements OnInit {

  fleet: string
  fleets: Fleet[]
  checklists: Checklist[];
  checklistsFromFleet: Checklist[];

  constructor(
    public dialog: MatDialog,
    private checklistService: ChecklistService,
    private fleetService: FleetService
  ) { }

  ngOnInit() {
    this.getList();
    this.getFleets();
  }

  getList() {
    this.checklistService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(checklist => {
      console.log(checklist);
      
      this.checklists = checklist;
      this.checklistsFromFleet = this.checklists.filter(checklist => checklist.fleet == this.fleet)
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ChecklistUpdateComponent, {
      width: '350px',
      data: {fleets: this.fleets}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    });
  }

  getFleets() {
    this.fleetService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(fleets => {
      this.fleets = fleets;
      this.fleet = fleets[0].id
      this.onUpdateFleet()
    });
  }

  onUpdateFleet() {
    this.checklistsFromFleet = this.checklists.filter(checklist => checklist.fleet == this.fleet)
  }
}
