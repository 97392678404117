<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
    <mat-card class="box">

        <mat-card-header>
            <mat-card-title>Adicionar geocerca</mat-card-title>
        </mat-card-header>

        <mat-card-content>

            <table>
                <TR>
                    <TH>Pontos</TH>
                </TR>
                <tr>
                    <td>

                        <div class="form-container">
                            <mat-list role="list">
                                <mat-list-item role="listitem" *ngFor="let point of pointList">
                                    {{ point.lat}},
                                    {{ point.lng }}
                                </mat-list-item>
                            </mat-list>

                            <mat-card *ngIf="selectedArea > 0">
                                Área selecionada: {{ selectedArea.toPrecision(5) }} m<sup>2</sup></mat-card>
                        </div>
                    </td>
                    <td>
                        <form [formGroup]="formPoint" #myFormPoint="ngForm"
                            (ngSubmit)="addPoint(latitude.value, longitude.value)" class="example-form">

                            <mat-form-field class="example-full-width">
                                <input matInput #latitude type="text" formControlName="latitude" placeholder="Latitude" required>
                                <mat-error *ngIf="isFieldInvalidPoint('latitude')">
                                    Informe a latitude
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class="example-full-width">
                                <input matInput #longitude type="text" formControlName="longitude" placeholder="Longitude" required>
                                <mat-error *ngIf="isFieldInvalidPoint('longitude')">
                                    Informe a longitude
                                </mat-error>
                            </mat-form-field>

                            <button mat-raised-button color="primary" type="submit" class="btn-block">Adicionar
                                ponto</button>
                            <button mat-raised-button color="warn" type="button" class="btn-block"
                                (click)="deleteSelectedShape()">Limpar
                                pontos</button>
                            <button mat-raised-button color="basic" type="button" class="btn-block"
                                (click)="openDialog()">Visualizar</button>
                        </form>

                        <form [formGroup]="form" #myForm="ngForm" (ngSubmit)="onSubmit()" class="example-form">

                            <mat-form-field class="example-full-width" style="margin-top: 20px;">
                                <input matInput type="text" placeholder="Identificador" [(ngModel)]="geocerca.name"
                                    formControlName="name" required>
                                <mat-error *ngIf="isFieldInvalid('name')">
                                    Informe o identificador
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class="example-full-width" style="width: 70%; margin-right: 20px;">
                                <input matInput type="text" placeholder="Cor" [(ngModel)]="geocerca.color" formControlName="color" required>
                                <mat-error *ngIf="isFieldInvalid('color')">
                                  Informe a cor
                                </mat-error>
                              </mat-form-field>
                              <button class="color" mat-mini-fab [style.background-color]="'#' + geocerca.color" type="button"
                                (click)="changeState()">
                                <mat-icon>colorize</mat-icon>
                              </button>
                              <div class="example-full-width" fxLayoutAlign="end">
                                <color-github *ngIf="displayColor" [color]="'#' + geocerca.color" (onChange)="changeComplete($event)">
                                </color-github>
                              </div>

                              <mat-checkbox type="checkbox" [(ngModel)]="geocerca.load" [checked]="geocerca.load" formControlName="load" >
                                Habilitado para carga
                              </mat-checkbox>
    
                              <mat-checkbox type="checkbox" [(ngModel)]="geocerca.unload" [checked]="geocerca.unload" formControlName="unload">
                                Habilitado para descarga
                              </mat-checkbox>

                            <button mat-raised-button color="primary" type="submit" class="btn-block">Adicionar
                                geocerca</button>

                        </form>
                    </td>
                </tr>
            </table>
        </mat-card-content>
    </mat-card>
</div>