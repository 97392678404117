import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar, MatSnackBarVerticalPosition, MatSnackBarHorizontalPosition } from '@angular/material/snack-bar';
import { FleetService } from '../fleet.service';
import { Fleet } from '../fleet.model';
import { FormGroup, FormControl, NgForm, Validators, FormBuilder } from '@angular/forms';

interface FleetClass {
  value: number;
  viewValue: string;
}

@Component({
  selector: 'app-fleet-update',
  templateUrl: './fleet-update.component.html',
  styleUrls: ['./fleet-update.component.scss']
})
export class FleetUpdateComponent implements OnInit {

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  fleet: Fleet = new Fleet();
  isTransport: boolean = false;

  @ViewChild('myForm') private myForm: NgForm;

  private formSubmitAttempt: boolean;
  form = new FormGroup({
    name: new FormControl(''),
    speedLimit: new FormControl(''),
    fleetClass: new FormControl(''),
    load: new FormControl('')
  });

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private snackBar: MatSnackBar,
    private fleetService: FleetService) { }

  ngOnInit() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      speedLimit: ['', Validators.required],
      fleetClass: ['', Validators.required],
      load: []
    });

    this.fleet = JSON.parse(window.localStorage.getItem("editFleetObj"));
    if (!this.fleet) {
      this.openSnackBar('Ação inválida.')
      this.router.navigate(['fleets']);
      return;
    }

    this.onFleetChange(this.fleet.fleetClass);
  }

  fleets: FleetClass[] = [
    { value: 1, viewValue: 'Transporte' },
    { value: 2, viewValue: 'Carregamento' },
    { value: 3, viewValue: 'Outros' }
  ];

  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitAttempt)
    );
  }

  new(): void {
    this.fleet = new Fleet();
  }

  save() {
    delete this.fleet.id;
    this.fleetService.create(this.fleet);
    this.fleet = new Fleet();
  }

  inactivate() {
    this.fleetService.update(this.fleet.id, { active: false })
      .catch(err => console.log(err));
  }

  onSubmit() {
    if (this.form.valid) {
      if (this.isTransport) {
        if (!this.form.get('load').value){
          this.openSnackBar("Preencha todos os campos");
          return;
        }

      } else delete this.fleet.load;

      this.inactivate();
      this.save();
      this.openSnackBar('Frota atualizada')
      this.router.navigate(['fleets']);
    } else this.openSnackBar("Preencha todos os campos");
  }

  onFleetChange(fleetClass: number) {
    this.isTransport = fleetClass == 1;
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "Fechar", {
      duration: 2000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  ngOnDestroy() {
    window.localStorage.removeItem("editFleetObj");
  }
}
