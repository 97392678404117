import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBarVerticalPosition, MatSnackBarHorizontalPosition, MatSnackBar } from '@angular/material/snack-bar';
import { NgForm, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { User } from 'src/app/shared/models/user.interface';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss']
})
export class UserCreateComponent implements OnInit {

  user: User = new User()
  toUpdate: boolean = false
  title: string = "Adicionar usuário"
  button: string = "Adicionar"

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  @ViewChild('myForm') private myForm: NgForm;

  private formSubmitAttempt: boolean;
  private readonly USER_OBJ = "editUserObj";

  form = new FormGroup({
    name: new FormControl(''),
    email: new FormControl(''),
    password: new FormControl('')
  });

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private authService: AuthService) { }

  ngOnInit() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.user = JSON.parse(window.localStorage.getItem(this.USER_OBJ));

    if (!this.user) {
      this.user = new User()
    } else {
      this.toUpdate = true
      this.title = "Alterar usuário"
      this.button = "Alterar"
    }
  }

  ngOnDestroy() {
    window.localStorage.removeItem(this.USER_OBJ);
  }

  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitAttempt)
    );
  }

  onSubmit() {
    if (this.form.valid) {
      this.openSnackBar(this.toUpdate ? "Usuário atualizado" : "Usuário inserido");
      this.save();
      this.myForm.resetForm();
      this.user = new User()
    } else this.openSnackBar("Preencha todos os campos");
  }

  save() {
    if (this.toUpdate)
      this.authService.updateUser(this.user)
    else
      this.authService.register(this.user, false)
      .then(_=>{
        this.openSnackBar("Usuário inserido");
      })
      .catch(error => {
        let message: string = "";
        if (error.code === "auth/email-already-in-use") message = "Email já utilizado"
        else if (error.code === "auth/weak-password") message = "Senha menor que 6 caracteres"
        else message = "Falha ao inserir"
        
        this.openSnackBar(message);
      })
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "Fechar", {
      duration: 2000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  resetPassword() {
    this.authService.resetPassword(this.user.email)
    this.openSnackBar("Email enviado!");
  }

}
