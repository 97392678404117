export class ContingencyItem {
    id: string;
    name: string;
    min: number;
    max: number;
    active: boolean;
    automatic: boolean;
    geocerca: boolean;

    constructor(
        id: string,
        name: string,
        min: number,
        max: number,
        active: boolean,
        automatic: boolean,
        geocerca: boolean = null
    ) {
        this.id = id;
        this.name = name;
        this.min = min;
        this.max = max;
        this.active = active;
        this.automatic = automatic;
        this.geocerca = geocerca;
    }
}
