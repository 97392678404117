<mat-toolbar color="primary" class="app-header" *ngIf="user$ | async">
  <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
    <mat-icon class="white-icon" aria-label="Side nav toggle icon">menu</mat-icon>
  </button>
  <div><a [routerLink]="['/home']" class="positronx">MinePro</a></div>
  <span class="nav-tool-items">
    <button mat-icon-button [matMenuTriggerFor]="settings">
      <mat-icon class="white-icon">settings</mat-icon>
    </button>
    <!--First level Menu-->

    <mat-menu #settings="matMenu">
      <div *ngIf="(configs$ | async) as config">
        <button *ngIf="config.dashboards" mat-menu-item [routerLink]="['/charts']">Dashboard</button>
        <button mat-menu-item [matMenuTriggerFor]="site">Site</button>
        <button *ngIf="config.cards" mat-menu-item [matMenuTriggerFor]="cards">Cards</button>
        <button *ngIf="config.material" mat-menu-item [matMenuTriggerFor]="materials">Materiais</button>
        <button *ngIf="config.equipment" mat-menu-item [matMenuTriggerFor]="equipments">Equipamentos</button>
        <button *ngIf="config.checklist" mat-menu-item [matMenuTriggerFor]="checklist">Checklist</button>
        <button *ngIf="config.drivers" mat-menu-item [matMenuTriggerFor]="operator">Operador</button>
        <button *ngIf="config.fleet" mat-menu-item [matMenuTriggerFor]="fleet">Frotas</button>
        <button *ngIf="config.geocerca" mat-menu-item [matMenuTriggerFor]="geocercas">Geocercas</button>
        <button *ngIf="config.contingencies" mat-menu-item [matMenuTriggerFor]="contingency">Contingências</button>
      </div>
    </mat-menu>

    <mat-menu #charts="matMenu">
      <button mat-menu-item [routerLink]="['/charts']">Gráficos</button>
    </mat-menu>

    <mat-menu #site="matMenu">
      <div *ngIf="(configs$ | async) as config">
        <button *ngIf="config.shifts" mat-menu-item [routerLink]="['/shifts']">Turnos</button>
        <button *ngIf="config.maintenance" mat-menu-item [routerLink]="['/settings']">Regras</button>
      </div>
    </mat-menu>

    <mat-menu #cards="matMenu">
      <button mat-menu-item [routerLink]="['/card-icons']">Ícones</button>
      <button mat-menu-item [routerLink]="['/card-add']">Inserir</button>
      <button mat-menu-item [routerLink]="['/cards']">Listar</button>
    </mat-menu>

    <mat-menu #materials="matMenu">
      <button mat-menu-item [routerLink]="['/material-add']">Inserir</button>
      <button mat-menu-item [routerLink]="['/materials']">Listar</button>
    </mat-menu>

    <mat-menu #equipments="matMenu">
      <button mat-menu-item [routerLink]="['/equipment-add']">Inserir</button>
      <button mat-menu-item [routerLink]="['/equipments']">Listar</button>
    </mat-menu>

    <mat-menu #checklist="matMenu">
      <button mat-menu-item [routerLink]="['/checklist']">Inserir</button>
      <button mat-menu-item [routerLink]="['/checklists']">Listar</button>
    </mat-menu>

    <mat-menu #operator="matMenu">
      <button mat-menu-item [routerLink]="['/driver-add']">Inserir</button>
      <button mat-menu-item [routerLink]="['/drivers']">Listar</button>
    </mat-menu>

    <mat-menu #fleet="matMenu">
      <button mat-menu-item [routerLink]="['/fleet-add']">Inserir</button>
      <button mat-menu-item [routerLink]="['/fleets']">Listar</button>
    </mat-menu>

    <mat-menu #geocercas="matMenu">
      <button mat-menu-item [routerLink]="['/geocerca-add']">Inserir</button>
      <button mat-menu-item [routerLink]="['/geocercas']">Listar</button>
    </mat-menu>

    <mat-menu #contingency="matMenu">
      <button mat-menu-item [routerLink]="['/contingency-filter-trip']">Viagens</button>
      <button mat-menu-item [routerLink]="['/contingency-filter-record']">Estados</button>
    </mat-menu>

    <button mat-icon-button [matMenuTriggerFor]="auth">
      <mat-icon class="white-icon">person</mat-icon>
    </button>
    <mat-menu #auth="matMenu">
      <span class="info">{{user.displayName}}</span>
      <span class="info">{{user.email}}</span>
      <span class="info">{{profile}}</span>
      <button mat-menu-item [routerLink]="['/profile']">Meus dados</button>
      <div *ngIf="(configs$ | async) as profile">
        <button *ngIf="profile.profiles" mat-menu-item [matMenuTriggerFor]="users">Editar Usuários</button>
      </div>

      <button mat-menu-item (click)="onLogout()">
        <mat-icon>exit_to_app</mat-icon>
        <span>Sair</span>
      </button>
    </mat-menu>

    <mat-menu #users="matMenu">
      <button mat-menu-item [routerLink]="['
      /profile-list']">Personalizar perfis</button>
      <button mat-menu-item [routerLink]="['/user-profile']">Atribuir perfis</button>
      <button mat-menu-item [routerLink]="['/user-list']">Gerenciar usuários</button>
    </mat-menu>

  </span>
</mat-toolbar>

<mat-sidenav-container class="sidenav-container" autosize>

  <mat-sidenav #drawer class="sidenav" mode="side" [opened]="user$ | async" (mouseenter)="isExpanded = !isExpanded" (mouseleave)="isExpanded = !isExpanded">
    <mat-nav-list>
      <mat-list-item [routerLink]="['/home']">
        <svg-icon mat-list-icon src="./assets/images/menu-icons/dashboard.svg"></svg-icon>
        <p matLine *ngIf="isExpanded">Dashboard</p>
      </mat-list-item>

      <div *ngIf="(reports$ | async) as report">

        <mat-list-item *ngIf="report.dispatch" [routerLink]="['/dispatch']">
          <svg-icon mat-list-icon src="./assets/images/menu-icons/dispatch.svg"></svg-icon>
          <p matLine *ngIf="isExpanded">Despacho</p>
        </mat-list-item>

        <mat-list-item *ngIf="report.trips" [routerLink]="['/trips']">
          <svg-icon mat-list-icon src="./assets/images/menu-icons/trips.svg"></svg-icon>
          <p matLine *ngIf="isExpanded">Viagens</p>
        </mat-list-item>

        <mat-list-item *ngIf="report.records" [routerLink]="['/records']">
          <svg-icon mat-list-icon src="./assets/images/menu-icons/times.svg"></svg-icon>
          <p matLine *ngIf="isExpanded">Tempos</p>
        </mat-list-item>

        <mat-list-item *ngIf="report.speed" [matMenuTriggerFor]="speed">
          <svg-icon mat-list-icon src="./assets/images/menu-icons/speed.svg"></svg-icon>
          <p matLine *ngIf="isExpanded">Velocidade</p>
        </mat-list-item>
        <mat-menu #speed="matMenu">
          <button mat-menu-item [routerLink]="['/speed']">
            <span>Média de velocidade</span>
          </button>
          <button mat-menu-item [routerLink]="['/speeding']">
            <span>Excessos de velocidade</span>
          </button>
        </mat-menu>

        <mat-list-item *ngIf="report.fuel" [routerLink]="['/fuel']">
          <svg-icon mat-list-icon src="./assets/images/menu-icons/fuel.svg"></svg-icon>
          <p matLine *ngIf="isExpanded">Abastecimento</p>
        </mat-list-item>

        <mat-list-item *ngIf="report.checklist" [routerLink]="['/checklists']">
          <svg-icon mat-list-icon src="./assets/images/menu-icons/checklists.svg"></svg-icon>
          <p matLine *ngIf="isExpanded">Checklist</p>
        </mat-list-item>

        <mat-list-item *ngIf="report.maintenance" [routerLink]="['/maintenance']">
          <svg-icon mat-list-icon src="./assets/images/menu-icons/maintenance.svg"></svg-icon>
          <p matLine *ngIf="isExpanded">Manutenção</p>
        </mat-list-item>

        <mat-list-item *ngIf="report.realtime" [routerLink]="['/map']">
          <svg-icon mat-list-icon src="./assets/images/menu-icons/map_realtime.svg"></svg-icon>
          <p matLine *ngIf="isExpanded">Mapa - Tempo Real</p>
        </mat-list-item>

        <mat-list-item *ngIf="report.timeline" [routerLink]="['/timeline-map']">
          <svg-icon mat-list-icon src="./assets/images/menu-icons/map_timeline.svg"></svg-icon>
          <p matLine *ngIf="isExpanded">Mapa - Timeline</p>
        </mat-list-item>

        <mat-list-item *ngIf="report.contingencies" [matMenuTriggerFor]="contingencies">
          <svg-icon mat-list-icon src="./assets/images/menu-icons/contingency.svg"></svg-icon>
          <p matLine *ngIf="isExpanded">Contingências</p>
        </mat-list-item>
        <mat-menu #contingencies="matMenu">
          <button mat-menu-item [routerLink]="['/contingency-trips']">
            <span>Viagens</span>
          </button>
          <button mat-menu-item [routerLink]="['/contingency-records']">
            <span>Estados</span>
          </button>
        </mat-menu>
        <mat-list-item *ngIf="report.geofence" [routerLink]="['/geofence-history']">
          <svg-icon mat-list-icon src="./assets/images/menu-icons/geofence.svg"></svg-icon>
          <p matLine *ngIf="isExpanded">Geocercas</p>
        </mat-list-item>
      </div>

    </mat-nav-list>
  </mat-sidenav>

  <router-outlet></router-outlet>

</mat-sidenav-container>