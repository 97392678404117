<div class="list">

  <div class="select" fxLayout="row" fxLayoutAlign="center center">
    <mat-form-field class="example-full-width">
      <mat-label>Frota</mat-label>
      <mat-select [(value)]="fleet" (selectionChange)="onUpdateFleet()" [(ngModel)]="fleet">
        <mat-option *ngFor="let fleet of fleets" [value]="fleet.id">
          {{fleet.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="wrapper">

    <div class="name">
      Nome
    </div>

    <div class="label">
      Crítico
    </div>

  </div>

  <div *ngFor="let checklist of checklistsFromFleet">
    <app-checklist-item [checklist]='checklist'></app-checklist-item>
  </div>

  <button mat-mini-fab color="primary" type="button" (click)="openDialog()">
    <mat-icon>add</mat-icon>
  </button>
</div>