export class Board {
    title: string;
    value: string;
    color: string;

    constructor(title: string, value: string, color: string) {
        this.title = title
        this.value = value
        this.color = color
    }
}
