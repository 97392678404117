import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Client } from '../auth/client.model';
import { Shift } from './shift.model';

@Injectable({
  providedIn: 'root'
})
export class ShiftService {

  private dbPath = '/shifts';
  client: string;

  collection: AngularFirestoreCollection<Shift> = null;

  constructor(private db: AngularFirestore) {
    this.client = JSON.parse(localStorage.getItem('documentData')).client_id;
    this.collection = db.collection('clients').doc(this.client)
      .collection(this.dbPath, ref => ref
        .where('active', '==', true)
        .orderBy('day', 'asc')
        .orderBy('start', 'asc'));
  }

  getClient(): AngularFirestoreDocument<Client> {
    return this.db.collection('clients').doc(this.client);
  }

  setDateBreak(dateBreak: number) {
    this.db.collection('clients').doc(this.client).update({ dateBreak: dateBreak });
  }

  setTripPeriod(tripPeriod: boolean) {
    this.db.collection('clients').doc(this.client).update({ tripPeriod: tripPeriod });
  }

  create(shift: Shift): void {
    this.collection.add({ ...shift });
  }

  update(key: string, value: any): Promise<void> {
    return this.collection.doc(key).update(value);
  }

  delete(key: string): Promise<void> {
    return this.collection.doc(key).delete();
  }

  getList(): AngularFirestoreCollection<Shift> {
    return this.collection;
  }

  getListBy(day: number): AngularFirestoreCollection<Shift> {
    return this.db.collection('clients').doc(this.client).collection(this.dbPath, ref => ref
      .where('active', '==', true)
      .where('day', '==', day)
      .orderBy('start', 'asc'));
  }

  deleteAll() {
    this.collection.get().subscribe(
      querySnapshot => {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
        });
      },
      error => {
        console.log('Error: ', error);
      });
  }
}
