import { Component, OnInit, ViewChild, ElementRef, Inject } from '@angular/core';
import { RecordService } from '../record.service';
import { map } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { Record } from '../record.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { EquipmentService } from 'src/app/equipments/equipment.service';
import { firestore } from 'firebase';
import { FleetService } from 'src/app/fleets/fleet.service';
import * as XLSX from 'xlsx';
import { GeocercaService } from 'src/app/geocercas/geocerca.service';
import { Geocerca } from 'src/app/geocercas/geocerca.model';
import { FuelService } from '../fuel.service';
import { Fuel } from '../fuel.model';


@Component({
  selector: 'app-fuel',
  templateUrl: './fuel.component.html',
  styleUrls: ['./fuel.component.scss']
})
export class FuelComponent implements OnInit {
  
  fuels: Fuel[];
  equipments: any;
  geocercas: Geocerca[];
  equipment: string = "all";
  fleets: any;
  fleet: string = "all";
  dataSource: MatTableDataSource<Fuel>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('TABLE') table: ElementRef;

  constructor(
    private geocercaService: GeocercaService,
    private fleetsService: FleetService,
    private equipmentService: EquipmentService,
    private fuelService: FuelService) { }

  classifyPoint = require("robust-point-in-polygon")

  displayedColumns: string[] = ['date_start', 'date_end', 'duration', 'geocerca', 'kms', 'liters', 
  'horimetro', 'fleet', 'equipment', 'operator'];

  onDateChange(dateStart, dateEnd) {

    var start = new Date(dateStart);
    var end = new Date(dateEnd);

    if (!isNaN(+start) && !isNaN(+end))
      this.getListTime(start, end, this.equipment, this.fleet);
  }

  ngOnInit() {
    this.getEquipments();
    this.getFleets();
    this.getGeocercas();
    this.getList();

    var today = new Date();
    var yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    this.selectedMoments = [yesterday, today];

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  public selectedMoments = [
    new Date(),
    new Date()
  ];

  getList() {
    this.fuelService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(fuels => {
      this.fuels = fuels;
      this.setGeocerca();
    });
  }

  setGeocerca() {

    this.fuels.forEach(fuel => {

      this.geocercas.forEach(geocerca => {

        var arr = Array();
        geocerca.points.forEach(point => {
          arr.push([point.lat, point.lng])
        })

        if (this.classifyPoint(arr, [fuel.location.latitude, fuel.location.longitude]) == -1)
          fuel.geocerca = geocerca.name;
      });
    });

    this.updateTable();
  }

  clear() {
    this.getList();
  }

  getEquipments() {
    this.equipmentService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(equipments => {
      this.equipments = equipments;
    });
  }

  getFleets() {
    this.fleetsService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(fleets => {
      this.fleets = fleets;
    });
  }

  getListTime(start: Date, end: Date, equipment: string, fleet: string) {
    this.fuelService.getListByTime(start, end, equipment, fleet).snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(records => {
      this.fuels = records;
      this.setGeocerca();
    });
  }

  private updateTable() {
    this.dataSource = new MatTableDataSource(this.fuels);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  delete() {
    this.fuelService.deleteAll();
  }

  getDateEnd(date: firestore.Timestamp): firestore.Timestamp {
    if (date == null) return firestore.Timestamp.now();
    else return date;
  }

  getDuration(dateStart: firestore.Timestamp, dateEnd: firestore.Timestamp) {
    if (dateEnd == null) dateEnd = firestore.Timestamp.now();

    return this.getDataDiff(dateStart.toDate(), dateEnd.toDate());
  }

  getDataDiff(startDate, endDate) {
    var milisecondsDiff = endDate - startDate;
    return Math.floor(milisecondsDiff / (1000 * 60 * 60)).toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ":" + (Math.floor(milisecondsDiff / (1000 * 60)) % 60).toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ":" + (Math.floor(milisecondsDiff / 1000) % 60).toLocaleString(undefined, { minimumIntegerDigits: 2 });
  }

  getGeocercas() {
    this.geocercaService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(geocercas => {
      this.geocercas = geocercas;
    });
  }

  exportAsExcel() {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    XLSX.writeFile(wb, 'Abastecimento.xlsx');

  }
}
