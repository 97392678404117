<div *ngIf="profile" class="wrapper">

    <button [disableRipple]="true" class="bt" mat-mini-fab color="warn" type="button" (click)="delete()">
      <mat-icon>clear</mat-icon>
    </button>

    <button [disableRipple]="true" class="bt" mat-mini-fab type="button" (click)="edit()">
      <mat-icon>edit</mat-icon>
    </button>
  
    <div class="name">
      {{profile.name}}
    </div>
  
  </div>