import { Component, OnInit, Input } from '@angular/core';
import { Checklist } from '../checklist.model';
import { Router } from '@angular/router';
import { ChecklistService } from '../checklist.service';

@Component({
  selector: 'app-checklist-item',
  templateUrl: './checklist-item.component.html',
  styleUrls: ['./checklist-item.component.scss']
})
export class ChecklistItemComponent implements OnInit {

  @Input() checklist: Checklist;
 
  constructor(
    private router: Router,
    private checklistService: ChecklistService) { }
 
  ngOnInit() {
  }

  inactivate() {
    this.checklistService
      .update(this.checklist.id, { active: false })
      .catch(err => console.log(err));
  }
}
