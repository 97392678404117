<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="box">

    <mat-card-header>
      <mat-card-title>Adicionar operador</mat-card-title>
    </mat-card-header>

    <form [formGroup]="form" #myForm="ngForm" (ngSubmit)="onSubmit()" class="example-form">

      <mat-card-content>
        <mat-form-field class="example-full-width">
          <input matInput type="text" placeholder="Nome" [(ngModel)]="driver.name" formControlName="name" required>
          <mat-error *ngIf="isFieldInvalid('name')">
            Informe o nome do operador
          </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <input matInput type="text" placeholder="Matrícula" [(ngModel)]="driver.identifier"
            formControlName="identifier" required>
          <mat-error *ngIf="isFieldInvalid('identifier')">
            Informe a matrícula
          </mat-error>
        </mat-form-field>

        <mat-label>Frotas</mat-label>
        <span class="example-list-section">
          <li *ngFor="let fleet of fleets">
            <mat-checkbox #checkboxes type="checkbox" (change)="onChangeFleets(fleet.id, fleet.name, $event)">
              {{fleet.name}}
            </mat-checkbox>
          </li>
        </span>

      </mat-card-content>

      <button mat-raised-button color="primary" type="submit" class="btn-block">Adicionar</button>
    </form>
  </mat-card>
</div>