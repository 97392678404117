<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
    <mat-card class="box">
  
      <mat-card-header>
        <mat-card-title>{{title}}</mat-card-title>
      </mat-card-header>
  
      <form [formGroup]="form" #myForm="ngForm" (ngSubmit)="onSubmit()" class="example-form">
  
        <mat-card-content>
          <mat-form-field class="example-full-width">
            <input matInput type="text" placeholder="Nome" [(ngModel)]="user.displayName" formControlName="name" required>
            <mat-error *ngIf="isFieldInvalid('name')">
              Informe o nome
            </mat-error>
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <input matInput type="text" placeholder="Email" [(ngModel)]="user.email" formControlName="email" required>
            <mat-error *ngIf="isFieldInvalid('email')">
                Informe o email
              </mat-error>
          </mat-form-field>

          <mat-form-field class="example-full-width">
            <input matInput type="password" placeholder="Senha" [(ngModel)]="user.password" formControlName="password" required>
            <mat-error *ngIf="isFieldInvalid('password')">
              Informe a senha
            </mat-error>
          </mat-form-field>
  
        </mat-card-content>
  
        <button mat-raised-button color="primary" type="submit" class="btn-block">{{button}}</button>
      </form>
    </mat-card>
  </div>