import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Material } from './material.model';

@Injectable({
  providedIn: 'root'
})
export class MaterialService {

  private dbPath = '/materials';
 
  collection: AngularFirestoreCollection<Material> = null;
 
  constructor(private db: AngularFirestore) {
      var client = JSON.parse(localStorage.getItem('documentData')).client_id;
    this.collection = db.collection('clients').doc(client).collection(this.dbPath, ref => ref.where('active', '==', true));
  }
 
  create(material: Material): void {
    this.collection.add({...material});
  }
 
  update(key: string, value: any): Promise<void> {
    return this.collection.doc(key).update(value);
  }
 
  delete(key: string): Promise<void> {
    return this.collection.doc(key).delete();
  }
 
  getList(): AngularFirestoreCollection<Material> {
    return this.collection;
  }
 
  deleteAll() {
    this.collection.get().subscribe(
      querySnapshot => {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
        });
      },
      error => {
        console.log('Error: ', error);
      });
  }
}
