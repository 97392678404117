import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Profile } from 'src/app/profiles/profile.model';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    var profile: Profile = JSON.parse(localStorage.getItem('profile')) as Profile

    var authorized: boolean = true
 
    console.log("ROLE ----------------");
    console.log(profile);
    console.log(next.data.role);

    if (next.data.type == 'configs') {
      console.log(profile.configs.find((config) => config.key == next.data.role));
      const configs = profile.configs.find((config) => config.key == next.data.role);
      authorized = configs ? configs.value : authorized
    }

    if (next.data.type == 'reports') {
      console.log(profile.reports.find((report) => report.key == next.data.role));
      const reports = profile.reports.find((report) => report.key == next.data.role);
      authorized = reports ? reports.value : authorized
    } 

    return authorized;
  }

}
