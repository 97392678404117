<table style="width:100%">
    <td>
        <div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
            <mat-card class="box">

                <mat-card-header>
                    <mat-card-title>Adicionar geocerca</mat-card-title>
                </mat-card-header>

                <mat-card-content>
                    <button mat-raised-button type="submit" class="btn-block"
                        [routerLink]="['/geocerca-manual']">Adicionar manualmente</button>

                    <form [formGroup]="form" #myForm="ngForm" (ngSubmit)="onSubmit()" class="example-form">

                        <mat-form-field class="example-full-width">
                            <input matInput type="text" placeholder="Identificador" [(ngModel)]="geocerca.name"
                                formControlName="name" required>
                            <mat-error *ngIf="isFieldInvalid('name')">
                                Informe o identificador
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field class="example-full-width" style="width: 70%; margin-right: 20px;">
                            <input matInput type="text" placeholder="Cor" [(ngModel)]="geocerca.color" formControlName="color" required>
                            <mat-error *ngIf="isFieldInvalid('color')">
                              Informe a cor
                            </mat-error>
                          </mat-form-field>
                          <button class="color" mat-mini-fab [style.background-color]="'#' + geocerca.color" type="button"
                            (click)="changeState()">
                            <mat-icon>colorize</mat-icon>
                          </button>
                          <div class="example-full-width" fxLayoutAlign="end">
                            <color-github *ngIf="displayColor" [color]="'#' + geocerca.color" (onChange)="changeComplete($event)">
                            </color-github>
                          </div>

                          <mat-checkbox type="checkbox" [(ngModel)]="geocerca.load" [checked]="geocerca.load" formControlName="load" >
                            Habilitado para carga
                          </mat-checkbox>

                          <mat-checkbox type="checkbox" [(ngModel)]="geocerca.unload" [checked]="geocerca.unload" formControlName="unload">
                            Habilitado para descarga
                          </mat-checkbox>

                        <button mat-raised-button color="primary" type="submit" class="btn-block">Adicionar</button>
                        <button mat-raised-button color="warn" type="button" class="btn-block"
                            (click)="deleteSelectedShape()">Limpar</button>
                        <button mat-raised-button type="button" class="btn-block" (click)="toggle()">
                            {{buttonName}}
                        </button>

                    </form>
                </mat-card-content>
            </mat-card>
        </div>
    </td>
    <td *ngIf="show">
        <div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
            <mat-card class="box">

                <mat-card-header>
                    <mat-card-title>Pontos</mat-card-title>
                </mat-card-header>

                <mat-card-content>

                    <mat-list role="list">
                        <mat-list-item role="listitem" *ngFor="let point of pointList">
                            {{ point.lat.toPrecision(5) }},
                            {{ point.lng.toPrecision(5) }}
                        </mat-list-item>
                    </mat-list>

                    <mat-card *ngIf="selectedArea > 0">
                        Área selecionada: {{ selectedArea.toPrecision(5) }} m<sup>2</sup>
                    </mat-card>

                </mat-card-content>
            </mat-card>
        </div>
    </td>
    <td>
        <agm-map [zoom]="16" [latitude]="lat" [longitude]="lng" (mapReady)="onMapReady($event)" [fullscreenControl]='true'
        [mapTypeId]="'satellite'">
            <agm-polygon [paths]="geocerca.points" *ngFor="let geocerca of geocercas" [fillColor]="'#' + geocerca.color"
                [strokeColor]="'#' + geocerca.color">
            </agm-polygon>
        </agm-map>
    </td>
</table>