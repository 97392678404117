<body>

    <div class="login-wrapper" fxLayout="row wrap" fxLayout.lt-sm="column wrap" fxLayoutAlign="center center">

        <mat-form-field class="example-full-width mat-elevation-z7">
            <mat-label class="example-header">Equipamento</mat-label>
            <mat-select [(value)]="equipment" (selectionChange)="onDateChange(dateStart.value, dateEnd.value)">
                <mat-option [value]="'all'">Todos</mat-option>
                <mat-option *ngFor="let equipment of equipments" [value]="equipment.id">
                    {{equipment.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="example-full-width mat-elevation-z7">
            <mat-label class="example-header">Frota</mat-label>
            <mat-select [(value)]="fleet" (selectionChange)="onDateChange(dateStart.value, dateEnd.value)">
                <mat-option [value]="'all'">Todas</mat-option>
                <mat-option *ngFor="let fleet of fleets" [value]="fleet.id">
                    {{fleet.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="example-full-width mat-elevation-z7">
            <mat-label class="example-header">Início</mat-label>
            <input matInput #dateStart [owlDateTimeTrigger]="dt12" [owlDateTime]="dt12" [selectMode]="'rangeFrom'"
                [(ngModel)]="selectedMoments" (dateTimeInput)="onDateChange(dateStart.value, dateEnd.value)">
            <owl-date-time #dt12></owl-date-time>
        </mat-form-field>

        <mat-form-field class="example-full-width mat-elevation-z7">
            <mat-label class="example-header">Fim</mat-label>
            <input matInput #dateEnd [owlDateTimeTrigger]="dt13" [owlDateTime]="dt13" [selectMode]="'rangeTo'"
                [(ngModel)]="selectedMoments" (dateTimeInput)="onDateChange(dateStart.value, dateEnd.value)">
            <owl-date-time #dt13></owl-date-time>
        </mat-form-field>

        <button mat-raised-button color="basic" type="submit" (click)="clear()" class="btn-block">Limpar</button>
    </div>

    <mat-form-field class="example-fill-width mat-elevation-z3">
        <mat-label>Filtro</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="" #input>
    </mat-form-field>

    <div class="container mat-elevation-z8">
        <mat-table matTableExporter [dataSource]="data" #exporter="matTableExporter" matSort>

            <ng-container *ngFor="let c of displayedColumns" matColumnDef="{{c}}">
                <mat-header-cell *matHeaderCellDef mat-sort-header> {{c}} </mat-header-cell>
                <mat-cell *matCellDef="let element "> {{element[c]}}</mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>

        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100, 1000, 10000]" showFirstLastButtons></mat-paginator>
    </div>
    <button class="commands" mat-raised-button
        (click)="exporter.exportTable('xlsx', {fileName:'Excessos de velocidade', Props: {Author: 'MinePro'}})">Excel</button>
    <button class="commands" mat-raised-button
        (click)="exporter.exportTable('csv', {fileName:'Excessos de velocidade', Props: {Author: 'MinePro'}})">Csv</button>
</body>