export class Card {
    id: string;
    color: string;
    name: string;
    number: number;
    timeClass: number;
    subClass: number;
    active: boolean = true;
    fleet: string;
    fleetName: string;
}

export class CardIcon {
    color: string;
    icon: number;
    name: string;

    constructor(color: string, icon: number, name: string) {
        this.color = color
        this.icon = icon
        this.name = name
    }
}

export class CardsIcon {
    id: string;
    fleet: string;
    card1: CardIcon;
    card2: CardIcon;
    card3: CardIcon;
    card4: CardIcon;
    card5: CardIcon;
    card6: CardIcon;

    constructor(fleet: string) {
        this.fleet = fleet
        this.card1 = Object.assign({}, new CardIcon("ffffff", 27, "Card A")) 
        this.card2 = Object.assign({}, new CardIcon("ffffff", 27, "Card B"))
        this.card3 = Object.assign({}, new CardIcon("ffffff", 27, "Card C"))
        this.card4 = Object.assign({}, new CardIcon("ffffff", 27, "Card D"))
        this.card5 = Object.assign({}, new CardIcon("ffffff", 13, "Abastecer"))
        this.card6 = Object.assign({}, new CardIcon("ffffff", 12, "Trocar turno"))
    }
}