import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { UserService } from '../user.service';
import { User } from 'src/app/shared/models/user.interface';
import { AuthService } from 'src/app/auth/services/auth.service';
import { ProfileService } from 'src/app/profiles/profile.service';
import { Profile } from 'src/app/profiles/profile.model';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  users: User[];
  profiles: Profile[]
  userUid: string;

  constructor(
    private userService: UserService,
    private profileService: ProfileService,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.getList();
    this.getProfiles();
    this.userUid = this.authService.getUser.uid
  }

  getList() {
    this.userService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(users => {
      users = users.filter(user => user.uid != this.userUid)
      this.users = users;
    });
  }

  getProfiles() {
    this.profileService.getList(false).snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(profiles => {

      profiles.push(
        new Profile("admin", "Administrador"),
        new Profile("manager", "Gestor"),
        new Profile("planning", "Planejamento"),
        new Profile("user", "Usuário"),
        new Profile("maintenance", "Manutenção")
      )

      profiles.sort((a, b) => (a.name > b.name) ? 1 : -1)
      this.profiles = profiles;
    });
  }

}
