<h1 mat-dialog-title>Adicionar Item</h1>
<div mat-dialog-content>
    <mat-form-field>
        <mat-label>Nome</mat-label>
        <input matInput [(ngModel)]="checklist.name">
    </mat-form-field>
    <mat-checkbox [(ngModel)]="checklist.critical">
        Crítico
    </mat-checkbox>

    <mat-form-field class="example-full-width">
        <mat-label>Frota</mat-label>
        <mat-select [(value)]="checklist.fleet" [(ngModel)]="checklist.fleet">
          <mat-option *ngFor="let fleet of data.fleets" [value]="fleet.id">
            {{fleet.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancelar</button>
    <button mat-button (click)="save()" cdkFocusInitial>Salvar</button>
</div>