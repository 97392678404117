import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import { CardNameDialogComponent } from 'src/app/cards/card-name-dialog/card-name-dialog.component';
import { ChartDash } from '../chart.model';
import { ChartService } from '../chart.service';

export interface DialogChartModelData {
  models: ChartDash[];
  format: number;
}

@Component({
  selector: 'app-chart-list',
  templateUrl: './chart-list.component.html',
  styleUrls: ['./chart-list.component.scss']
})
export class ChartListComponent implements OnInit {

  charts: ChartDash[];
  models: ChartDash[];

  constructor(
    private dialog: MatDialog,
    private chartService: ChartService
  ) { }

  ngOnInit(): void {
    this.getList();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(CardNameDialogComponent, {
      width: '350px',
      data: { models: this.models }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    });
  }

  getList() {
    this.chartService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(charts => {
      console.log(charts);

      this.charts = charts;

      let add: ChartDash = new ChartDash();
      add.id = "add";
      add.title = "Adicionar";
      this.charts.push(add);
    });
  }

  getModels() {
    this.chartService.getModels().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(models => {
      console.log(models);

      this.models = models;
    });
  }

}
