import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { UserService } from '../user.service';
import { User } from 'src/app/shared/models/user.interface';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {

  users: User[];
  disabled: User[];
  userUid: string;

  constructor(
    private userService: UserService,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.getList();
    this.userUid = this.authService.getUser.uid
  }

  getList() {
    this.userService.getAll().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(users => {
      users = users.filter(user => user.uid != this.userUid)
      this.users = users.filter(user => user.enabled == true);
      this.disabled = users.filter(user => user.enabled == false);
    });
  }

}
