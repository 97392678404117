<form [formGroup]="form" #myForm="ngForm" (ngSubmit)="onSubmit()" class="example-form">

    <table style="width:100%">
        <td>
            <div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
                <mat-card class="box">

                    <mat-card-header>
                        <mat-card-title>Filtros</mat-card-title>
                    </mat-card-header>

                    <mat-card-content>

                        <!-- FROTA -->
                        <mat-form-field class="example-full-width">
                            <mat-label>Frota</mat-label>
                            <mat-select [(value)]="chart.fleet_name" [(ngModel)]="chart.fleet_name"
                                formControlName="fleet" (selectionChange)="onFleetChange($event.value)">
                                <mat-option [value]="'all'">Todos</mat-option>
                                <mat-option *ngFor="let fleet of fleets" [value]="fleet.name">
                                    {{fleet.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <!-- EQUIPAMENTO -->
                        <mat-form-field class="example-full-width" *ngIf="isProd">
                            <mat-label>Equipamento</mat-label>
                            <mat-select [(value)]="chart.equipment_name" [(ngModel)]="chart.equipment_name"
                                formControlName="equipment" (selectionChange)="onEquipmentChange($event.value)">
                                <mat-option [value]="'all'">Todos</mat-option>
                                <mat-option *ngFor="let equipment of equipments" [value]="equipment.name">
                                    {{equipment.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <!-- MATERIAIS -->
                        <mat-form-field class="example-full-width" *ngIf="isProd">
                            <mat-label>Material</mat-label>
                            <mat-select [(value)]="chart.material_name" [(ngModel)]="chart.material_name"
                                formControlName="material" (selectionChange)="onMaterialChange($event.value)">
                                <mat-option [value]="'all'">Todos</mat-option>
                                <mat-option *ngFor="let material of materials" [value]="material.name">
                                    {{material.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <!-- UNIDADE -->
                        <mat-form-field class="example-full-width" *ngIf="isProd">
                            <mat-label>Unidade</mat-label>
                            <mat-select [(value)]="chart.unit" [(ngModel)]="chart.unit" formControlName="unit">
                                <mat-option *ngFor="let unit of units" [value]="unit.value">
                                    {{unit.label}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="isFieldInvalid('unit')">
                                Informe a unidade
                            </mat-error>
                        </mat-form-field>

                        <!-- INTERVALO -->
                        <mat-form-field class="example-full-width">
                            <mat-label>Período</mat-label>
                            <mat-select [(value)]="chart.interval" [(ngModel)]="chart.interval"
                                formControlName="interval" (selectionChange)="onIntervalChange(chart.interval)"
                                required>
                                <mat-option *ngFor="let interval of intervals" [value]="interval.value">
                                    {{interval.label}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="isFieldInvalid('interval')">
                                Informe o período
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field *ngIf="isPersonalized" class="example-full-width">
                            <input matInput [max]="max" [owlDateTime]="dt1" [selectMode]="'range'"
                                [owlDateTimeTrigger]="dt1" placeholder="Início - Fim" [(ngModel)]="chart.interval_date"
                                formControlName="interval_date">
                            <owl-date-time #dt1></owl-date-time>
                        </mat-form-field>

                        
                        <!-- META -->
                        <mat-form-field class="example-full-width">
                            <input matInput type="number" placeholder="Meta %" [(ngModel)]="chart.goal"
                                formControlName="goal">
                        </mat-form-field>

                        <!-- POSITIVE TOLERANCE -->
                        <mat-form-field class="example-full-width">
                            <input matInput type="number" placeholder="Tolerância positiva %"
                                [(ngModel)]="chart.positive_tolerance" formControlName="positive_tolerance">
                        </mat-form-field>

                        <!-- NEGATIVE TOLERANCE -->
                        <mat-form-field class="example-full-width">
                            <input matInput type="number" placeholder="Tolerância negativa %"
                                [(ngModel)]="chart.negative_tolerance" formControlName="negative_tolerance">
                        </mat-form-field>

                    </mat-card-content>
                </mat-card>
            </div>
        </td>
        <td>
            <div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
                <mat-card class="box">

                    <mat-card-header>
                        <mat-card-title>Configurações</mat-card-title>
                    </mat-card-header>

                    <mat-card-content>

                        <!-- TÍTULO -->
                        <mat-checkbox [(ngModel)]="chart.use_title" formControlName="use_title">Título padrão
                        </mat-checkbox>

                        <mat-form-field class="example-full-width">
                            <input matInput [attr.disabled]="chart.use_title? true : null" type="text"
                                placeholder="Titulo" [(ngModel)]="chart.title" formControlName="title">
                        </mat-form-field>

                        <!-- COR ABAIXO DA META -->
                        <mat-form-field class="example-full-width" style="width: 70%; margin-right: 20px;">
                            <input matInput type="text" placeholder="Abaixo da meta"
                                [(ngModel)]="chart.below_goal_color" formControlName="below_goal_color" required>
                            <mat-error *ngIf="isFieldInvalid('below_goal_color')">
                                Informe a cor
                            </mat-error>
                        </mat-form-field>
                        <button class="color" mat-mini-fab [style.background-color]="'#' + chart.below_goal_color"
                            type="button" (click)="changeState(1)">
                            <mat-icon>colorize</mat-icon>
                        </button>
                        <div class="example-full-width" fxLayoutAlign="end">
                            <color-github *ngIf="displayBelowColor" [color]="'#' + chart.below_goal_color"
                                (onChange)="changeComplete($event, 1)">
                            </color-github>
                        </div>

                        <!-- COR DENTRO DA META -->
                        <mat-form-field class="example-full-width" style="width: 70%; margin-right: 20px;">
                            <input matInput type="text" placeholder="Dentro da tolerância"
                                [(ngModel)]="chart.within_goal_color" formControlName="within_goal_color" required>
                            <mat-error *ngIf="isFieldInvalid('within_goal_color')">
                                Informe a cor
                            </mat-error>
                        </mat-form-field>
                        <button class="color" mat-mini-fab [style.background-color]="'#' + chart.within_goal_color"
                            type="button" (click)="changeState(2)">
                            <mat-icon>colorize</mat-icon>
                        </button>
                        <div class="example-full-width" fxLayoutAlign="end">
                            <color-github *ngIf="displayWithinColor" [color]="'#' + chart.within_goal_color"
                                (onChange)="changeComplete($event, 2)">
                            </color-github>
                        </div>

                        <!-- COR ACIMA DA META -->
                        <mat-form-field class="example-full-width" style="width: 70%; margin-right: 20px;">
                            <input matInput type="text" placeholder="Acima da meta" [(ngModel)]="chart.above_goal_color"
                                formControlName="above_goal_color" required>
                            <mat-error *ngIf="isFieldInvalid('above_goal_color')">
                                Informe a cor
                            </mat-error>
                        </mat-form-field>
                        <button class="color" mat-mini-fab [style.background-color]="'#' + chart.above_goal_color"
                            type="button" (click)="changeState(3)">
                            <mat-icon>colorize</mat-icon>
                        </button>
                        <div class="example-full-width" fxLayoutAlign="end">
                            <color-github *ngIf="displayAboveColor" [color]="'#' + chart.above_goal_color"
                                (onChange)="changeComplete($event, 3)">
                            </color-github>
                        </div>

                        <button mat-raised-button color="primary" type="submit" class="btn-block">Salvar</button>

                    </mat-card-content>
                </mat-card>
            </div>
        </td>
    </table>
</form>