import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import { TripService } from '../trip.service';
import { map } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Trip } from '../trip.model';
import { firestore } from 'firebase';
import { EquipmentService } from 'src/app/equipments/equipment.service';
import { FleetService } from 'src/app/fleets/fleet.service';
import { Geocerca } from 'src/app/geocercas/geocerca.model';
import { GeocercaService } from 'src/app/geocercas/geocerca.service';
import { RecordService } from 'src/app/records/record.service';
import { Location } from 'src/app/equipments/location.model';
import { MatDialog } from '@angular/material/dialog';
import { TripDialogComponent } from '../trip-dialog/trip-dialog.component';
/// <reference types="@types/googlemaps" />

export interface DialogData {
  trip: Trip;
  locations: any;
  geocercas: Geocerca[];
}

declare var google: any;

@Component({
  selector: 'app-trips-list',
  templateUrl: './trips-list.component.html',
  styleUrls: ['./trips-list.component.scss']
})
export class TripsListComponent implements OnInit {

  trip: Trip;

  locations: Location[];
  geocercas: Geocerca[];
  trips: Trip[];
  equipment: string = "all";
  equipments: any;
  fleets: any;
  fleet: string = "all";
  dataSource: MatTableDataSource<Trip>;
  totalLoad: number = 0;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns: string[] = ['date_start', 'geocerca_start', 'date_end', 'geocerca_end',
    'duration', 'fleet', 'load', 'equipment', 'equipment_load', 'material',
    'operator', 'vmc', 'vmv', 'peak', 'dmt_full', 'dmt_empty', 'status', 'map'];

  onDateChange(dateStart, dateEnd) {

    var start = new Date(dateStart);
    var end = new Date(dateEnd);

    if (!isNaN(+start) && !isNaN(+end))
      this.getListTime(start, end, this.equipment, this.fleet);
  }

  ngOnInit() {
    this.getEquipments();
    this.getFleets();
    this.getGeocercas();
    this.getList();

    var today = new Date();
    var yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    this.selectedMoments = [yesterday, today];

  }

  constructor(
    public dialog: MatDialog,
    private geocercaService: GeocercaService,
    private fleetsService: FleetService,
    private equipmentService: EquipmentService,
    private recordService: RecordService,
    private tripService: TripService) { }

  classifyPoint = require("robust-point-in-polygon")

  openDialog(): void {
    const dialogRef = this.dialog.open(TripDialogComponent, {
      width: '900px',
      height: '600px',
      data: {
        trip: this.trip,
        locations: this.locations,
        geocercas: this.geocercas
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  openMap(trip: Trip) {
    this.trip = trip;
    this.getTripList(trip.date_start.toDate(), trip.date_end ? trip.date_end.toDate() : new Date(), trip.equipment_id);
  }

  public selectedMoments = [
    new Date(),
    new Date()
  ];

  clear() {
    this.getList();
  }

  setGeocerca() {

    this.totalLoad = 0;
    this.trips.forEach(async trip => {

      if (trip.fleet_class == 1) this.totalLoad += trip.load;

      this.geocercas.forEach(geocerca => {

        var arr = Array();
        geocerca.points.forEach(point => {
          arr.push([point.lat, point.lng])
        })

        if (this.classifyPoint(arr, [trip.location_start.latitude, trip.location_start.longitude]) == -1)
          trip.geocerca_start = geocerca.name;

        if (trip.location_end)
          if (this.classifyPoint(arr, [trip.location_end.latitude, trip.location_end.longitude]) == -1)
            trip.geocerca_end = geocerca.name;
      });

      if (!trip.geocerca_start) trip.geocerca_start = trip.location_start.latitude + ', ' + trip.location_start.longitude;
      if (!trip.geocerca_end) trip.geocerca_end = trip.location_end.latitude + ', ' + trip.location_end.longitude;
    });

    this.totalLoad /= 1000;

    this.updateTable();
  }

  getRecordListStatus(start: Date, end: Date, equipment: string, status: string) {

    return this.recordService.getListByStatus(start, end, equipment, status).snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    )
  }

  getListLocationTime(start: Date, end: Date, uid: string) {
    return this.equipmentService.getListByTime(start, end, uid).snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    );
  }

  getList() {
    let sub = this.tripService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(trips => {
      sub.unsubscribe();
      this.trips = trips;
      this.setGeocerca();
    });
  }

  private updateTable() {
    this.dataSource = new MatTableDataSource(this.trips);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getListTime(start: Date, end: Date, equipment: string, fleet: string) {
    let sub = this.tripService.getListByTime(start, end, equipment, fleet).snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(trips => {
      sub.unsubscribe();
      this.trips = trips;
      this.setGeocerca();
    });
  }

  delete() {
    this.tripService.deleteAll();
  }

  getTripList(start: Date, end: Date, uid: string) {
    let sub = this.equipmentService.getListByTime(start, end, uid).snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(locations => {
      this.locations = locations;
      sub.unsubscribe();
      this.openDialog();
    });
  }

  getEquipments() {
    let sub = this.equipmentService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(equipments => {
      sub.unsubscribe();
      this.equipments = equipments;
    });
  }

  getFleets() {
    let sub = this.fleetsService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(fleets => {
      sub.unsubscribe();
      this.fleets = fleets;
    });
  }

  getDateEnd(date: firestore.Timestamp): firestore.Timestamp {
    if (date == null) return firestore.Timestamp.now();
    else return date;
  }

  getDuration(dateStart: firestore.Timestamp, dateEnd: firestore.Timestamp) {
    if (dateEnd == null) dateEnd = firestore.Timestamp.now();

    return this.getDataDiff(dateStart.toDate(), dateEnd.toDate());
  }

  getDMT(dmt: number) : string {
    return dmt ? Number(dmt / 1000).toFixed(2) : "-"
  }

  getDataDiff(startDate, endDate) {
    var milisecondsDiff = endDate - startDate;
    return Math.floor(milisecondsDiff / (1000 * 60 * 60)).toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ":" + (Math.floor(milisecondsDiff / (1000 * 60)) % 60).toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ":" + (Math.floor(milisecondsDiff / 1000) % 60).toLocaleString(undefined, { minimumIntegerDigits: 2 });
  }

  getGeocercas() {
    let sub = this.geocercaService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(geocercas => {
      sub.unsubscribe();
      this.geocercas = geocercas;
    });
  }
}