<body>
  
    <div class="mat-elevation-z8" style="margin-bottom: 50px;" #TABLE>
      <table mat-table [dataSource]="dataSource" matSort>
  
        <ng-container matColumnDef="date_start">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Início </th>
          <td mat-cell *matCellDef="let row"> {{row.date_start.toDate() | date:'dd/MM/yyyy - HH:mm:ss'}} </td>
        </ng-container>
  
        <ng-container matColumnDef="geocerca_start">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Geocerca </th>
          <td mat-cell *matCellDef="let row"> {{row.geocerca_start}} </td>
        </ng-container>
  
        <ng-container matColumnDef="date_end">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Fim </th>
          <td mat-cell *matCellDef="let row"> {{getDateEnd(row.date_end).toDate() | date:'dd/MM/yyyy - HH:mm:ss'}} </td>
        </ng-container>
  
        <ng-container matColumnDef="geocerca_end">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Geocerca </th>
          <td mat-cell *matCellDef="let row"> {{row.geocerca_end}} </td>
        </ng-container>
  
        <ng-container matColumnDef="duration">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Duração </th>
          <td mat-cell *matCellDef="let row"> {{getDuration(row.date_start, row.date_end)}} </td>
        </ng-container>
  
        <ng-container matColumnDef="fleet">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Frota </th>
          <td mat-cell *matCellDef="let row"> {{row.fleet}} </td>
        </ng-container>
  
        <ng-container matColumnDef="load">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Massa </th>
          <td mat-cell *matCellDef="let row"> {{row.load}} </td>
        </ng-container>
  
        <ng-container matColumnDef="equipment">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Equipamento </th>
          <td mat-cell *matCellDef="let row"> {{row.equipment}} </td>
        </ng-container>
  
        <ng-container matColumnDef="equipment_load">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Equipamento de carga</th>
          <td mat-cell *matCellDef="let row"> {{row.equipment_load}} </td>
        </ng-container>
  
        <ng-container matColumnDef="material">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Material </th>
          <td mat-cell *matCellDef="let row"> {{row.material}} </td>
        </ng-container>
  
        <ng-container matColumnDef="operator">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Operador </th>
          <td mat-cell *matCellDef="let row"> {{row.operator}}</td>
        </ng-container>
  
        <ng-container matColumnDef="vmc">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> VMC (Km/h) </th>
          <td mat-cell *matCellDef="let row"> {{row.vmc}}</td>
        </ng-container>
  
        <ng-container matColumnDef="vmv">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> VMV (Km/h) </th>
          <td mat-cell *matCellDef="let row"> {{row.vmv}}</td>
        </ng-container>
  
        <ng-container matColumnDef="peak">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Pico (Km/h) </th>
          <td mat-cell *matCellDef="let row"> {{row.peak}} </td>
        </ng-container>
  
        <ng-container matColumnDef="dmt_full">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> DMT Cheio (km) </th>
          <td mat-cell *matCellDef="let row"> {{getDMT(row.dmt_full)}} </td>
        </ng-container>
        
        <ng-container matColumnDef="dmt_empty">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> DMT Vazio (km) </th>
          <td mat-cell *matCellDef="let row"> {{getDMT(row.dmt_empty)}} </td>
        </ng-container>
  
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
          <td mat-cell *matCellDef="let row"> {{row.status}} </td>
        </ng-container>
  
        <ng-container matColumnDef="map">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Ações </th>
          <td mat-cell *matCellDef="let row">
            <button mat-icon-button (click)="openMap(row)">
              <mat-icon>map</mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="filter">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Filtros </th>
          <td mat-cell *matCellDef="let row">
            <button mat-icon-button (click)="openDialogReasons(row)">
              <mat-icon>filter_alt</mat-icon>
            </button>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Editar </th>
          <td mat-cell *matCellDef="let row">
            <button mat-icon-button>
              <mat-icon>description</mat-icon>
            </button>
          </td>
        </ng-container>
  
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Selecionar </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox type="checkbox" (change)="onChangeSelect(row, $event)"></mat-checkbox>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>
  
      <mat-paginator [pageSizeOptions]="[10, 25, 100, 1000, 10000]"></mat-paginator>
    </div>
  </body>