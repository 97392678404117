import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { ChartDash } from './chart.model';
@Injectable({
  providedIn: 'root'
})
export class ChartService {
  private dbPath = '/charts';

  collection: AngularFirestoreCollection<ChartDash> = null;
  collectionFleet: AngularFirestoreCollection<ChartDash> = null;
  models: AngularFirestoreCollection<ChartDash> = null;

  constructor(db: AngularFirestore) {
    var client = JSON.parse(localStorage.getItem('documentData')).client_id;
    this.collection = db.collection('clients').doc(client).collection(this.dbPath);
    this.collectionFleet = db.collection('clients').doc(client).collection(this.dbPath);
    this.models = db.collection('charts');
  }

  create(chart: ChartDash): void {
    this.collection.add({ ...chart });
  }

  update(key: string, value: any): Promise<void> {
    return this.collection.doc(key).update(value);
  }

  getChart(key: string): AngularFirestoreDocument<ChartDash> {
    return this.collection.doc(key);
  }

  set(chart: ChartDash): Promise<void> {
    return this.collection.doc(chart.id).set(chart);
  }

  delete(key: string): Promise<void> {
    return this.collection.doc(key).delete();
  }

  getList(): AngularFirestoreCollection<ChartDash> {
    return this.collection;
  }

  getModels(): AngularFirestoreCollection<ChartDash> {
    return this.models;
  }
}
