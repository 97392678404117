<div class="list">
    <div class="wrapper">
        <div class="name">
            Nome
        </div>
    </div>

    <div *ngFor="let profile of profiles">
        <app-profile-item [profile]='profile'></app-profile-item>
    </div>

    <button mat-mini-fab color="primary" type="button" [routerLink]="['/profile-create']">
        <mat-icon>add</mat-icon>
    </button>
</div>