<div class="map-layout">
  <agm-map (mapReady)="mapReady($event)" [latitude]="lat" [longitude]="lng" [zoom]="16" [fullscreenControl]='true'
    [mapTypeControl]='true' [mapTypeId]="'satellite'">

    <agm-polygon [paths]="geocerca.points" *ngFor="let geocerca of geocercas" [fillColor]="'#' + geocerca.color"
      [strokeColor]="'#' + geocerca.color" (polyClick)="polyClick(geocerca, infoWindow)">

    </agm-polygon>
    <agm-marker [latitude]="lat" [longitude]="lng" [visible]="false">
      <agm-info-window #infoWindow [disableAutoPan]="false">
        <div>
          {{infoWindowData.name}}
        </div>
      </agm-info-window>
    </agm-marker>

    <agm-marker *ngFor="let point of points; trackBy:trackByUid" [latitude]="point.lat" [longitude]="point.lng"
      [iconUrl]="getIcon(point.fleet_class, point.status_code)">
      <agm-info-window>
        <div>
          <h2>{{point.equipment_name}}</h2>
          <p>Operador: {{point.driver}}</p>
          <p>Status: {{point.status}}</p>
          <p>Frota: {{point.fleet_name}}</p>
        </div>
      </agm-info-window>
    </agm-marker>
  </agm-map>
</div>