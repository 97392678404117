<mat-grid-list [cols]="breakpoint" rowHeight="2:1.5" (window:resize)="onResize($event)" class="center">

    <mat-card class="box" *ngFor="let board of boards">
        <div style="text-align:center;">
            <mat-card-subtitle>{{board.title}}</mat-card-subtitle>
            <mat-card-title [ngStyle]="{'color':board.color}">{{board.value}}</mat-card-title>
        </div>
    </mat-card>

</mat-grid-list>

<mat-grid-list [cols]="breakpoint" rowHeight="2:1.5" (window:resize)="onResize($event)" class="center">

    <mat-card class="box" *ngFor="let graph of graphs">
        <div [chart]="graph"></div>
    </mat-card>

</mat-grid-list>