<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">

  <mat-card class="box">

    <mat-card-header>
      <mat-card-title>Cadastrar</mat-card-title>
    </mat-card-header>

    <form [formGroup]="registerForm" (ngSubmit)="onRegister()" class="example-form">
      <mat-card-content>
        <mat-form-field class="example-full-width">
          <input matInput type="email" placeholder="Email" formControlName="email" required>
          <mat-error *ngIf="isFieldInvalid('email')">
            Por favor informe o email
          </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <input matInput type="password" placeholder="Senha" formControlName="password" required>
          <mat-error *ngIf="isFieldInvalid('password')">
            Por favor informe a senha
          </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <input matInput type="name" placeholder="Nome do usuário" formControlName="name" required>
          <mat-error *ngIf="isFieldInvalid('name')">
            Por favor informe o nome do usuário
          </mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <input matInput type="client" placeholder="Nome do cliente" formControlName="client" required>
          <mat-error *ngIf="isFieldInvalid('client')">
            Por favor informe o cliente
          </mat-error>
        </mat-form-field>
      </mat-card-content>

      <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
      <button mat-raised-button color="primary" type="submit" class="btn-block">Cadastrar</button>
    </form>

    <mat-card-bottom>
      <div>
        <p class="bottom">
          Já possui conta? <a [routerLink]="['/login']"> Entrar </a>
        </p>
      </div>
    </mat-card-bottom>
  </mat-card>
</div>