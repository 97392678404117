import { Component, OnInit, ViewChild } from '@angular/core';
import { Material } from '../material.model';
import { MaterialService } from '../material.service';
import { Router } from '@angular/router';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { NgForm, FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ColorEvent } from 'ngx-color';

@Component({
  selector: 'app-material-update',
  templateUrl: './material-update.component.html',
  styleUrls: ['./material-update.component.scss']
})
export class MaterialUpdateComponent implements OnInit {

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';

  material: Material = new Material();

  @ViewChild('myForm') private myForm: NgForm;

  private formSubmitAttempt: boolean;

  form = new FormGroup({
    name: new FormControl(''),
    color: new FormControl('')
  });

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private snackBar: MatSnackBar,
    private materialService: MaterialService) { }

  ngOnInit() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      color: ['', Validators.required]
    });

    this.material = JSON.parse(window.localStorage.getItem("editMaterialObj"));
    if (!this.material) {
      this.openSnackBar('Ação inválida.')
      this.router.navigate(['materials']);
      return;
    }
  }

  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitAttempt)
    );
  }

  new(): void {
    this.material = new Material();
  }

  save() {
    delete this.material.id;
    this.materialService.create(this.material);
    this.myForm.resetForm();
    this.material = new Material();
  }

  inactivate() {
    this.materialService.update(this.material.id, { active: false })
      .catch(err => console.log(err));
  }

  onSubmit() {
    if (this.form.valid) {
      this.inactivate();
      this.save();
      this.openSnackBar('Material atualizado')
      this.router.navigate(['materials']);
    } else this.openSnackBar("Preencha todos os campos");
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, "Fechar", {
      duration: 2000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
  }

  ngOnDestroy() {
    window.localStorage.removeItem("editMaterialObj");
  }
      
  displayColor: boolean = false;

  changeState(){
    this.displayColor = !this.displayColor;
  }

  changeComplete($event: ColorEvent) {
    this.displayColor = false;

    var color = $event.color.hex.replace(/#/i, '');

    this.material.color = color;
  }
}
