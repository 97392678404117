import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Driver } from './driver.model';

@Injectable({
  providedIn: 'root'
})
export class DriverService {

  private dbPath = '/drivers';

  collection: AngularFirestoreCollection<Driver> = null;

  constructor(private db: AngularFirestore) {
    var client = JSON.parse(localStorage.getItem('documentData')).client_id;
    this.collection = db.collection('clients').doc(client).collection(this.dbPath, ref => ref.where('active', '==', true));
  }

  create(driver: Driver): void {
    this.collection.add({ ...driver });
  }

  update(key: string, value: any): Promise<void> {
    return this.collection.doc(key).update(value);
  }

  delete(key: string): Promise<void> {
    return this.collection.doc(key).delete();
  }

  getList(): AngularFirestoreCollection<Driver> {
    return this.collection;
  }

  getDriver(key: string): AngularFirestoreDocument<Driver> {
    return this.collection.doc(key);
  }

  deleteAll() {
    this.collection.get().subscribe(
      querySnapshot => {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
        });
      },
      error => {
        console.log('Error: ', error);
      });
  }
}
