import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Profile } from '../profile.model';
import { ProfileService } from '../profile.service';

@Component({
  selector: 'app-profiles-list',
  templateUrl: './profiles-list.component.html',
  styleUrls: ['./profiles-list.component.scss']
})
export class ProfilesListComponent implements OnInit {

  profiles: Profile[];

  constructor(
    public dialog: MatDialog,
    private profileService: ProfileService
  ) { }

  ngOnInit() {
    this.getList();
  }

  getList() {
    this.profileService.getList(true).snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(profiles => {
      this.profiles = profiles;
    });
  }

}
