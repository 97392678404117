import { firestore } from 'firebase';

export class Trip {
    id: string;
    date_start: firestore.Timestamp;
    date_end: firestore.Timestamp;
    date_restart: firestore.Timestamp;
    geocerca_start: string;
    geocerca_end: string;
    device_id: string;
    equipment: string;
    equipment_id: string;
    equipment_load: string;
    equipment_load_id: string;
    fleet: string;
    fleet_id: string;
    fleet_class: number;
    load: number;
    location_start: firestore.GeoPoint;
    location_end: firestore.GeoPoint;
    material: string;
    operator: string;
    operator_id: string;
    vmc: number;
    vmv: number;
    peak: number;
    dmt_empty: number;
    dmt_full: number;
    status: string;
    contingency: boolean;
    contingency_reasons: string[];
}
