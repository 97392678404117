import { Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Geocerca } from './geocerca.model';

@Injectable({
  providedIn: 'root'
})
export class GeocercaService {
  private dbPath = '/geocerca';
  client: string;

  collection: AngularFirestoreCollection<Geocerca> = null;

  constructor(private db: AngularFirestore) {
    this.client = JSON.parse(localStorage.getItem('documentData')).client_id;
    this.collection = db.collection('clients').doc(this.client).collection(this.dbPath, ref => ref.where('active', '==', true));
  }

  create(customer: Geocerca): void {
    this.collection.add({ ...customer });
  }

  update(key: string, value: any): Promise<void> {
    return this.collection.doc(key).update(value);
  }

  delete(key: string): Promise<void> {
    return this.collection.doc(key).delete();
  }

  getList(): AngularFirestoreCollection<Geocerca> {
    return this.collection;
  }

  getGeocerca(key: string): AngularFirestoreDocument<Geocerca>{
     return this.collection.doc(key);
  }
}
