<h1 mat-dialog-title>{{data.equipment.name}}</h1>

<div mat-dialog-content>

  <p>{{data.driver ? data.driver.name : 'Sem operador'}}</p>
  <p>Status: {{data.equipment.status}}</p>
  <p>Localização: {{data.trip.geocerca_start}}</p>
  <br>

  <mat-form-field appearance="fill">
    <mat-label>Mensagem</mat-label>
    <textarea [(ngModel)]="message.message" matInput maxlength="150" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
      cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea>
  </mat-form-field>

  <mat-checkbox [(ngModel)]="message.hasActions">
    Solicitar resposta
  </mat-checkbox>

  <mat-form-field class="equipment">
    <mat-label>Mudar máquina</mat-label>
    <mat-select [(value)]="message.loadUID">
      <mat-option [value]="''">Selecione</mat-option>
      <mat-option *ngFor="let equipment of data.equipments" [value]="equipment.id">
        {{equipment.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

</div>

<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Fechar</button>
  <button mat-button (click)="closeDialog()">Enviar</button>
</div>