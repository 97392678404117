import { Component, OnInit } from '@angular/core';
import { MaterialService } from '../material.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-materials-list',
  templateUrl: './materials-list.component.html',
  styleUrls: ['./materials-list.component.scss']
})
export class MaterialsListComponent implements OnInit {

  materials: any;
  breakpoint: number = 1;
 
  constructor(private materialService: MaterialService) { }
 
  ngOnInit() {
    this.getList();

    if (window.innerWidth > 1600) this.breakpoint = 4;
    if (window.innerWidth > 1200) this.breakpoint = 3;
    if (window.innerWidth > 800) this.breakpoint = 2;
    if (window.innerWidth > 400) this.breakpoint = 1;

    console.log(window.innerWidth);
    console.log(this.breakpoint);
  
  }

  onResize(event) {
    if(event.target.innerWidth > 1600) this.breakpoint = 4;
    if(event.target.innerWidth > 1200) this.breakpoint = 3;
    if(event.target.innerWidth > 800) this.breakpoint = 2;
    if(event.target.innerWidth > 400) this.breakpoint = 1;
  }
 
  getList() {
    this.materialService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(materials => {
      this.materials = materials;
    });
  }
 
  delete() {
    this.materialService.deleteAll();
  }
}
