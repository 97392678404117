import { AfterViewInit, Component } from '@angular/core';
import { map, subscribeOn, take } from 'rxjs/operators';
import { EquipmentService } from 'src/app/equipments/equipment.service';
import { GeocercaService } from 'src/app/geocercas/geocerca.service';
import { MapService } from '../map.service';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements AfterViewInit {

  map: any;
  lat: number;
  lng: number;
  equipment: string;

  showGeocercas: boolean = true;
  showGeocercasText: string = "Ocultar geocercas";

  points: any;
  locations: any;
  equipments: any;
  geocercas: any;

  constructor(
    private geocercaService: GeocercaService,
    private equipmentService: EquipmentService,
    private mapService: MapService) { }

  ngAfterViewInit(): void {
    this.getList();
    this.getEquipments();
    this.getGeocercas();

    var today = new Date();
    var yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    this.selectedMoments = [yesterday, today];
  }

  mapReady(map) {
    this.map = map;
  }

  public selectedMoments = [
    new Date(),
    new Date()
  ];

  onDateChange(dateStart, dateEnd) {

    var start = new Date(dateStart);
    var end = new Date(dateEnd);

    if (!isNaN(+start) && !isNaN(+end))
      this.getListTime(start, end, this.equipment);
  }

  private getList() {
    let sub = this.mapService.getList()
      .valueChanges()
      .subscribe(points => {
        sub.unsubscribe();
        this.points = points;
        console.log(points);
      })
  }

  getListTime(start: Date, end: Date, uid: string) {
    let sub = this.equipmentService.getListByTime(start, end, uid).snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(locations => {
      sub.unsubscribe();
      this.locations = locations;
      console.log(locations);
    });
  }

  private centerMap() {


    var boundsAll = new google.maps.LatLngBounds();

    this.geocercas.forEach(geocerca => {
      var bounds = new google.maps.LatLngBounds();

      geocerca.points.forEach(point => bounds.extend(point))

      boundsAll.extend(bounds.getCenter())
    })

    this.lat = boundsAll.getCenter().lat();
    this.lng = boundsAll.getCenter().lng();

    console.log(" --- " + this.lat + "-" + this.lng);


    this.map.setCenter(boundsAll.getCenter());
    this.map.fitBounds(boundsAll);
  }

  getEquipments() {
    this.equipmentService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(equipments => {
      this.equipments = equipments;
    });
  }

  getGeocercas() {
    this.geocercaService.getList().snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(geocercas => {
      this.geocercas = geocercas;

      this.centerMap();
    });
  }

  toggleGeocercas() {
    this.showGeocercas = !this.showGeocercas;
    if (this.showGeocercas)
      this.showGeocercasText = "Ocultar geocercas";
    else
      this.showGeocercasText = "Exibir geocercas";
  }
}
