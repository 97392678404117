import { firestore } from "firebase";

export class ChartDash {
    id: string;
    meta_color: string;
    main_color: string;
    default_title: string;
    title: string;
    use_title: boolean;
    type: string;
    category: number; // 1 - operacao 2 - manutencao
    format: number; // 1 - pizza 2 - barra 3 - board
    model: boolean; // se é modelo pra criar o gráfico
    isPeriodChecked: boolean; // se agrega pelo inicio ou final da viagem
    start_time: number;
    end_time: number;
    active: boolean;
    within_goal_color: string;
    above_goal_color: string;
    below_goal_color: string;
    interval: number; // tipo do intervalo
    movel: number; // dias para o calculo da media
    movel_days: number; // dias a serem mostrados
    interval_date: firestore.Timestamp[];
    goal: number;
    negative_tolerance: number;
    positive_tolerance: number;
    fleet_id: string;
    fleet_name: string;
    equipment_id: string;
    equipment_name: string;
    material_id: string;
    material_name: string;
    unit: number;
    meta: number[] = new Array(40)
}
