<div fxLayout="column" fxLayoutAlign="center center">
    <mat-card-header>
        <mat-card-title>Configurações dos filtros de Estados</mat-card-title>
    </mat-card-header>

    <mat-form-field class="example-full-width">
        <mat-label>Frota</mat-label>
        <mat-select [(value)]="contingency.fleet" (selectionChange)="onFleetChange()">
            <mat-option *ngFor="let fleet of fleets" [value]="fleet.id">
                {{fleet.name}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <div class="list">
        <div *ngFor="let filter of contingency.filters">
            <app-filter-record-item [contingency]='filter'></app-filter-record-item>
        </div>
    </div>

    <button mat-raised-button color="primary" type="button" class="btn-block" (click)="onSave()">Salvar</button>
</div>