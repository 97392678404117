import { Component, OnInit, Input } from '@angular/core';
import { Fleet } from '../fleet.model';
import { FleetService } from '../fleet.service';
import { Router } from '@angular/router';

export interface FleetDialogData {
  fleet: string;
  icon: string;
  name: string;
}

@Component({
  selector: 'app-fleet-details',
  templateUrl: './fleet-details.component.html',
  styleUrls: ['./fleet-details.component.scss']
})
export class FleetDetailsComponent implements OnInit {

  @Input() fleet: Fleet;
 
  constructor(
    private router: Router,
    private fleetService: FleetService) { }
 
  ngOnInit() {
  }
   
  inactivate() {
    this.fleetService
      .update(this.fleet.id, { active: false })
      .catch(err => console.log(err));
  }

  edit() {
    window.localStorage.removeItem("editFleetObj");
    localStorage.setItem('editFleetObj', JSON.stringify(this.fleet));
    this.router.navigate(['fleet-update']);
  }
}
