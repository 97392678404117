export class ContingencyGeocercaItem {
    carga: boolean;
    descarga: boolean;
    all: boolean;
    automatic: boolean;

    constructor(
        carga: boolean,
        descarga: boolean,
        all: boolean,
        automatic: boolean
    ) {
        this.carga = carga;
        this.descarga = descarga;
        this.all = all;
        this.automatic = automatic;
    }
}
