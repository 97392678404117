<h1 mat-dialog-title>Início {{data.trip.date_start.toDate() | date:'dd/MM/yyyy - HH:mm:ss'}},
  Fim {{data.trip.date_end.toDate() | date:'dd/MM/yyyy - HH:mm:ss'}}
</h1>
<div mat-dialog-content>
  <agm-map (mapReady)="mapReady($event)" [latitude]="data.trip.location_start.latitude"
    [longitude]="data.trip.location_start.longitude" [zoom]="16" [fullscreenControl]='true' [mapTypeControl]='true'
    [mapTypeId]="'satellite'">

    <agm-marker [latitude]="data.locations[0].position.latitude" [longitude]="data.locations[0].position.longitude">
    </agm-marker>

    <agm-polyline [strokeColor]="'#fff'">
      <agm-polyline-point *ngFor="let location of data.locations" [latitude]="location.position.latitude"
        [longitude]="location.position.longitude">
      </agm-polyline-point>

    </agm-polyline>

    <agm-polygon [paths]="geocerca.points" *ngFor="let geocerca of data.geocercas" [fillColor]="'#' + geocerca.color"
      [strokeColor]="'#' + geocerca.color">
    </agm-polygon>

  </agm-map>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Fechar</button>
</div>