import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Profile } from '../profile.model';
import { ProfileService } from '../profile.service';

@Component({
  selector: 'app-profile-item',
  templateUrl: './profile-item.component.html',
  styleUrls: ['./profile-item.component.scss']
})
export class ProfileItemComponent implements OnInit {

  @Input() profile: Profile;

  constructor(
    private router: Router,
    private profileService: ProfileService) { }

  ngOnInit() {
  }

  delete() {
    this.profileService
      .delete(this.profile.id)
      .catch(err => console.log(err));
  }

  edit() {
    window.localStorage.removeItem("editProfileObj");
    localStorage.setItem('editProfileObj', JSON.stringify(this.profile));
    this.router.navigate(['profile-create']);
  }
}
