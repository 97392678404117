export class Shift {
    active: boolean = true;
    id: string;
    day: number;
    label: string;
    start: number;
    end: number;
    toleranceStart: number;
    toleranceEnd: number;
}
